import React, {useEffect, useState} from "react";
import ShoppingCartCard from './Card';
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    ticketList: {
        maxHeight: 500, minHeight: 400, overflow: "scroll",
    },
}));

// function addToGroup(groups, ticket, lotteryList, group) {
//     if (!group) {
//         const lottery_name = lotteryList.find((l) => l.value === ticket.lottery_id).label;
//
//         group = {
//             lottery_id: ticket.lottery_id, tickets: [], lottery_name, source: ticket.source, count: 0, ticket_price: 0,
//         };
//         groups.push(group);
//     }
//     group.tickets.push(ticket);
//     group.count = group.count + 1;
//     group.ticket_price = group.ticket_price + ticket.ticket_price;
// }

const ShoppingCart = ({
                          lotteryList,
                          selectedTickets,
                          setSelected,
                          handleDelete,
                          handleQuickDelete,
                          quantity,
                          price,
                          availableTickets,
                          setAvailableTicketQty,
                          availableTicketsQty,
                          lotteryId,
                          ticketSource
                      }) => {
    const classes = useStyles();
    const [rows, setRows] = useState([]);

    useEffect(() => {
        const groups = [];
        selectedTickets.forEach((ticket) => {
            if (ticket.created_at) {
                console.log("created_at available");
            } else {
                console.log("created_at not available");
            }

            if (ticket.source === 'quick_buy') {
                let group = groups.find((g) => g.lottery_id === ticket.lottery_id && g.source === 'quick_buy');
                if (!group) {
                    const lottery_name = lotteryList.find((l) => l.value === ticket.lottery_id).label;
                    group = {
                        lottery_id: ticket.lottery_id,
                        lottery_name,
                        draw_id: ticket.draw_id,
                        source: 'quick_buy',
                        count: 0,
                        tickets_price: 0,
                    };
                    groups.push(group);
                }
                group.count = group.count + ticket.quantity;
                group.tickets_price = group.tickets_price + ticket.tickets_price;
            } else if (ticket.source !== 'quick_buy') {
                let group = groups.find((g) => g.lottery_id === ticket.lottery_id && g.source === undefined);
                if (!group) {
                    const lottery_name = lotteryList.find((l) => l.value === ticket.lottery_id).label;
                    group = {
                        lottery_id: ticket.lottery_id,
                        tickets: [],
                        lottery_name,
                        source: undefined,
                        draw_number: ticket.draw_number,
                        count: 0,
                        ticket_price: 0,
                    };
                    groups.push(group);
                }
                group.tickets.push(ticket);
                group.count = group.count + 1;
                group.ticket_price = group.ticket_price + ticket.ticket_price;
            }
        });
        setRows(groups);
    }, [selectedTickets, lotteryList]);

    useEffect(() => {
        try {
            if (quantity > 0 && quantity === availableTicketsQty) {
                setSelected([...availableTickets, ...selectedTickets]);
                setAvailableTicketQty(0);
            }
        } catch (error) {
            console.error("Error in useEffect:", error);
        }
    }, [availableTicketsQty, lotteryList]);

    return (<ShoppingCartCard rows={rows} setRows={setRows} handleDelete={handleDelete}
                              handleQuickDelete={handleQuickDelete} quantity={quantity}
                              price={price}
                              availableTickets={availableTickets}
                              lotteryList={lotteryList}/>);
};

export default ShoppingCart;