import React from "react";
import {useIntl} from "react-intl";
import {DialogModal, Grid, Stepper} from "app/uielements";
import useStyles from "./AddTickets.styles";
import ImportTickets from "./ImportTickets";
import DisplayImport from "./DisplayImport";
import useAddTickets from "./AddTickets.state";

const AddTickets = ({open, handleClose, id, lottery_id, lotteries}) => {
  const classes = useStyles();

  const {
    loading,
    uploadTickets,
    setFile,
    step,
    setStep,
    primaryDisabled,
    data,
    processImport,
    ...rest
  } = useAddTickets({handleClose, draw_id: id, lottery_id});

  const intl = useIntl();


  const format = id =>
    intl.formatMessage({
      id: `tickets.add.${id}`
    });

  const handleBack = () => {
    if (step === 0) {
      handleClose();
    } else {
      setStep(prevActiveStep => prevActiveStep - 1);
    }
  };

  const handleChange = files => setFile(files[0]);

  const steps = [
    {label: format("upload"), optional: false},
    {label: format("verify"), optional: false}
  ];

  const handleNext = async () => {
    if (step === 0) {
      await uploadTickets();
    } else {
      await processImport();
    }
  };

  return (
    <DialogModal
      isOpen={open}
      handleClose={handleClose}
      handlePrimaryButton={handleNext}
      handleSecondaryButton={handleBack}
      primaryButtonLabel={step === 2 ? format("finish") : format("continue")}
      secondaryButtonLabel={step === 0 ? format("cancel") : format("back")}
      primaryDisabled={primaryDisabled}
      title={format("title")}
      loading={loading}
    >
      <div className={classes.modelRoot}>
        <Grid
          container
          direction="column"
          justify="space-between"
          alignItems="center"
          className={classes.modelContent}
        >
          {step == 0 && (
            <ImportTickets loading={loading} handleChange={handleChange} />
          )}
          {step == 1 && <DisplayImport lotteries={lotteries} {...rest} loading={loading} lotteryList={data} />}
          <Stepper steps={steps} activeStep={step} />
        </Grid>
      </div>
      <div />
    </DialogModal>
  );
};

export default AddTickets;
