import {
  fetchError,
  fetchStart,
  fetchSuccess,
} from "../../../redux/store/common/actions";
import {
  setAuthUser,
  setForgetPassMailSent,
  updateLoadUser,
  logInUser,
  registerUser,
  sendVerifyEmail,
  logOutUser,
} from "../../../redux/store/auth/actions";
import * as t from "../../../redux/store/auth/actionTypes";
import React from "react";

let userProfile = null;
export const securityMiddleware = () => (next) => (action) => {
  if (
    action.type === t.LOGIN_USER_SUCCESS ||
    action.type === t.REGISTER_USER_SUCCESS
  ) {
    const { data } = action.payload;
    if (data) {
      if (
        data.role !== "admin" &&
        data.role !== "staff1" &&
        data.role !== "staff2" &&
        data.role !== "dialog" &&
        data.role !== "agent" &&
        process.env.REACT_APP_ONLY_ADMIN === "true"
      ) {
        window.location.href = "/signin";
      } else {
        // This is just temporary. There is a vulnerability in projection. Must change this later.
        localStorage.setItem("atkn", data.token);
        localStorage.setItem("user", JSON.stringify(data.user));
        localStorage.setItem("permissions", JSON.stringify(data.permissions));
        localStorage.setItem("role", data.role);
        localStorage.setItem("agentCode", data.agentCode);

        userProfile = data.user;
      }
    }
  }
  if (action.type === t.FETCH_USER_SUCCESS) {
    const { data } = action.payload;
    if (data) {
      // This is just temporary. There is a vulnerability in projection. Must change this later.
      // localStorage.setItem("atkn", data.token);
      localStorage.setItem("user", JSON.stringify(data));
      userProfile = data.user;
    }
  }

  if (action.type === t.LOGOUT_USER_SUCCESS) {
    localStorage.removeItem("atkn");
    localStorage.removeItem("user");
    localStorage.removeItem("permissions");
  }
  return next(action);
};

const BasicAuth = {
  onRegister: (userObj) => {
    return async (dispatch) => {
      dispatch(fetchStart());
      try {
        await dispatch(registerUser(userObj));
        dispatch(sendVerifyEmail());
        dispatch(fetchSuccess());
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },

  onLogin: (user) => {
    return async (dispatch) => {
      try {
        dispatch(fetchStart());
        await dispatch(logInUser(user));
        dispatch(fetchSuccess());
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },
  onLogout: () => {
    return (dispatch) => {
      dispatch(fetchStart());

      setTimeout(() => {
        dispatch(logOutUser());
        dispatch(fetchSuccess());
        dispatch(setAuthUser(null));
      }, 300);
    };
  },

  getAuthUser: (loaded = false) => {
    return (dispatch) => {
      dispatch(fetchStart());
      dispatch(updateLoadUser(loaded));

      setTimeout(() => {
        dispatch(fetchSuccess());
        if (localStorage.getItem("user") !== "undefined") {
          dispatch(setAuthUser(JSON.parse(localStorage.getItem("user"))));
        } else {
          dispatch(setAuthUser(null));
        }
      }, 300);
    };
  },

  onForgotPassword: () => {
    return (dispatch) => {
      dispatch(fetchStart());

      setTimeout(() => {
        dispatch(setForgetPassMailSent(true));
        dispatch(fetchSuccess());
      }, 300);
    };
  },

  onResendEmail: () => {
    return (dispatch) => {
      dispatch(fetchStart());
      setTimeout(() => {
        dispatch(sendVerifyEmail());
        dispatch(fetchSuccess());
      }, 300);
    };
  },

  getSocialMediaIcons: () => {
    return <React.Fragment> </React.Fragment>;
  },
};

export default BasicAuth;
