import React from "react";

import PageContainer from "@jumbo/components/PageComponents/layouts/PageContainer";
import IntlMessages from "@jumbo/utils/IntlMessages";
import { Backdrop, Box, CircularProgress, Grid } from "@material-ui/core";
import { useParams } from "react-router-dom";
import useStyles from "./Styles";
import SearchReport from "./SearchReport";
import useData from "./State";
import useLotteryList from "app/hooks/useLotteries";
import { useIntl } from "react-intl";
import Results from "./Results";

const AgentViewReport = ({}) => {
  let { status } = useParams();
  console.log("status",status)
  const intl = useIntl();
  const breadcrumbs = [
    { label: "Home", link: "/" },
    { label: <IntlMessages id="reports.title" />, link: "agent/reports" },
    {
      label: <IntlMessages id={"reports.view.title"} />,
      link: "agent/reports/" + status,
      isActive: true,
    },
  ];

  const classes = useStyles();
  const { lotteryList } = useLotteryList();

  const {
    register,
    getValues,
    errors,
    setValue,
    onReset,
    onSearch,
    onDownload,
    loading,
    rows,
    total,
    pageSize,
    handlePageChange,
    handlePageCountChange,
    handleSortModelChange,
    sortModel,
    handleFilterChange
  } = useData({ status });
  return (
    <PageContainer
      heading={<IntlMessages id="reports.view.title" />}
      breadcrumbs={breadcrumbs}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} xl={12} className={classes.orderLg2}>
          <Box mb={6}>
            <SearchReport
              getValues={getValues}
              onSearch={onSearch}
              onReset={onReset}
              register={register}
              setValue={setValue}
              errors={errors}
              title={intl.formatMessage({ id: "reports.type." + status })}
              lotteries={lotteryList}
              onDownload={onDownload}
              status={status}
            />
          </Box>
          <Box>
            <Results
              title={<IntlMessages id="reports.view.result.title" />}
              rows={rows}
              total={total}
              pageSize={pageSize}
              loading={loading}
              handlePageChange={handlePageChange}
              handlePageCountChange={handlePageCountChange}
              handleSortModelChange={handleSortModelChange}
              sortModel={sortModel}
              handleFilterChange={handleFilterChange}
              status={status}
            />
          </Box>
        </Grid>
      </Grid>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </PageContainer>
  );
};

export default AgentViewReport;
