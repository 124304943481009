import useAxios from "app/hooks/useAxios";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { transformInToFormObject } from "services/utils";
import showAlert from "app/hooks/useAlerts";
import moment from "moment";

const useData = ({ handleClose, id, data = {} }) => {
  const [primaryDisabled, setPrimaryDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [mobileList, setMobileList] = useState([]);

  const axios = useAxios();

  const defaultValues = {
    package_name: "daily",
    run_date: null,
    channel: "crm",
    ticket_quantity: "",
    start_time: null,
    mobile_no: null
  };

  const {
    register,
    getValues,
    formState: { errors },
    setValue,
    handleSubmit,
    reset,
  } = useForm({
    defaultValues,
  });

  useEffect(() => {
    let active = true;
    setLoading(true);
    (async () => {
      try {
        const response = await axios.post("/api/user/view/" + id);
        setLoading(false);

        if (!active) return;
        if (response.status === 200) {
          const user = response.data.user;
          setMobileList(
            response.data.mobiles.map((m) => ({
              value: '94' + m.mobile_no,
              label: '94' + m.mobile_no,
            }))
          );

          if (response.data.mobiles.length === 0) {
            setMobileList([
              {
                value: '94' + user.telephone,
                label: '94' + user.telephone,
              },
            ]);
          }
        }
      } catch (error) {
        setLoading(false);
      }
    })();

    return () => {
      active = false;
    };
  }, []);


  useEffect(() => {
    let active = true;

    if (active && data && data.id) {
      reset({ ...data });
    }

    return () => {
      active = false;
    };
  }, [data]);

  const save = async (data) => {
    setLoading(true);
    const formData = transformInToFormObject({
      ...data,
      start_time: moment(data.start_time).format("yyyy-MM-DD"),
      user_id: id,
    });
    try {
      const response = await axios.post(
        "/api/user/subscription/update/" + data.id,
        formData
      );
      setLoading(false);
      if (response.status === 200) {
        showAlert("success", "Subscription updated successfully.");
        handleClose(data);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const submit = handleSubmit(save);

  return {
    primaryDisabled,
    getValues,
    register,
    errors,
    loading,
    setValue,
    submit,
    reset,
    mobileList
  };
};

export default useData;
