import IntlMessages from "@jumbo/utils/IntlMessages";
import useAxios from "app/hooks/useAxios";
import React, { useState } from "react";
import FileSaver from "file-saver";
import { Button, Paper, Typography } from "@material-ui/core";
import { Loader, Select } from "app/uielements";
import useStyles from "./Download.styles";
import showAlert from "app/hooks/useAlerts";
import { useIntl } from "react-intl";

const DownloadCard = ({ status, draw_id, includeDBF }) => {
  const [type, setType] = useState("");
  const [loading, setLoading] = useState(false);
  const axios = useAxios();
  const classes = useStyles();
  const intl = useIntl();
  const download = () => {


    if(!type) {
      showAlert('error', intl.formatMessage({id: "draws.download.errors.type"}))
      return;
    }

    setLoading(true);
    axios
      .post(
        `/api/ticket/excel/download`,
        { draw_id, status, type },
        { responseType: "blob" }
      )
      .then((response) => {
        // Log somewhat to show that the browser actually exposes the custom HTTP header
        const fileNameHeader = "x-suggested-filename";
        const suggestedFileName = response.headers[fileNameHeader];
        const effectiveFileName =
          suggestedFileName === undefined
            ? `draws_${draw_id}_${status}.${type}`
            : suggestedFileName;

        // Let the user save the file.
        FileSaver.saveAs(response.data, effectiveFileName);
        setLoading(false);
      })
      .catch((response) => {
        console.error(
          "Could not Download the report from the backend.",
          response
        );
        setLoading(false);
      });
  };

  const downloadTypes = [
    {
      value: "csv",
      label: <IntlMessages id="draws.download.csv" />,
    },
    {
      value: "xlsx",
      label: <IntlMessages id="draws.download.xlsx" />,
    },
    {
      value: "xls",
      label: <IntlMessages id="draws.download.xls" />,
    },
    {
      value: "pdf",
      label: <IntlMessages id="draws.download.pdf" />,
    },
  ];

  if (includeDBF) {
    downloadTypes.push({
      value: "dbf",
      label: <IntlMessages id="draws.download.dbf" />,
    });
  }

  const handleChange = (evt) => {
    setType(evt.target.value);
  };
  return (
    <Paper className={classes.paper}>
      {loading && <Loader />}
      <Typography variant="subtitle1" gutterBottom>
        <IntlMessages id={`draws.download.${status}`} />
      </Typography>
      <Select
        name="FileType"
        options={downloadTypes}
        onChange={handleChange}
        error={!type}
      />
      <Button onClick={download}>
        <IntlMessages id="draws.download" />
      </Button>
    </Paper>
  );
};

export default DownloadCard;
