import {Grid} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {DataGrid} from "@material-ui/data-grid";
import useData from "./SelectTickets.state";
import {Loader, TextField} from "app/uielements";
import CmtCard from "@coremat/CmtCard";
import CmtCardHeader from "@coremat/CmtCard/CmtCardHeader";
import IntlMessages from "@jumbo/utils/IntlMessages";
import CmtCardContent from "@coremat/CmtCard/CmtCardContent";
import {Box, Button} from "@material-ui/core";
import SelectTickets from "./SelectTickets";
import ShoppingCart from "./ShoppingCart";
import Balance from "./Balance";
import axios from 'axios';
import useStyles from "./Styles";

const QuickBuy = ({
                      setSelected,
                      selectedTickets,
                      lotteryList,
                      handleDelete,
                      handleQuickDelete,
                      selected,
                      winning,
                      wallet,
                      genie,
                      getValues,
                      setValue,
                      mobileList,
                      enableOno,
                      premium,
                      lotteryId,
                      tabId,
                      draw,
                      userId
                  }) => {

    const [showComponent, setShowComponent] = useState(false);
    const [number, setNumber] = useState(0);
    const [availableTickets, setAvailableTickets] = useState([]);
    const [availableTicketsQty, setAvailableTicketsQty] = useState([]);
    const [availableMessage, setAvailableMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const ticketSource = 'quick_buy'

    console.log('setAvailableTicketsQty', availableTicketsQty)
    console.log('LotteryList', lotteryList);

    // const [isButtonsDisabled, setIsButtonsDisabled] = React.useState(false);
    // const [ticketIds, setTicketIds] = React.useState([]);
    console.log("lottery", lotteryId)
    const handleInputChange = (e) => {
        const inputValue = e.target.value.replace(/[^0-9]/g, '');
        setNumber(parseInt(inputValue));
        // setShowComponent(false);
    };


    console.log('availableTicketsselected', availableTickets)
    console.log('quickselectedselectedTickets', availableTickets)

    const handleClick = () => {
        // setShowComponent(true);
    };
    console.log('userid', userId)
    const classes = useStyles();

    const handleChange = (event) => {
        setNumber(parseInt(event.target.value));
        setShowComponent(false);
    };
    const price = () => {
        return number * 40;
    }

    const isButtonDisabled = number === 0 || isNaN(number);

    const handleClicks = async () => {
        try {
            setLoading(true);

            const response = await axios.post('/api/ticket_bulk_qty_enough_test/lock', {
                lottery_id: lotteryId, draw_id: draw, quantity: number, user_id: userId, tab_id: tabId,
            });

            const {locked_qty, draw_id, lottery_id, source, tickets_price, available_qty} = response.data;
            setLoading(true);
            setShowComponent(locked_qty === number);
            setLoading(false);
            setAvailableTickets([{
                'lottery_id': lottery_id,
                'quantity': locked_qty,
                'source': source,
                'draw_id': draw_id,
                'tickets_price': tickets_price,
            }]);
            setAvailableTicketsQty(locked_qty);

            if (available_qty < number) {
                setAvailableMessage(`Only   ${available_qty} Tickets are Available`);
                setNumber(" ");
                console.log('Available ticket quantity :', available_qty);
            } else {
                setAvailableMessage('  ')
            }
        } catch (error) {
            console.error('Error making API call:', error);
        } finally {

        }
    };
    const quickBuyCart = () => {
        if (showComponent || selectedTickets.length > 0) {

            return (<ShoppingCart
                className={classes.formColumn}
                lotteryList={lotteryList}
                handleDelete={handleDelete}
                handleQuickDelete={handleQuickDelete}
                quantity={number}
                // price={price()}
                availableTickets={availableTickets}
                availableTicketsQty={availableTicketsQty}
                setAvailableTicketQty={setAvailableTicketsQty}
                selectedTickets={selectedTickets}
                setSelected={setSelected}
                lotteryId={lotteryId}
                ticketSource={ticketSource}
                // mergedTickets={mergedTickets}
            />);
        }
        return null;
    };
    // console.log('price',price)

    const quickBuyBalance = () => {
        if (showComponent || selectedTickets.length > 0) {
            return (<Balance
                className={classes.formColumn}
                selected={selected}
                winning={winning}
                wallet={wallet}
                genie={genie}
                getValues={getValues}
                setValue={setValue}
                mobileList={mobileList}
                enableOno={enableOno}
                premium={premium}
                //  price={price()}
                quantity={number}
                availableTickets={availableTickets}
            />);
        }
        return null;
    };

    return (<CmtCard>
        <CmtCardContent>
            <Grid container>
                <Grid container item>
                    <CmtCardHeader title={'Number of Tickets'}/>
                </Grid>
                <Grid container item>
                    <Box m={5} style={{width: "1500px"}}>
                        <Box>
                            <TextField
                                value={isNaN(number) || number === 0 ? '' : String(number)}
                                onChange={handleChange}
                                variant="outlined"
                                type="text"
                                inputProps={{
                                    inputMode: 'numeric', pattern: '[0-9]*', onChange: handleInputChange,
                                }}
                            />
                        </Box>
                        <Box>
                            <Button
                                onClick={handleClicks}
                                // onChange={handleClicks}
                                variant="contained"
                                color="primary"
                                m={5}
                                disabled={isButtonDisabled}
                            >
                                OK
                            </Button>
                        </Box>
                        {availableMessage && <p style={{color: "red"}}>{availableMessage}</p>}
                        {loading && <Loader/>}
                        <Box mt={4}>{quickBuyCart()}</Box>
                        <Box mt={4}>{quickBuyBalance()}</Box>
                    </Box>
                </Grid>
            </Grid>
        </CmtCardContent>
    </CmtCard>);
};

export default QuickBuy;

