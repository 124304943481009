import React from "react";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import PropTypes from "prop-types";

import useStyles from "./Select.styles";

const Select = React.forwardRef(({
  id,
  label,
  value,
  onChange,
  helperText,
  native,
  variant,
  options,
  ...props
}, ref) => {
  const classes = useStyles();
  return (
    <TextField
      classes={classes}
      id={id}
      select
      label={label}
      ref={ref}
      value={value}
      onChange={onChange}
      helperText={helperText}
      SelectProps={{
        native: native,
      }}
      variant={variant}
      {...props}
    >
      {options && options.map((option) => {
        return native ? (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ) : (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        );
      })}
    </TextField>
  );
});

Select.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func.isRequired,
  helperText: PropTypes.string,
  native: PropTypes.bool,
  variant: PropTypes.string,
  options: PropTypes.array.isRequired,
};

Select.defaultProps = {
  id: "0",
  helperText: "",
  native: false,
  variant: "standard",
  options: []
};

export default Select;
