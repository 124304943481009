import React , { useEffect, useState }from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Card, CardContent, Typography } from '@material-ui/core';

// import Grid from "@material-ui/core/Grid";
import PerfectScrollbar from 'react-perfect-scrollbar';

import axios from "axios";
import {
  BasicInfoCard,
  InformationPanel,
  Avatar,
  AvatarWithName,
} from "../uielements";

import usePermissions from "app/hooks/usePermissions";


const { applyPermissions, permissions } = usePermissions();


const AgentRevenue = () => {
  const [amount, setAmount] = useState('');
  const [userId, setUserId] = useState(null);
  const [blocking, setBlocking] = useState(false);

  
  

  
  const useStyles = makeStyles({
    root: {
      minWidth: 275,
      minHeight: 150,
    },
    scrollbarRoot: {
      height: 390,
      '& .CmtList-EmptyResult': {
        backgroundColor: 'transparent',
        border: '0 none',
      },
    },
  });

  const classes = useStyles();
  

  const breadcrumbs = [
    { label: "Home", link: "/" },
    { label: "Sample Page", isActive: true },
  ];

  const getContent = () => {
    return (
      <AvatarWithName
        alt="Cinnamon Chip Wealth Promoters (Pvt) Ltd"
        src="/static/images/avatar/1.jpg"
        name="Cinnamon Chip Wealth Promoters (Pvt) Ltd"
      />
    );
  };

 
  const renderDraws = () => {
    return (
      <PerfectScrollbar className={classes.scrollbarRoot}>
        
      </PerfectScrollbar>
    );
  };

  return (
    <div>
      <Grid container spacing={3}>
       
 

        <Grid container spacing={3}>
   
      <Grid item xs={4} sm={4} md={4}>
      <BasicInfoCard
              cardObj={{
                total: "",
                newValue:  amount ,
                type: "Today Revenue", 
              }}
              name="Today Revenue"
            />
      </Grid>
      
      <Grid item xs={4} sm={4} md={4}>
      {(localStorage.getItem("role") != "dialog") ?(   
            <BasicInfoCard
              cardObj={{
                total: "",
                // newValue: (data?.sales?.totalsales || 0),
                type: "Pending"
              }}
              name="Pending"
            /> ):("")}
      </Grid>
      <Grid item xs={4} sm={4} md={4}>
      {(localStorage.getItem("role") != "dialog") ?(   
            <BasicInfoCard
              cardObj={{
                total: "",
                // newValue: (data?.sales?.totalsales || 0),
                type: "Settled"
              }}
              name="Settled"
            /> ):("")}
      </Grid>
      
    </Grid>


       

  
</Grid>
      
    </div>
  );
};

export default AgentRevenue;
