import React from "react";

import LotteryTable from "./LotteryTable";

import makeStyles from "@material-ui/core/styles/makeStyles";
import CmtCard from "@coremat/CmtCard";

import CmtCardContent from "@coremat/CmtCard/CmtCardContent";


const useStyles = makeStyles(theme => ({
  cardContentRoot: {
    padding: "0 !important"
  },
  titleRoot: {
    letterSpacing: 0.15
  },
  scrollbarRoot: {
    height: "70vh"
  }
}));

const LotteryList = ({
  data,
  total,
  pageSize,
  page,
  handlePageChange,
  handlePageCountChange,
  handleMenu,
  handleRefresh
}) => {
  const classes = useStyles();

  return (
    <CmtCard>
      
      <CmtCardContent className={classes.cardContentRoot}>
        <LotteryTable
          tableData={data}
          total={total}
          page={page}
          pageSize={pageSize}
          handlePageChange={handlePageChange}
          handlePageCountChange={handlePageCountChange}
          handleMenu={handleMenu}
          handleRefresh={handleRefresh}
        />
      </CmtCardContent>
    </CmtCard>
  );
};

export default LotteryList;
