import React, { useState, useEffect } from "react";
import useAxios from "../../hooks/useAxios";
const useRows = () => {
  const axios = useAxios();

  const [id, setId] = useState(0);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState(0);
  const [rows, setRows] = useState([]);
  const [walletBalance, setWalletBalance] = useState([]);
  const [holdbalance, setHoldbalance] = useState([]);  
  const [loading, setLoading] = useState(false);

  const handlePageChange = (params) => {
    setPage(params.page);
  };

  const handlePageCountChange = (params) => {
    setPageSize(params.pageSize);
    setPage(0);
  };

  useEffect(() => {
    let active = true;
    if(id === 0) return;
    (async () => {
      try {
        const responseWalletBalance = await axios.get(
          `/api/user/credit/balance/${id}`
        );
        setWalletBalance(responseWalletBalance.data.acccount_balance);
        setHoldbalance(responseWalletBalance.data.hold_balance);
      } catch (error) {}

      if (!active) {
        return;
      }
    })();
    return () => {
      active = false;
    };
  }, [id]);

  useEffect(() => {
    let active = true;

    (async () => {
      if (id < 1) return;
      setLoading(true);
      const response = await axios.post(
        `/api/user/wallet/${id}?page=${page + 1}`,
        {
          page_count: pageSize,
        }
      );
      const newRows = response.data.data;
      const totalValue = response.data.total;

      if (!active) {
        return;
      }

      console.log(newRows);
      setRows(newRows);
      setTotal(totalValue);

      setLoading(false);
    })();

    return () => {
      active = false;
    };
  }, [page, total, pageSize, id]);

  return {
    rows,
    walletBalance,
    holdbalance,
    total,
    loading,
    pageSize,
    handlePageChange,
    handlePageCountChange,
    setRows,
    setId,
  };
};

export default useRows;
