import {makeStyles} from "@material-ui/core/styles";


const useStyles = makeStyles(theme => ({
 grid: {
     height: '640px',
     padding: theme.spacing(2),
     width: "100%"
 },
 root: {
    paddingBottom: 24,
  },
  perScrollbarRoot: {
    maxHeight: 540,
  },
}));

export default useStyles;
