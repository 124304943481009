import React, { useState } from "react";
import useDraws from "./DrawList.state";
import { useIntl } from "react-intl";

import ContextMenu from "app/uielements/ContextMenu";
import DataGrid from "app/uielements/Table/DataGrid";
import TicketList from "../Tickets";
import Download from "../Download";
import Returns from "../Returns";
import Winners from "../Winners";
import AddTickets from "../AddTickets";
import EditDraw from "../EditDraw";
import TicketAutomation from "../TicketAutomation";
import AddWinnings from "../AddWinnings";
import moment from "moment";
import usePermissions from "app/hooks/usePermissions";
import { IconButton, Paper, Switch } from "@material-ui/core";
import { PlayArrow, StopRounded } from "@material-ui/icons";
import useStyles from './DrawList.styles';
import IntlMessages from "@jumbo/utils/IntlMessages";
const DrawList = ({ lotteries, total, setTotal }) => {
  const {
    rows,
    loading,
    pageSize,
    id,
    selected,
    setSelected,
    handlePageChange,
    handlePageCountChange,
    setId,
    updateRow,
    toggleDraw,
    toggleStatus,
    handleSortModelChange,
    sortModel,
    handleFilterChange,
    handleRefresh
  } = useDraws({ total, setTotal});
  const classes = useStyles();
  const intl = useIntl();
  const { applyPermissions } = usePermissions();
  const format = (id) =>
    intl.formatMessage({
      id: `draws.list.${id}`,
    });

  const rowRender = (rows, props) => {
    const available = !rows.count;
    const green = {
      backgroundColor: "rgb(55, 180, 0,0.32)",
    };
    const red = {
      backgroundColor: "rgb(243, 23, 0, 0.32)",
    };
    const trProps = {
      style: available ? green : red,
    };
  };

  const handleMenu = (evt, option, data) => {
    if (id !== evt) {
      setId(evt);
      setSelected(data);
    }

    if (option === format("options.view_tickets")) {
      setTicketListOpen(true);
    }

    if (option === format("options.download")) {
      setDownloadOpen(true);
    }

    if (option === format("options.view_return")) {
      setReturnsOpen(true);
    }

    if (option === format("options.view_winnings")) {
      setWinnersOpen(true);
    }

    if (option === format("options.add_tickets")) {
      setAddTicketsOpen(true);
    }

    if (option === format("options.edit")) {
      setEditOpen(true);
    }

    if (option === format("options.add_winnings")) {
      setAddWinningsOpen(true);
    }
    if (option === format("options.ticket_automation")) {
      setTicketAutomationOpen(true);
    }
  };

  const [ticketListOpen, setTicketListOpen] = useState(false);
  const [downloadOpen, setDownloadOpen] = useState(false);
  const [returnsOpen, setReturnsOpen] = useState(false);
  const [winnersOpen, setWinnersOpen] = useState(false);
  const [addTicketsOpen, setAddTicketsOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [addWiingsOpen, setAddWinningsOpen] = useState(false);
  const [ticketAutomationOpen, setTicketAutomationOpen] = useState(false);
  const handleClose = () => {
    setTicketListOpen(false);
    setDownloadOpen(false);
    setReturnsOpen(false);
    setWinnersOpen(false);
    setAddTicketsOpen(false);
    setAddWinningsOpen(false);
    setTicketAutomationOpen(false)
  };

  const handleEditClose = (saved) => {
    setEditOpen(false);
    saved && updateRow(selected);
  };

  const options = applyPermissions([
    { key: "draw.update", value: format("options.edit") },
    { key: "ticket.create", value: format("options.add_tickets") },
    { key: "ticket.view", value: format("options.view_tickets") },
    { key: "ticket.return", value: format("options.view_return") },
    { key: "winning.create", value: format("options.add_winnings") },
    { key: "winning.view", value: format("options.view_winnings") },
    { key: "download.tickets", value: format("options.download") },
    { key: "ticket.return", value: format("options.ticket_automation") },
  ]);

  const columns = [
    {
      field: "name",
      headerName: format("name"),
      flex: 1,
    },
    {
      field: "status",
      headerName: format("status"),
      flex: 1,
      renderCell: (params) => (
        <React.Fragment>
          <Switch
            checked={!!params.value}
            onClick={async (evt) => await toggleStatus(params.row.id, params.row)}
          />
        </React.Fragment>
      ),
    },
    {
      field: "lottery_id",
      headerName: format("lottery_id"),
      flex: 1,
      valueFormatter: (params) =>
        (
          lotteries.find((l) => l.value == params.value) || {
            label: params.value,
          }
        ).label,
    },
    // { field: "owner", headerName: format("created_by"), flex: 1 },
    {
      field: "issue_start_date",
      headerName: format("issue_start_date"),
      flex: 1,
      valueFormatter: (params) => moment(params.value).format("MM/DD/yyyy"),
    },
    {
      field: "issue_end_date",
      headerName: format("issue_end_date"),
      flex: 1,
      valueFormatter: (params) => moment(params.value).format("MM/DD/yyyy"),
    },
    { field: "draw_number", headerName: format("draw_number"), flex: 1 },
    { field: "available", headerName: format("available"), flex: 1 },
    { field: "returned", headerName: format("returned"), flex: 1 },
    {
      field: "total_count",
      headerName: format("available_buffer"),
      flex: 1,
    },
    {
      field: "id",
      headerName: format("action"),
      sortable:false,
      filterable:false,
      renderCell: (params) => (
        <React.Fragment>
          <ContextMenu
            options={options}
            onClick={(evt) => handleMenu(params.value, evt, params.row)}
          />
          <IconButton onClick={async (evt) => await toggleDraw(params.value, params.row)}>
            {params.row.stop_sell === 0 && <PlayArrow />}
            {params.row.stop_sell === 1 && <StopRounded />}
          </IconButton>
        </React.Fragment>
      ),
      flex: 1,
    },
  ];

  return (
    <React.Fragment>
      <Paper className={classes.grid}>
        <DataGrid
          title={<IntlMessages id="draws.list.title" />}
          showToolbar
          rows={rows}
          rowrender={rowRender}
          columns={columns}
          pagination
          pageSize={pageSize}
          rowsPerPageOptions={[10, 20, 50, 100]}
          rowCount={total}
          paginationMode="server"
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageCountChange}
          sortingMode="server"
          sortModel={sortModel}
          onSortModelChange={handleSortModelChange}
          filterMode="server"
          onFilterModelChange={handleFilterChange}
          loading={loading}
          getRowClassName={(params) =>
            `with-winning--${params.getValue(params.id, 'winning_id') ? 'present': 'not-present'}`
          }
          onRefresh={handleRefresh}
        />
      </Paper>
      {ticketListOpen && (
        <TicketList id={id} draw={selected} open={ticketListOpen} lotteries={lotteries} handleClose={handleClose} />
      )}
      {downloadOpen && (
        <Download id={id} open={downloadOpen} handleClose={handleClose} />
      )}
      {returnsOpen && (
        <Returns id={id} open={returnsOpen} handleClose={handleClose} />
      )}
      {winnersOpen && (
        <Winners id={id} open={winnersOpen} handleClose={handleClose} />
      )}
      {addWiingsOpen && (
        <AddWinnings
          id={id}
          lottery_id={selected.lottery_id}
          open={addWiingsOpen}
          handleClose={handleClose}
          draw={selected}
          lotteries={lotteries}
        />
      )}
      {editOpen && (
        <EditDraw
          data={selected}
          setData={updateRow}
          open={editOpen}
          lotteries={lotteries}
          handleClose={handleEditClose}
        />
      )}
      {addTicketsOpen && (
        <AddTickets
          id={id}
          lottery_id={selected.lottery_id}
          open={addTicketsOpen}
          handleClose={handleClose}
          lotteries={lotteries}
        />
      )}
       {ticketAutomationOpen && (
        <TicketAutomation
          id={id}
          lottery_id={selected.lottery_id}
          name={selected.name}
          open={ticketAutomationOpen}
          handleClose={handleClose}
          draw_number={selected.draw_number}
          lotteries={lotteries}
        />
      )}
    </React.Fragment>
  );
};

export default DrawList;
