import React from 'react';
import Box from '@material-ui/core/Box';
import DataTable from './DataTable';
import CmtCardHeader from '@coremat/CmtCard/CmtCardHeader';
import CmtCard from '@coremat/CmtCard';
import PerfectScrollbar from 'react-perfect-scrollbar';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {fade} from '@material-ui/core';
import IntlMessages from '@jumbo/utils/IntlMessages';

const useStyles = makeStyles(theme => ({
    cardRoot: {
        position: 'relative',
        '& .Cmt-card-content': {
            padding: 0,
            paddingBottom: 24,
        },
    },
    scrollbarRoot: {
        height: 280,
    },
    badgeRoot: {
        fontSize: 14,
        letterSpacing: 0.25,
        backgroundColor: fade(theme.palette.warning.main, 0.15),
        color: theme.palette.warning.main,
        padding: '2px 10px',
        borderRadius: 30,
    },
}));

const ShoppingCartCard = ({
                              rows,
                              handleDelete,
                              handleQuickDelete,
                              quantity,
                              price,
                              availableTickets,
                              lotteryList,
                              setRows,
                          }) => {
    const classes = useStyles();
    return (
        <CmtCard className={classes.cardRoot}>
            <CmtCardHeader title={<IntlMessages id="user.purchase.selected.title"/>}>
                <Box component="span" className={classes.badgeRoot}>
                    <IntlMessages id="user.purchase.selected.badge"/>
                </Box>
            </CmtCardHeader>
            <CmtCardContent>
                <PerfectScrollbar className={classes.scrollbarRoot}>
                    <DataTable data={rows} handleDelete={handleDelete} quantity={quantity} price={price}
                               availableTickets={availableTickets} lotteryList={lotteryList} setRows={setRows}/>
                </PerfectScrollbar>
            </CmtCardContent>
        </CmtCard>
    );
};

export default ShoppingCartCard;
