import usePermissions from "app/hooks/usePermissions";
import React, {Fragment} from "react";
import PropTypes from "prop-types";

const WithPermission = ({children, permissions}) => {
  const {hasSomePermissions} = usePermissions();
  return <Fragment>{hasSomePermissions(permissions) && children}</Fragment>;
};

WithPermission.propTypes = {
  permissions: PropTypes.array.isRequired
};

export default WithPermission;
