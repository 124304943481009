import React from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import {IconButton} from "@material-ui/core";
import {MoreVert} from "@material-ui/icons";

const ContextMenu = ({options, onClick}) => {
  const [ anchorEl, setAnchorEl ] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (evt, option) => {
    setAnchorEl(null);
    onClick(option);
  };

  return (
    <div>
      <IconButton
        aria-controls="customized-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVert />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {options.map(option => (
          <MenuItem key={option} onClick={evt => handleClose(evt, option)}>
            {option}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default ContextMenu;
