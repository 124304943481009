import React, { useState, useEffect } from "react";
import useAxios from "../../hooks/useAxios";
import throttle from "lodash/throttle";

const useDraws = ({ total, setTotal }) => {
  const axios = useAxios();

  const [page, setPage] = useState(0);
  const [id, setId] = useState(0);
  const [selected, setSelected] = useState({});
  const [pageSize, setPageSize] = useState(10);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);

  const [previousRequest, setPreviousRequest] = useState("");
  const [filterModel, setFilterModel] = React.useState({ items: [] });

  const [sortModel, setSortModel] = React.useState([
    { field: "issue_start_date", sort: "desc" },
  ]);

  const toggleDraw = async (drawId, row) => {
    try {
      let url = "";
      setLoading(true);
      if (row.stop_sell) {
        url = `/api/draw/stop/${drawId}`;
      } else {
        url = `/api/draw/start/${drawId}`;
      }
      const response = await axios.get(url);
      setLoading(false);

      if (response.status === 200) {
        updateRow({
          ...row,
          stop_sell: row.stop_sell === 0 ? 1 : 0,
        });
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const toggleStatus = async (drawId, row) => {
    try {
      let url = "";
      setLoading(true);
      if (!row.status) {
        url = `/api/draw/active/${drawId}`;
      } else {
        url = `/api/draw/inactive/${drawId}`;
      }
      const response = await axios.get(url);
      setLoading(false);

      if (response.status === 200) {
        updateRow({
          ...row,
          status: row.status === 0 ? 1 : 0,
        });
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const handleSortModelChange = (params) => {
    if (params.sortModel !== sortModel) {
      setSortModel(params.sortModel);
      setPage(0);
    }
  };

  const handleFilter = (params) => {
    setFilterModel(params.filterModel);
    setPage(0);
  };

  const handleFilterChange = React.useCallback(throttle(handleFilter, 500), []);

  const handlePageChange = (params) => {
    setPage(params.page);
  };

  const handlePageCountChange = (params) => {
    setPageSize(params.pageSize);
    setPage(0);
  };

  const updateRow = (data) => {
    const newRows = [...rows];

    const row = newRows.find((r) => r.id === data.id);
    Object.keys(row).forEach((key) => (row[key] = data[key]));

    setRows(newRows);
  };

  const buildPreviousRequest = ({ page, body }) => {
    return JSON.stringify({
      page,
      body,
    });
  };

  const refresh = async (active = true) => {
    setLoading(true);
    const body = {
      page_count: pageSize,
      sortModel,
      filterModel: {
        items: filterModel.items
          .filter((i) => i.value)
          .map((i) => ({
            ...i,
            columnField: `draws.${i.columnField}`,
          })),
      },
    };

    const currentRequest = buildPreviousRequest({ page, body });

    try {
      const response = await axios.post("/api/draw?page=" + (page + 1), body);
      const newRows = response.data.data;
      const totalValue = response.data.total;
console.log("drawslist",response.data.data)
      if (!active) {
        return;
      }

      setPreviousRequest(currentRequest);

      setRows(newRows);
      setTotal(totalValue);
      setLoading(false);
    } catch (error) {
      setLoading(false)
    }
  }

  const handleRefresh  = async (active) => {
    await refresh(active);
  }

  useEffect(() => {
    let active = true;

    (async () => {
      await refresh(active);
    })();

    return () => {
      active = false;
    };
  }, [page, total, pageSize, sortModel, filterModel]);

  return {
    rows,
    total,
    loading,
    pageSize,
    id,
    selected,
    setSelected,
    handlePageChange,
    handlePageCountChange,
    setId,
    updateRow,
    toggleDraw,
    toggleStatus,
    handleSortModelChange,
    sortModel,
    handleFilterChange,
    handleRefresh
  };
};

export default useDraws;
