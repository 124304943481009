import React from "react";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
} from "@material-ui/pickers";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import { Select, TextField } from "../../uielements";

import useStyles from "./CreateDraw.styles";

import { drawTypes } from "../utils";

import IntlMessages from "@jumbo/utils/IntlMessages";
import clsx from "clsx";
import useValidations from "./CreateDraw.Validations";

const CreateDraw = ({ lotteries, next, ...rest }, ref) => {
  const classes = useStyles();

  const { register, setValue, errors, getValues } = rest;

  const validations = useValidations({getValues, lotteries});

  const handleDateChange = (id, date) => {
    const data = {
      key: id,
      value: date,
    };
    setValue(data.key, data.value, { shouldValidate: true });
  };

  const handleOnChange = (event) => {
    const data = {
      key: event.target.id || event.target.name,
      value: event.target.value,
    };
    setValue(data.key, data.value, { shouldValidate: true });
  };

  return (
    <Grid
      container
      direction="row"
      justify="space-around"
      alignItems="flex-start"
      className={classes.newDrawWrapper}
    >
      <form ref={ref}>
        <Grid item xs={12}>
          <Typography variant="subtitle1" gutterBottom>
            <IntlMessages id="draws.add.title" />
          </Typography>
        </Grid>
        <Grid container item xs={12}>
          <Grid item xs={4} className={classes.formColumn}>
            <TextField
              required
              variant="outlined"
              id="draw_number"
              name="draw_number"
              label="Draw Number"
              InputProps={{ inputProps: { min: 0 } }}
              {...register("draw_number", {
                required: {
                  value: true,
                  message: <IntlMessages id="errors.required" />,
                },
              })}
              value={getValues().draw_number}
              onChange={handleOnChange}
              error={!!errors.draw_number}
              helperText={errors.draw_number?.message}
            />
            <TextField
              required
              {...register("name", {
                required: {
                  value: true,
                  message: <IntlMessages id="errors.required" />,
                },
              })}
              id="name"
              name="name"
              label="Name"
              variant="outlined"
              value={getValues().name}
              onChange={handleOnChange}
              error={!!errors.name}
              helperText={errors.name?.message}
            />
            <Select
              id="draw_type"
              name="draw_type"
              label="Draw Type"
              variant="outlined"
              {...register("draw_type", {
                required: {
                  value: true,
                  message: <IntlMessages id="errors.required" />,
                },
              })}
              value={getValues().draw_type}
              onChange={handleOnChange}
              options={drawTypes}
              error={!!errors.draw_type}
              helperText={errors.draw_type?.message}
            />
            <Select
              required
              variant="outlined"
              id="lottery_id"
              name="lottery_id"
              label="Lottery"
              InputProps={{ inputProps: { min: 0 } }}
              {...register("lottery_id", {
                required: {
                  value: true,
                  message: <IntlMessages id="errors.required" />,
                },
              })}
              value={getValues().lottery_id}
              onChange={handleOnChange}
              options={lotteries}
              error={!!errors.lottery_id}
              helperText={errors.lottery_id?.message}
            />
            <TextField
              required
              variant="outlined"
              id="winning_price"
              name="winning_price"
              label="Winning Price"
              InputProps={{ inputProps: { min: 0 } }}
              {...register("winning_price", {
                required: {
                  value: true,
                  message: <IntlMessages id="errors.required" />,
                },
              })}
              type="number"
              value={getValues().winning_price}
              onChange={handleOnChange}
              error={!!errors.winning_price}
              helperText={errors.winning_price?.message}
            />{" "}
          </Grid>
          <Grid item xs={4} className={classes.formColumn}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDateTimePicker
                required
                variant="inline"
                inputVariant="outlined"
                format="yyyy-MM-dd hh:mm a"
                margin="normal"
                id="issue_start_date"
                name="issue_start_date"
                label="Issue Start Date and Time"
                className={clsx(classes.fullWidth, classes.mt0)}
                {...register("issue_start_date", {
                  required: {
                    value: true,
                    message: <IntlMessages id="errors.required" />,
                  },
                  validate: validations.validateStartDate,
                })}
                value={getValues().issue_start_date}
                onChange={(val) => handleDateChange("issue_start_date", val)}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                autoOk
                minDate={new Date()}
                error={!!errors.issue_start_date}
                helperText={errors.issue_start_date?.message}
              />
              <KeyboardDateTimePicker
                required
                variant="inline"
                inputVariant="outlined"
                format="yyyy-MM-dd hh:mm a"
                margin="normal"
                id="issue_end_date"
                name="issue_end_date"
                label="Issue End Date and Time"
                className={clsx(classes.fullWidth, classes.mt0)}
                {...register("issue_end_date", {
                  required: {
                    value: true,
                    message: <IntlMessages id="errors.required" />,
                  },
                  validate: validations.validateEndDate,
                })}
                value={getValues().issue_end_date}
                onChange={(val) => handleDateChange("issue_end_date", val)}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                autoOk
                minDate={new Date()}
                error={!!errors.issue_end_date}
                helperText={errors.issue_end_date?.message}
              />
              <KeyboardDateTimePicker
                required
                variant="inline"
                inputVariant="outlined"
                format="yyyy-MM-dd hh:mm a"
                margin="normal"
                id="draw_date"
                name="draw_date"
                label="Draw Date and Time"
                className={clsx(classes.fullWidth, classes.mt0)}
                {...register("draw_date", {
                  required: {
                    value: true,
                    message: <IntlMessages id="errors.required" />,
                  },
                  validate: validations.validateDrawDate,
                })}
                value={getValues().draw_date}
                onChange={(val) => handleDateChange("draw_date", val)}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                autoOk
                minDate={new Date()}
                error={!!errors.draw_date}
                helperText={errors.draw_date?.message}
              />
              <KeyboardDateTimePicker
                required
                variant="inline"
                inputVariant="outlined"
                format="yyyy-MM-dd hh:mm a"
                margin="normal"
                id="returned_buffer_date"
                name="returned_buffer_date"
                label={<IntlMessages id="draws.add.returned_buffer_date" />}
                className={clsx(classes.fullWidth, classes.mt0)}
                {...register("returned_buffer_date", {
                  required: {
                    value: true,
                    message: <IntlMessages id="errors.required" />,
                  },
                  validate: validations.validateReturnDate,
                })}
                value={getValues().returned_buffer_date}
                onChange={(val) =>
                  handleDateChange("returned_buffer_date", val)
                }
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                autoOk
                minDate={new Date()}
                error={!!errors.returned_buffer_date}
                helperText={errors.returned_buffer_date?.message}
              />
              <KeyboardDateTimePicker
                required
                variant="inline"
                inputVariant="outlined"
                format="yyyy-MM-dd hh:mm a"
                margin="normal"
                id="subscription_start_date"
                name="subscription_start_date"
                label={<IntlMessages id="draws.add.subscription_start_date" />}
                className={clsx(classes.fullWidth, classes.mt0)}
                {...register("subscription_start_date", {
                  required: {
                    value: true,
                    message: <IntlMessages id="errors.required" />,
                  },
                  validate: validations.validateSubscriptionStartDate,
                })}
                value={getValues().subscription_start_date}
                onChange={(val) =>
                  handleDateChange("subscription_start_date", val)
                }
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                autoOk
                minDate={new Date()}
                error={!!errors.subscription_start_date}
                helperText={errors.subscription_start_date?.message}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={4} className={classes.formColumn}>
            <TextField
              required
              variant="outlined"
              id="selling_quantity"
              name="selling_quantity"
              label="Selling Quantity"
              {...register("selling_quantity", {
                required: {
                  value: true,
                  message: <IntlMessages id="errors.required" />,
                },
                validate: validations.validateBlocks,
              })}
              type="number"
              value={getValues().selling_quantity}
              onChange={handleOnChange}
              error={!!errors.selling_quantity}
              helperText={errors.selling_quantity?.message}
            />
            <TextField
              required
              variant="outlined"
              id="reserved_buffer"
              name="reserved_buffer"
              label="Reserved Count"
              {...register("reserved_buffer", {
                required: {
                  value: true,
                  message: <IntlMessages id="errors.required" />,
                },
                validate: validations.validateBlocks,
              })}
              inputProps={{ step: 20 }}
              type="number"
              value={getValues().reserved_buffer}
              onChange={handleOnChange}
              error={!!errors.reserved_buffer}
              helperText={errors.reserved_buffer?.message}
            />
            <TextField
              required
              variant="outlined"
              id="return_buffer"
              name="return_buffer"
              label="Return Count"
              {...register("return_buffer", {
                required: {
                  value: true,
                  message: <IntlMessages id="errors.required" />,
                },
                validate: validations.validateReturnBuffer,
              })}
              type="number"
              value={getValues().return_buffer}
              onChange={handleOnChange}
              error={!!errors.return_buffer}
              helperText={errors.return_buffer?.message}
            />
            <TextField
              required
              variant="outlined"
              id="total_count"
              name="total_count"
              label={<IntlMessages id="draws.add.total_count" />}
              {...register("total_count", {
                required: {
                  value: true,
                  message: <IntlMessages id="errors.required" />,
                },
                validate: validations.validateTotal,     
              })}
              type="number"
              value={getValues().total_count}
              onChange={handleOnChange}
              error={!!errors.total_count}
              helperText={errors.total_count?.message}
            />
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
};

export default React.forwardRef(CreateDraw);
