import { DialogModal, GridContainer, Loader, Select } from "app/uielements";
import React from "react";

import useStyles from "./Styles";

import IntlMessages from "@jumbo/utils/IntlMessages";
import Message from "./Message";
import useData from "./State";
import { Box, Grid, IconButton } from "@material-ui/core";
import { AddCircleOutlined } from "@material-ui/icons";

const Messages = ({ id, open, handleClose }) => {
  const classes = useStyles();
  const {
    messageTypes,
    languages,
    type,
    language,
    messages,
    save,
    setMessage,
    loading,
    setLanguage,
    setType,
    addMessage,
    deleteMessage
  } = useData({ id });

  return (
    <DialogModal
      isOpen={open}
      handleClose={handleClose}
      enableActions={false}
      title={<IntlMessages id="lottery.messages.title" />}
    >
      <div className={classes.modelContent}>
        <GridContainer>
          <Grid container item xs={12}>
            <Grid item xs={10}>
              <Box mb={{ xs: 2 }}>
                <Select value={type} onChange={evt => setType(evt.target.value)} options={messageTypes} />
              </Box>
              <Box mb={{ xs: 2 }}>
                <Select value={language} onChange={evt => setLanguage(evt.target.value)} options={languages} />
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                mb={{ xs: 2 }}
              >
                <IconButton onClick={addMessage}>
                  <AddCircleOutlined />
                </IconButton>
              </Box>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {loading && <Loader />}
          </Grid>
          <Grid item xs={12}>
            <Box mt={{ xs: 4, md: 8 }}>
              {messages.map((message, index) => (
                <Message
                  key={index}
                  index={index}
                  setMessage={setMessage}
                  save={save}
                  message={message}
                  deleteMessage={deleteMessage}
                />
              ))}
            </Box>
          </Grid>
        </GridContainer>
      </div>
    </DialogModal>
  );
};

export default Messages;
