import CmtGridView from "@coremat/CmtGridView";
import React from "react";
import LotteryCard from "./LotteryCard";
import useLotteries from "./LotteryStatus.state";

const LotteryStatus = ({}) => {
  const [ lotteries ] = useLotteries();
  return (
    <CmtGridView
      column={lotteries.length}
      data={lotteries}
      responsive={{
        xs: 1,
        sm: 2,
        md: 2,
        lg: 4,
        xl: 7
      }}
      itemPadding={10}
      renderRow={l => <LotteryCard lottery={l} />}
    />
  );
};

export default LotteryStatus;
