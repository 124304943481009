const usePermissions = () => {
  let permissions = [];

  const permissionsData = localStorage.getItem("permissions");
  if (permissionsData) {
    permissions = JSON.parse(permissionsData);
  }

  const applyPermissions = permissionMap => {
    let map = [];
    permissionMap.forEach(({key, value}) => {
      if (permissions.includes(key)) {
        map.push(value);
      }
    })
    return map;
  };

  const hasPermission = key => permissions.includes(key);
  const hasSomePermissions = keys => {
    return permissions.some(p => keys.includes(p));
  };

  return {
    permissions,
    applyPermissions,
    hasPermission,
    hasSomePermissions
  };
};

export default usePermissions;
