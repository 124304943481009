import IntlMessages from "@jumbo/utils/IntlMessages";
import useAxios from "app/hooks/useAxios";
import {Grid, Loader, Select} from "app/uielements";
import React, {useEffect, useState} from "react";
import useStyles from "./Styles";

const Draws = ({lottery_id, draw, setDraw}) => {
    const [draws, setDraws] = useState([]);
    const [loading, setLoading] = useState(false);
    const axios = useAxios();
    const classes = useStyles();
    useEffect(() => {
        let active = true;
        setLoading(true);
        setDraws([]);
        (async () => {
            try {
                const response = await axios.get(`/api/draws/active/${lottery_id}`);
                setLoading(false);
                if (!active) return;

                setDraws(response.data.draws.map(d => ({
                    value: d.id,
                    label: `${d.drawnumber} ${d.drawdate}`
                })));
            } catch (error) {
                setLoading(false);
            }
        })();

        return () => {
            active = false;
        };
    }, [lottery_id]);

    const handleSelectDraw = (evt) => {
        setDraw(evt.target.value);
    };

    return (
        <Grid item xs={12} className={classes.formColumn}>
            {loading && <Loader/>}
            {!loading && (
                <Select
                    required
                    fullWidth
                    value={draw}
                    onChange={handleSelectDraw}
                    id="draw_id"
                    name="draw_id"
                    variant="outlined"
                    label={<IntlMessages id="user.purchase.draw"/>}
                    options={draws}
                />
            )}
        </Grid>
    );
};

export default Draws;
