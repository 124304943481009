import React, { useEffect } from "react";

import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import useAxios from "app/hooks/useAxios";
import throttle from "lodash/throttle";
import IntlMessages from "@jumbo/utils/IntlMessages";
import { TextField } from "app/uielements";

export default function Bank({ onChange, errors, register, value: outerValue }) {
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState([]);
  const [loading, setLoading] = React.useState(null);
  const axios = useAxios();

  const fetch = React.useMemo(
    () =>
      throttle((request, callback) => {
        axios
          .post("/api/banks", request)
          .then(callback)
          .catch((e) => setLoading(false));
      }, 200),
    []
  );

  useEffect(() => {
    setInputValue(outerValue);
  }, [outerValue])

  React.useEffect(() => {
    let active = true;

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }
    setLoading(true);

    fetch(
      {
        bank_name: inputValue,
        page_count: 10,
      },
      (response) => {
        if (active) {
          let newOptions = [];

          if (value) {
            newOptions = [value];
          }

          if (response.status === 200) {
            const banks = response.data.data;
            newOptions = [...newOptions, ...banks];
          }

          setOptions(newOptions);

          setLoading(false);
        }
      }
    );

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  return (
    <Autocomplete
      id="bank"
      style={{ width: "100%" }}
      getOptionSelected={(option, value) =>
        option.bank_code === value.bank_code
      }
      getOptionLabel={(option) => option.bank_name}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      onChange={(event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
        onChange(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      options={options}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          label={<IntlMessages id="user.bank.bank_name" />}
          fullWidth
          variant="outlined"
          {...register("bank_name", {
            required: { value: true, message: <IntlMessages id="errors.required" /> },
          })}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
          error={errors.bank_name}
          helperText={errors.bank_name?.message}
        />
      )}
    />
  );
}
