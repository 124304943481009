import React from "react";
import SearchSMSForm from "./SearchSMSForm";
import CmtCard from "@coremat/CmtCard";
import CmtCardHeader from "@coremat/CmtCard/CmtCardHeader";
import CmtCardContent from "@coremat/CmtCard/CmtCardContent";

const SearchSMS = ({
  title,
  titleProps,
  className,
  getValues,
  setValue,
  onSearch,
  onReset,
  register,
  errors,
  onDownload,
  status
}) => {
  return (
    <CmtCard className={className}>
      <CmtCardHeader title={title} titleProps={titleProps} />
      <CmtCardContent>
        <SearchSMSForm
          getValues={getValues}
          onSearch={onSearch}
          onReset={onReset}
          register={register}
          setValue={setValue}
          errors={errors}
          onDownload={onDownload}
          status={status}
        />
      </CmtCardContent>
    </CmtCard>
  );
};

export default SearchSMS;
