import axios from "axios";
import MockAdapter from "axios-mock-adapter";
import showAlert from "./useAlerts";

if (process.env.NODE_ENV !== "production") {
  const mock = new MockAdapter(axios);
  // mock
  //   .onGet("/api/lotteryStatus")
  //   .reply(200, require("./Mock/lotteryStatus.json"));

  mock.onAny().passThrough();
}

const useAxios = () => {
  axios.interceptors.request.use((config) => {
    config.headers.common.Authorization = `Bearer ${localStorage.getItem(
      "atkn"
    )}`;
    config.headers.common.Accept = "application/json";
    config.baseURL = process.env.REACT_APP_BASE_URL;
    return config;
  });

  axios.interceptors.response.use(
    (resp) => resp,
    (error) => {
      if (error && error.response) {
        if (error.response.status === 403 || error.response.status === 401) {
          localStorage.removeItem("atkn");
          localStorage.removeItem("user");
          if (window.location.pathname != "/signin") {
            window.location.pathname = "/signin";
          }
        } else if (error.response.status === 402) {
          window.location.pathname = "/logout";
          return { code: 402 };
        } else if (error.response.status === 412) {
          window.location.pathname = "/logout";
        }
        else if (error.response.status === 422) {
          if(Array.isArray(error.response.data.errors)){
            showAlert('error', error.response.data.errors[0])
          }else {
            showAlert('error', error.response.data.error || error.response.data.errors)
          }
         
        }
        else if (error.response.status === 500) {
          showAlert('error', 'Error occurred. Please contact administrator');
        }
      }
      return Promise.reject(error);
    }
  );

  return axios;
};

export default useAxios;
