import { DialogModal, Stepper } from "app/uielements";
import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import useStyles from "./Lottery.styles";
import useLotteries from "./Lottery.state";
import ImportImage from "./Import";
import UpdateStep from "./Edit";
import IntlMessages from "@jumbo/utils/IntlMessages";

const EditLotteries = ({ open, updateRow, lottery, handleClose }) => {

  const classes = useStyles();

  const intl = useIntl();

  const {
    loading,
    values,
    formValues,
    errors,
    submit,
    step,
    setValue,
    setFile,
    upload,
  } = useLotteries({ updateRow, lottery });

  const handleUpload = async () => {
    await upload(handleClose);
  };

  const format = (id) =>
    intl.formatMessage({
      id: `lottery.add.${id}`,
    });

  const steps = [
    { label: format("update"), optional: false },
    { label: format("import"), optional: false },
  ];

  const handleNext = async () => {
    if (step === 0) {
      await submit();
    } else {
      await handleUpload();
    }
  };

  return (
    <DialogModal
      isOpen={open}
      handleClose={handleClose}
      handlePrimaryButton={handleNext}
      handleSecondaryButton={handleClose}
      primaryButtonLabel={<IntlMessages id="draws.edit.update" />}
      secondaryButtonLabel={<IntlMessages id="draws.edit.cancel" />}
      primaryDisabled={loading}
      loading={loading}
      title={format("update")}
    >
      <div className={classes.modelContent}>
        <Stepper steps={steps} activeStep={step} />
        {step == 0 && (
          <UpdateStep
            loading={loading}
            errors={errors}
            values={values}
            setValue={setValue}
            formValues={formValues}
            lottery={lottery}
          />
        )}
        {step == 1 && <ImportImage loading={loading} setFile={setFile} />}
      </div>
    </DialogModal>
  );
};

export default EditLotteries;
