import React from "react";

import Grid from "@material-ui/core/Grid";

import LotteryList from "./LotteryList";
import useLotteries from "./Lotteries.state";
import IntlMessages from "@jumbo/utils/IntlMessages";
import { Loader } from "app/uielements";
import AddLottery from "./AddLottery";
import EditLottery from "./EditLottery";
import AutomationLotteries from "./AutomationLotteries";
import { Box, Button } from "@material-ui/core";
import FileFormat from "./FileFormat";
import WithPermission from "app/uielements/WithPermission";
import data from "@coremat/CmtTimeLine/data";
import moment from "moment";
import PageContainer from "@jumbo/components/PageComponents/layouts/PageContainer";
import Messages from "./Messages";

const Lotteries = (props) => {
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setFileFormatOpen(false);
    setEditOpen(false);
    setMessagesOpen(false);
    setAutomationOpen(false)
  };

  const handleMenu = (evt, selectedId, row) => {
    if (selectedId !== id) {
      setId(selectedId);
      setSelected(row);
    }

    if (evt === "File Format") {
      setFileFormatOpen(true);
    }

    if (evt === "Messages") {
      setMessagesOpen(true);
    }

    if (evt === "Edit") {
      setEditOpen(true);
    }
    if (evt === "Auto Restock") {
      setAutomationOpen(true);
    }
  };

  const timeAjust = (data) => {
    return {
      ...data,
      start_time:
        moment().format("MM/DD/yyyy") + " " + data.start_time.substring(0, 5),
      return_time:
        moment().format("MM/DD/yyyy") + " " + data.return_time.substring(0, 5),
      close_time:
        moment().format("MM/DD/yyyy") + " " + data.close_time.substring(0, 5),
    };
  };

  const {
    loading,
    rows,
    open,
    fileFormatOpen,
    setOpen,
    id,
    setId,
    total,
    page,
    pageSize,
    handlePageChange,
    handlePageCountChange,
    appendRow,
    setFileFormatOpen,
    selected,
    setSelected,
    updateRow,
    editOpen,
    setEditOpen,
    messagesOpen,
    setMessagesOpen,
    handleRefresh,
    automationOpen,
    setAutomationOpen
  } = useLotteries();

  const breadcrumbs = [
    { label: "Home", link: "/" },
    { label: "Lotteries", link: "/lotteries", isActive: true },
  ];

  return (
    <PageContainer
      heading={<IntlMessages id="lottery.title" />}
      breadcrumbs={breadcrumbs}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid
            container
            direction="row"
            justify="space-around"
            alignItems="flex-start"
            spacing={1}
          />
        </Grid>
        <Grid item xs={12}>
          <WithPermission permissions={["lottery.create"]}>
            <Box p={1} m={1}>
              <Button
                onClick={handleClickOpen}
                variant="contained"
                color="primary"
              >
                <IntlMessages id="lottery.list.create" />
              </Button>
            </Box>
          </WithPermission>
          {loading && <Loader/>}
          {true && (
            <LotteryList
              data={rows}
              total={total}
              page={page}
              pageSize={pageSize}
              handlePageChange={handlePageChange}
              handlePageCountChange={handlePageCountChange}
              handleMenu={handleMenu}
              handleRefresh={handleRefresh}
            />
          )}
        </Grid>
      </Grid>
      {open && (
        <AddLottery
          open={open}
          appendRow={appendRow}
          handleClose={handleClose}
        />
      )}
      {fileFormatOpen && (
        <FileFormat id={id} open={fileFormatOpen} handleClose={handleClose} />
      )}
      {editOpen && (
        <EditLottery
          open={editOpen}
          updateRow={updateRow}
          handleClose={handleClose}
          lottery={timeAjust(selected)}
        />
      )}
      {messagesOpen && (
        <Messages id={id} open={messagesOpen} handleClose={handleClose} />
      )}
        {automationOpen && (
        <AutomationLotteries id={id} open={automationOpen} handleClose={handleClose} rows={rows} />
      )}
    </PageContainer>
  );
};

export default Lotteries;
