import React from "react";
import { lighten, makeStyles } from "@material-ui/core/styles";

import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionActions from "@material-ui/core/AccordionActions";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import { Box, CircularProgress } from "@material-ui/core";
import { TextField } from "app/uielements";
import IntlMessages from "@jumbo/utils/IntlMessages";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& .MuiPaper-root": {
      backgroundColor: lighten(theme.palette.background.paper, 0.1),
    },
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  icon: {
    verticalAlign: "bottom",
    height: 20,
    width: 20,
  },
  details: {
    alignItems: "center",
  },
  column: {
    flexBasis: "100%",
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(2, 4),
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
}));

export default function Message({
  message,
  save,
  setMessage,
  index,
  deleteMessage,
}) {
  const classes = useStyles();
  const { name, content, loading } = message;
  const handleClick = (evt) => {
    evt.stopPropagation();
    evt.preventDefault();
  };

  const onSave = async () => {
    if (name && content) await save(index, message);
  };

  const handleExpand = () => {
    // setExpanded(index);
  };

  const onDelete = async () => {
    await deleteMessage(index, message);
  };

  const handleChange = (evt) => {
    const data = {
      ...message,
      [evt.target.name]: evt.target.value,
    };

    setMessage(index, data);
  };
  return (
    <Box className={classes.root} mb={{ xs: 2 }}>
      <Accordion
        onClick={handleClick}
        onFocus={handleClick}
        onChange={handleExpand}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1c-content"
          id="panel1c-header"
        >
          <div className={classes.column}>
            <TextField
              placeholder="Heading"
              className={classes.heading}
              fullWidth
              name="name"
              value={name}
              onClick={handleClick}
              onFocus={handleClick}
              onChange={handleChange}
              error={!name}
              helpText={<IntlMessages id="errors.required" />}
            />
          </div>
        </AccordionSummary>
        <AccordionDetails className={classes.details}>
          <div className={classes.column}>
            <TextField
              placeholder="Message"
              fullWidth
              value={content}
              multiline
              name="content"
              onChange={handleChange}
              rows={7}
              error={!content}
              helpText={<IntlMessages id="errors.required" />}
            />
          </div>
        </AccordionDetails>
        <Divider />
        <AccordionActions>         
          {/* <Button
            onClick={onDelete}
            variant="outlined"
            size="small"
            color="warning"
            disabled={loading}
          >
            <IntlMessages id="lottery.messages.delete" />
          </Button> */}
          <Button
            onClick={onSave}
            variant="outlined"
            color="primary"
            disabled={loading}
            endIcon={loading ? <CircularProgress size={20} /> : null}
          >
            <IntlMessages id="lottery.messages.save" />
          </Button>
        </AccordionActions>
      </Accordion>
    </Box>
  );
}
