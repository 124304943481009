import FileSaver from "file-saver";
import moment from "moment";
import React, {useState, useEffect} from "react";
import {useIntl} from "react-intl";
import useAxios from "../../hooks/useAxios";

const useReturns = () => {
  const axios = useAxios();

  const [ id, setId ] = useState(0);
  const [ rows, setRows ] = useState([]);
  
  const [ loading, setLoading ] = useState(false);
const [ data, setData ] = useState({});
  const intl = useIntl();

  const format = id =>
    intl.formatMessage({
      id: `returns.${id}`
    });

  const formatDate = value => moment(value).format("yyyy-MM-DD h:mm:ss a");

  const toArray = data => [
    {
      label: format("agent_code"),
      value: data.agent_code
    },
    {
      label: format("company_name"),
      value: data.company_name
    },
    {
      label: format("lottery_name"),
      value: data.lottery_name
    },
    {
      label: format("draw_number"),
      value: data.draw_number
    },
    {
      label: format("date"),
      value: formatDate(data.date)
    },
    {
      label: format("ticket_count"),
      value: data.ticket_count
    },
    {
      label: format("start_serial"),
      value: data.start_serial
    },
    {
      label: format("end_serial"),
      value: data.end_serial
    }
  ];

  const download = () => {
    setLoading(true);
    axios
      .get("/api/return/pdf/download/" + id, {responseType: "blob"})
      .then(response => {
        // Log somewhat to show that the browser actually exposes the custom HTTP header
        const fileNameHeader = "x-suggested-filename";
        const suggestedFileName = response.headers[fileNameHeader];
        const effectiveFileName =
          suggestedFileName === undefined
            ? `draws_${id}.pdf`
            : suggestedFileName;

        // Let the user save the file.
        FileSaver.saveAs(response.data, effectiveFileName);
        setLoading(false);
      })
      .catch(response => {
        console.error(
          "Could not Download the report from the backend.",
          response
        );
        setLoading(false);
      });
  };

  useEffect(
    () => {
      let active = true;

      (async () => {
        if (id < 1) return;
        setLoading(true);
        const response = await axios.get("/api/ticket/return/" + id);
        const newRows = toArray(response.data);

        if (!active) {
          return;
        }
        setData(response.data);
        setRows(newRows);
        setLoading(false);
      })();

      return () => {
        active = false;
      };
    },
    [ id ]
  );

  return {
    rows,
    loading,
    data,
    setRows,
    setId,
    setData,
    download
  };
};

export default useReturns;
