import {makeStyles} from "@material-ui/core/styles";


const useStyles = makeStyles(theme => ({
 grid: {
     height: '710px',
     padding: theme.spacing(2),
     width: "100%"
 },
 searchForm: {
    marginBottom: theme.spacing(4),
    width: "100%"
 }

}));

export default useStyles;
