import {darken, lighten, makeStyles} from "@material-ui/core/styles";
import {getThemePaletteMode} from "@material-ui/data-grid";


const useStyles = makeStyles((theme) => {
    const getBackgroundColor = (color) =>
        getThemePaletteMode(theme.palette) === "dark"
            ? darken(color, 0.6)
            : lighten(color, 0.6);

    const getHoverBackgroundColor = (color) =>
        getThemePaletteMode(theme.palette) === "dark"
            ? darken(color, 0.5)
            : lighten(color, 0.5);
    return {
        grid: {
            height: "640px",
            padding: theme.spacing(2),
            width: "100%",
            "& .request-viewed": {
                backgroundColor: getBackgroundColor(theme.palette.info.main),
                "&:hover": {
                    backgroundColor: getHoverBackgroundColor(theme.palette.info.main),
                },
            }
        },
        root: {
            paddingBottom: 24,
        },
        perScrollbarRoot: {
            maxHeight: 340,
        },
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
    }
});

export default useStyles;
