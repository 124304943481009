import React from "react";
import SearchContactSms from "./SearchContactSms";
import PageContainer from "@jumbo/components/PageComponents/layouts/PageContainer";
import IntlMessages from "@jumbo/utils/IntlMessages";
import { Backdrop, Box, CircularProgress, Grid } from "@material-ui/core";
import { useParams } from "react-router-dom";
import useStyles from "./Styles";
import { useIntl } from "react-intl";
import Results from "./Results";
import useData from "./State";

const ContactSms = ({}) => {
  let { status } = useParams();
  const intl = useIntl();
  const breadcrumbs = [
    { label: "Home", link: "/" },
    {
      label: "Contact Us",
      link: "/contactsms/",
      isActive: true,
    },
  ];
  const {
    register,
    getValues,
    errors,
    setValue,
    onReset,
    onSearch,
    onDownload,
    loading,
    rows,
    total,
    pageSize,
    handlePageChange,
    handlePageCountChange,
    handleSortModelChange,
    sortModel,
    handleFilterChange,
    resend,
    setMessage,
    message,
    handleRefresh,
    updateRow,
    setSelected,
  } = useData({ status });
  const classes = useStyles();
  return (
    <PageContainer heading={"Contact Us"} breadcrumbs={breadcrumbs}>
      <Grid container spacing={3}>
        <Grid item xs={12} xl={12} className={classes.orderLg2}>
          <Box mb={6}>
            <SearchContactSms
              getValues={getValues}
              onSearch={onSearch}
              onReset={onReset}
              register={register}
              setValue={setValue}
              errors={errors}
              // title={intl.formatMessage({ id: "sms.form.title" })}
              onDownload={onDownload}
              status={status}
            />
          </Box>
          <Box>
            <Results
              title={<IntlMessages id="sms.results.title" />}
              handleRefresh={handleRefresh}
              rows={rows}
              total={total}
              pageSize={pageSize}
              total={total}
              loading={loading}
              handlePageChange={handlePageChange}
              handlePageCountChange={handlePageCountChange}
              handleSortModelChange={handleSortModelChange}
              sortModel={sortModel}
              handleFilterChange={handleFilterChange}
              resend={resend}
              message={message}
              setMessage={setMessage}
              handleRefresh={handleRefresh}
            />
          </Box>
        </Grid>
      </Grid>
      {/* <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop> */}
    </PageContainer>
  );
};

export default ContactSms;
