import {makeStyles} from "@material-ui/core/styles";

import {useFluidModelContent} from "../../../uielements/Dialog.styles";

const useStyles = makeStyles(theme => ({
  ...useFluidModelContent(theme),
  error: {
    border: '1px solid red'
  },
  root: {
    minWidth: '50vw'
  }
}));

export default useStyles;
