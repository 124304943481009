import {makeStyles} from "@material-ui/core/styles";

import {useFluidModelContent} from "app/uielements/Dialog.styles";

const useStyles = makeStyles(theme => ({
  ...useFluidModelContent(theme),
  root: {
    minWidth: 400,
    minHeight: 150,
    height: "85%"
  },
  newDrawWrapper: {
    height: 500
  },

  tableContainer: {
    height: "400px",
    overflow: "auto"
  }
}));

export default useStyles;
