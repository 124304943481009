import { DialogModal, TextField, Loader, Select } from "app/uielements";
import React, { useEffect, useState } from 'react';
import { useIntl } from "react-intl";
import CmtCard from "@coremat/CmtCard";
import Grid from "@material-ui/core/Grid";
import useStyles from "./Styles";
import useData from "./State";
import { Switch, Typography } from "@material-ui/core";
import clsx from "clsx";
import IntlMessages from "@jumbo/utils/IntlMessages";
import BankList from "./BankList";
import { truncate } from "lodash";


const UpdateInsurance = ({ id, open, handleClose, updateRow, user }) => {
  const classes = useStyles();
  

  const {
    primaryDisabled,
    controls,
    setValue,
    errors,
    getValues,
    loading,
    submit,
    register,
    showEdit,
    handleEditClose
  } = useData({ handleClose, updateRow, user });

  const intl = useIntl();
  const format = (id) =>
    intl.formatMessage({
      id: `user.add.${id}`,
    });

  const [onoStatus, setOnoStatus] = useState(false);
  const [onoRegister, setOnoRegister] = useState(false);
  const [onoRegisterInt, setOnoRegisterInt] = useState(false);


  const handleChange = (evt) => {
    setValue(evt.target.name, evt.target.value, { shouldValidate: true });
  };



  useEffect(() => {
    if (onoStatus)
     setValue('ono_active_status',1);
    else
     setValue('ono_active_status',0); 
  }, [onoStatus]);

  useEffect(() => {
    if (onoRegister)
     setValue('is_ono_registered',1);
    else
     setValue('is_ono_registered',0); 
  }, [onoRegister]);
 

  useEffect(() => {
  
    if (getValues().ono_active_status===1)
      setOnoStatus(true);
    else
      setOnoStatus(false);
     
  }, [getValues().ono_active_status]);



  useEffect(() => {
  
    if (getValues().is_ono_registered===1){
      setOnoRegister(true);
      setOnoRegisterInt(true);
   }else{
      setOnoRegister(false);
    
   }
  }, [getValues().is_ono_registered]);

  if (localStorage.getItem("role") != "dialog") {
    return (

      <DialogModal
        isOpen={open}
        handleClose={handleClose}
        handlePrimaryButton={submit}
        handleSecondaryButton={handleClose}
        primaryButtonLabel={format("save")}
        secondaryButtonLabel={format("cancel")}
        primaryDisabled={primaryDisabled}
        title={format("insurance.title")}
        loading={(localStorage.getItem("role") != "dialog") ? (loading) : ("")}
      >


        <form noValidate>
          {loading && <Loader />}
          {onoRegisterInt &&        (<CmtCard className={classes.cardRoot}>
          <Grid container item className={classes.modelContent1} xs={12}>
            <Grid item xs={4} md={4} className={classes.formColumnEx}>
              <Typography variant="h4" className="cmt-title">
                <IntlMessages id="user.insurance.status" />
              </Typography>
            </Grid>
            <Grid item xs={3} md={3} className={classes.formColumnEx}>
              <Switch
                {...controls.ono_active_status}
                checked={onoStatus}
                name="ono_active_status"
                color="primary"
                value={1}
                InputLabelProps={{ shrink: onoStatus}}
                onChange={event =>setOnoStatus(event.target.checked)}
                
              />
            </Grid>
            <Grid item xs={5} md={5} ></Grid>
            <Grid item xs={4} md={4}  className={classes.formColumnEx}>
              <Typography variant="h4" className="cmt-title">
                <IntlMessages id="user.insurance.cuspercentage" />
              </Typography>
            </Grid>
            <Grid item xs={3} md={3}  className={classes.formColumnEx} >
              <TextField
                {...controls.premium_percentage_cx}
                id="premium_percentage_cx"
                name="premium_percentage_cx"
                variant="outlined"
                type="number"
                value={getValues().premium_percentage_cx}
                onChange={handleChange}
                InputLabelProps={{ shrink: getValues().premium_percentage_cx}}
                helperText={errors.premium_percentage_cx?.message}
              />
            </Grid>
            <Grid item xs={5} md={5} className={classes.formColumnEx}></Grid>
            <Grid item xs={4} md={4}  className={classes.formColumnEx}>
              <Typography variant="h4" className="cmt-title">
                <IntlMessages id="user.insurance.ccwppercentage" />
              </Typography>
            </Grid>
            <Grid item xs={3} md={3}  className={classes.formColumnEx}>
             <TextField
                
                id="premium_percentage_ccwp"
                name="premium_percentage_ccwp"
                variant="outlined"
                type="number"
                value={getValues().premium_percentage_ccwp}
                onChange={handleChange}
                InputLabelProps={{ shrink: getValues().premium_percentage_ccwp}}
                helperText={errors.premium_percentage_ccwp?.message}

              />
          



            </Grid>
            <Grid item xs={5} md={5}  className={classes.formColumn}></Grid>
          </Grid>
        </CmtCard>)}
        <br/>
        <CmtCard className={classes.cardRoot}>
          <Grid container item className={classes.modelContent} xs={12}>
            <Grid item xs={12} md={6} className={classes.formColumn}>
              <TextField
                required
                {...controls.fname}
                id="fname"
                name="fname"
                variant="outlined"
                label={format("fname")}
                InputLabelProps={{ shrink: getValues().fname }}
                value={getValues().fname}
                onChange={handleChange}
                error={errors.fname}
                helperText={errors.fname?.message}
              />
              <TextField
                required
                {...controls.lname}
                id="lname"
                name="lname"
                variant="outlined"
                label={format("lname")}
                InputLabelProps={{ shrink: getValues().lname }}
                value={getValues().lname}
                onChange={handleChange}
                error={errors.lname}
                helperText={errors.lname?.message}
              />
              <TextField
                {...controls.email}
                id="email"
                name="email"
                type="email"
                variant="outlined"
                label={format("email")}
                InputLabelProps={{ shrink: getValues().email }}
                value={getValues().email}
                onChange={handleChange}
                error={errors.email}
                helperText={errors.email?.message}
              />

              <TextField
                required
                {...controls.nicno}
                id="nicno"
                name="nicno"
                variant="outlined"
                label={format("nicno")}
                InputLabelProps={{ shrink: getValues().nicno }}
                value={getValues().nicno}
                onChange={handleChange}
                error={errors.nicno}
                helperText={errors.nicno?.message}
              />
            </Grid>
            <Grid item xs={12} md={6} className={classes.formColumn}>
              <TextField
                required
                {...controls.address_1}
                id="address_1"
                name="address_1"
                variant="outlined"
                label={format("address_1")}
                InputLabelProps={{ shrink: getValues().address_1 }}
                value={getValues().address_1}
                onChange={handleChange}
                error={errors.address_1}
                helperText={errors.address_1?.message}
              />
              <TextField
                {...controls.address_2}
                id="address_2"
                name="address_2"
                variant="outlined"
                label={format("address_2")}
                value={getValues().address_2}
                InputLabelProps={{ shrink: getValues().address_2 }}
                onChange={handleChange}
                error={errors.address_2}
                helperText={errors.address_2?.message}
              />
              <TextField
                required
                {...controls.city}
                id="city"
                name="city"
                variant="outlined"
                label={format("city")}
                value={getValues().city}
                InputLabelProps={{ shrink: getValues().city }}
                onChange={handleChange}
                error={errors.city}
                helperText={errors.city?.message}
              />
              <TextField
                required
                {...controls.postal_code}
                id="postal_code"
                name="postal_code"
                variant="outlined"
                label={format("postal_code")}
                value={getValues().postal_code}
                InputLabelProps={{ shrink: getValues().postal_code }}
                onChange={handleChange}
                error={errors.postal_code}
                helperText={errors.postal_code?.message}
              />
              <div />
            </Grid>
            </Grid>
           </CmtCard> 
           <br/>

      {!onoRegisterInt &&   (<CmtCard className={classes.cardRoot1} >
            <Grid container item className={classes.modelContent1} xs={12}>
              <Grid item xs={8} md={8} className={classes.formColumn}>
              <Typography variant="h4" className="cmt-title">
                <IntlMessages id="user.insurance.register" />
</Typography>
              </Grid>
              <Grid item xs={4} md={4} className={classes.formColumn}>
                <Switch
                  {...controls.is_ono_registered}
                  checked={onoRegister}
                  name="is_ono_registered"
                  color="primary"
                  value={1}
                  InputLabelProps={{ shrink: onoRegister}}
                  onChange={event =>setOnoRegister(event.target.checked)}
                />
              </Grid>
            
            </Grid>
            </CmtCard>
 ) }
        </form>
      </DialogModal>
    );

  } else {
    return null;
  }
};

export default UpdateInsurance;
