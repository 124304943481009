import React from "react";
import IntlMessages from "@jumbo/utils/IntlMessages";
import useValidations from "../CreateDraw/CreateDraw.Validations";
import { Box, Grid } from "@material-ui/core";
import { GridContainer, TextField } from "app/uielements";

const EditForm = (props) => {
  const { getValues, register, errors, setValue, lotteries } = props;
  const validations = useValidations({ getValues, lotteries });
  const handleOnChange = (event) => {
    setValue(event.target.name, event.target.value, { shouldValidate: true });
  };

  return (
    <GridContainer spacing={3}>
      <Grid item xs md={6} spacing={3}>
        <Box m={2}>
          <TextField
            required
            variant="outlined"
            id="selling_quantity"
            name="selling_quantity"
            label="Selling Quantity"
            {...register("selling_quantity", {
              required: {
                value: true,
                message: <IntlMessages id="errors.required" />,
              },
              validate: validations.validateBlocks,
            })}
            type="number"
            value={getValues().selling_quantity}
            onChange={handleOnChange}
            error={!!errors.selling_quantity}
            helperText={errors.selling_quantity?.message}
          />
        </Box>
        <Box m={2}>
          <TextField
            required
            variant="outlined"
            id="reserved_buffer"
            name="reserved_buffer"
            label="Reserved Count"
            {...register("reserved_buffer", {
              required: {
                value: true,
                message: <IntlMessages id="errors.required" />,
              },
              validate: validations.validateBlocks,
            })}
            inputProps={{ step: 20 }}
            type="number"
            value={getValues().reserved_buffer}
            onChange={handleOnChange}
            error={!!errors.reserved_buffer}
            helperText={errors.reserved_buffer?.message}
          />
        </Box>
      </Grid>
      <Grid item xs md={6}>
        <Box m={2}>
          <TextField
            required
            variant="outlined"
            id="return_buffer"
            name="return_buffer"
            label="Return Count"
            {...register("return_buffer", {
              required: {
                value: true,
                message: <IntlMessages id="errors.required" />,
              },
              validate: validations.validateReturnBuffer,
            })}
            type="number"
            value={getValues().return_buffer}
            onChange={handleOnChange}
            error={!!errors.return_buffer}
            helperText={errors.return_buffer?.message}
          />
        </Box>
        <Box m={2}>
          <TextField
            required
            variant="outlined"
            id="total_count"
            name="total_count"
            label={<IntlMessages id="draws.add.total_count" />}
            {...register("total_count", {
              required: {
                value: true,
                message: <IntlMessages id="errors.required" />,
              },
              validate: validations.validateTotal,
            })}
            type="number"
            value={getValues().total_count}
            onChange={handleOnChange}
            error={!!errors.total_count}
            helperText={errors.total_count?.message}
          />
        </Box>
      </Grid>
    </GridContainer>
  );
};

export default EditForm;
