import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import MUIStepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const Stepper = ({ steps, activeStep, isSkipped }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <MUIStepper activeStep={activeStep}>
        {steps.map((step, index) => {
          const { label, optional } = step;
          const stepProps = {};
          const labelProps = {};
          if (optional) {
            labelProps.optional = (
              <Typography variant="caption">Optional</Typography>
            );
          }
          if (isSkipped) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </MUIStepper>
    </div>
  );
};

Stepper.propTypes = {
  steps: PropTypes.array.isRequired,
  activeStep: PropTypes.bool.isRequired,
  isSkipped: PropTypes.func.isRequired,
  handlePrimaryButton: PropTypes.func.isRequired,
  handleSecondaryButton: PropTypes.func.isRequired,
  primaryButtonLabel: PropTypes.string,
  secondaryButtonLabel: PropTypes.string,
  actions: PropTypes.object,
  title: PropTypes.string,
  description: PropTypes.string,
};

Stepper.defaultProps = {
  primaryButtonLabel: "Submit",
  secondaryButtonLabel: "Cancel",
  actions: null,
  title: "",
  description: "",
};

export default Stepper;
