import React from "react";
import {makeStyles} from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import PerfectScrollbar from 'react-perfect-scrollbar';
import useData from "./State";

import {
    BasicInfoCard,
    InformationPanel,
    Avatar,
    AvatarWithName,
} from "../uielements";
import DrawSummaryContainer from "./Draws";
import AgentsSalesContainer from "./AgentsSales";
import usePermissions from "app/hooks/usePermissions";
import {RefreshSharp} from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";


const {applyPermissions, permissions} = usePermissions();


const DashboardData = () => {

    const useStyles = makeStyles({
        root: {
            minWidth: 275,
            minHeight: 150,
        },
        scrollbarRoot: {
            height: 390,
            '& .CmtList-EmptyResult': {
                backgroundColor: 'transparent',
                border: '0 none',
            },
        },
    });

    const classes = useStyles();
    const {
        register,
        errors,
        setValue,
        getValues,
        loading,
        data,
        drawSummary,
        rows,
        updateRow,
        handleRefresh,
        handleAgentRefresh,
        agentsSalesWiseAgent,
        agentSalesSummary,
        ...rest
    } = useData();

    const breadcrumbs = [
        {label: "Home", link: "/"},
        {label: "Sample Page", isActive: true},
    ];

    const getContent = () => {
        return (
            <AvatarWithName
                alt="Cinnamon Chip Wealth Promoters (Pvt) Ltd"
                src="/static/images/avatar/1.jpg"
                name="Cinnamon Chip Wealth Promoters (Pvt) Ltd"
            />
        );
    };


    const renderDraws = () => {
        return (
            <PerfectScrollbar className={classes.scrollbarRoot}>
                <DrawSummaryContainer draws={drawSummary.data}/>
            </PerfectScrollbar>
        );
    };

    return (
        <div>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Grid
                        container
                        direction="row"
                        justify="space-around"
                        alignItems="flex-start"
                        spacing={1}
                    >
                        {(localStorage.getItem("role") != "dialog") ? (
                            <BasicInfoCard
                                cardObj={{
                                    total: "",
                                    newValue: data?.sales?.today || 0,
                                    type: "Today Sale",
                                }}
                                name="Today Sale"
                            />) : ("")}

                        {(localStorage.getItem("role") != "dialog") ? (
                            <BasicInfoCard
                                cardObj={{
                                    total: "",
                                    newValue: (data?.available || 0),
                                    type: "Total Available Tickets",
                                }}
                                name="Total Available Tickets"
                            />) : ("")}

                        {(localStorage.getItem("role") != "dialog") ? (
                            <BasicInfoCard
                                cardObj={{
                                    total: "",
                                    newValue: (data?.sales?.total || <IconButton style={{padding: 0}}><RefreshSharp onClick={handleRefresh}/></IconButton>),
                                    type: "Total Sale",
                                }}
                                name="Total Sale"
                                refreshable={!!data?.sales?.total}
                                onRefresh={handleRefresh}
                                loading={loading}
                            />) : ("")}
                        {/* <BasicInfoCard
              cardObj={{
                total: "",
                newValue:  "", //data?.wallets?.today ||
                type: "", // "Today Wallet Balance",
              }}
              name="Today Wallet Balance"
            /> */}
                        {(localStorage.getItem("role") != "dialog") && permissions.includes("draw.edit") ? (
                            <BasicInfoCard
                                cardObj={{
                                    total: "",
                                    newValue: (data?.wallets?.total || <IconButton style={{padding: 0}}><RefreshSharp onClick={handleRefresh}/></IconButton>),
                                    type: "Total Wallet Balance",
                                    hidden: true,
                                }}
                                name="Total Wallet Balance"
                                refreshable={!!data?.wallets?.total}
                                onRefresh={handleRefresh}
                                loading={loading}
                            />) : ("")}
                    </Grid>
                </Grid>
                {(localStorage.getItem("role") != "dialog") ? (
                    <Grid item xs={9}>
                        <DrawSummaryContainer draws={drawSummary.data}/>
                    </Grid>) : ("")}

        {(localStorage.getItem("role") != "dialog") ?(
            <Grid item xs={3}>
              <InformationPanel headerTitle="Subscribers" />
            </Grid> ):("")}

        {(localStorage.getItem("role") != "dialog") ?(
        <Grid item xs={9}>
        <AgentsSalesContainer agentSalesSummary={agentSalesSummary}  agentsSalesWiseAgent={agentsSalesWiseAgent} handleAgentRefresh={handleAgentRefresh}/>
        </Grid> ):("")}

        {(localStorage.getItem("role") != "dialog") ?(
            <Grid item xs={3}>
              <InformationPanel
                  headerTitle="Recent Activity"
                  content={getContent()}
              />
            </Grid>  ):("")}

        {(localStorage.getItem("role") != "dialog") ?(
        <Grid item xs={9}>
          <InformationPanel headerTitle="Winners" />
        </Grid> ):("")}
      </Grid>
    </div>
  );
};

export default DashboardData;
