import React from "react";
import MUITextField from "@material-ui/core/TextField";
import PropTypes from "prop-types";

import useStyles from "./TextField.styles";

const TextField = React.forwardRef(({
  id,
  label,
  value,
  onChange,
  helperText,
  variant,
  options,
  ...props
}, ref) => {
  const classes = useStyles();
  return (
    <MUITextField
      classes={classes}
      id={id}
      ref={ref}
      label={label}
      value={value}
      onChange={onChange}
      helperText={helperText}
      variant={variant}
      {...props}
    />
  );
});

TextField.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func.isRequired,
  helperText: PropTypes.string,
  variant: PropTypes.string,
};

TextField.defaultProps = {
  id: "0",
  helperText: "",
  variant: "standard",
};

export default TextField;
