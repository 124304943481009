import React from 'react'
import ContactSmsForm from './ContactSmsForm';
import CmtCard from "@coremat/CmtCard";
import CmtCardHeader from "@coremat/CmtCard/CmtCardHeader";
import CmtCardContent from "@coremat/CmtCard/CmtCardContent";
const SearchContactSms = ({title,
    titleProps,
    className,
    getValues,
    setValue,
    onSearch,
    onReset,
    register,
    errors,
    onDownload,
    status }) => {
  return (
    <CmtCard className={className}>
    <CmtCardHeader title={title} titleProps={titleProps} />
    <CmtCardContent>
      <ContactSmsForm
       getValues={getValues}
       onSearch={onSearch}
       onReset={onReset}
       register={register}
       setValue={setValue}
       errors={errors}
       onDownload={onDownload}
       status={status}
      />
    </CmtCardContent>
  </CmtCard>
  )
}

export default SearchContactSms
