import GridContainer from "@jumbo/components/GridContainer";
import IntlMessages from "@jumbo/utils/IntlMessages";
import { Box, IconButton, makeStyles, Typography } from "@material-ui/core";
import { AddSharp, Delete, Edit } from "@material-ui/icons";
import { Grid } from "app/uielements";
import DataGrid from "app/uielements/Table/DataGrid";
import React from "react";
import { useIntl } from "react-intl";
import EditAccount from "./EditAccount";

const useStyles = makeStyles((theme) => ({
  grid: {
    minHeight: 270,
  },
}));

const BankList = ({
  id,
  banks,
  editAccount,
  deleteAccount,
  addAccount,
  showEdit,
  selected,
  updateAccount,
  handleEditClose
}) => {
  const intl = useIntl();
  const styles = useStyles();

  const columns = [
    {
      field: "account_name",
      headerName: intl.formatMessage({ id: "user.bank.account_name" }),
      flex: 1,
    },
    {
      field: "account_number",
      headerName: intl.formatMessage({ id: "user.bank.account_number" }),
      flex: 1,
    },
    {
      field: "bank_name",
      headerName: intl.formatMessage({ id: "user.bank.bank_name" }),
      flex: 1,
    },
    {
      field: "branch_name",
      headerName: intl.formatMessage({ id: "user.bank.branch_name" }),
      flex: 1,
    },
    {
      field: "id",
      headerName: intl.formatMessage({ id: "user.bank.actions" }),
      flex: 1,
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <React.Fragment>
          {/* <IconButton
            onClick={async (evt) => await editAccount(params.value, params.row)}
          >
            <Edit />
          </IconButton> */}
          <IconButton
            onClick={async (evt) =>
              await deleteAccount(params.value, params.row)
            }
          >
            <Delete />
          </IconButton>
        </React.Fragment>
      ),
    },
  ];
  return (
    <GridContainer xs={12}>
      <GridContainer wrap="nowrap" alignItems="center">
        <Grid item>
          <Box pl={4}>
            <Typography>
              <IntlMessages id="user.bank.title" />
            </Typography>
          </Box>
        </Grid>
        <Grid item>
          <IconButton onClick={addAccount}>
            <AddSharp />
          </IconButton>
        </Grid>
      </GridContainer>
      <Grid container item xs={12}>
        <DataGrid className={styles.grid} columns={columns} rows={banks} />
      </Grid>
      {showEdit && (
        <EditAccount
          open={showEdit}
          id={id}
          bankItem={selected}
          handleClose={handleEditClose}
          updateAccount={updateAccount}
        />
      )}
    </GridContainer>
  );
};

export default BankList;
