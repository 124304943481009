import CmtCard from "@coremat/CmtCard";
import CmtCardContent from "@coremat/CmtCard/CmtCardContent";
import CmtCardHeader from "@coremat/CmtCard/CmtCardHeader";
import IntlMessages from "@jumbo/utils/IntlMessages";
import { DataGrid } from "@material-ui/data-grid";
import React from "react";

const Results = ({
  rows,
  title,
  className,
  titleProps,
  total,
  pageSize,
  handlePageChange,
  handlePageCountChange,
  handleSortModelChange,
  sortModel,
  handleFilterChange,
  loading,
  status,
}) => {

  let columns = [];
  if (status == 'revenue') {
    columns = [
      {
        field: "draw_date",
        headerName: <IntlMessages id="report.revenue.results.draw_date"/>,
        flex: 1,
      },
      {
        field: "lottery_name",
        headerName: <IntlMessages id="report.revenue.results.lottery_name"/>,
        flex: 1.2,
      },
      {
        field: "sms_channel",
        headerName: <IntlMessages id="report.revenue.results.sms"/>,
        flex: 1.2,
      },
      {
        field: "web_channel",
        headerName: <IntlMessages id="report.revenue.results.web"/>,
        flex: 1.2,
      },
      {
        field: "crm_channel",
        headerName: <IntlMessages id="report.revenue.results.crm"/>,
        flex: 1,
      },
    ]

  } else if (status == 'sales') {

    columns = [
      {
        field: "lottery_name",
        headerName: <IntlMessages id="report.sale.results.lottery_name"/>,
        flex: 1.2,
      },
      {
        field: "draw_number",
        headerName: <IntlMessages id="report.sale.results.draw_number"/>,
        flex: 1,
      },
      {
        field: "draw_date",
        headerName: <IntlMessages id="report.sale.results.draw_date"/>,
        flex: 1,
      },

      {
        field: "total_sales",
        headerName: <IntlMessages id="report.sale.results.total_sales"/>,
        flex: 1.2,
      },
      {
        field: "ap_account",
        headerName: <IntlMessages id="report.sale.results.ap_account"/>,
        flex: 1,
      },
    ]

  } else if (status == 'salesdetail') {

    columns = [
      {
        field: "lottery_name",
        headerName: <IntlMessages id="report.salesdetail.results.lottery_name"/>,
        flex: 1.2,
      },
      {
        field: "draw_number",
        headerName: <IntlMessages id="report.salesdetail.results.draw_number"/>,
        flex: 1,
      },
      {
        field: "draw_date",
        headerName: <IntlMessages id="report.salesdetail.results.draw_date"/>,
        flex: 1,
      },

      {
        field: "serial_no",
        headerName: <IntlMessages id="report.salesdetail.results.serial_no"/>,
        flex: 1.2,
      },
      {
        field: "mobile_no",
        headerName: <IntlMessages id="report.salesdetail.results.msisdn"/>,
        flex: 1,
      },
      {
        field: "nicno",
        headerName: <IntlMessages id="report.salesdetail.results.nicno"/>,
        flex: 1,
      },
      {
        field: "payment_method",
        headerName: <IntlMessages id="report.salesdetail.results.payment_method"/>,
        flex: 1,
      }
    ]
}
  return (
    <CmtCard className={className}>
      <CmtCardHeader title={title} titleProps={titleProps} />
      {rows.length > 0 && (
        <CmtCardContent style={{ height: 655 }}>
          <DataGrid
            rows={rows}
            columns={columns}
            pagination
            pageSize={pageSize}
            rowsPerPageOptions={[10, 20, 50, 100]}
            rowCount={total}
            paginationMode="server"
            onPageChange={handlePageChange}
            onPageSizeChange={handlePageCountChange}
            sortingMode="server"
            sortModel={sortModel}
            onSortModelChange={handleSortModelChange}
            filterMode="server"
            onFilterModelChange={handleFilterChange}
            loading={loading}
          />
        </CmtCardContent>
      )}
    </CmtCard>
  );
};

export default Results;
