import React, {useEffect, useState} from "react";
import useAxios from "../../hooks/useAxios";

const useRows = ({setSelected, selected, draw}) => {
    const axios = useAxios();

    const [id, setId] = useState(0);
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState(0);
    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState([]);
    const [searchFields, setSearchFields] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isCheckAnywhere, setIsCheckAnywhere] = useState(false);
    const [selectionModel, setSelectionModel] = React.useState([]);
    const [unavailableTickets, setUnavailableTickets] = useState([]);

    const handlePageChange = (params) => {
        setPage(params.page);
    };

    const handlePageCountChange = (params) => {
        setPageSize(params.pageSize);
        setPage(0);
    };

    const handleSelection = (newSelection) => {
        const selectedTickets = rows.filter((r) => newSelection.selectionModel.includes(r.ticket_id));

        setSelected((tickets) => {
            const selection = [...selectedTickets];
            tickets.forEach((ticket) => {
                if (!rows.find((r) => r.ticket_id === ticket.ticket_id)) {
                    selection.push(ticket);
                }
            });

            return selection;
        });
    };

    const lockTickets = (id) => {
        (async () => {
            if (id.length) {
                const response = await axios.post('/api/ticket/lock', {id})

                console.log('locked tickets', response);

                const LockedTickets = response.data.data

                LockedTickets.forEach((lockedTicket) => {
                    if (lockedTicket.availability_status === 'false') {
                        setUnavailableTickets(prevTicketId => [...prevTicketId, lockedTicket.id])
                    }
                })
            }
        })();
    };


    const unlockTickets = (id) => {
        (async () => {
            if (id.length) {
                await axios.post('/api/ticket/unlock', {id})
            }
        })();
    };

    useEffect(() => {
        let active = true;

        let selectedTickets = rows
            .filter((r) => selected.find((s) => s.ticket_id === r.ticket_id))
            .map((r) => r.ticket_id);

        selectedTickets = selectedTickets.filter(ticketId => !unavailableTickets.includes(ticketId))

        const unSelected = selectionModel.filter((s) => !selectedTickets.includes(s));
        const newSelected = selectedTickets.filter((s) => !selectionModel.includes(s));

        lockTickets(newSelected, selectedTickets);
        unlockTickets(unSelected);

        setSelectionModel(selectedTickets);

        return () => {
            active = false;
        };
    }, [selected, unavailableTickets]);

    useEffect(() => {
        const headers = [...searchFields.map((val) => ({
            field: val.key, headerName: val.key.toUpperCase(), flex: 1,
        })),];

        setColumns([
            {
                "field": "tk_number",
                "headerName": "Ticket Numbers",
                "flex": 1,
                renderCell: (params) => (
                    <span style={{ fontFamily: 'Courier New, monospace' }}>
                    {params.value}
                </span>
                ),
            },
        ]);
    }, [searchFields]);

    useEffect(() => {
        let active = true;

        (async () => {
            if (id < 1) return;
            setLoading(true);
            const params = new URLSearchParams();
            searchFields.forEach((val) => params.append(val.key, val.value));
            try {
                const response = await axios.post(`/api/agent/ticket/search?page=${page + 1}&draw_id=${draw}&lottery_id=${id}&check_anywhere=${isCheckAnywhere}&${params.toString()}`, {
                    page_count: pageSize,
                });
                setLoading(false);
                const newRows = response.data.data.map((r) => ({
                    ...r, lottery_id: id,
                }));
                const totalValue = response.data.total;

                if (!active) {
                    return;
                }

                setRows(newRows);
                setTotal(totalValue);

                const selection = [];
                newRows.forEach((row) => {
                    if (selected.find((s) => s.ticket_id === row.ticket_id)) {
                        selection.push(row.ticket_id);
                    }
                });
                setSelectionModel(selection);
            } catch (error) {
                console.log(error);
                setLoading(false);
            }
        })();

        return () => {
            active = false;
        };
    }, [page, total, pageSize, id, searchFields, draw]);

    return {
        rows,
        total,
        loading,
        pageSize,
        handlePageChange,
        handlePageCountChange,
        setRows,
        setId,
        setSearchFields,
        columns,
        selectionModel,
        handleSelection,
        setPage,
        setSelected,
        id,
        setIsCheckAnywhere,
        unavailableTickets
    };
};

export default useRows;