import React from 'react';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableHeading from './TableHeading';
import TableBody from '@material-ui/core/TableBody';
import TableItem from './TableItem';
import Box from '@material-ui/core/Box';

const DataTable = ({ data,
                       handleDelete,
                       handleQuickDelete,
                       quantity,
                       price,
                       availableTickets,
                       lotteryList,
                       setRows, }) => {
  return (
    <Box className="Cmt-table-responsive">
      <Table>
        <TableHead>
          <TableHeading />
        </TableHead>
        <TableBody>
            {data.map(row => (
                <>
                    {row.source === undefined ?
                        <TableItem handleDelete={handleDelete} handleQuickDelete={handleQuickDelete} row={row} key={row.lottery_id}
                                   buyingType={"Search And Buy"} /> :
                        ''
                    }

                    {row.source === 'quick_buy' ?
                        <TableItem handleDelete={handleDelete} handleQuickDelete={handleQuickDelete} row={row} key={row.lottery_id}
                                   buyingType={"Quick Buy"} availableTickets={availableTickets}/> :
                        ''
                    }
                </>
            ))}
        </TableBody>
      </Table>
    </Box>
  );
};

export default DataTable;
