import useAxios from "app/hooks/useAxios";
import FileSaver from "file-saver";
import moment from "moment";
import { useState } from "react";
import { useForm } from "react-hook-form";

const useData = ({id}) => {
    const defaultValues = {
        type: 'csv',
        start_date: new Date(),
        end_date: new Date()
    };

    const axios = useAxios();
    const formatDate = (val) => val ? moment(val).format("yyyy-MM-DD"): '';
    const {
        register,
        getValues,
        formState: {errors},
        handleSubmit,
        setValue
    } = useForm({
        defaultValues
    })

    const [loading, setLoading] = useState(false);

    const download = handleSubmit(async data => {
        setLoading(true);
        try {
            axios
            .post(
              `/api/ticket/excel/download`,
              {
                  ...data,
                  status: 'usertickets',
                  user_id: id,
                  start_date: formatDate(data.start_date),
                  end_date: formatDate(data.end_date),
              },
              { responseType: "blob" }
            )
            .then((response) => {
              const effectiveFileName = `tickets_${id}_${data.start_date}_${data.end_date}.${data.type}`
            
              // Let the user save the file.
              FileSaver.saveAs(response.data, effectiveFileName);
              setLoading(false);
            })
            .catch((response) => {
              console.error(
                "Could not Download the report from the backend.",
                response
              );
              setLoading(false);
            });
        } catch (error) {
            setLoading(false);
        };
    })

    return {
        register,
        getValues,
        errors,
        setValue,
        download,
        loading
    }
}

export default useData;