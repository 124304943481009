import React, { useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";

import { ReactTable } from "../../uielements";
import useStyles from "./CreateDraw.styles";

const DisplayImport = ({ data }) => {
  const [columns, setColumns] = useState([]);
  const [dataList, setDataList] = useState([]);

  const classes = useStyles();

  useEffect(() => {
    setColumns(data.columns);
    setDataList(data.data);
  }, [data]);

  const generateColumns = (columns) => {
    return columns
      ? columns.map((value) => ({
          Header: value,
          accessor: value,
        }))
      : [];
  };

  const generateData = () => {
    if (columns && dataList) {
      return dataList.map((data) => {
        let obj = {};
        columns.map((value, index) => {
          obj = { [value]: data[index], ...obj };
        });
        return obj;
      });
    }
  };

  const u = generateData(dataList);
  return (
    <div className={classes.root}>
      <Typography variant="h3" gutterBottom>
        Import Lotteries to the draw
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        Please import our lottery file hear in order to complete draw creation.
      </Typography>
      <div className={classes.tableContainer}>
        <ReactTable columns={generateColumns(columns)} data={generateData()} />
      </div>
    </div>
  );
};

export default DisplayImport;
