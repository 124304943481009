import IntlMessages from "@jumbo/utils/IntlMessages";
import showAlert from "app/hooks/useAlerts";
import useAxios from "app/hooks/useAxios";
import { padStart } from "lodash-es";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

const useData = ({ handleClose, lotteries, setTotal }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [file, setFile] = useState(null);
  const [primaryDisabled, setPrimaryDisabled] = useState(false);
  const [id, setId] = useState(0);
  const [fileId, setFileId] = useState(0);
  const [data, setData] = useState({
    id: 0,
    columns: [],
    data: [],
  });
  const [loading, setLoading] = useState(false);

  const axios = useAxios();

  const defaultValues = {
    issue_start_date: null,
    issue_end_date: null,
    draw_date: null,
    returned_buffer_date: null,
  };

  const {
    register,
    formState: { errors },
    setValue,
    getValues,
    handleSubmit,
  } = useForm({defaultValues});

  useEffect(() => {
    if (activeStep === 0) setPrimaryDisabled(false);
    else if (activeStep === 1 && file) setPrimaryDisabled(false);
    else if (activeStep === 2 && data) setPrimaryDisabled(false);
    else setPrimaryDisabled(true);
  }, [activeStep, file, data]);
 
  const formatDate = (val) => moment(val).format("MM/DD/yyyy hh:mm A");

  const processImport = async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("csv_data_file_id", fileId);
    formData.append("type", 1);
    try {
      const response = await axios.post("/api/import_process", formData);
      setLoading(false);
      if (response.status == 200) {
        showAlert(
          "success",
          `${response.data.message} uploaded count: ${response.data.uploaded_count}. updated count: ${response.data.updated_count} `
        );
        setTotal((total) => total + 1);
        handleClose();
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const uploadTickets = async () => {
    if (!file) throw Error("please select file");

    setLoading(true);
    const formData = new FormData();
    formData.append("csv_file", file);
    formData.append("draw_id", id);
    formData.append("lottery_id", getValues().lottery_id);
    formData.append("header", 1);
    formData.append("type", 1);
    try {
      const response = await axios.post("/api/import_parse", formData);
      setLoading(false);
      if (response.status == 200) {
        setFileId(response.data.id);
        const data = {
          id: response.data.id,
          columns: response.data.csv_data_header,
          data: response.data.csv_data_body,
        };
        setData(data);
        setActiveStep(2);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const saveDraw = async (data) => {
    setLoading(true);
    try {
      const body = {
        ...data,
        issue_start_date: formatDate(data.issue_start_date),
        issue_end_date: formatDate(data.issue_end_date),
        draw_date: formatDate(data.draw_date),
        returned_buffer_date: formatDate(data.returned_buffer_date),
        subscription_start_date:  formatDate(data.subscription_start_date),
      };

      if (id === 0) {
        const response = await axios.post("/api/draw/create", body);
        if (response.status == 200) {
          setId(response.data.draw.id);
        }
      } else {
        await axios.post("/api/draw/update/" + id, body);
      }
      setLoading(false);
      setActiveStep(1);
    } catch (error) {
      setLoading(false);
    }
  };

  const submit = handleSubmit(saveDraw);

  const handleNext = async () => {
    if (activeStep === 0) {
      await submit();
    }
    if (activeStep === 1) {
      const selectedLottery = lotteries.find(
        (l) => l.value === getValues().lottery_id
      );

      const draw_number = getValues().draw_number;
      const fileName = `${selectedLottery.file_prefix.toLowerCase()}${draw_number.padStart(4,'0')}`;
      if (!file) showAlert("error", <IntlMessages id="draws.errors.file" />);
      //else if (!file.name.startsWith(fileName))
       // showAlert("error", `The file name should be ${fileName}`);
      else await uploadTickets();
    }
    if (activeStep === 2) {
      await processImport();
    }
  };

  return {
    register,
    errors,
    setValue,
    getValues,
    handleSubmit,
    activeStep,
    submit,
    setActiveStep,
    setFile,
    handleNext,
    primaryDisabled,
    loading,
    data,
  };
};

export default useData;
