import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import useAxios from "../../hooks/useAxios";
import { useForm } from "react-hook-form";
import moment from "moment";
import showAlert from "app/hooks/useAlerts";

const useLotteries = ({ updateRow, lottery }) => {
  const axios = useAxios();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    getValues,
    reset,
  } = useForm();
  const intl = useIntl();

  const format = (id) =>
    intl.formatMessage({
      id: `lottery.add.${id}`,
    });

  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(0);
  const [id, setId] = useState(0);
  const [file, setFile] = useState(null);

  const upload = async (handleClose) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("upload", file);
    formData.append("file_id", id);
    formData.append("type", "lottery");
    try {
      if (!file) throw Error("please select file");
      const response = await axios.post("/api/upload_files", formData);
      if (response.status === 200) {
        showAlert('success', 'Lottery updated successfully');
        handleClose();
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const values = {
    name: register("name", {
      required: true,
      // maxLength: { value: 25, message: format("errors.name") },
    }),
    code: register("code", {
      required: { value: true, message: format("errors.required") },
      //minLength: { value: 4, message: format("errors.code") },
      maxLength: { value: 4, message: format("errors.code") },
    }),
    lottery_type: register("lottery_type", {
      required: true,
    }),
    day_type: register("day_type", { required: true }),
    duration_type: register("duration_type", {
      required: true,
    }),
    duration_type: register("status", { required: true, valueAsNumber: true }),
    start_time: register("start_time", { required: true }),
    close_time: register("close_time", { required: true }),
    return_time: register("return_time", { required: true }),
    min_return_count: register("min_return_count", {
      required: true,
    }),
    ticket_price: register("ticket_price", {
      required: true,
    }),
    return_block_size: register("return_block_size", {
      required: true,
    }),
    commission: register("commission", { required: true }),
    ussd_order: register("ussd_order", { required: true }),
    agent_id: register("agent_id", { required: true, valueAsNumber: true }),
    winning_number_format: register("winning_number_format", {required: true}),
  };

  useEffect(() => {
    reset({
      ...lottery
    })
  }, [lottery]);

  const toTime = (time) => moment(time).format("HH:mm") + ":00";

  const buildRequest = (data) => {
    return {
      ...data,
      start_time: toTime(data.start_time),
      close_time: toTime(data.close_time),
      return_time: toTime(data.return_time),
      min_return_count: parseFloat(data.min_return_count),
      ticket_price: parseFloat(data.ticket_price),
      return_block_size: parseFloat(data.return_block_size),
      commission: parseFloat(data.commission),
    };
  };

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const response = await axios.post(
        "/api/lottery/update/" + lottery.id,
        buildRequest(data)
      );

      if (response.status === 200) {
        setId(response.data.lottery.id);
        setStep(1);
        updateRow(response.data.lottery);
      }
    } catch (error) {
      console.log(error);
    }

    setLoading(false);
  };

  const formValues = getValues();

  const submit = handleSubmit(onSubmit);

  return {
    loading,
    values,
    formValues,
    errors,
    setValue,
    submit,
    step,
    setStep,
    setFile,
    upload,
  };
};

export default useLotteries;
