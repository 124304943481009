import React, {useState} from "react";
import {
    Box,
    Button,
    FormControlLabel,
    Grid,
    Hidden,
    Radio,
    Switch,
    Typography,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import {SortableElement, SortableHandle} from "react-sortable-hoc";

import {makeStyles} from "@material-ui/styles";
import GridContainer from "@jumbo/components/GridContainer";
import {Delete} from "@material-ui/icons";
import IntlMessages from "@jumbo/utils/IntlMessages";
import {TextField} from "app/uielements";
import useAxios from "app/hooks/useAxios";

const useStyles = makeStyles({
    dragButton: {
        minWidth: "auto",
        "&:hover": {
            cursor: "grab",
        },
        "&:focus, &:active, &:focus-within": {
            cursor: "grabbing",
        },
    },
});

const DragHandle = SortableHandle(() => <DragIndicatorIcon/>);

const Mobile = (props) => {
    const classes = useStyles();
    const {column, handleDelete, setColumn} = props;
    const axios = useAxios();
    const [isOtpSend, setIsOtpSend] = useState(false);

    const OnDelete = () => {
        handleDelete({column});
    };

    const handleVerify = () => {
        const value = {
            ...column,
            verified: true,
        };
        setColumn({value, column});
    };

    const handleOtp = async () => {
        try {
            const response = ''
            //     await axios.post('/api/admin/otp/send', {
            //     mobile_no: '94' + mobile_no
            // });

            if (response.status === 200) {
                const value = {
                    ...column,
                    otp: response.data.otp,
                };
                setColumn({value, column});
            }

            setIsOtpSend(true);


        } catch (error) {

        }

    };

    const handlePrimary = () => {
        const value = {
            ...column,
            is_primary: true,
        };
        setColumn({value, column});
    };

    const {is_primary, mobile_no, otp} = column;

    return (
        <Box>
            <GridContainer wrap="nowrap" alignItems="center">
                <Grid item xs={1} lg={3} wrap zeroMinWidth>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={is_primary}
                                onChange={handlePrimary}
                                name="is_primary"
                                size="small"
                            />
                        }
                    />
                </Grid>
                <Grid item xs={5} lg={3}>
                    <Typography noWrap><TextField readOnly value={mobile_no}/></Typography>
                </Grid>
                {!isOtpSend &&
                <Grid item xs={2}>
                    <Box display="flex" justifyContent="flex-start" p={2} m={2}>
                        <Button color="primary" onClick={handleOtp}>
                            <IntlMessages id="user.add.otp"/>
                        </Button>
                    </Box>
                </Grid>
                }
                {isOtpSend &&
                    <>
                        <Grid item xs={4} lg={2} zeroMinWidth>
                            <TextField value={otp}/>
                        </Grid>
                        <Grid item xs={3}>
                            <Box display="flex" justifyContent="flex-start" p={2} m={2}>
                                <Button color="primary" onClick={handleVerify}>
                                    <IntlMessages id="user.add.verify"/>
                                </Button>
                            </Box>
                        </Grid>
                    </>
                }
                <Grid item xs={4} zeroMinWidth>
                    <Box display="flex" justifyContent="flex-end">
                        <IconButton onClick={OnDelete}>
                            <Delete fontSize="small"/>
                        </IconButton>
                    </Box>
                </Grid>
            </GridContainer>
        </Box>
    );
};

export default SortableElement(Mobile);
