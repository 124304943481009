import React, { useState, useEffect } from "react";
import useAxios from "../../hooks/useAxios";
import throttle from "lodash/throttle";
import { useIntl } from "react-intl";
import moment from "moment";
import { toTicketNumber } from "services/utils";

const useTickets = ({lottery_id,lotteries}) => {
  const axios = useAxios();

  const intl = useIntl();

  const format = (id) =>
    intl.formatMessage({
      id: `tickets.list.${id}`,
    });

  const [id, setId] = useState(0);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState(0);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [columns, setColumns] = useState([]);

  const [filterModel, setFilterModel] = React.useState({ items: [] });

  const [sortModel, setSortModel] = React.useState([
    { field: "purchase_date", sort: "desc" },
  ]);
  
  const formatDate = (params) =>
    moment(params.value).isValid()
      ? moment(params.value).format("yyyy-MM-DD HH:mm")
      : "";

  const getTicketNumber = (params) => {
    return toTicketNumber(params.row);
  };

  const getLotteryName = (params) => {
    return (lotteries.find(l => l.value === params.value) || {}).label
  }


  const getSerialNumber = (params) =>{
    if(params === 'prn_code'){
      return 'prn_code';
    }else{
      return 'code';
    }
  }

  const getStatus = (params) => {
    const mappings = {
      0: format("status.pending"),
      1: format("status.issued"),
      2: format("status.received"),
      3: format("status.returned"),
    };

    return mappings[params.value];
  };

  useEffect(() => {
   
    const cols = [
      {
        field: 'serial_code',
        headerName: format("serial"),
        flex: 1.2,
      },
      {
        field: 'lottery_id',
        headerName: format("lottery_name"),
        flex: 1.2,
        valueFormatter: getLotteryName
      },
      {
        field: "draw_date",
        headerName: format("draw_date"),
        flex: 1.2,
        valueFormatter: formatDate,
      },
      {
        field: "drawnumber",
        headerName: format("draw_number"),
        flex: 1,
      },
      {
        field: "tk_number",
        headerName: format("ticket_number"),
        flex: 2,
        // valueGetter: getTicketNumber,
        // sortable: false,
        // filterable: false,
      },
      // {
      //   field: "sno",
      //   headerName: format("SNO"),
      //   flex: 1,
      //   hide: true,
      // },
      // {
      //   field: "lagna",
      //   headerName: format("lagna"),
      //   flex: 1,
      //   hide: true,
      // },
      {
        field: "winning_price",
        headerName: format("winning_price"),
        flex: 1,
      },
      {
        field: "purchase_date",
        headerName: format("purchase_date"),
        flex: 1.2,
        valueFormatter: formatDate,
      },      
      // {
      //   field: "special_number",
      //   headerName: format("special_number"),
      //   flex: 1,
      //   hide: true,
      // },      
      {
        field: "status",
        headerName: format("status"),
        flex: 1,
        valueGetter: getStatus,
      },      
      {
        field: "created_at",
        headerName: format("created_at"),
        flex: 2,
        valueFormatter: formatDate,
        hide: true,
      },      
    ];

    setColumns(cols);
  }, [])

  
  const handleSortModelChange = (params) => {
    if (params.sortModel !== sortModel) {
      setSortModel(params.sortModel);
      setPage(0);
    }
  };

  const handleFilter = (params) => {
    setFilterModel(params.filterModel);
    setPage(0);
  };

  const handleFilterChange = React.useCallback(throttle(handleFilter, 500), []);

  const handlePageChange = (params) => {
    setPage(params.page);
  };

  const handlePageCountChange = (params) => {
    setPageSize(params.pageSize);
    setPage(0);
  };

  useEffect(() => {
    let active = true;

    (async () => {
      if (id < 1) return;
      setLoading(true);

      const body = {
        page_count: pageSize,
        user_id: id,
        sortModel,
        filterModel: {
          items: filterModel.items
            .filter((i) => i.value)
            .map((i) => ({
              ...i,
              columnField: `tickets.${i.columnField}`,
            })),
        },
      };


      try {
        const response = await axios.post(
          "/api/ticket?page=" + (page + 1),
          body
        );
        const newRows = response.data.data;
        const totalValue = response.data.total;

        if (!active) {
          return;
        }

        setRows(newRows);
        setTotal(totalValue);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    })();

    return () => {
      active = false;
    };
  }, [page, total, pageSize, id, sortModel, filterModel]);

  return {
    rows,
    total,
    loading,
    pageSize,
    handlePageChange,
    handlePageCountChange,
    setRows,
    setId,
    handleSortModelChange,
    sortModel,
    handleFilterChange,
    columns
  };
};

export default useTickets;
