import React, { useState, useEffect } from "react";
import useAxios from "../../hooks/useAxios";
import throttle from "lodash/throttle";
import useLotteryFormat from "app/hooks/useFormat";
import { useIntl } from "react-intl";
import moment from "moment";
import { toTicketNumber } from "services/utils";

const useTickets = ({ lottery_id, lotteries, draw }) => {
  const axios = useAxios();

  const intl = useIntl();

  const format = (id) =>
    intl.formatMessage({
      id: `tickets.list.${id}`,
    });

  const [id, setId] = useState(0);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState(0);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [columns, setColumns] = useState([]);

  const [previousRequest, setPreviousRequest] = useState("");
  const [filterModel, setFilterModel] = React.useState({ items: [] });

  const [sortModel, setSortModel] = React.useState([
    { field: "id", sort: "asc" },
  ]);

  const { lagnaVisible, charVisible, snoVisible } = useLotteryFormat({
    setLoading,
    lottery_id,
  });

  const getSerialField = () => {
    const lottery = lotteries.find((l) => l.value === draw.lottery_id);
    return (lottery.matching_column || "").toLowerCase();
  };

  const formatDate = (params) =>
    moment(params.value).isValid()
      ? moment(params.value).format("yyyy-MM-DD")
      : "";

  const formatDateTime = (params) =>
      moment(params.value).isValid()
        ? moment(params.value).format("yyyy-MM-DD HH:MM")
        : "";
  const getTicketNumber = (params) => {
    return toTicketNumber(params.row);
  };

  const getStatus = (params) => {
    const mappings = {
      0: format("status.pending"),
      1: format("status.issued"),
      2: format("status.received"),
      3: format("status.returned"),
    };

    return mappings[params.value];
  };
 
  useEffect(() => {
    const cols = [
      {
        field: 'serial_code', //getSerialField(),
        headerName: format("serial"),
        flex: 1.2,
      },
      {
        field: "draw_date",
        headerName: format("draw_date"),
        flex: 1.3,
        valueFormatter: formatDateTime,
      },
      {
        field: "drawnumber",
        headerName: format("draw_number"),
        flex: 1,
      },
      // {
      //   field: "sno",
      //   headerName: format("SNO"),
      //   flex: 1.2,
      //   hide: !snoVisible,
      // },
      // {
      //   field: "lagna",
      //   headerName: format("lagna"),
      //   flex: 1.2,
      //   hide: !lagnaVisible,
      // },
      // {
      //   field: "special_number",
      //   headerName: format("special_number"),
      //   flex: 1,
      //   hide: true,
      // },
      {
        field: "tk_number",
        headerName: format("ticket_number"),
        flex: 1.9,
        // valueGetter: getTicketNumber,
        // sortable: false,
        // searchable: false,
      },
      {
        field: "status",
        headerName: format("status"),
        flex: 1,
        valueGetter: getStatus,
      },
      { field: "telephone", headerName: format("msisdn"), flex: 1 },
      { field: "nicno", headerName: format("nic"), flex: 1.2 },
      {
        field: "created_at",
        headerName: format("created_at"),
        flex: 1,
        valueFormatter: formatDate,
        hide: true,
      },
      {
        field: "purchase_date",
        headerName: format("purchase_date"),
        flex: 1,
        valueFormatter: formatDate,
      },
    ];

    setColumns(cols);
  }, [charVisible, lagnaVisible])

  

  const buildPreviousRequest = ({ page, body }) => {
    return JSON.stringify({
      page,
      body,
    });
  };

  const handleSortModelChange = (params) => {
    if (params.sortModel !== sortModel) {
      setSortModel(params.sortModel);
      setPage(0);
    }
  };

  const handleFilter = (params) => {
    setFilterModel(params.filterModel);
    setPage(0);
  };

  const handleFilterChange = React.useCallback(throttle(handleFilter, 500), []);

  const handlePageChange = (params) => {
    setPage(params.page);
  };

  const handlePageCountChange = (params) => {
    setPageSize(params.pageSize);
    setPage(0);
  };

  useEffect(() => {
    let active = true;

    (async () => {
      if (id < 1) return;
      setLoading(true);

      const body = {
        page_count: pageSize,
        draw_id: id,
        sortModel,
        filterModel: {
          items: filterModel.items
            .filter((i) => i.value)
            .map((i) => ({
              ...i,
              columnField: `tickets.${i.columnField}`,
            })),
        },
      };

      const currentRequest = buildPreviousRequest({ page, body, total });

      try {
        const response = await axios.post(
          "/api/ticket?page=" + (page + 1),
          body
        );
        const newRows = response.data.data;
        const totalValue = response.data.total;

        if (!active) {
          return;
        }
        setPreviousRequest(currentRequest);
        setRows(newRows);
        setTotal(totalValue);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    })();

    return () => {
      active = false;
    };
  }, [page, total, pageSize, id, sortModel, filterModel]);

  return {
    rows,
    total,
    loading,
    pageSize,
    handlePageChange,
    handlePageCountChange,
    setRows,
    setId,
    handleSortModelChange,
    sortModel,
    handleFilterChange,
    lagnaVisible,
    charVisible,
    columns
  };
};

export default useTickets;
