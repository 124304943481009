import { fade } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  rowRoot: {
    padding: '5px',
    position: "relative",
    transition: "all .2s",
    minHeight: '50px',
    alignItems: 'center',
    borderTop: `solid 1px ${theme.palette.borderColor.main}`,
    "&:hover": {
      backgroundColor: fade(theme.palette.primary.main, 0.08),
      transform: "translateY(-4px)",
      boxShadow: `0 3px 10px 0 ${fade(theme.palette.common.dark, 0.2)}`,
      borderTopColor: "transparent",
      "& $tableCellRoot": {
        color: theme.palette.text.primary,
        "&:last-child": {
          color: theme.palette.error.main,
        },
        "&.success": {
          color: theme.palette.success.main,
        },
      },
    },
    "&:last-child": {
      borderBottom: `solid 1px ${theme.palette.borderColor.main}`,
    },
  },
}));

export default useStyles;
