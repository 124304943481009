import React, { useEffect, useState } from "react";
import useAxios from "app/hooks/useAxios";
import { transformInToFormObject } from "services/utils";
import { useForm } from "react-hook-form";
import showAlert from "app/hooks/useAlerts";
import { useIntl } from "react-intl";
const useData = ({ id, handleClose }) => {
  const intl = useIntl();
  const defaultValues = {
    barcode_prefix: "",
    file_prefix: "",
    format_description: "",
    is_split: null,
    matching_column: "",
    special_column: "",
    winning_prefix: "",
  };

  const columnDefaults = {
    file_header: "",
    status: 1,
    length: "",
    required: 1,
  };

  const axios = useAxios();
  const {
    handleSubmit,
    formState: { errors },
    register,
    setValue,
    reset,
    getValues,
  } = useForm({ defaultValues: columnDefaults });

  const {
    handleSubmit: handleSubmitOuter,
    formState,
    register: registerOuter,
    setValue: setValueOuter,
    getValues: getValuesOuter,
    reset: resetOuter,
  } = useForm({ defaultValues });

  const { errors: errorsOuter } = formState;

  const [columns, setColumns] = useState([]);
  const [columnOptions, setColumnOptions] = useState([]);
  const [primaryDisabled, setPrimaryDisabled] = useState(true);
  const [loading, setLoading] = useState(false);

  const onSubmit = (data, e) => {
    setColumns((previousColumns) => [...previousColumns, data]);
    reset({ ...columnDefaults });
  };

  useEffect(() => {
    setColumnOptions(
      columns.map((col) => ({ value: col.file_header, label: col.file_header }))
    );
    setPrimaryDisabled(columns.length === 0);
  }, [columns]);

  useEffect(() => {
    setLoading(true);
    let active = true;
    (async () => {
      try {
        const response = await axios.get("/api/lottery/format/" + id);
        setLoading(false);
        if (!active) return;
        if (response.status === 200) {
          console.log(response.data[0]);
          resetOuter({
            ...response.data[0],
          });

          setColumns(response.data[1].fields);
        }
      } catch (error) {
        setLoading(false);
      }
    })();

    return () => {
      active = false;
    };
  }, []);


  const save = async (data, e) => {
    setLoading(true);
    const formData = transformInToFormObject({
      ...data,
      is_split: data.is_split ? 1 : 0,
      file_format: columns.map((c, i) => ({ ...c, order: i + 1 })),
    });
    try {
      const response = await axios.post(
        "/api/lottery/format/update/" + id,
        formData
      );
      if (response.status === 200) {
        showAlert(
          "success",
          intl.formatMessage({ id: "lottery.fileformat.success" })
        );
        handleClose();
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };
  const handleNext = handleSubmitOuter(save);

  return {
    handleNext,
    register,
    errors,
    setValue,
    handleSubmit,
    onSubmit,
    getValues,
    registerOuter,
    errorsOuter,
    setValueOuter,
    columnOptions,
    primaryDisabled,
    loading,
    columns,
    setColumns,
    getValuesOuter,
  };
};

export default useData;
