import {makeStyles} from "@material-ui/core/styles";

import {useGridModelContent} from "../../uielements/Dialog.styles";

const useStyles = makeStyles(theme => ({
  ...useGridModelContent(theme),
  comment: {
    wordWrap: 'break-word',
    overflow: 'hidden'
  },
  walletStyle:{
    fontWeight: "bold",
    fontSize: "17px"   
  },
  walletAmountStyle:{
    //fontWeight: "bold",
    //fontSize: "17px",
    color: "#3700B3"
  },
  type: {
    margin: theme.spacing(4)
  }

}));

export default useStyles;
