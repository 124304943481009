import React, { useState, useEffect } from "react";
import useAxios from "../hooks/useAxios";
const useLotteries = () => {
  const axios = useAxios();

  const [open, setOpen] = useState(false);
  const [fileFormatOpen, setFileFormatOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [id, setId] = useState(0);
  const [selected, setSelected] = useState({});
  const [pageSize, setPageSize] = useState(5);
  const [total, setTotal] = useState(0);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [messagesOpen, setMessagesOpen] = useState(false);
  const [automationOpen, setAutomationOpen] = useState(false);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const appendRow = (row) => {
    setRows([...rows, row]);
  };

  const updateRow = (data) => {
    const newRows = [...rows];
   
    const row = newRows.find((r) => r.id === data.id);
    Object.keys(row).forEach((key) => (row[key] = data[key]));

    setRows(newRows);
  };

  const handlePageCountChange = (event) => {
    setPageSize(+event.target.value);
    setPage(0);
  };
console.log("rows",rows)

  const handleRefresh = async (active = true) => {
    try {
      setLoading(true);
      const response = await axios.post("/api/lottery?page=" + (page + 1), {
        page_count: pageSize,
      });
      setLoading(false);
      const newRows = response.data.data;
      const totalValue = response.data.total;
console.log("lottery", response.data.data)

      if (!active) {
        return;
      }

      setRows(newRows);
      setTotal(totalValue);
     
    } catch (error) {
      setLoading(false);
    }
  }

  useEffect(() => {
    let active = true;

    (async () => {
      await handleRefresh(active);
    })();

    return () => {
      active = false;
    };
  }, [page, pageSize]);

  return {
    rows,
    total,
    loading,
    page,
    pageSize,
    id,
    open,
    fileFormatOpen,
    setOpen,
    handlePageChange,
    handlePageCountChange,
    setId,
    appendRow,
    setFileFormatOpen,
    selected,
    setSelected,
    updateRow,
    editOpen,
    setEditOpen,
    messagesOpen,
    setMessagesOpen,
    handleRefresh,
    automationOpen,
    setAutomationOpen
  };
};

export default useLotteries;
