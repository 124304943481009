import useAxios from "app/hooks/useAxios";
import {useEffect, useState} from "react";
import {toFixed, transformInToFormObject} from "services/utils";
import showAlert from "../../hooks/useAlerts";

const useData = ({handleClose}) => {
    const [primaryDisabled, setPrimaryDisabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [tabId, setTabId] = useState(null);
    const [lotteryId, setLotteryId] = useState(0);
    const [searchFields, setSearchFields] = useState([]);
    const [selected, setSelected] = useState([]);
    const [winning, setWinning] = useState(0);
    const [wallet, setWallet] = useState(0);
    const [genie, setGenie] = useState(0);
    const [id, setId] = useState(0);
    const [draw, setDraw] = useState(0);
    const [mobileList, setMobileList] = useState([]);
    const [premium, setPremium] = useState(0);
    const [enableOno, setEnableOno] = useState(false);
    const axios = useAxios();

    const [form, setForm] = useState({
        enableWinning: false,
        enableWallet: false,
        winningAmount: 0,
        walletAmount: 0,
        genieAmount: 0,
        mobile_no: null,
        enableOno: false,
        premium: 0,
    });

    const getValues = () => form;

    const setValue = (name, value) => {
        setForm({
            ...form, [name]: value,
        });
    };

    useEffect(() => {
        let active = true;
        if (!id) {
            return;
        }
        setLoading(true);

        (async () => {
            try {
                const [response, userResponse] = await Promise.all([axios.get("/api/user/credits/view/" + id), axios.post("/api/user/view/" + id),]);

                setLoading(false);

                const newRows = response.data;

                if (!active) {
                    return;
                }

                const wallet = newRows
                    .filter((r) => r.type === "bank")
                    .reduce((a, c) => a + Number(c.total_balance), 0);
                setWallet(wallet);

                const winnings = newRows
                    .filter((r) => r.type === "winning")
                    .reduce((a, c) => a + Number(c.total_balance), 0);
                setWinning(winnings);

                const genie = newRows
                    .filter((r) => r.type === "genie")
                    .reduce((a, c) => a + Number(c.total_balance), 0);
                setGenie(genie);

                if (userResponse.status === 200) {
                    const user = userResponse.data.user;
                    const o = userResponse.data?.ono_active_subscription;
                    if (o && o.ono_active_status === 1) {
                        setValue('premium', o.premium_percentage_cx);
                        setValue('enableOno', true);
                        setPremium(o.premium_percentage_cx);
                        setEnableOno(true);


                    }
                    setMobileList(userResponse.data.mobiles.map((m) => ({
                        value: m.mobile_no, label: m.mobile_no,
                    })));

                    if (userResponse.data.mobiles.length === 0) {
                        setMobileList([{
                            value: user.telephone, label: user.telephone,
                        },]);
                    }
                }
            } catch (error) {
                console.log(error);
                setLoading(false);
            }
        })();

        return () => {
            active = false;
        };
    }, [id]);

    const submit = async () => {
        setLoading(true);
        let tot;

        if (form.enableOno) {
            tot = selected.map((s) => s.source === 'quick_buy' ? s.tickets_price : s.ticket_price).reduce((a, n) => a + n * (1 + parseFloat(form.premium) / 100), 0)

        } else {
            tot = selected.map((s) => s.source === 'quick_buy' ? s.tickets_price : s.ticket_price).reduce((a, n) => a + n, 0)
        }

        function mergeQuickBuyLotteries(selected) {
            const mergedLotteries = {};

            const quickBuyEntries = selected.filter(l => l.lottery_id && l.source === 'quick_buy');

            quickBuyEntries.forEach((entry) => {
                const {lottery_id, quantity, draw_id} = entry;

                if (mergedLotteries[lottery_id]) {
                    mergedLotteries[lottery_id].quantity += quantity;
                } else {
                    mergedLotteries[lottery_id] = {lottery_id, quantity, draw_id};
                }
            });

            return Object.values(mergedLotteries);
        }

        const getUniqueDrawIds = (draws) => {
            const drawIdSet = new Set(draws.map(draw => draw.draw_id));
            return Array.from(drawIdSet).map(id => ({ 'draw_id': id }));
        };

        const draw_ids = getUniqueDrawIds(selected);

        try {
            const data = {
                total: toFixed(tot),
                draw_ids: draw_ids,
                premium: form.premium,
                enableOno: form.enableOno,
                credits: [{
                    type: "winning", amount: Number(form.winningAmount || 0),
                }, {
                    type: "bank", amount: Number(form.walletAmount || 0),
                }, {
                    type: "genie", amount: Number(form.genieAmount || 0),
                },],
                tickets: selected.filter(t => t.ticket_id).map(t => ({id: t.ticket_id})),
                quickBuyLotteries: mergeQuickBuyLotteries(selected),
                tab_id: tabId,
                mobile_no:
                form.mobile_no,
            };

            if (data.tickets.length === 0 && data.quickBuyLotteries.length === 0) {
                showAlert("error", "Please select atleast one ticket");
                throw Error("Please select atleast one ticket");
            }

            if (!data.mobile_no) {
                showAlert("error", "Please select mobile number");
                throw Error("Please select mobile number");
            }

            //if (data.credits[0].amount + data.credits[1].amount + data.credits[2].amount > 0) {
            //if (data.total !== data.credits[0].amount + data.credits[1].amount + data.credits[2].amount) {
            //showAlert("error", "Full amount is not matched");
            //throw Error("Full amount is not matched");
            //}
            //}

            transformInToFormObject(data)

            const response = await axios.post("/api/user/tickets/bulkBuy/" + id, transformInToFormObject(data));
            setLoading(false);
            handleClose();
            showAlert("success", "Tickets purchased successfully");
        } catch (error) {
            setLoading(false);
        }
    };

    const unlockTickets = (bulk) => {
        (async () => {
            try {
                const data = {
                    user_id: id,
                    lottery_id: bulk.lottery_id,
                    draw_id: bulk.draw_id,
                    quantity: bulk.count,
                    tab_id: tabId,
                };

                const response = await axios.post("/api/ticket_bulk_qty/unlock", transformInToFormObject(data));
                setLoading(false);
                showAlert("success", "Tickets purchased successfully");
            } catch (e) {
                console.log(e)
            }
        })();
    };

    const handleDelete = (group) => {
        console.log('delete_group', group);
        setSelected(selected.filter((s) => !group.tickets.find((t) => t.ticket_id === s.ticket_id)));
        // unlockTickets(group)
    };

    const handleQuickDelete = (group) => {
        setSelected(selected.filter((s) => !(s.lottery_id === group.lottery_id && s.source === group.source)));
        unlockTickets(group)
    };

    return {
        primaryDisabled,
        loading,
        lotteryId,
        setLotteryId,
        searchFields,
        setSearchFields,
        setSelected,
        selected,
        winning,
        wallet,
        genie,
        setId,
        getValues,
        setValue,
        submit,
        handleDelete,
        handleQuickDelete,
        draw,
        setDraw,
        mobileList,
        enableOno,
        premium,
        setTabId,
    };
};

export default useData;
