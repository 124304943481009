import { Box, Button, Grid, Typography } from "@material-ui/core";
import { DropzoneArea } from "material-ui-dropzone";
import React from "react";

const ImportImage = ({ setFile, format }) => {
  const handleChange = (files) => setFile(files[0]);

  return (
    <Grid
      container
      direction="row"
      justify="space-around"
      alignItems="flex-start"
    >
      <Grid container item xs={12}>
        <Typography variant="h3" gutterBottom>
          {format("upload")}
        </Typography>
      </Grid>
      <Grid container item xs={12}>
        <DropzoneArea
          acceptedFiles={[".png", ".jpeg", ".jpg", ".bmp"]}
          showPreviews={true}
          showPreviewsInDropzone={false}
          useChipsForPreview
          previewGridProps={{
            container: { spacing: 1, direction: "row" },
          }}
          previewText={format("preview")}
          onChange={handleChange}
          filesLimit={1}
        />
      </Grid>
    </Grid>
  );
};

export default ImportImage;
