export const CREATE_DRAW = "CREATE_DRAW";
export const CREATE_DRAW_SUCCESS = "CREATE_DRAW_SUCCESS";
export const CREATE_DRAW_FAIL = "CREATE_DRAW_FAIL";

export const IMPORT_LOTTERIES = "IMPORT_LOTTERIES";
export const IMPORT_LOTTERIES_SUCCESS = "IMPORT_LOTTERIES_SUCCESS";
export const IMPORT_LOTTERIES_FAIL = "IMPORT_LOTTERIES_FAIL";

export const CONFIRM_LOTTERIES = "CONFIRM_LOTTERIES";
export const CONFIRM_LOTTERIES_SUCCESS = "CONFIRM_LOTTERIES_SUCCESS";
export const CONFIRM_LOTTERIES_FAIL = "CONFIRM_LOTTERIES_FAIL";

export const SET_DRAW_DETAILS = "SET_DRAW_DETAILS";

export const SET_IMPORT_FILE = "SET_IMPORT_FILE";

export const GET_DRAW_DETAILS = "GET_DRAW_DETAILS";
