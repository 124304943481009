import React from 'react';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableHeading from './TableHeading';
import TableItem from './TableItem';
import Box from '@material-ui/core/Box';
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

const AgentsSalesSummary = ({agentsSalesWiseAgent}) => {

    console.log("agentsSalesWiseAgent", agentsSalesWiseAgent)
    return (
        <Box className="Cmt-table-responsive">
            <Table>
                <TableHead>
                    <TableHeading/>
                </TableHead>
                <TableBody>
                    {Array.isArray(agentsSalesWiseAgent) ? (
                        agentsSalesWiseAgent.map((row) => (
                            <TableItem row={row} key={row.agent_code}/>
                        ))
                    ) : (
                        <TableRow>
                            <TableCell colSpan='12'>
                                No data available
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </Box>
    );
};

export default AgentsSalesSummary;
