import showAlert from "app/hooks/useAlerts";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import useAxios from "../../hooks/useAxios";

const useAddTickets = ({ handleClose, draw_id, lottery_id }) => {
  const axios = useAxios();

  const [loading, setLoading] = useState(false);
  const [primaryDisabled, setPrimaryDisabled] = useState(false);
  const [step, setStep] = useState(0);
  const [id, setId] = useState(0);
  const [file, setFile] = useState(null);
  const [data, setData] = useState(null);

  const defaultValues = {
    selling_quantity:"",
    return_buffer: "",
    reserved_buffer: "",
    total_count: "",
    lottery_id
  };

  const {
    getValues,
    formState: { errors },
    register,
    reset,
    setValue,
    handleSubmit
  } = useForm({ defaultValues });

  useEffect(() => {
    if (step === 0 && file) setPrimaryDisabled(false);
    else if (step === 1 && data) setPrimaryDisabled(false);
    else setPrimaryDisabled(true);
  }, [step, file, data]);

  const process = async (data) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("csv_data_file_id", id);
    formData.append("selling_quantity", data.selling_quantity);
    formData.append("return_buffer", data.return_buffer);
    formData.append("reserved_buffer", data.reserved_buffer);
    formData.append("total_count", data.total_count)
    formData.append("add_more", 1)
    formData.append("type", 1);
    try {
      const response = await axios.post("/api/import_process", formData);
      setLoading(false);
      if (response.status == 200) {
        showAlert(
          "success",
          `${response.data.message} uploaded count: ${response.data.uploaded_count}. updated count: ${response.data.updated_count} `
        );
        handleClose();
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const uploadTickets = async () => {
    if (!file) throw Error("please select file");

    setLoading(true);
    const formData = new FormData();
    formData.append("csv_file", file);
    formData.append("draw_id", draw_id);
    formData.append("lottery_id", lottery_id);
;
    formData.append("header", 1);
    formData.append("type", 1);
    try {
      const response = await axios.post("/api/import_parse", formData);
      setLoading(false);
      if (response.status == 200) {
        setId(response.data.id);
        const lotteries = {
          id: response.data.id,
          columns: response.data.csv_data_header,
          data: response.data.csv_data_body,
        };
        setData(lotteries);
        setStep(1);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const processImport = handleSubmit(process);

  return {
    loading,
    uploadTickets,
    setFile,
    step,
    setStep,
    data,
    processImport,
    primaryDisabled,
    getValues,
    register,
    errors,
    setValue,
    handleSubmit
  };
};

export default useAddTickets;
