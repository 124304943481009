import React, {useEffect, useState} from "react";
import Typography from "@material-ui/core/Typography";

import {Loader} from "../../uielements";
import useStyles from "./DisplayImport.styles";
import {useIntl} from "react-intl";
import {DataGrid} from "@material-ui/data-grid";
import EditForm from "./EditForm";

const DisplayImport = ({lotteryList, loading, ...rest}) => {
  const [ columns, setColumns ] = useState([]);
  const [ dataList, setDataList ] = useState([]);

  const intl = useIntl();
  const format = id =>
    intl.formatMessage({
      id: `tickets.add.${id}`
    });

  const classes = useStyles();

  useEffect(
    () => {
      setColumns(lotteryList.columns);
      setDataList(lotteryList.data);
    },
    [ lotteryList ]
  );

  const generateColumns = columns => {
    return columns
      ? columns.map(value => ({
          field: value,
          headerName: value,
          flex: 1
        }))
      : [];
  };

  const generateData = () => {
    if (columns && dataList) {
      return dataList.map((data, i) => {
        let obj = {id: i};
        columns.map((value, index) => {
          obj = {[value]: data[index], ...obj};
        });
        return obj;
      });
    }
  };

  const u = generateData(dataList);
  return (
    <div className={classes.root}>
      <Typography variant="subtitle1" gutterBottom>
        {format("verify.title")}
      </Typography>
      <EditForm {...rest} />
      <div className={classes.modelContent}>
        {loading && <Loader />}
        <DataGrid columns={generateColumns(columns)} rows={generateData()} />
      </div>
    </div>
  );
};

export default DisplayImport;
