import GridContainer from "@jumbo/components/GridContainer";
import IntlMessages from "@jumbo/utils/IntlMessages";
import { Box, Button, CircularProgress, FormControl, Grid, Paper } from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import Select from "app/uielements/Select/Select";
import React from "react";
import DateFnsUtils from "@date-io/date-fns";
import useStyles from "./Tickets.styles";

import useData from "./Download.state";

const Download = ({ id }) => {
  const { setValue, errors, register, getValues, download, loading } = useData({id});
  const classes = useStyles();
  const downloadTypes = [
    {
      value: "csv",
      label: <IntlMessages id="download.csv" />,
    },
    {
      value: "xlsx",
      label: <IntlMessages id="download.xlsx" />,
    },
    {
      value: "xls",
      label: <IntlMessages id="download.xls" />,
    },
    {
      value: "pdf",
      label: <IntlMessages id="download.pdf" />,
    },
  ];

  return (
    <Paper>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <KeyboardDatePicker
              variant="inline"
              inputVariant="outlined"
              format="MM/dd/yyyy"
              margin="normal"
              id="start_date"
              name="start_date"
              label={<IntlMessages id="user.transactions.start_date" />}
              fullWidth
              {...register("start_date",{
                  required: {
                      value: true,
                      message: <IntlMessages id="errors.required" />
                  }
              })}
              value={getValues().start_date}
              onChange={(val) =>
                setValue("start_date", val, { shouldValidate: true })
              }
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              error={!!errors.start_date}
              helperText={errors.start_date?.message}
              autoOk
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <KeyboardDatePicker
              variant="inline"
              inputVariant="outlined"
              format="MM/dd/yyyy"
              margin="normal"
              id="end_date"
              name="end_date"
              label={<IntlMessages id="user.transactions.end_date" />}
              fullWidth
              {...register("end_date",{
                required: {
                    value: true,
                    message: <IntlMessages id="errors.required" />
                }
            })}
              value={getValues().end_date}
              onChange={(val) =>
                setValue("end_date", val, { shouldValidate: true })
              }
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              error={!!errors.end_date}
              helperText={errors.end_date?.message}
              autoOk
            />
          </Grid>
          <Grid item xs={12} md={3} className={classes.type}>
            <FormControl style={{ width: "100%" }} variant="outlined">
              <Select
                name="type"
                variant="outlined"
                id="type"
                label={<IntlMessages id="user.transactions.file_type" />}
                fullWidth
                options={downloadTypes}
                {...register('type',{
                    required: {
                        value: true,
                        message: <IntlMessages id="errors.required" />
                    }
                })}
                value={getValues().type}
                onChange={(evt) =>
                  setValue("type", evt.target.value, { shouldValidate: true })
                }
                error={!!errors.type}
                helperText={errors.type?.message}
              />
            </FormControl>
          </Grid>
          <Button onClick={download}  variant="contained" color="primary"
            style={{ height: 40, marginTop: 25 }}
            disabled={loading}
            endIcon={loading ? <CircularProgress size={20} /> : null}>
            <IntlMessages id="user.transactions.download" />
          </Button>
        </Grid>
      </MuiPickersUtilsProvider>
    </Paper>
  );
};

export default Download;
