import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import { Box, IconButton } from "@material-ui/core";
import { RefreshSharp } from "@material-ui/icons";
import { Loader } from "app/uielements";

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: 275,
    minHeight: 150,
    marginBottom: 8,
    [theme.breakpoints.down('xs')]: {
      minWidth: 100,
      minHeight: 120,
      // marginBottom: 8,
    },
  },
  type: {
    marginTop: 8,
  },
  title: {
    fontSize: 30,
  },
  total: {
    marginBottom: 8,
    marginTop: 12,
  },
  cardContent: { textAlign: "center" },
}));

const BasicInfoCard = ({ cardObj, onRefresh, refreshable, loading }) => {
  const classes = useStyles();

  return (
    <Card className={classes.root} variant="outlined">
      <Box display="flex" alignItems="center" justifyContent="flex-end">
        <IconButton style={{ padding: refreshable === true ? 0 : undefined }}>
          <RefreshSharp
            onClick={onRefresh}
            style={{ display: refreshable === true ? "block" : "none" }}
          />
        </IconButton>
      </Box>
      <CardContent className={classes.cardContent}>
        {!loading && (
          <>
           <Typography className={classes.type} variant="h5" component="h2">
              {cardObj.type}
            </Typography>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
              variant="h1"
              component="h1"
            >
              {cardObj.newValue}
            </Typography>
            {/* <Typography className={classes.type} variant="h5" component="h2">
              {cardObj.type}
            </Typography> */}
            <Typography className={classes.total} color="textSecondary">
              {cardObj.total}
            </Typography>
          </>
        )}
        {loading && (
          <>
            <Loader />
            <Typography className={classes.type} variant="h5" component="h2">
              {cardObj.type}
            </Typography>
          </>
        )}
      </CardContent>
    </Card>
  );
};

BasicInfoCard.propTypes = {
  cardObj: PropTypes.object.isRequired,
};

export default BasicInfoCard;
