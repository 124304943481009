import { DialogModal, Loader, Stepper } from "app/uielements";
import React from "react";

import useData from "./State";
import CreateTransaction from "./Create";
import Verify from "./Verify";
import useStyles from "../EditUser/Styles";

const TopupWallet = ({ open, handleClose, id }) => {
  const { submit, loading, format, step, verifyData,getValues, setFile, ...rest } = useData({
    handleClose,
    id,
  });

  const classes = useStyles();

  const steps = [
    { label: format("details"), optional: false },
    { label: format("verify"), optional: false },
  ];

  if(localStorage.getItem("role") != "dialog") {
  return (
    <DialogModal
      isOpen={open}
      handleClose={handleClose}
      handlePrimaryButton={submit}
      handleSecondaryButton={handleClose}
      primaryButtonLabel={format("save")}
      secondaryButtonLabel={format("cancel")}
      primaryDisabled={false}
      title={format("title")}
      loading={loading}
      PaperProps={{ className: classes.dialogPaper }}
    >
      {loading && <Loader />}
      {step === 0 && <CreateTransaction {...rest} format={format} getValues={getValues} />}
      {step === 1 && <Verify options={rest.options} format={format} getValues={getValues} />}

      <Stepper activeStep={step} steps={steps} />
    </DialogModal>
  );
}else{
  return null;
}

};

export default TopupWallet;
