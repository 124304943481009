import React from 'react';
import CmtCard from '@coremat/CmtCard';
import CmtCardHeader from '@coremat/CmtCard/CmtCardHeader';
import Box from '@material-ui/core/Box';
import AddIcon from '@material-ui/icons/Add';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import DrawSummary from './RecentPaymentsTable';
import PerfectScrollbar from 'react-perfect-scrollbar';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Button from '@material-ui/core/Button';
import { GridSearchIcon } from '@material-ui/data-grid';
import CmtCardFooter from '@coremat/CmtCard/CmtCardFooter';

const useStyles = makeStyles(theme => ({
  cardRoot: {
    [theme.breakpoints.down('xs')]: {
      '& .Cmt-header-root': {
        flexDirection: 'column',
      },
      '& .Cmt-action-default-menu': {
        marginLeft: 0,
        marginTop: 10,
      },
    },
  },
  cardContentRoot: {
    padding: 0,
  },
  scrollbarRoot: {
    height: 275,
  },
}));

const DrawSummaryContainer = ({draws}) => {
  const classes = useStyles();
  var nf = new Intl.NumberFormat();
 

  const getTotals = (data, key) => {
    let total = 0;
    data.forEach(item => {
      total += item[key];
    });
    return nf.format(total);
  };

  return (
    <CmtCard className={classes.cardRoot}>
      <CmtCardHeader
        className="pt-4"
        title="Draw Summary"
        titleProps={{
          variant: 'h4',
          component: 'div',
        }}>
        <Box clone>
          <Button color="primary">
            <GridSearchIcon />
            <Box component="span" ml={2}>
              Search
            </Box>
          </Button>
        </Box>
      </CmtCardHeader>
      <CmtCardContent className={classes.cardContentRoot}>
        <PerfectScrollbar className={classes.scrollbarRoot}>
          <DrawSummary draws={draws} />
        </PerfectScrollbar>
      </CmtCardContent>
     
    </CmtCard>
    
  );
};

export default DrawSummaryContainer;
