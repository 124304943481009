import React, {useState, useEffect} from "react";
import {DialogModal} from 'app/uielements';
import {useIntl} from 'react-intl';
import Swal from 'sweetalert2';
import {TextField, Button, Grid, Typography, Switch, FormControlLabel} from '@material-ui/core';
import useAxios from "../../hooks/useAxios";
import {TramRounded} from "@material-ui/icons";
import showAlert from "app/hooks/useAlerts";

const AutomationLotteries = ({open, handleClose, id, lottery_id, name, draw_number, rows}) => {
    const [minQuantity, setMinQuantity] = useState();
    const [amountTransfer, setAmountTransfer] = useState();
    const [returnBlockSize, setReturnBlockSize] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [toggle, setToggle] = useState(false);
    const axios = useAxios(false);
    const handleToggleChange = (event) => {
        setToggle(event.target.checked);
    };


    const handleDefaultSubmit = async () => {
        try {

            const response = await axios.get(`/api/ticket_auto_transfer/lottery/${id}`, {});

            if (!response.data) {
                throw new Error('Failed to fetch data');
            }

            const data = {
                minQuantity: response.data.min_lottery_trigger,
                amountTransfer: response.data.transfer_qty,
                status: response.data.auto_transfer_status,
                return_block_size: response.data.return_block_size
            };

            setMinQuantity(data.minQuantity);
            setAmountTransfer(data.amountTransfer);
            setToggle(data.status);
            setReturnBlockSize(data.return_block_size)

        } catch (error) {
            console.error('Error fetching data:', error);
            setErrorMessage('Failed to fetch data');
        }
    };

    const handleSubmit = async () => {
        if (amountTransfer % returnBlockSize !== 0) {
            setErrorMessage('Amount Transfer must be a multiplication of return block size')
            return
        }

        const data = {
            min_lottery_trigger: minQuantity,
            transfer_qty: amountTransfer,

            auto_transfer_status: toggle ? 1 : 0
        };

        try {
            const response = await axios.post(`/api/ticket_auto_transfer/lottery/update/${id} `, data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            console.log("response", response);


            if (response.status === 200) {
                showAlert("success", "Restock Value Updated Successfully.");

                handleClose();
            } else {


                showAlert("error", 'error');

            }

            setErrorMessage('');
        } catch (error) {
            console.error('Error:', error);
            setErrorMessage('There was an error submitting the data.');
            setSuccessMessage('');


            Swal.fire({
                title: 'Error!',
                text: 'There was an error submitting the data.',
                icon: 'error',
                confirmButtonText: 'OK'
            });
        }
    };

    const handleAmountChange = (e) => {
        const newAmount = e.target.value;
        setAmountTransfer(newAmount);

        if (newAmount % returnBlockSize === 0) {
            setErrorMessage('');
        } else {
            setErrorMessage(`Amount must be a multiple of ${returnBlockSize}.`);
        }
    };

    console.log("minQuantity", minQuantity)

    useEffect(() => {
        if (open && id) {
            handleDefaultSubmit();
        }
    }, [open, id]);


    console.log("name", name)
    console.log("rows", rows)
    return (
        <div>
            <DialogModal
                isOpen={open}
                handleClose={handleClose}
                primaryDisabled={true}
                enableActions={false}
                title={"Auto Restock "}
                backdrop="static"
            >
                <Grid container spacing={4} alignItems="center" justifyContent="center">
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Min Quantity To Trigger"
                                    type="number"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    variant="outlined"
                                    value={minQuantity}
                                    onChange={(e) => setMinQuantity(e.target.value)}
                                    disabled={!toggle}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Amount Transfer"
                                    type="number"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    variant="outlined"
                                    value={amountTransfer}
                                    onChange={handleAmountChange}
                                    disabled={!toggle}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                        {errorMessage && (
                            <Grid item xs={12}>
                                <Typography variant="body1" style={{color: 'red'}}>
                                    {errorMessage}
                                </Typography>
                            </Grid>
                        )}

                        <Grid item xs={12}>
                            <Grid container spacing={2} justifyContent="center" alignItems="center">
                                <Grid item xs={12} sm={6}  md={10}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={toggle}
                                                onChange={handleToggleChange}
                                                name="toggle"
                                                color="primary"
                                            />
                                        }
                                        label={toggle ? 'On' : 'Off'}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={3} md={2}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleSubmit}
                                        fullWidth
                                    >
                                        OK
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogModal>
        </div>
    );
};

export default AutomationLotteries;
