import {makeStyles} from "@material-ui/core/styles";

import {useGridModelContent} from "../../uielements/Dialog.styles";

const useStyles = makeStyles(theme => ({
  ...useGridModelContent(theme),
  cardRoot: {
    position: "relative",
    "& .Cmt-card-content": {
      padding: 0,
      paddingBottom: 24
    }
  },
  scrollbarRoot: {
    height: 305
  },
  progress: {
    display: "flex",
    alignContent: "center"
  }
}));

export default useStyles;
