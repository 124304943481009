import {makeStyles} from "@material-ui/core/styles";

import {useFluidModelContent} from "../../uielements/Dialog.styles";

const useStyles = makeStyles(theme => ({
  error: {
    border: '1px solid red'
  },
  formColumn: {
    padding: theme.spacing(2)
  },
  selection: {
    minHeight: 400,
  },
  dialogPaper: {
    [theme.breakpoints.down('md')]: {
      width: '80vw',
      minWidth: '350px',
    },
  },
  root:{
    padding: '0 !important'
  }
}));

export default useStyles;
