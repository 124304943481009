import React from "react";
import Box from "@material-ui/core/Box";
import FormControl from "@material-ui/core/FormControl";
import DateFnsUtils from "@date-io/date-fns";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";

import makeStyles from "@material-ui/core/styles/makeStyles";

import { GridContainer, Select, TextField } from "app/uielements";
import IntlMessages from "@jumbo/utils/IntlMessages";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  textPrimary: {
    color: theme.palette.primary.main,
  },
  mbSpace: {
    marginBottom: 16,
  },
  mtO: {
    margin: 0,
  },
}));

const SearchReportForm = ({
  lotteries,
  onSearch,
  onReset,
  register,
  errors,
  setValue,
  getValues,
  onDownload,
  status,
}) => {
  const handleOnChange = (evt) => {
    setValue(evt.target.name, evt.target.value, { shouldValidate: true });
  };
  const classes = useStyles();
  const lotteryTypes = [
    {
      value: null,
      label: <IntlMessages id="options.select" />,
    },
    ...lotteries,
  ];

  const paymentTypes= [
    {
      value: null,
      label: <IntlMessages id="options.select" />,
    },
     ...[{'value':'bank','label':'Bank'},
         {'value':'winning','label':'Winning'},
         {'value':'hold_transfer','label':'Hold Transfer'},
         {'value':'payout','label':'Payment'},
         {'value':'initial','label':'Initial'},
         {'value':'dialog','label':'Dialog'},
         {'value':'genie','label':'Genie'}],

  ]

  const downloadTypes = [
    {
      value: "csv",
      label: <IntlMessages id="draws.download.csv" />,
    },
    {
      value: "xlsx",
      label: <IntlMessages id="draws.download.xlsx" />,
    },
    {
      value: "xls",
      label: <IntlMessages id="draws.download.xls" />,
    },
    {
      value: "pdf",
      label: <IntlMessages id="draws.download.pdf" />,
    },
  ];

  if (status === "consoldwithmask") {
    downloadTypes.push({
      value: "dbf",
      label: <IntlMessages id="draws.download.dbf" />,
    });
  }
  return (
    <Box>
      <Box pb={{ xs: 6, md: 10, xl: 16 }}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <GridContainer>
          {(status === "consoldwithmask" 
              || status === "consoldwithoutmask" 
              || status === "sales" 
              || status === "salesdetail"
              || status === "revenue"
              || status === "highwinning"
              || status === "agentssales"
            ) && <Grid item xs={12} sm={4}>
              <FormControl style={{ width: "100%" }} variant="outlined">
            <Select                 
                  variant="outlined"
                  id="lottery_id"
                  name="lottery_id"
                  label="Lottery"
                  InputProps={{ inputProps: { min: 0 } }}
                  {...register("lottery_id")}
                  value={getValues().lottery_id}
                  onChange={handleOnChange}
                  options={lotteryTypes}
                  error={!!errors.lottery_id}
                  helperText={errors.lottery_id?.message}
                /> 

     
             
             
              </FormControl>
            </Grid>
}

{(status === "agentssales"
                ) &&
            <Grid item xs={12} sm={4}>
              <FormControl style={{ width: "100%" }} variant="outlined">
                <TextField
                    required
                    variant="outlined"
                    id="agent_code"
                    name="agent_code"
                    label={<IntlMessages id="reports.view.agent_code" />}
                    InputProps={{ inputProps: { min: 0 } }}
                    {...register("agent_code")}
                    type="text"
                    value={getValues().agent_code}
                    onChange={handleOnChange}
                />
              </FormControl>
            </Grid>
}

{(status === "consoldwithmask" 
              || status === "consoldwithoutmask" 
              || status === "sales" 
              || status === "salesdetail"
              || status === "revenue"
              || status === "highwinning"
            ) &&
            <Grid item xs={12} sm={4}>
              <FormControl style={{ width: "100%" }} variant="outlined">
                <TextField
                  required
                  variant="outlined"
                  id="min_price"
                  name="min_price"
                  label={<IntlMessages id="reports.view.min_price" />}
                  InputProps={{ inputProps: { min: 0 } }}
                  {...register("min_price")}
                  type="number"
                  value={getValues().min_price}
                  onChange={handleOnChange}
                />
              </FormControl>
            </Grid>
}

{(status === "consoldwithmask" 
              || status === "consoldwithoutmask" 
              || status === "sales" 
              || status === "salesdetail"
              || status === "revenue"
              || status === "highwinning"
            ) &&
            <Grid item xs={12} sm={4}>
              <FormControl style={{ width: "100%" }} variant="outlined">
                <TextField
                  required
                  variant="outlined"
                  id="max_price"
                  name="max_price"
                  label={<IntlMessages id="reports.view.max_price" />}
                  InputProps={{ inputProps: { min: 0 } }}
                  {...register("max_price")}
                  type="number"
                  value={getValues().max_price}
                  onChange={handleOnChange}
                />
              </FormControl>
            </Grid>
}
            <Grid item xs={12} sm={4}>
              <FormControl style={{ width: "100%" }} variant="outlined">
              {(status === "consoldwithmask" 
              || status === "consoldwithoutmask" 
              || status === "sales" 
              || status === "salesdetail"
              || status === "revenue"
              || status === "highwinning"
              || status === "agentssales"
            )
            && <TextField
                  required
                  variant="outlined"
                  id="draw_number"
                  name="draw_number"
                  label={<IntlMessages id="reports.view.draw_number" />}
                  InputProps={{ inputProps: { min: 0 } }}
                  {...register("draw_number")}
                  type="number"
                  value={getValues().draw_number}
                  onChange={handleOnChange}
                  helperText={errors.draw_id?.draw_number}
                />
  }

{(status === "wallettransfer")
            &&  <Select                 
                  variant="outlined"
                  id="payment_type"
                  name="payment_type"
                  label="Payment Type"
                  InputProps={{ inputProps: { min: 0 } }}
                  {...register("payment_type")}
                  value={getValues().payment_type}
                  onChange={handleOnChange}
                  options={paymentTypes}
                  error={!!errors.payment_type}
                  helperText={errors.payment_type?.message}
                /> }

{(status === "walletbalance")
            &&     <TextField
            required
            variant="outlined"
            id="mobile_no"
            name="mobile_no"
            label={<IntlMessages id="reports.view.mobile_no" />}
            InputProps={{ inputProps: { min: 0 } }}
            {...register("mobile_no")}
            type="number"
            value={getValues().mobile_no}
            onChange={handleOnChange}
          /> }
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={4}>
              <KeyboardDatePicker
                variant="inline"
                inputVariant="outlined"
                format="MM/dd/yyyy"
                margin="normal"
                id="start_date"
                name="start_date"
                label={<IntlMessages id="reports.view.start_date" />}
                fullWidth
                className={classes.mtO}
                {...register("start_date", {
                  required: {
                    value: true,
                    message: <IntlMessages id="errors.required" />,
                  },
                })}
                value={getValues().start_date}
                onChange={(val) =>
                  setValue("start_date", val, { shouldValidate: true })
                }
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                error={!!errors.start_date}
                helperText={errors.start_date?.message}
                autoOk
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <KeyboardDatePicker
                variant="inline"
                inputVariant="outlined"
                format="MM/dd/yyyy"
                margin="normal"
                id="end_date"
                name="end_date"
                label={<IntlMessages id="reports.view.end_date" />}
                fullWidth
                className={classes.mtO}
                {...register("end_date", {
                  required: {
                    value: true,
                    message: <IntlMessages id="errors.required" />,
                  },
                  //validate: (val) => moment(getValues().start_date).isBefore(moment(val)) || (<IntlMessages id="reports.errors.end_date" />),
                })}
                value={getValues().end_date}
                onChange={(val) =>
                  setValue("end_date", val, { shouldValidate: true })
                }
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                error={!!errors.end_date}
                helperText={errors.end_date?.message}
                autoOk
              />
            </Grid>
          </GridContainer>
        </MuiPickersUtilsProvider>
      </Box>

      <Box>
        <GridContainer>
          <Grid item xs={6} sm={6}>
            <Box display="flex" alignItems="center">
              <Button variant="outlined" onClick={onSearch}>
                <IntlMessages id="reports.view.search" />
              </Button>
              <Box ml={3}>
                <Button variant="outlined" onClick={onReset}>
                  <IntlMessages id="reports.view.reset" />
                </Button>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={6} sm={6}>
            <Box display="flex" alignItems="center" justifyContent="flex-end">
              <Select
                name="type"
                {...register("type")}
                value={getValues().type}
                options={downloadTypes}
                onChange={handleOnChange}
              />
              <Box ml={3}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={onDownload}
                >
                  <IntlMessages id="reports.view.download" />
                </Button>
              </Box>
            </Box>
          </Grid>
        </GridContainer>
      </Box>
    </Box>
  );
};

export default SearchReportForm;
