import {makeStyles} from "@material-ui/core/styles";

import {useGridModelContent} from "../../uielements/Dialog.styles";

const useStyles = makeStyles(theme => ({
  ...useGridModelContent(theme),
  type: {
    margin: theme.spacing(4)
  },
  dialogPaper: {
    [theme.breakpoints.down('md')]: {
      width: '80vw',
      minWidth: '350px',
    },
  },
}));

export default useStyles;
