import React, { useEffect } from "react";
import IntlMessages from "../../../utils/IntlMessages";
import { useDispatch, useSelector } from "react-redux";
import { Box, fade, Link } from "@material-ui/core";
import { AuhMethods } from "../../../../services/auth";
import ContentLoader from "../../ContentLoader";
import makeStyles from "@material-ui/core/styles/makeStyles";
import CmtImage from "../../../../@coremat/CmtImage";
import Typography from "@material-ui/core/Typography";
import { CurrentAuthMethod } from "../../../constants/AppConstants";
import AuthWrapper from "./AuthWrapper";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  authThumb: {
    backgroundColor: fade(theme.palette.primary.main, 0.12),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 20,
    [theme.breakpoints.up("md")]: {
      width: "50%",
      order: 2,
    },
  },
  authContent: {
    padding: 30,
    [theme.breakpoints.up("md")]: {
      order: 1,
      width: (props) => (props.variant === "default" ? "50%" : "100%"),
    },
    [theme.breakpoints.up("xl")]: {
      padding: 50,
    },
  },
  titleRoot: {
    marginBottom: 14,
    color: theme.palette.text.primary,
  },
  textFieldRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: fade(theme.palette.common.dark, 0.12),
    },
  },
  alertRoot: {
    marginBottom: 10,
  },
  productLogo: {
    width: "20%",
  },
}));

const VerifyUser = ({
  method = CurrentAuthMethod,
  variant = "default",
  wrapperVariant = "default",
}) => {
  const { authUser } = useSelector(({ auth }) => auth);
  const dispatch = useDispatch();
  const classes = useStyles({ variant });
  const history = useHistory();

  useEffect(() => {
    if (!authUser) {
      history.push("/signin");
    }
  }, [authUser]);

  const email = authUser ? authUser.email : "";
  const onResendEmail = (event) => {
    event.preventDefault();
    dispatch(AuhMethods[method].onResendEmail());
  };

  const onLogout = (event) => {
    event.preventDefault();
    dispatch(AuhMethods[method].onLogout());
  };

  return (
    <AuthWrapper variant={wrapperVariant}>
      {variant === "default" ? (
        <Box className={classes.authThumb}>
          <CmtImage src={"/images/auth/login-img.png"} />
        </Box>
      ) : null}
      <Box className={classes.authContent}>
        <Box mb={7}>
          <CmtImage className={classes.productLogo} src={"/images/logo.png"} />
        </Box>
        <Typography component="div" variant="h1" className={classes.titleRoot}>
          Verify Account
        </Typography>
        <form>
          <Box mb={5}>
            <Typography>
              We have sent an email to: {email} in order to verify your email
              address.
            </Typography>
            <Typography>
              Please click the link in that email so that we can verify your
              account.
            </Typography>
          </Box>
          <Box>
            <Typography>
              Didn't get the email?
              <Box component="span" ml={2}>
                <Link href="#" onClick={onResendEmail}>
                  <IntlMessages id="appModule.resendEmail" />
                </Link>
              </Box>
            </Typography>
          </Box>
          <Box mt={5}>
            <Link href="#" onClick={onLogout}>
              <IntlMessages id="popup.logout" />
            </Link>
          </Box>
        </form>
        <ContentLoader />
      </Box>
    </AuthWrapper>
  );
};

export default VerifyUser;
