import React from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

const showAlert = (variant, message) => {
    const Toast = MySwal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 5000,
      timerProgressBar: true,
      onOpen: toast => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: variant,
      title: message,
    });
}

/**
 * 
 * @param {Object} param0 Params
 * @param {string} param0.title The title
 * @param {string} param0.text The text
 * @param {Function} param0.successCallback The callback
 * @param {Function} param0.failCallback The callback
 */
export const confirm = ({title, text, successCallback, failCallback, confirmButtonText = 'Yes', cancelButtonText = 'No', icon = 'warning'}) => {
  MySwal.fire({
    title,
    text,
    icon,
    showCancelButton: true,
    confirmButtonText,
    cancelButtonText,
    reverseButtons: true,
  }).then(result => {
    if (result.value) {
      successCallback && successCallback();
    } else if (
      /* Read more about handling dismissals below */
      result.dismiss === Swal.DismissReason.cancel
    ) {
      failCallback && failCallback();
    }
  });
};

export default showAlert;
