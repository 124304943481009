import React, { useEffect, useState } from "react";
import useAxios from "app/hooks/useAxios";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {useIntl} from "react-intl";

const useProfile = () => {
    const axios = useAxios();
    const { authUser } = useSelector(({ auth }) => auth);
    // console.log(authUser);
    const [updateUser, setUpdateUser] = useState (authUser);

    const email = authUser ? authUser.email : "";
    const fullName = authUser ? `${authUser.fname} ${authUser.lname}` : "";
    const fullTelephone = authUser ? `${authUser.dialcode} ${authUser.telephone}` : "";

    const userInfo = {
        id: authUser.id,
        fullName: fullName,
        email : email,
        fullTelephone : fullTelephone,
        avatar_path : authUser.avatar,
        gender: authUser.gender,
        nicno: authUser.nicno
    }

    return (
        {
            userInfo,
            updateUser,
            setUpdateUser
        }
    );
};

export default useProfile;

/*
account_balance: "0.00"
avatar_path: null
change_password: 0
created_at: "2021-06-27 20:25:02"
created_by: null
deleted: 0
dialcode: "+94"
dob: null
email: "dilan@codesands.com"
email_verified_at: "2021-06-10 00:00:00"
fbid: null
fname: "Dilan I"
gender: "male"
gpid: null
id: 1
last_login_at: "2021-06-27 16:42:40"
lname: "Withanachchi"
locked: 0
nicno: "771063098V"
published: 0
status: 1
telephone: 763303352
telephone_verified_at: "2021-06-10 00:00:00"
updated_at: "2021-06-27 20:25:02"
updated_by: 2
* */