import React, { useEffect, useState } from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import PropTypes from "prop-types";
import axios from "axios"; 
const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(1),
      width: "100%",
      minHeight: 300
    }
  },
  header: {
    borderBottom: "1px solid #e2e2e2",
    padding: 16
  },
  content: {
    padding: 16
  }
}));

const InformationPanel = ({headerTitle, content}) => {
  const classes = useStyles();
  const [blocking, setBlocking] = useState(false);
  const [amount, setAmount] = useState('');

  return (
    <div className={classes.root}>
      <Paper>
        <div className={classes.header}>{headerTitle}</div>
        <div className={classes.content}>{content}</div>
        <div className={classes.footer} />
      </Paper>
    </div>
  );
};

InformationPanel.prototype = {
  headerTitle: PropTypes.string,
  content: PropTypes.oneOfType([ PropTypes.string, PropTypes.object ]),
  footer: PropTypes.oneOfType([ PropTypes.string, PropTypes.object ])
};

InformationPanel.defaultProps = {
  headerTitle: "",
  content: "",
  footer: ""
};

export default InformationPanel;
