import React from "react";
import Box from "@material-ui/core/Box";
import FormControl from "@material-ui/core/FormControl";
import DateFnsUtils from "@date-io/date-fns";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { GridContainer, TextField } from "app/uielements";
import IntlMessages from "@jumbo/utils/IntlMessages";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";

const useStyles = makeStyles((theme) => ({
  textPrimary: {
    color: theme.palette.primary.main,
  },
  mbSpace: {
    marginBottom: 16,
  },
  mtO: {
    margin: 0,
  },
}));

const SearchSMSForm = ({
  onSearch,
  onReset,
  register,
  errors,
  setValue,
  getValues,
}) => {
  const handleOnChange = (evt) => {
    setValue(evt.target.name, evt.target.value, { shouldValidate: true });
  };
  const classes = useStyles();

  return (
    <Box>
      <Box pb={{ xs: 6, md: 10, xl: 16 }}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <GridContainer>
            <Grid item xs={12} sm={4}>
              <FormControl style={{ width: "100%" }} variant="outlined">
                <TextField
                  variant="outlined"
                  id="mobile_no"
                  name="mobile_no"
                  label={<IntlMessages id="sms.mobile_no" />}
                  {...register("mobile_no")}
                  value={getValues().mobile_no}
                  onChange={handleOnChange}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <KeyboardDatePicker
                variant="inline"
                inputVariant="outlined"
                format="MM/dd/yyyy"
                margin="normal"
                id="start_date"
                name="start_date"
                label={<IntlMessages id="sms.start_date" />}
                fullWidth
                className={classes.mtO}
                {...register("start_date")}
                value={getValues().start_date}
                onChange={(val) =>
                  setValue("start_date", val, { shouldValidate: true })
                }
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                error={!!errors.start_date}
                helperText={errors.start_date?.message}
                autoOk
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <KeyboardDatePicker
                variant="inline"
                inputVariant="outlined"
                format="MM/dd/yyyy"
                margin="normal"
                id="end_date"
                name="end_date"
                label={<IntlMessages id="sms.end_date" />}
                fullWidth
                className={classes.mtO}
                {...register("end_date")}
                value={getValues().end_date}
                onChange={(val) =>
                  setValue("end_date", val, { shouldValidate: true })
                }
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                error={!!errors.end_date}
                helperText={errors.end_date?.message}
                autoOk
              />
            </Grid>
          </GridContainer>
        </MuiPickersUtilsProvider>
      </Box>

      <Box>
        <GridContainer>
          <Grid item xs={6} sm={6}>            
          </Grid>
          <Grid item xs={6} sm={6}>
            <Box display="flex" alignItems="center" justifyContent="flex-end">
              <Box ml={3}>
                <Button variant="outlined" onClick={onReset}>
                  <IntlMessages id="sms.reset" />
                </Button>
              </Box>
              <Box ml={3}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={onSearch}
                >
                  <IntlMessages id="sms.search" />
                </Button>
              </Box>
            </Box>
          </Grid>
        </GridContainer>
      </Box>
    </Box>
  );
};

export default SearchSMSForm;
