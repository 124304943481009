import {makeStyles} from "@material-ui/core/styles";
import {useFluidModelContent} from "app/uielements/Dialog.styles";

const useStyles = makeStyles(theme => ({
  ...useFluidModelContent(theme),
  root: {
    minWidth: 275,
    minHeight: 150
  },
  modelRoot: {
    flexGrow: 1
  },
  previewChip: {
    minWidth: 160,
    maxWidth: 810
  }
}));

export default useStyles;
