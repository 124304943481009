import React from 'react'

import IntlMessages from "@jumbo/utils/IntlMessages";
import moment from "moment";

const useValidations = ({ getValues, lotteries }) => {
  const validateTotal = (val) => {
    const numberValue = Number(val);

    if (Number.isNaN(numberValue))
      return <IntlMessages id="errors.invalid_number" />;

    const total =
      Number(getValues().return_buffer) +
      Number(getValues().reserved_buffer) +
      Number(getValues().selling_quantity);

    if (total !== numberValue)
      return <IntlMessages id="draws.add.errors.total" />;

    const selectedLottery = lotteries.find(l => l.value === getValues().lottery_id);
    
    if(!selectedLottery) return <IntlMessages id="draws.add.errors.no_lottery" />;

    const blockSize = selectedLottery.block_size;

    if(numberValue < blockSize || numberValue % blockSize !== 0) return <IntlMessages id="draws.add.errors.block" />;

    return true;
  };

  const validateReturnBuffer = val => {
    const numberValue = Number(val);

    if (Number.isNaN(numberValue))
      return <IntlMessages id="errors.invalid_number" />;
   
    const selectedLottery = lotteries.find(l => l.value === getValues().lottery_id);
    
    if(!selectedLottery) return <IntlMessages id="draws.add.errors.no_lottery" />;

    const blockSize = selectedLottery.block_size;

    if(numberValue % blockSize !== 0) return <IntlMessages id="draws.add.errors.block" />;

    //if( Number(getValues().reserved_buffer) < numberValue) return <IntlMessages id="draws.add.errors.return_buffer" />;

    return true;
  }

  const validateBlocks = (val) => {
    const numberValue = Number(val);

    if (Number.isNaN(numberValue))
      return <IntlMessages id="errors.invalid_number" />;
   
    const selectedLottery = lotteries.find(l => l.value === getValues().lottery_id);
    
    if(!selectedLottery) return <IntlMessages id="draws.add.errors.no_lottery" />;

    const blockSize = selectedLottery.block_size;

    if(numberValue % blockSize !== 0) return <IntlMessages id="draws.add.errors.block" />;

    return true;
  };

  const validateStartDate = (val) => {
    if(!moment(val).isValid())  return <IntlMessages id="draws.add.errors.date" />
    return true;
  }

  const validateEndDate = (val) => {
    if(!moment(val).isValid())  return <IntlMessages id="draws.add.errors.date" />
    return moment(val).diff(moment(getValues().issue_start_date)) > 0|| <IntlMessages id="draws.add.errors.end_date" />
  }

  const validateDrawDate = (val) => {
    if(!moment(val).isValid())  
    return <IntlMessages id="draws.add.errors.date" />
    return moment(val).diff(moment(getValues().issue_end_date)) > 0 || <IntlMessages id="draws.add.errors.draw_date" />
  }

  const validateReturnDate = (val) => {
    if(!moment(val).isValid())  return <IntlMessages id="draws.add.errors.date" />
    return moment(val).diff(moment(getValues().issue_start_date)) > 0 || <IntlMessages id="draws.add.errors.return_date" />
  }
  
  const validateSubscriptionStartDate = (val) => {
    if(!moment(val).isValid())  return <IntlMessages id="draws.add.errors.date" />
    return moment(val).diff(moment(getValues().issue_start_date)) > 0 || <IntlMessages id="draws.add.errors.return_date" />
  }
  

  return {
    validateTotal,
    validateStartDate,
    validateEndDate,
    validateDrawDate,
    validateReturnDate,
    validateBlocks,
    validateReturnBuffer, 
    validateSubscriptionStartDate
  };
};

export default useValidations;
