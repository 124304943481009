import React from "react";
import useUsers from "./AgentsList.state";
import {useIntl} from "react-intl";
import DataGrid from "app/uielements/Table/DataGrid";
import ContextMenu from "app/uielements/ContextMenu";

import TopupWallet from "../Topup";
import TransactionList from "../Transactions";
import usePermissions from "app/hooks/usePermissions";
import useStyles from "./AgentsList.style";
import {Paper} from "@material-ui/core";
import IntlMessages from "@jumbo/utils/IntlMessages";
import EditUser from "../EditUser";
import SearchForm from "./SearchForm";
import moment from "moment-timezone";

const UserList = ({lotteryList, total, setTotal}) => {
    const {
        rows,
        loading,
        pageSize,
        id,
        setSelected,
        handlePageChange,
        handlePageCountChange,
        setId,
        topupOpen,
        setTopupOpen,
        transactionOpen,
        setTransactionOpen,
        purchaseOpen,
        setPurchaseOpen,
        handleSortModelChange,
        sortModel,
        handleFilterChange,
        ticketsOpen,
        setTicketsOpen,
        selected,
        handleRefresh,
        billOpen,
        setBillOpen,
        addSubscriptionOpen,
        setAddSubscriptionOpen,
        viewSubscriptionOpen,
        setViewSubscriptionOpen,
        deleteUser,
        setEditOpen,
        editOpen,
        setUpdateInsurance,
        updateInsurance,
        updateRow,
        onSearch,
        onReset,
        form,
        setValue,
        params,
        isDataGridVisible,
        setIsDataGridVisible
    } = useUsers({total, setTotal});

    //Generate Unique Key for Tabs
    const timestamp = moment.tz('Asia/Colombo').format('YYYYMMDDHHmmss');
    const randomKey = Math.floor(100000 + Math.random() * 900000);
    const tabId = `${timestamp}${randomKey}`;

    console.log('tabId', tabId);
    console.log('tabId timestamp', timestamp);
    console.log('tabId randomKey', randomKey);

    const classes = useStyles();
    const intl = useIntl();
    const {applyPermissions} = usePermissions();
    const format = (id) =>
        intl.formatMessage({
            id: `user.list.${id}`,
        });

    const handleMenu = async (evt, option, data) => {
        if (id !== evt) {
            setId(evt);
            setSelected(data);
        }

        if (option === format("options.topup")) {
            setTopupOpen(true);
        }

        if (option === format("options.transactions")) {
            setTransactionOpen(true);
        }

        if (option === format("options.edit")) {
            setEditOpen(true);
        }

        if (option === format("options.updateinsurance")) {
            setUpdateInsurance(true);
        }

        if (option === format("options.delete")) {
            await deleteUser(data);
        }
    };

    const handleClose = () => {
        setTopupOpen(false);
        setTransactionOpen(false);
        setPurchaseOpen(false);
        setTicketsOpen(false);
        setBillOpen(false);
        setAddSubscriptionOpen(false);
        setViewSubscriptionOpen(false);
        setEditOpen(false);
        setUpdateInsurance(false);
    };

    const options = applyPermissions([
        (localStorage.getItem("role") != "dialog") ? {key: "user.update", value: format("options.edit")} : {
            key: null,
            value: null
        },
        (localStorage.getItem("role") != "dialog") ? {
            key: "transaction.add",
            value: format("options.topup")
        } : {key: null, value: null},
        (localStorage.getItem("role") != "dialog") ? {
            key: "transactions.view",
            value: format("options.transactions")
        } : {key: null, value: null},
    ]);


    const columns = [
        {field: "agent_code", headerName: format("agent_code"),flex: 1,},
        {field: "fname", headerName: format("fname"),flex: 1,},
        {field: "lname", headerName: format("lname"), flex: 1},
        {field: "email", headerName: format("email"), flex: 1.5},
        {field: "telephone", headerName: format("telephone"), flex: 1},
        {field: "nicno", headerName: format("nicno"), flex: 1},
        {
            field: "created_at",
            headerName: format("created_at"),
            flex: 1,
            valueFormatter: (params) => moment(params.value).format("MM/DD/yyyy"),
        },
        {field: "status", headerName: format("status"), flex: 1},
        {
            field: "id",
            headerName: format("action"),
            sortable: false,
            filterable: false,
            renderCell: (params) => (
                <ContextMenu
                    options={options}
                    onClick={(evt) => handleMenu(params.value, evt, params.row)}
                />
            ),
            flex: 1,
        },
    ];

    return (
        <React.Fragment>
            <SearchForm
                className={classes.searchForm}
                form={form}
                onSearch={onSearch}
                onReset={onReset}
                setValue={setValue}
                setIsDataGridVisible={setIsDataGridVisible}
                loading={loading}
                title={intl.formatMessage({id: "agents.list.search.title"})}
            />

            {isDataGridVisible && params ?
                <Paper className={classes.grid}>
                    <DataGrid
                        title={<IntlMessages id="agents.list.title"/>}
                        rows={rows}
                        showToolbar
                        columns={columns}
                        pagination
                        pageSize={pageSize}
                        rowsPerPageOptions={[10, 20, 50, 100]}
                        rowCount={total}
                        paginationMode="server"
                        onPageChange={handlePageChange}
                        onPageSizeChange={handlePageCountChange}
                        sortingMode="server"
                        sortModel={sortModel}
                        onSortModelChange={handleSortModelChange}
                        filterMode="server"
                        onFilterModelChange={handleFilterChange}
                        onRefresh={handleRefresh}
                    />
                </Paper> : ''}

            {topupOpen && (
                <TopupWallet id={id} open={topupOpen} handleClose={handleClose}/>
            )}
            {transactionOpen && (
                <TransactionList
                    id={id}
                    open={transactionOpen}
                    handleClose={handleClose}
                />
            )}
            {editOpen && (
                <EditUser
                    id={id}
                    open={editOpen}
                    handleClose={handleClose}
                    lotteries={lotteryList}
                    user={selected}
                    updateRow={updateRow}
                />
            )}
        </React.Fragment>
    );
};

export default UserList;
