import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import Common from "./common/reducer"; //"./common/reducer";
import Auth from "./auth/reducer";
import Draw from "./draws/reducer";

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    common: Common,
    auth: Auth,
    draw: Draw,
  });
