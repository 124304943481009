import { DialogModal, TextField, Loader, Select } from "app/uielements";
import React from "react";
import { useIntl } from "react-intl";

import Grid from "@material-ui/core/Grid";
import useStyles from "./Styles";
import useData from "./State";

import MobileList from "./MobileList";
import clsx from "clsx";
import IntlMessages from "@jumbo/utils/IntlMessages";

const EditUser = ({ id, open, handleClose, updateRow, user }) => {
  const classes = useStyles();

  const {
    primaryDisabled,
    controls,
    mobileList,
    setMobileList,
    setValue,
    errors,
    getValues,
    loading,
    submit,
    register,
  } = useData({ handleClose, updateRow, user });

  const intl = useIntl();
  const format = (id) =>
    intl.formatMessage({
      id: `user.add.${id}`,
    });

  const genders = [
    { value: "male", label: format("gender.male") },
    { value: "female", label: format("gender.female") },
  ];

  const handleChange = (evt) => {
    setValue(evt.target.name, evt.target.value, { shouldValidate: true });
  };

  if(localStorage.getItem("role") != "dialog") {
  return (

    <DialogModal
      isOpen={open}
      handleClose={handleClose}
      handlePrimaryButton={submit}
      handleSecondaryButton={handleClose}
      primaryButtonLabel={format("save")}
      secondaryButtonLabel={format("cancel")}
      primaryDisabled={primaryDisabled}
      title={format("edit.title")}
      loading={(localStorage.getItem("role") != "dialog") ?( loading ):("")}
      PaperProps={{ className: classes.dialogPaper }}
    >
    

      <form noValidate>
        {loading && <Loader />}
        <Grid container item className={classes.modelContent} xs={12}>
          <Grid item xs={12} md={6} className={classes.formColumn}>
            <TextField
              required
              {...controls.fname}
              id="fname"
              name="fname"
              variant="outlined"
              label={format("fname")}
              InputLabelProps={{ shrink: getValues().fname }}
              value={getValues().fname}
              onChange={handleChange}
              error={errors.fname}
              helperText={errors.fname?.message}
            />
            <TextField
              required
              {...controls.lname}
              id="lname"
              name="lname"
              variant="outlined"
              label={format("lname")}
              InputLabelProps={{ shrink: getValues().lname }}
              value={getValues().lname}
              onChange={handleChange}
              error={errors.lname}
              helperText={errors.lname?.message}
            />
            <TextField
              {...controls.email}
              id="email"
              name="email"
              type="email"
              variant="outlined"
              label={format("email")}
              InputLabelProps={{ shrink: getValues().email }}
              value={getValues().email}
              onChange={handleChange}
              error={errors.email}
              helperText={errors.email?.message}
            />
            <Select
              required
              {...register("gender")}
              id="gender"
              name="gender"
              variant="outlined"
              label={format("gender")}
              InputLabelProps={{ shrink: getValues().gender }}
              value={getValues().gender}
              onChange={handleChange}
              error={errors.gender}
              helperText={errors.gender?.message}
              options={genders}
            />
            <TextField
              required
              {...controls.nicno}
              id="nicno"
              name="nicno"
              variant="outlined"
              label={format("nicno")}
              InputLabelProps={{ shrink: getValues().nicno }}
              value={getValues().nicno}
              onChange={handleChange}
              error={errors.nicno}
              helperText={errors.nicno?.message}
              disabled
            />
          </Grid>
          <Grid item xs={12} md={6} className={classes.formColumn}>
            <TextField
              required
              {...controls.address_1}
              id="address_1"
              name="address_1"
              variant="outlined"
              label={format("address_1")}
              InputLabelProps={{ shrink: getValues().address_1 }}
              value={getValues().address_1}
              onChange={handleChange}
              error={errors.address_1}
              helperText={errors.address_1?.message}
            />
            <TextField
              {...controls.address_2}
              id="address_2"
              name="address_2"
              variant="outlined"
              label={format("address_2")}
              value={getValues().address_2}
              InputLabelProps={{ shrink: getValues().address_2 }}
              onChange={handleChange}
              error={errors.address_2}
              helperText={errors.address_2?.message}
            />
            <TextField
              required
              {...controls.city}
              id="city"
              name="city"
              variant="outlined"
              label={format("city")}
              value={getValues().city}
              InputLabelProps={{ shrink: getValues().city }}
              onChange={handleChange}
              error={errors.city}
              helperText={errors.city?.message}
            />
            <TextField
              required
              {...controls.postal_code}
              id="postal_code"
              name="postal_code"
              variant="outlined"
              label={format("postal_code")}
              value={getValues().postal_code}
              InputLabelProps={{ shrink: getValues().postal_code }}
              onChange={handleChange}
              error={errors.postal_code}
              helperText={errors.postal_code?.message}
            />
            <div />
          </Grid>
          <Grid
            item
            xs={12}
            className={clsx(
              classes.formColumn,
              errors.telephone && classes.error
            )}
          >
            <MobileList columns={mobileList} setColumns={setMobileList} />
          </Grid>
        </Grid>
      </form>
    </DialogModal>
  );
  
  }else{
  return null;
  }
};

export default EditUser;
