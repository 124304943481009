import { DialogModal, TextField, Loader, Select } from "app/uielements";
import React from "react";
import { useIntl } from "react-intl";

import Grid from "@material-ui/core/Grid";
import useStyles from "./Styles";
import useData from "./State";
import clsx from "clsx";
import IntlMessages from "@jumbo/utils/IntlMessages";

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { isEmpty } from "lodash-es";

const EditSubscription = ({ id, open, handleClose, lotteries, user, data }) => {
  const classes = useStyles();

  const {
    primaryDisabled,
    register,
    setValue,
    getValues,
    errors,
    loading,
    submit,
    mobileList
  } = useData({ handleClose, id, data });

  const intl = useIntl();
  const format = (id, params) =>
    intl.formatMessage(
      {
        id: `user.subscriptions.${id}`,
      },
      params
    );

  const handleOnChange = (event) =>
    setValue(event.target.name, event.target.value, { shouldValidate: true });

  const handlePackageChange = (event) => {
    setValue('run_date', null, { shouldValidate: true });
    setValue(event.target.name, event.target.value, { shouldValidate: true });
  };

  const packages = [
    { value: "daily", label: format("packages.daily") },
    { value: "monthly", label: format("packages.monthly") },
    { value: "weekly", label: format("packages.weekly") },
    { value: "fortnight", label: format("packages.fortnight") },
    { value: "triweekly", label: format("packages.triweekly") },
  ];

  const runDateValues = {
    daily: [],
    weekly: [
      { value: 1, label: format("dates.monday") },
      { value: 2, label: format("dates.tuesday") },
      { value: 3, label: format("dates.wednesday") },
      { value: 4, label: format("dates.thursday") },
      { value: 5, label: format("dates.friday") },
      { value: 6, label: format("dates.saturday") },
      { value: 7, label: format("dates.sunday") },
    ],
    monthly: Array.from({ length: 31 }, (value, k) => ({
      value: k + 1,
      label: k + 1,
    })),
    fortnight: Array.from({ length: 14 }, (value, k) => ({
      value: k + 1,
      label: k + 1,
    })),
    triweekly: Array.from({ length: 21 }, (v, k) => ({
      value: k + 1,
      label: k + 1,
    })),
  };

  const runDates = runDateValues[getValues().package_name];

  return (
    <DialogModal
      isOpen={open}
      handleClose={handleClose}
      handlePrimaryButton={submit}
      handleSecondaryButton={handleClose}
      primaryButtonLabel={format("save")}
      secondaryButtonLabel={format("cancel")}
      primaryDisabled={primaryDisabled}
      title={format("edit.title", user)}
      loading={loading}
      PaperProps={{ className: classes.dialogPaper }}
    >
      <form noValidate>
        {loading && <Loader />}
        <Grid container item className={classes.modelContent} xs={12}>
          <Grid container item xs={12}>
            <Grid item xs={12} md={6} className={classes.formColumn}>
              <Select
                required
                variant="outlined"
                id="lottery_id"
                name="lottery_id"
                label={<IntlMessages id="user.subscriptions.lottery_name" />}
                InputProps={{ inputProps: { min: 0 } }}
                {...register("lottery_id", {
                  required: {
                    value: true,
                    message: <IntlMessages id="errors.required" />,
                  },
                })}
                value={getValues().lottery_id}
                onChange={handleOnChange}
                options={lotteries}
                error={!!errors.lottery_id}
                helperText={errors.lottery_id?.message}
              />
              <TextField
                required
                {...register("ticket_quantity", {
                  required: {
                    value: true,
                    message: <IntlMessages id="errors.required" />,
                  },
                })}
                type="number"
                id="ticket_quantity"
                name="ticket_quantity"
                value={getValues().ticket_quantity}
                onChange={handleOnChange}
                variant="outlined"
                label={<IntlMessages id="user.subscriptions.ticket_quantity" />}
                error={errors.ticket_quantity}
                helperText={errors.ticket_quantity?.message}
              />
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  required
                  variant="inline"
                  inputVariant="outlined"
                  format="yyyy-MM-dd"
                  margin="normal"
                  id="start_time"
                  name="start_time"
                  label={<IntlMessages id="user.subscriptions.start_time" />}
                  className={clsx(classes.fullWidth, classes.mt0)}
                  {...register("start_time", {
                    required: {
                      value: true,
                      message: <IntlMessages id="errors.required" />,
                    },
                  })}
                  value={getValues().start_time}
                  onChange={(val) =>
                    setValue("start_time", val, { shouldValidate: true })
                  }
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  autoOk
                  minDate={new Date()}
                  error={!!errors.start_time}
                  helperText={errors.start_time?.message}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} md={6} className={classes.formColumn}>
            <Select
                variant="outlined"
                id="mobile_no"
                name="mobile_no"
                label={<IntlMessages id="user.subscriptions.mobile_no" />}
                {...register("mobile_no", {
                  required: {
                    value: true,
                    message: <IntlMessages id="errors.required" />,
                  },
                })}
                value={getValues().mobile_no}
                onChange={handleOnChange}
                options={mobileList}
                error={!!errors.mobile_no}
                helperText={errors.mobile_no?.message}
              />
              <Select
                required
                variant="outlined"
                id="package_name"
                name="package_name"
                label={<IntlMessages id="user.subscriptions.package_name" />}
                InputProps={{ inputProps: { min: 0 } }}
                {...register("package_name", {
                  required: {
                    value: true,
                    message: <IntlMessages id="errors.required" />,
                  },
                })}
                value={getValues().package_name}
                onChange={handlePackageChange}
                options={packages}
                error={!!errors.package_name}
                helperText={errors.package_name?.message}
              />

              <Select
                variant="outlined"
                id="run_date"
                name="run_date"
                label={<IntlMessages id="user.subscriptions.run_date" />}
                {...register("run_date")}
                value={getValues().run_date}
                onChange={handleOnChange}
                options={runDates}
                error={!!errors.run_date}
                helperText={errors.run_date?.message}
              />

              <input 
                id="channel"
                type="hidden" 
                name="channel" 
                value="crm" 
                {...register("channel")}
              />
             
            </Grid>
          </Grid>
        </Grid>
      </form>
    </DialogModal>
  );
};

export default EditSubscription;
