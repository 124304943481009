import React from "react";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableHeading from "./LotteryTableHeading";
import TableItem from "./LotteryTableItem";
import {TableContainer, TablePagination} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import IntlMessages from "@jumbo/utils/IntlMessages";

const useStyles = makeStyles({
  root: {
    width: "100%"
  },
  container: {
    maxHeight: "69vh"
  }
});

const OrderTable = ({
  tableData,
  total,
  pageSize,
  page,
  handlePageChange,
  handlePageCountChange,
  handleMenu,
  handleRefresh
}) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <TableContainer className={classes.container}>
        <Table stickyHeader>
          <TableHead>
            <TableHeading title={<IntlMessages id="lottery.list.title" />} onRefresh={handleRefresh} />
          </TableHead>
          <TableBody>
            {tableData.map((row, index) => (
              <TableItem row={row} key={index} handleMenu={handleMenu} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[ 5, 10, 20 ]}
        component="div"
        count={total}
        rowsPerPage={pageSize}
        page={page}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handlePageCountChange}
      />
    </React.Fragment>
  );
};

export default OrderTable;
