import {useState, useEffect} from "react";

import useAxios from "../../hooks/useAxios";

const useLotteries = () => {
  const [ lotteries, setLotteries ] = useState([]);
  const axios = useAxios();
  useEffect(() => {
    const fetch = async () => {
     // const response = await axios.get("/api/lotteryStatus");

     // setLotteries(response.data.data);
    };

    fetch();
  }, []);

  return [ lotteries, setLotteries ];
};

export default useLotteries;
