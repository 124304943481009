import React from "react";
import { Loader, TextField } from "../../uielements";
import { useIntl } from "react-intl";
import DateFnsUtils from "@date-io/date-fns";
import {  Select,MenuItem,InputLabel,FormControl,OutlinedInput} from '@material-ui/core'
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
} from "@material-ui/pickers";
import Grid from "@material-ui/core/Grid";
import useStyles from "./Winngs.styles";

const Winnings = ({
  errors,
  controls,
  setValue,
  loading,
  values,
  snoVisible,
  lagnaVisible,
  luckyVisible,
  daynVisible,
  specialNumberVisible,
  special2NumberVisible,
  charVisible,
  handleChange
}) => {

  const classes = useStyles();

  const intl = useIntl();

  const format = (id) =>
    intl.formatMessage({
      id: `winnings.add.${id}`,
    });

  return (
    <Grid
      container
      direction="row"
      justify="space-around"
      alignItems="flex-start"
      className={classes.newDrawWrapper}
    >
      <form noValidate>
        <Grid container item className={classes.container} xs={12}>
          <Grid item xs={12} md={6} className={classes.formColumn}>
            <TextField
              required
              {...controls.winning_numbers}
              value={values.winning_numbers}
              onChange={handleChange}
              InputProps={{ inputProps: { min: 1 } }}
              InputLabelProps={{ shrink: true }}
              id="winning_numbers"
              name="winning_numbers"
              variant="outlined"
              label={format("winning_numbers")}
              error={errors.winning_numbers}
              helperText={errors.winning_numbers?.message} 
            />
            <TextField
              required
              {...controls.count} 
              value={values.count}
              onChange={handleChange}
              InputProps={{ inputProps: { min: 1 } }}
              InputLabelProps={{ shrink: true }}
              id="count"
              name="count"
              type="number"
              variant="outlined"
              label={format("count")}
              error={errors.count}
              helperText={errors.count?.message}
            />            
            {/* <TextField
              required
              id="next_draw_number"
              label={format("next_draw_number")}
              InputProps={{ inputProps: { min: 1 } }}
              InputLabelProps={{ shrink: true }}
              type="number"
              variant="outlined"
              {...controls.next_draw_number}
              value={values.next_draw_number}
              onChange={handleChange}
              error={errors.next_draw_number}
              helperText={errors.next_draw_number?.message}
            /> */}
            {/* <TextField
              required
              {...controls.grand_price}
              value={values.grand_price}
              onChange={handleChange}
              InputProps={{ inputProps: { min: 1 } }}
              InputLabelProps={{ shrink: true }}
              id="grand_price"
              name="grand_price"
              type="number"
              variant="outlined"
              label={format("grand_price")}
              error={errors.grand_price}
              helperText={errors.grand_price?.message}
            /> */}
            <TextField
              required
              id="next_grand_price"
              name="next_grand_price"
              label={format("next_grand_price")}
              InputProps={{ inputProps: { min: 1 } }}
              InputLabelProps={{ shrink: true }}
              {...controls.next_grand_price}
              value={values.next_grand_price}
              onChange={handleChange}
              type="number"
              variant="outlined"
              error={errors.next_grand_price}
              helperText={errors.next_grand_price?.message}
            />  
          </Grid>
          <Grid item xs={12} md={6} className={classes.formColumn}>
            {charVisible && (
              <TextField
                required
                id="letter"
                name="letter"
                label={format("letter")}
                variant="outlined"
                InputProps={{ inputProps: { maxLength: 1 } }}
                InputLabelProps={{ shrink: true }}
                {...controls.letter}
                value={values.letter}
                onChange={handleChange}
                error={errors.letter}
                helperText={errors.letter?.message}
              />
            )}
            {lagnaVisible && (
                // <TextField
                //   {...controls.lagna}
                //   value={values.lagna}
                //   onChange={handleChange}
                //   InputProps={{ inputProps: { min: 1 } }}
                //   InputLabelProps={{ shrink: true }}
                //   id="lagna"
                //   name="lagna"
                //   type="text"
                //   variant="outlined"
                //   label={format("lagna")}
                //   error={errors.lagna}
                //   helperText={errors.lagna?.message}
                // />
         <div>  
          <FormControl fullWidth required >
          {/* <InputLabel id="demo-simple-select-label">lagna</InputLabel> */}
          <InputLabel id="demo-simple-select-required-label" style={{fontSize:"13px",margin:'4px'}}>lagna</InputLabel>
         <Select
   
           {...controls.lagna}
             value={values.lagna}
             onChange={handleChange}
             InputProps={{ inputProps: { min: 1 } }}
             InputLabelProps={{ shrink: true }}
             id="lagna"
             name="lagna"
             type="text"
             variant="outlined"
             input={<OutlinedInput label={format("lagna")} />}
             error={errors.lagna}
             helperText={errors.lagna?.message}
             
         >
           <MenuItem value={'ARIES'}>ARIES/මේෂ</MenuItem>
           <MenuItem value={'TAURUS'}>TAURUS/වෘෂභ</MenuItem>
           <MenuItem value={'GEMINI'}>GEMINI/මිථුන</MenuItem>
           <MenuItem value={'CANCER'}>CANCER/කටක</MenuItem>
           <MenuItem value={'LEO'}>LEO/සිංහ</MenuItem>
           <MenuItem value={'VIRGO'}>VIRGO/කන්‍යා</MenuItem>
           <MenuItem value={'LIBRA'}>LIBRA/තුලා</MenuItem>
           <MenuItem value={'SCORPIO'}>SCORPIO/වෘශ්චික</MenuItem>
           <MenuItem value={'SAGITTARIUS'}>SAGITTARIUS/ධනු</MenuItem>
           <MenuItem value={'CAPRICORN'}>CAPRICORN/මකර</MenuItem>
           <MenuItem value={'AQUARIUS'}>AQUARIUS/කුම්භ</MenuItem>
           <MenuItem value={'PISCES'}>PISCES/මීන</MenuItem>
         </Select>
         </FormControl></div>     


              )}
            {snoVisible && (
              <TextField
                {...controls.super_number}
                value={values.super_number}
                onChange={handleChange}
                InputProps={{ inputProps: { min: 1 } }}
                InputLabelProps={{ shrink: true }}
                id="super_number"
                name="super_number"
                type="number"
                variant="outlined"
                label={format("super_number")}
                error={errors.super_number}
                helperText={errors.super_number?.message}
              />
            )}
            {specialNumberVisible && (
              <TextField
                required  
                {...controls.special_no}
                value={values.special_no}
                onChange={handleChange}
                InputProps={{ inputProps: { min: 1 } }}
                InputLabelProps={{ shrink: true }}
                id="special_no"
                name="special_no"
                type="text"
                variant="outlined"
                label={format("special")}
                error={errors.special_no}
                helperText={errors.special_no?.message}
              />
            )} 
            {special2NumberVisible && (
              <TextField
                required  
                {...controls.special2_no}
                value={values.special2_no}
                onChange={handleChange}
                InputProps={{ inputProps: { min: 1 } }}
                InputLabelProps={{ shrink: true }}
                id="special2_no"
                name="special2_no"
                type="text"
                variant="outlined"
                label={format("special2")}
                error={errors.special2_no}
                helperText={errors.special2_no?.message}
              />
            )}   
            {luckyVisible && (
                <TextField
                  {...controls.lucky}
                  value={values.lucky}
                  onChange={handleChange}
                  InputProps={{ inputProps: { min: 1 } }}
                  InputLabelProps={{ shrink: true }}
                  id="lucky"
                  name="lucky"
                  type="text"
                  variant="outlined"
                  label={format("lucky")}
                  error={errors.lucky}
                  helperText={errors.lucky?.message}
                />
            )}    
            {daynVisible && (
                <TextField
                  {...controls.dayn}
                  value={values.dayn}
                  onChange={handleChange}
                  InputProps={{ inputProps: { min: 1 } }}
                  InputLabelProps={{ shrink: true }}
                  id="dayn"
                  name="dayn"
                  type="text"
                  variant="outlined"
                  label={format("dayn")}
                  error={errors.dayn}
                  helperText={errors.dayn?.message}
                />
            )}                   
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDateTimePicker
                required
                disableToolbar
                format="MM/dd/yyyy hh:mm a"
                margin="normal"
                inputVariant="outlined"
                id="schedule_start_date"
                label={format("schedule_start_date")}
                InputLabelProps={{ shrink: true }}
                className={classes.fullWidth}
                value={values.schedule_start_date}
                onChange={(val) =>
                  setValue("schedule_start_date", val, { shouldValidate: true })
                }
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                autoOk
                error={errors.schedule_start_date}
                helperText={errors.schedule_start_date?.message}
              />
            </MuiPickersUtilsProvider>
          </Grid>

          <Grid item xs={12} className={classes.lastCol}>
            <TextField
              required
              className={classes.fullWidth}
              {...controls.message}
              value={values.message}
              onChange={(e) =>
                setValue("message", e.target.value, { shouldValidate: true })
              }
              InputLabelProps={{ shrink: true }}
              name="message"
              id="message"
              multiline
              rows={7}
              label={format("message")}
              variant="outlined"
              error={!!errors.message}
              helperText={errors.message ? errors.message.message : ""}
            />
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
};

export default Winnings;
