import {applyMiddleware, compose, createStore} from "redux";
import axios from "axios";
import thunk from "redux-thunk";
import {createBrowserHistory} from "history";
import axiosMiddleware from "redux-axios-middleware";

import reducers from "./reducers";
import {securityMiddleware} from "../../services/auth/Basic";

const history = createBrowserHistory();

const client = axios.create({
  baseURL: window.location.origin,
  responseType: "json"
});

window.axiosClient = client;

const axiosMiddlewareOptions = {
  interceptors: {
    request: [
      async (getState, config) => {
        config.headers.common.Authorization = `Bearer ${localStorage.getItem(
          "atkn"
        )}`;
        config.headers.common.Accept = "application/json";
        const baseURL = process.env.REACT_APP_BASE_URL;
        config.url = `${baseURL}/api${config.url}`;
        return config;
      }
    ],
    response: [
      {
        error({getState, dispatch}, error) {
          if (error.response.status === 403 || error.response.status === 401) {
            if (window.location.pathname != "/signin") {
              window.location.pathname = "/signin";
            }
          } else if (error.response.status === 402) {
            window.location.pathname = "/logout";
            return {code: 402};
          } else if (error.response.status === 412) {
            window.location.pathname = "/logout";
          }
          return Promise.reject(error);
        }
      }
    ]
  },
  returnRejectedPromiseOnError: true
};

const enhancers = [];

const middleware = [
  thunk,
  axiosMiddleware(client, axiosMiddlewareOptions),
  securityMiddleware
];

if (process.env.NODE_ENV === "development") {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

  if (typeof devToolsExtension === "function") {
    enhancers.push(devToolsExtension());
  }
}

const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers);

function configureStore(initialState = {}){
  const store = createStore(reducers(history), initialState, composedEnhancers);
  return store;
}
export default configureStore;
export {history};
