import CmtCard from "@coremat/CmtCard";
import CmtCardContent from "@coremat/CmtCard/CmtCardContent";
import CmtCardHeader from "@coremat/CmtCard/CmtCardHeader";
import IntlMessages from "@jumbo/utils/IntlMessages";
import { DataGrid } from "@material-ui/data-grid";
import React from "react";

const Results = ({
  rows,
  title,
  className,
  titleProps,
  total,
  pageSize,
  handlePageChange,
  handlePageCountChange,
  handleSortModelChange,
  sortModel,
  handleFilterChange,
  loading,
  status,
}) => {

  let columns=[];
  if (status=='highwinning'){
    columns = [
      {
        field: "draw_date",
        headerName: <IntlMessages id="report.highwinning.results.draw_date" />,
        flex: 1,
      },
      {
        field: "lottery_name",
        headerName: <IntlMessages id="report.highwinning.results.lottery_name" />,
        flex: 1.2,
      },
      {
        field: "serial_no",
        headerName: <IntlMessages id="report.highwinning.results.serial_no" />,
        flex: 1.2,
      },
      {
        field: "ticket_numbers",
        headerName: <IntlMessages id="report.highwinning.results.ticket_numbers" />,
        flex: 1,
      },
      {
        field: "mobile_no",
        headerName: <IntlMessages id="report.highwinning.results.mobile_no" />,
        flex: 1.2,
      },
      {
        field: "nicno",
        headerName: <IntlMessages id="report.highwinning.results.nicno" />,
        flex: 1,
      }
    ]

  }
  else if (status=='revenue'){
    columns = [
      {
        field: "draw_date",
        headerName: <IntlMessages id="report.revenue.results.draw_date" />,
        flex: 1,
      },
      {
        field: "lottery_name",
        headerName: <IntlMessages id="report.revenue.results.lottery_name" />,
        flex: 1.2,
      },
      {
        field: "sms_channel",
        headerName: <IntlMessages id="report.revenue.results.sms" />,
        flex: 1.2,
      },
      {
        field: "ussd",
        headerName: <IntlMessages id="report.revenue.results.ussd" />,
        flex: 1,
      },
      {
        field: "web_channel",
        headerName: <IntlMessages id="report.revenue.results.web" />,
        flex: 1.2,
      },
      {
        field: "crm_channel",
        headerName: <IntlMessages id="report.revenue.results.crm" />,
        flex: 1,
      },
      {
        field: "sub_channel",
        headerName: <IntlMessages id="report.revenue.results.subscription" />,
        flex: 1,
      },
    
      {
        field: "unsale",
        headerName: <IntlMessages id="report.revenue.results.unsale" />,
        flex: 1,
      }
    ]

  }else if (status=='agentssales'){
    columns = [
      {
        field: "agent_code",
        headerName: <IntlMessages id="report.revenue.results.agent_code" />,
        flex: 1,
      },
      {
        field: "draw_number",
        headerName: <IntlMessages id="report.sale.results.draw_number" />,
        flex: 1,
      },{
        field: "draw_date",
        headerName: <IntlMessages id="report.revenue.results.draw_date" />,
        flex: 1,
      },
      {
        field: "lottery_name",
        headerName: <IntlMessages id="report.revenue.results.lottery_name" />,
        flex: 1.2,
      },
      {
        field: "sms_channel",
        headerName: <IntlMessages id="report.revenue.results.sms" />,
        flex: 1.2,
      },
      {
        field: "web_channel",
        headerName: <IntlMessages id="report.revenue.results.web" />,
        flex: 1.2,
      },
      {
        field: "crm_channel",
        headerName: <IntlMessages id="report.revenue.results.crm" />,
        flex: 1,
      },
      {
        field: "sub_channel",
        headerName: <IntlMessages id="report.revenue.results.subscription" />,
        flex: 1,
      },
    ]

  } else if (status=='sales'){

    columns = [
      {
        field: "lottery_name",
        headerName: <IntlMessages id="report.sale.results.lottery_name" />,
        flex: 1.2,
      },
      {
        field: "draw_number",
        headerName: <IntlMessages id="report.sale.results.draw_number" />,
        flex: 1,
      },
      {
        field: "draw_date",
        headerName: <IntlMessages id="report.sale.results.draw_date" />,
        flex: 1,
      },
     
      {
        field: "total_sales",
        headerName: <IntlMessages id="report.sale.results.total_sales" />,
        flex: 1.2,
      },
      {
        field: "unsale",
        headerName: <IntlMessages id="report.sale.results.unsale" />,
        flex: 1,
      },
      {
        field: "actual_sales",
        headerName: <IntlMessages id="report.sale.results.actual_sales" />,
        flex: 1,
      },
      {
        field: "ap_account",
        headerName: <IntlMessages id="report.sale.results.ap_account" />,
        flex: 1,
      },
      {
        field: "dialog",
        headerName: <IntlMessages id="report.sale.results.dialog" />,
        flex: 1,
      },
      {
        field: "mobitel",
        headerName: <IntlMessages id="report.sale.results.mobitel" />,
        flex: 1,
      },
      {
        field: "promo",
        headerName: <IntlMessages id="report.sale.results.promo" />,
        flex: 1,
      },
      {
        field: "ezcash",
        headerName: <IntlMessages id="report.sale.results.ezcash" />,
        flex: 1,
      },
    ]

  }else if (status=='salesdetail'){

    columns = [
      {
        field: "lottery_name",
        headerName: <IntlMessages id="report.salesdetail.results.lottery_name" />,
        flex: 1.2,
      },
      {
        field: "draw_number",
        headerName: <IntlMessages id="report.salesdetail.results.draw_number" />,
        flex: 1,
      },
      {
        field: "draw_date",
        headerName: <IntlMessages id="report.salesdetail.results.draw_date" />,
        flex: 1,
      },
     
      {
        field: "serial_no",
        headerName: <IntlMessages id="report.salesdetail.results.serial_no" />,
        flex: 1.2,
      },
      {
        field: "mobile_no",
        headerName: <IntlMessages id="report.salesdetail.results.msisdn" />,
        flex: 1,
      },
      {
        field: "nicno",
        headerName: <IntlMessages id="report.salesdetail.results.nicno" />,
        flex: 1,
      },
      {
        field: "payment_method",
        headerName: <IntlMessages id="report.salesdetail.results.payment_method" />,
        flex: 1,
      }
    ]

  }else if (status=='wallettransfer'){

    columns = [
      {
        field: "nicno",
        headerName:"NIC No.",
        flex: 1.2,
      },
      {
        field: "fulname",
        headerName: "Full Name",
        flex: 1,
      },
      {
        field: "transxdate",
        headerName: "Trans. Date",
        flex: 1,
      },   
      {
        field: "credit",
        headerName: "Credit",
        flex: 1.2,
      },
      {
        field: "debit",
        headerName:"Debit",
        flex: 1.2,
      },
      {
        field: "slip_no",
        headerName:"Slip No",
        flex: 1,
      },
      {
        field: "updated",
        headerName: "Updated By",
        flex: 1,
      }
    ]

  }else if (status=='walletbalance'){

    columns = [
      {
        field: "telephone",
        headerName:"Mobile No",
        flex: 1.2,
      },
      {
        field: "nicno",
        headerName: "NIC No",
        flex: 1,
      },
      {
        field: "fulname",
        headerName: "Full Name",
        flex: 1,
      },   
      {
        field: "transxdate",
        headerName: "Trans. Date",
        flex: 1.2,
      },
      {
        field: "balance",
        headerName:"Balance",
        flex: 1.2,
      },
    
    ]

  }else{

  columns = [
    {
      field: "serial_no",
      headerName: <IntlMessages id="report.search.results.serial_no" />,
      flex: 1,
    },
    {
      field: "tk_number",
      headerName: <IntlMessages id="report.search.results.tk_number" />,
      flex: 1.2,
    },
    {
      field: "purchase_date",
      headerName: <IntlMessages id="report.search.results.purchase_date" />,
      flex: 1.2,
    },
    {
      field: "winning_price",
      headerName: <IntlMessages id="report.search.results.winning_price" />,
      flex: 1,
    },
    {
      field: "nicno",
      headerName: <IntlMessages id="report.search.results.nicno" />,
      flex: 1,
    },
    {
      field: "mobile_no",
      headerName: <IntlMessages id="report.search.results.mobile_no" />,
      flex: 1,
    },
  ];
}
  return (
    <CmtCard className={className}>
      <CmtCardHeader title={title} titleProps={titleProps} />
      {rows.length > 0 && (
        <CmtCardContent style={{ height: 655 }}>
          <DataGrid
            rows={rows}
            columns={columns}
            pagination
            pageSize={pageSize}
            rowsPerPageOptions={[10, 20, 50, 100]}
            rowCount={total}
            paginationMode="server"
            onPageChange={handlePageChange}
            onPageSizeChange={handlePageCountChange}
            sortingMode="server"
            sortModel={sortModel}
            onSortModelChange={handleSortModelChange}
            filterMode="server"
            onFilterModelChange={handleFilterChange}
            loading={loading}
          />
        </CmtCardContent>
      )}
    </CmtCard>
  );
};

export default Results;
