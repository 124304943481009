import React, {useState, useEffect} from "react";
import {DialogModal} from 'app/uielements';
import {useIntl} from 'react-intl';
import showAlert from "app/hooks/useAlerts";
import Swal from 'sweetalert2';
import {TextField, Button, Grid, Typography, Switch, FormControlLabel, DialogContent} from '@material-ui/core';
import useAxios from "app/hooks/useAxios";

const TicketAutomation = ({open, handleClose, id, lottery_id, name, draw_number}) => {
    const intl = useIntl();
    const [minQuantity, setMinQuantity] = useState('');
    const [amountTransfer, setAmountTransfer] = useState('');
    const [returnBlockSize, setReturnBlockSize] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [toggle, setToggle] = useState(false);
    const axios = useAxios();
    const handleDialogClose = () => {
        handleClose();
    };

    console.log("draw_number", draw_number)


    const handleSubmit = async () => {
        if (amountTransfer % returnBlockSize !== 0) {
            setErrorMessage(`Amount Transfer must be a multiple of ${returnBlockSize}`)
            return
        }

        const data = {
            min_lottery_trigger: minQuantity,
            transfer_qty: amountTransfer,
            status: toggle ? 1 : 0
        };

        try {
            const response = await axios.post(`/api/ticket_auto_transfer/draw/update/${id}`, data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            console.log("response", response);

            if (response.status !== 200) {
                throw new Error('Network response was not ok');
            }
            if (response.status === 200) {
                showAlert("success", "Restock Value Updated Successfully.");

                handleClose();
            } else {


                showAlert("error", 'error');

            }
            setErrorMessage('');

        } catch (error) {
            console.error('Error:', error);
            setErrorMessage('There was an error submitting the data.');
            setSuccessMessage('');
        }
    };

    const handleAmountChange = (e) => {
        const newAmount = e.target.value;
        setAmountTransfer(newAmount);

        if (newAmount % returnBlockSize === 0) {
            setErrorMessage('');
        } else {
            setErrorMessage(`Amount must be a multiple of ${returnBlockSize}.`);
        }
    };


    const handleDefaultSubmit = async () => {
        try {

            const response = await axios.get(`/api/ticket_auto_transfer/draw/${id}`, {});

            if (!response.data) {
                throw new Error('Failed to fetch data');
            }

            console.log('toggle', response.data)
            const data = {
                minQuantity: response.data[1].min_lottery_trigger,
                amountTransfer: response.data[1].transfer_qty,
                status: response.data[1].status,
                return_block_size: response.data[0].lottery.return_block_size
            };

            setMinQuantity(data.minQuantity);
            setAmountTransfer(data.amountTransfer);
            setToggle(data.status);
            setReturnBlockSize(data.return_block_size);


        } catch (error) {
            console.error('Error fetching data:', error);
            setErrorMessage('Failed to fetch data');
        }
    };

    useEffect(() => {
        if (open && id) {
            handleDefaultSubmit();
        }
    }, [open, id]);


    const handleToggleChange = (event) => {
        setToggle(event.target.checked);
    };
    const handleReset = async () => {

        try {

            const response = await axios.get(`/api/ticket_auto_transfer/draw/reset/${id}`, {});

            if (!response.data) {
                throw new Error('Failed to fetch data');
            }

            if (response.status === 200) {
                showAlert("success", "Restock Value Reset Successfully.");
            }

            console.log('toggle', response.data.auto_transfer_status)
            const data = {
                minQuantity: response.data[0].min_lottery_trigger,
                amountTransfer: response.data[0].transfer_qty,

            };

            setMinQuantity(data.minQuantity);
            setAmountTransfer(data.amountTransfer);


        } catch (error) {
            console.error('Error fetching data:', error);
            setErrorMessage('Failed to fetch data');
        }
    }

    return (
        <div>
            <DialogModal
                isOpen={open}
                handleClose={handleDialogClose}
                primaryDisabled={true}
                enableActions={false}
                title={"TicketAuto Restock - " + " " + name}
                backdrop="static"
            >
                <Grid container spacing={2} alignItems="center" justifyContent="center">
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Min Quantity To Trigger"
                                    type="number"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    variant="outlined"
                                    value={minQuantity}
                                    onChange={(e) => setMinQuantity(e.target.value)}
                                    disabled={!toggle}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Amount Transfer"
                                    type="number"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    variant="outlined"
                                    value={amountTransfer}
                                    onChange={handleAmountChange}
                                    disabled={!toggle}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            {errorMessage && (
                                <Typography variant="body1" style={{color: 'red'}}>
                                    {errorMessage}
                                </Typography>
                            )}
                        </Grid>

                        <Grid container spacing={4} justifyContent="center" alignItems="center">
                            <Grid item xs={12} sm={6} md={8}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={toggle}
                                            onChange={handleToggleChange}
                                            name="toggle"
                                            color="primary"
                                        />
                                    }
                                    label={toggle ? 'On' : 'Off'}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3} md={2}>
                                <Button
                                    variant="outlined"
                                    style={{backgroundColor: "#ff3d00", color: "white"}}
                                    fullWidth
                                    onClick={handleReset}
                                >
                                    Reset
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={3} md={2}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleSubmit}
                                    fullWidth
                                >
                                    OK
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogModal>
        </div>
    );
};

export default TicketAutomation;
