import React from 'react';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableHeading from './TableHeading';
import TableItem from './TableItem';
import Box from '@material-ui/core/Box';

const DrawSummary = ({draws}) => {
  return (
    <Box className="Cmt-table-responsive">
      <Table>
        <TableHead>
          <TableHeading />
        </TableHead>
        <TableBody>
          {draws.map(row => (
            <TableItem row={row} key={row.id} />
          ))}        
        </TableBody>
      </Table>      
    </Box>
  );
};

export default DrawSummary;
