import React from "react";

import CmtCard from "@coremat/CmtCard";
import CmtCardHeader from "@coremat/CmtCard/CmtCardHeader";
import CmtList from "@coremat/CmtList";
import PageContainer from "@jumbo/components/PageComponents/layouts/PageContainer";
import IntlMessages from "@jumbo/utils/IntlMessages";
import { Grid } from "@material-ui/core";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Link } from "react-router-dom";
import useStyles from "./Styles";
import ReportItem from "./ReportItem";

const Reports = ({}) => {
  const reportList = [
    {
      title: <IntlMessages id="reports.type.consoldwithmask" />,
      subtitle: <IntlMessages id="reports.type.consoldwithmask.subtitle" />,
      type: "consoldwithmask",
      url: "/reports/consoldwithmask",
      image: '/images/reports/chrome.png',

    },
    {
      title: <IntlMessages id="reports.type.consoldwithoutmask" />,
      subtitle: <IntlMessages id="reports.type.consoldwithoutmask.subtitle" />,
      type: "consoldwithoutmask",
      url: "/reports/consoldwithoutmask",
      image: '/images/reports/firefox.png',

    },
    {
      title: <IntlMessages id="reports.type.sales" />,
      subtitle: <IntlMessages id="reports.type.sales.subtitle" />,
      type: "sales",
      url: "/reports/sales",
      image: '/images/reports/firefox.png'
    
    },
    {
      title: <IntlMessages id="reports.type.salesdetail" />,
      subtitle: <IntlMessages id="reports.type.sales.salestracking" />,
      type: "salesdetail",
      url: "/reports/salesdetail",
      image: '/images/reports/firefox.png'
    
    },
    // {
    //   title: <IntlMessages id="reports.type.salestracking" />,
    //   subtitle: <IntlMessages id="reports.type.salestracking" />,
    //   type: "salestracking",
    //   url: "/reports/salestracking",
    //   image: '/images/reports/firefox.png'
    
    // },
    {
      title: <IntlMessages id="reports.type.revenue" />,
      subtitle: <IntlMessages id="reports.type.revenue.subtitle" />,
      type: "revenue",
      url: "/reports/revenue",
      image: '/images/reports/firefox.png'
      
    },
    {
      title: <IntlMessages id="reports.type.highwinning" />,
      subtitle: <IntlMessages id="reports.type.highwinning.subtitle" />,
      type: "highwinning",
      url: "/reports/highwinning",
      image: '/images/reports/firefox.png'
      
    },
    {
      title: <IntlMessages id="reports.type.wallettransfer" />,
      subtitle: <IntlMessages id="reports.type.wallettransfer.subtitle" />,
      type: "walletransfer",
      url: "/reports/wallettransfer",
      image: '/images/reports/firefox.png'
      
    },
    {
      title: <IntlMessages id="reports.type.walletbalance" />,
      subtitle: <IntlMessages id="reports.type.walletbalance.subtitle" />,
      type: "walletbalance",
      url: "/reports/walletbalance",
      image: '/images/reports/firefox.png'

    },
    {
      title: <IntlMessages id="reports.type.agentssales" />,
      subtitle: <IntlMessages id="reports.type.agentssales.subtitle" />,
      type: "agentssales",
      url: "/reports/agentssales",
      image: '/images/reports/firefox.png'

    }
  ];

  const breadcrumbs = [
    { label: "Home", link: "/" },
    { label: "Reports", link: "/reports", isActive: true },
  ];

  const classes = useStyles();

  return (
    <PageContainer
      heading={<IntlMessages id="reports.title" />}
      breadcrumbs={breadcrumbs}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <CmtCard className={classes.root}>
            <CmtCardHeader title={<IntlMessages id="reports.list.title" />} />
            <PerfectScrollbar className={classes.perScrollbarRoot}>
              <CmtList
                data={reportList}
                renderRow={(report, index) => (
                  <Link to={report.url}>
                    <ReportItem item={report} />
                  </Link>
                )}
              />
            </PerfectScrollbar>
          </CmtCard>
        </Grid>
      </Grid>
    </PageContainer>
  );
};

export default Reports;
