import React, { useState, useEffect } from "react";
import useAxios from "../../hooks/useAxios";
const useTickets = () => {
  const axios = useAxios();

  const [id, setId] = useState(0);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState(0);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);

  const handlePageChange = (params) => {
    setPage(params.page);
  };

  const handlePageCountChange = (params) => {
    setPageSize(params.pageSize);
    setPage(0);
  };

  useEffect(() => {
    let active = true;
    setLoading(true);
    (async () => {
      if (id < 1) return;
      try {
        const response = await axios.post("/api/ticket?page=" + (page + 1), {
          page_count: pageSize,
          draw_id: id,
          status: "winning",
        });
        setLoading(false);
        const newRows = response.data.data;
        const totalValue = response.data.total;

        if (!active) {
          return;
        }

        setRows(newRows);
        setTotal(totalValue);
      } catch (error) {
        setLoading(false);
      }
    })();

    return () => {
      active = false;
    };
  }, [page, total, pageSize, id]);

  return {
    rows,
    total,
    loading,
    pageSize,
    handlePageChange,
    handlePageCountChange,
    setRows,
    setId,
  };
};

export default useTickets;
