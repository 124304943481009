export const drawTypes = [
  {
    value: "Normal",
    label: "Normal",
  },
  {
    value: "Special",
    label: "Special",
  },
];

export const steps = [
  { label: "Create Draw", optional: false },
  { label: "Import Lotteries", optional: false },
  { label: "Finish Import", optional: false },
];

export const drawFormErrors = {
  default: "This field can not be empty",
  dateFormatError: "Invalid date format",
  invalid: "Invalid input value",
  startDateError: "Start date can not be a past date",
  endDateError: "This must be a date after issue start date",
  drawDateError: "This must be a date after issue start date",
  bufferError: "This value should be at least 10",
};
