import React from "react";
import Typography from "@material-ui/core/Typography";
import { DropzoneArea } from "material-ui-dropzone";

import useStyles from "./CreateDraw.styles";

const ImportLotteries = ({setFile}) => {
  const classes = useStyles();

  const handleChange = (files) => {    
    setFile(files[0]);
  };

  return (
    <div className={classes.root}>
      <Typography variant="h3" gutterBottom>
        Import Lotteries to the draw
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        Please import our lottery file here in order to complete draw creation.
      </Typography>
      <DropzoneArea
        acceptedFiles={[
          ".csv",
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          "application/vnd.ms-excel",
        ]}
        showPreviews={true}
        showPreviewsInDropzone={false}
        useChipsForPreview
        previewGridProps={{
          container: { spacing: 1, direction: "row" },
        }}
        previewChipProps={{ classes: { root: classes.previewChip } }}
        previewText="Selected files"
        onChange={handleChange}
        filesLimit={1}
      />
    </div>
  );
};

export default ImportLotteries;
