import { DialogModal, TextField, Loader, Select } from "app/uielements";
import React from "react";
import { useIntl } from "react-intl";

import Grid from "@material-ui/core/Grid";
import useStyles from "./Styles";

import Bank from "./Bank";
import Branch from "./Branch";
import IntlMessages from "@jumbo/utils/IntlMessages";
import useData from './State';

const EditAccount = ({ open, handleClose, updateAccount, id, bankItem }) => {
  const classes = useStyles();
  const {
    register,
    setValue,
    setBank,
    errors,
    submit,
    loading,
    bank,
    getValues
  } = useData({ id, updateAccount, bankItem})

  const intl = useIntl();
  const format = (id) =>
    intl.formatMessage({
      id: `user.bank.${id}`,
    });

    const handleChange = evt => setValue(evt.target.name, evt.target.value, {shouldValidate: true});

    return (
    <DialogModal
      isOpen={open}
      handleClose={handleClose}
      handlePrimaryButton={submit}
      handleSecondaryButton={handleClose}
      primaryButtonLabel={format("save")}
      secondaryButtonLabel={format("cancel")}
      primaryDisabled={loading}
      title={format("add.title")}
    >
      <form noValidate className={classes.root}>
        {loading && <Loader />}
        <Grid container item className={classes.modelContent} xs={12}>
          <Grid item xs={12} md={6} className={classes.formColumn}>
            <Bank
              register={register}
              value={getValues().bank_name}
              onChange={(evt) => {
                if (evt) {
                  setBank(evt);
                  setValue("bank_name", evt.bank_name, {
                    shouldValidate: true,
                  });
                } else {
                  setBank(null);
                  setValue("bank_name", undefined, {
                    shouldValidate: true,
                  });
                }
              }}
              errors={errors}
            />
            <Branch
              register={register}
              value={getValues().branch_name}
              onChange={(evt) => {
                if (evt) {
                  setValue("branch_code", evt.branch_code, {
                    shouldValidate: true,
                  });
                  setValue("branch_name", evt.bank_name, {
                    shouldValidate: true,
                  });
                } else {
                  setValue("branch_code", undefined, {
                    shouldValidate: true,
                  });
                  setValue("branch_name", undefined, {
                    shouldValidate: true,
                  });
                }
              }}
              bank={bank}
              errors={errors}
            />
          </Grid>
          <Grid item xs={12} md={6} className={classes.formColumn}>
            <TextField
              required
              {...register("account_name", {
                required: {
                  value: true,
                  message: <IntlMessages id="errors.required" />,
                },
              })}
              id="account_name"
              name="account_name"
              variant="outlined"
              label={format("account_name")}
              value={getValues().account_name}
              onChange={handleChange}
              error={errors.account_name}
              helperText={errors.account_name?.message}
            />
            <TextField
              required
              {...register("account_number", {
                required: {
                  value: true,
                  message: <IntlMessages id="errors.required" />,
                },
              })}
              id="account_number"
              name="account_number"
              variant="outlined"
              label={format("account_number")}
              value={getValues().account_number}
              onChange={handleChange}
              error={errors.account_number}
              helperText={errors.account_number?.message}
            />
          </Grid>
        </Grid>
      </form>
    </DialogModal>
  );
};

export default EditAccount;
