import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import makeStyles from '@material-ui/core/styles/makeStyles';
import IntlMessages from '@jumbo/utils/IntlMessages';

const useStyles = makeStyles(theme => ({
  tableCellRoot: {
    paddingLeft: 6,
    paddingRight: 6,
    paddingTop: 0,
    paddingBottom: 12,
    fontSize: 12,
    letterSpacing: 0.4,
    color: theme.palette.common.dark,
    fontWeight: theme.typography.fontWeightRegular,
    borderBottom: '0 none',
    '&:first-child': {
      paddingLeft: 32,
    },
  },
}));

const TableHeading = () => {
  const classes = useStyles();
  return (
    <TableRow>
      <TableCell m="auto" className={classes.tableCellRoot}><IntlMessages id="dashboard.draws_summary.agent_code" /></TableCell>
      <TableCell m="auto" className={classes.tableCellRoot}><IntlMessages id="dashboard.draws_summary.draw_date" /></TableCell>
      <TableCell m="auto" className={classes.tableCellRoot}><IntlMessages id="dashboard.draws_summary.passive_sales_count" /></TableCell>
      <TableCell m="auto" className={classes.tableCellRoot}><IntlMessages id="dashboard.draws_summary.active_sales_count" /></TableCell>
    </TableRow>
  );
};

export default TableHeading;
