import IntlMessages from "@jumbo/utils/IntlMessages";
import { Box, Grid, Paper, Switch, Typography } from "@material-ui/core";
import { TextField } from "app/uielements";
import Select from "app/uielements/Select/Select";
import React, { useMemo,useEffect,useState } from "react";
import { toFixed } from "services/utils";

import useStyles from "./Styles";

const Balance = ({ selected, winning, wallet, agentWallet, genie, getValues, setValue, mobileList,enableOno,premium }) => {
  const classes = useStyles();
  const [premiumOn, setPremiumOn] = useState(false);

  useEffect(() => {

    if (enableOno){
      setValue('enableOno',true);
      setValue('premium',premium);
      setPremiumOn(true);
    }else{

      setValue('premium',0);
      setPremiumOn(false);
    }
    
  }, [enableOno]);

  useEffect(() => {

    if (premium)
      setValue('premium',premium);
    
}, [premium]);

  const totalAmount = useMemo(() =>
    toFixed(selected.map((s) => s.ticket_price).reduce((a, n) => a + n, 0))
  );

  const enableWinning = useMemo(() => winning > 0);
  const enableWallet = useMemo(() => wallet > 0);


  const enableGenie = useMemo(() => genie > 0);
  const enableAgentWallet = useMemo(() => agentWallet > 0);
  const handleMobileChange = (event) =>
    setValue(event.target.name, event.target.value);
  const onChange = (evt) => {
    setValue(evt.target.name, evt.target.checked);
  };

  const onChangeOno = (evt) => {
    setValue(evt.target.name, evt.target.checked);
    setPremiumOn(evt.target.checked);

  };



  return (
    <Paper title={"Ticket Details"}>
      <form>
        <Grid container xs={12}>
          <Grid container item xs={12} className={classes.rowRoot}>
            <Grid item xs={6} md={4}>
              <Typography variant="h4" className="cmt-title">
                <IntlMessages id="user.purchase.total_tickets" />
              </Typography>
            </Grid>
            <Grid item xs={6} md={4}>
              {selected.length}
            </Grid>
          </Grid>
          <Grid container item xs={12} className={classes.rowRoot}>
            <Grid item xs={6} md={4}>
              <Typography variant="h4" className="cmt-title">
                <IntlMessages id="user.purchase.total_amount" />
              </Typography>
            </Grid>
            <Grid item xs={2} md={2}>
              {premiumOn?totalAmount*(1+parseFloat(getValues().premium)/100):totalAmount}
            </Grid>
            <Grid item xs={2} md={2}>
            <Typography variant="h4" className="cmt-title">
            {enableOno && (  <IntlMessages id="user.purchase.insurance_status" />)}
           </Typography>
           </Grid>
           <Grid item xs={2} md={2}>
          {enableOno && (<Switch
                name="enableOno"
                checked={getValues().enableOno}
                color="primary"
                onChange={onChangeOno}
                disabled={!enableWallet && !enableWinning && !enableGenie}
              />)}
            </Grid>
          </Grid>
          <Grid container item xs={12} className={classes.rowRoot}>
            <Grid item xs={6} md={4}>
              <Typography variant="h4" className="cmt-title">
                <IntlMessages id="user.purchase.winning_balance" />
              </Typography>
            </Grid>
            <Grid item xs={6} md={4}>
              {toFixed(winning)}
            </Grid>
            <Grid item xs={6} md={3}>
              <TextField
                variant="outlined"
                type="number"
                disabled={!getValues().enableWinning}
                value={getValues().winningAmount}
                onChange={(e) => setValue("winningAmount", e.target.value)}
                error={premiumOn?(getValues().winningAmount > totalAmount*(1+parseFloat(getValues().premium))):(getValues().winningAmount > totalAmount)}
                helperText={
                  getValues().winningAmount > winning
                    ? "Not enough balance"
                    : ""
                }
              />
            </Grid>
            <Grid item xs={6} md={1}>
              <Switch
                disabled={!enableWinning}
                checked={getValues().enableWinning}
                color="primary"
                onChange={(e) => setValue("enableWinning", e.target.checked)}
              />
            </Grid>
          </Grid>
          <Grid container item xs={12} className={classes.rowRoot}>
            <Grid item xs={6} md={4}>
              <Typography variant="h4" className="cmt-title">
                <IntlMessages id="user.purchase.wallet_balance" />
              </Typography>
            </Grid>
            <Grid item xs={6} md={4}>
              {toFixed(wallet)}
            </Grid>
            <Grid item xs={6} md={3}>
              <TextField
                variant="outlined"
                type="number"
                disabled={!getValues().enableWallet}
                value={getValues().walletAmount}
                onChange={(e) => setValue("walletAmount", e.target.value)}
                error={premiumOn?getValues().walletAmount > totalAmount*(1+parseFloat(getValues().premium)):getValues().walletAmount > totalAmount}
                helperText={
                  getValues().walletAmount > wallet ? "Not enough balance" : ""
                }
              />
            </Grid>
            <Grid item xs={4} md={1}>
              <Switch
                name="enableWallet"
                checked={getValues().enableWallet}
                onChange={onChange}
                color="primary"
                disabled={!enableWallet}
              />
            </Grid>
            <Grid item xs={2} md={1}>
              {getValues().enableWallet}
            </Grid>
          </Grid>
          <Grid container item xs={12} className={classes.rowRoot}>
            <Grid item xs={6} md={4}>
              <Typography variant="h4" className="cmt-title">
                <IntlMessages id="user.purchase.genie_balance" />
              </Typography>
            </Grid>
            <Grid item xs={6} md={4}>
              {toFixed(genie)}
            </Grid>
            <Grid item xs={6} md={3}>
              <TextField
                variant="outlined"
                type="number"
                disabled={!getValues().enableGenie}
                value={getValues().genieAmount}
                onChange={(e) => setValue("genieAmount", e.target.value)}
                error={getValues().genieAmount > totalAmount}
                helperText={
                  getValues().genieAmount > genie ? "Not enough balance" : ""
                }
              />
            </Grid>
            <Grid item xs={4} md={1}>
              <Switch
                name="enableGenie"
                checked={getValues().enableGenie}
                onChange={onChange}
                color="primary"
                disabled={!enableGenie}
              />
            </Grid>
            <Grid item xs={2} md={1}>
              {getValues().enableGenie}
            </Grid>
          </Grid>
          <Grid container item xs={12} className={classes.rowRoot}>
            <Grid item xs={6} md={4}>
              <Typography variant="h4" className="cmt-title">
                <IntlMessages id="user.purchase.agent_balance" />
              </Typography>
            </Grid>
            <Grid item xs={6} md={4}>
              {toFixed(agentWallet)}
            </Grid>
            <Grid item xs={6} md={3}>
              <TextField
                  variant="outlined"
                  type="number"
                  disabled={!getValues().enableAgentWallet}
                  value={getValues().agentWalletAmount}
                  onChange={(e) => setValue("agentWalletAmount", e.target.value)}
                  error={getValues().agentWalletAmount > totalAmount}
                  helperText={
                    getValues().agentWalletAmount > agentWallet ? "Not enough balance" : ""
                  }
              />
            </Grid>
            <Grid item xs={4} md={1}>
              <Switch
                  name="enableAgentWallet"
                  checked={getValues().enableAgentWallet}
                  onChange={onChange}
                  color="primary"
                  disabled={!enableAgentWallet}
              />
            </Grid>
            <Grid item xs={2} md={1}>
              {getValues().enableAgentWallet}
            </Grid>
          </Grid>
          <Grid container item xs={12} className={classes.rowRoot}>
            <Grid item xs={6} md={4}>
              <Typography variant="h4" className="cmt-title">
                <IntlMessages id="user.purchase.mobile" />
              </Typography>
            </Grid>
            <Grid item xs={6} md={4}>
            </Grid>
            <Grid item xs={6} md={3}>
            <Select
                variant="outlined"
                id="mobile_no"
                name="mobile_no"
                value={getValues().mobile_no}
                onChange={handleMobileChange}
                options={mobileList}
              />
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};

export default Balance;
