import React from "react";

import ImportLotteries from "./ImportLotteries";
import DisplayImport from "./DisplayImport";
import CreateDraw from "./CreateDraw";
import useStyles from "./Styles";

import Grid from "@material-ui/core/Grid";

import { DialogModal, Loader, Stepper } from "../../uielements";
import useData from "./State";
import { steps } from "../utils";

const Create = ({ lotteries, open, handleClose, setTotal }) => {
  const classes = useStyles();

  const {
    submit,
    activeStep,
    setActiveStep,
    handleNext,
    primaryDisabled,
    loading,
    setFile,
    data,
    ...rest
  } = useData({
    handleClose,
    lotteries,
    setTotal
  });

  const handleBack = () => {
    if (activeStep === 0) {
      handleClose();
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return <CreateDraw lotteries={lotteries} {...rest} />;
      case 1:
        return <ImportLotteries setFile={setFile} />;
      case 2:
        return <DisplayImport data={data} />;
      default:
        return "";
    }
  };

  return (
    <DialogModal
      isOpen={open}
      handleClose={handleClose}
      handlePrimaryButton={handleNext}
      handleSecondaryButton={handleBack}
      primaryButtonLabel={activeStep === 2 ? "Finish" : "Continue"}    
      secondaryButtonLabel={activeStep === 0 ? "Cancel" : "Back"}
      primaryDisabled={primaryDisabled}
      title="Create Draw"
      backdrop="static"
      loading={loading}
    >
      <div className={classes.modelRoot}>
        <Grid
          container
          direction="column"
          justify="space-between"
          alignItems="center"
          className={classes.modelContent}
        >
          {loading && <Loader />}
          {getStepContent(activeStep)}
          <Stepper steps={steps} activeStep={activeStep} />
        </Grid>
      </div>
      <div />
    </DialogModal>

  );
};

export default Create;
