import React, {useEffect, useState} from "react";
import { DialogModal, TextField, Loader, Select } from "app/uielements";
import Grid from "@material-ui/core/Grid";
import {useIntl} from "react-intl";
import { transformInToFormObject } from "services/utils";
import useStyles from "./UpdateProfile.styles";
import clsx from "clsx";
import MobileList from "../../CRM/AddUser/MobileList";
import {useForm} from "react-hook-form";
import useAxios from "app/hooks/useAxios";
import showAlert from 'app/hooks/useAlerts';

const UpdateProfile = ({open, handleClose, user}) => {
    const [primaryDisabled, setPrimaryDisabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const axios = useAxios();
    const userUpdateUrl = `/api/user/update/${user.id}`;
    const {
        register,
        getValues,
        setValue,
        formState: { errors },
        handleSubmit,
    } = useForm();

    const values = getValues();
    console.log("values from updated form(useForm) :" ,values);

    // TODO: change with i18n/locale entries
    const intl = useIntl();
    const format = (id) =>
        intl.formatMessage({
            id: `user.add.${id}`,
        });

    const controls = {
        fname: register("fname", {
            required: { value: true, message: format("errors.required") },
        }),
        lname: register("lname", {
            required: { value: true, message: format("errors.required") },
        }),
        email: register("email", {
            required: { value: true },
            pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: format("errors.email"),
            },
        }),
        gender: register("gender", {
            required: { value: true, message: format("errors.required") },
        }),
        nicno: register("nicno", {
            required: { value: true, message: format("errors.required") },
            minLength: { value: 10, message: format("errors.nicno") },
            maxLength: { value: 12, message: format("errors.nicno") },
        }),
        telephone: register("telephone", {
            required: { value: true, message: format("errors.required") },
            minLength: { value: 9, message: format("errors.telephone") },
            maxLength: { value: 9, message: format("errors.telephone") },
            pattern: {
                value: /^[0-9]*$/,
                message: format("errors.telephone"),
            },
        }),
        password: register("password", {
            required: { value: true, message: format("errors.required") },
        }),
        password_confirmation: register("password_confirmation", {
            required: { value: true, message: format("errors.required") },
            validate: {
                isMatch: (value) => {
                    console.log(values, value)
                    return value === values.password || format("errors.password")
                },
            },
        }),
    };

    // xHttp call by axios
    const save = async (data) => {
        console.log(userUpdateUrl);
        setLoading(true);
        const formData = transformInToFormObject({ ...data, dialcode: "+94"});
        try {
            const response = await axios.post(userUpdateUrl, formData);
            if (response.status === 200) {
                showAlert('success', 'Profile Update is successful.')
                handleClose();
            } else {
                setLoading(false);
                showAlert('error', response.data.errors[0])
            }
        } catch (error) {
            console.error(error);
            showAlert('error', 'Profile Update has failed.')
            setLoading(false);
        }
    };

    const submit = handleSubmit(save);

    const classes = useStyles();
    const genders = [
        { value: "male", label: format("gender.male") },
        { value: "female", label: format("gender.female") },
    ];

    // const onSubmit = data => console.log("Data from submit method :",data);
    // const submit = handleSubmit(onSubmit);;

    return (
        <DialogModal
            isOpen={open}
            handleClose={handleClose}
            handlePrimaryButton={submit}
            handleSecondaryButton={handleClose}
            primaryButtonLabel={"Update"}
            secondaryButtonLabel={"Cancel"}
            primaryDisabled={primaryDisabled}
            title={"Update Profile"}
            loading={loading}
        >
            <form noValidate>
                {loading && <Loader />}
                    <Grid container item className={classes.modelContent} xs={12}>
                        <Grid item xs={12} md={6} className={classes.formColumn}>
                            <TextField
                                {...controls.fname}
                                id="fname"
                                name="fname"
                                variant="outlined"
                                label={format("fname")}
                                error={errors.fname}
                                helperText={errors.fname?.message}
                                defaultValue={user.fname}
                            />
                            <TextField
                                {...controls.lname}
                                id="lname"
                                name="lname"
                                variant="outlined"
                                label={format("lname")}
                                error={errors.lname}
                                helperText={errors.lname?.message}
                                defaultValue={user.lname}
                            />
                            <TextField
                                {...controls.email}
                                id="email"
                                name="email"
                                variant="outlined"
                                label={format("email")}
                                error={errors.email}
                                helperText={errors.email?.message}
                                defaultValue={user.email}
                            />
                            <Select
                                required
                                id="gender"
                                name="gender"
                                inputRef={controls.gender}
                                onChange={(e) =>
                                    setValue("gender", e.target.value, {
                                        shouldValidate: true,
                                    })
                                }
                                variant="outlined"
                                label={format("gender")}
                                error={errors.gender}
                                helperText={errors.gender?.message}
                                defaultValue={user.gender}
                                options={genders}
                             />
                        </Grid>
                        <Grid item xs={12} md={6} className={classes.formColumn}>
                            <TextField
                                {...controls.nicno}
                                id="nicno"
                                name="nicno"
                                variant="outlined"
                                label={format("nicno")}
                                error={errors.nicno}
                                helperText={errors.nicno?.message}
                                defaultValue={user.nicno}
                            />
                            <TextField
                                {...controls.password}
                                id="password"
                                label={format("password")}
                                type="password"
                                variant="outlined"
                                error={errors.password}
                                helperText={errors.password?.message}
                            />
                            <TextField
                                {...controls.password_confirmation}
                                id="password_confirmation"
                                label={format("password_confirmation")}
                                variant="outlined"
                                type="password"
                                error={!!errors.password_confirmation}
                                helperText={errors.password_confirmation?.message}
                            />
                            <TextField
                                {...controls.telephone}
                                id="telephone"
                                name="telephone"
                                label={format("telephone")}
                                variant="outlined"
                                error={errors.telephone}
                                helperText={errors.telephone?.message}
                                defaultValue={user.telephone}
                            />
                            <div/>
                        </Grid>
                </Grid>
            </form>
        </DialogModal>
    );
}


export default UpdateProfile;