import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import {deepOrange} from "@material-ui/core/colors";
import PropTypes from "prop-types";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1)
    }
  },
  orange: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500]
  },
  user: {
    margin: "auto",
    marginLeft: 5,
    fontSize: 17,
    fontWeight: "bold"
  }
}));

const AvatarWithName = ({name, ...props}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Avatar {...props} className={classes.orange} />
      <div className={classes.user}> {name}</div>
    </div>
  );
};

AvatarWithName.propType = {
  name: PropTypes.string.isRequired
};

export default Avatar;
export {AvatarWithName};
