import React, { useState } from "react";
import { useIntl } from "react-intl";
import throttle from "lodash/throttle";
import { useForm } from "react-hook-form";
import useAxios from "app/hooks/useAxios";
import showAlert from "app/hooks/useAlerts";
import { transformInToFormObject } from "services/utils";
import moment from "moment";

const useData = ({ handleClose, id }) => {
  const axios = useAxios();
  const defaultValues = {
    transaction_date: new Date()
  }
  const {
    register,
    setValue,
    formState: { errors },
    handleSubmit,
    getValues,
  } = useForm({defaultValues});

  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(0);
  const [options, setOptions] = React.useState([]);

  const intl = useIntl();
  const format = (id) =>
    intl.formatMessage({
      id: `user.topup.${id}`,
    });

  const paymentTypes = [{ value: "bank", label: format("payment_type.bank") }];

  const [file, setFile] = useState(null);

  const upload = async (id) => {
    if (!file) {
      setLoading(false);
      showAlert("success", "Topup wallet success.");
      handleClose();
    }
    setLoading(true);
    const formData = new FormData();
    formData.append("upload", file);
    formData.append("file_id", id);
    formData.append("type", "transaction");
    try {
      const response = await axios.post("/api/upload_files", formData);
      if (response.status === 200) {
        showAlert("success", "Topup wallet success.");
        handleClose();
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const save = async (data) => {
    setLoading(true);
    const formData = transformInToFormObject({
      ...data,
      transaction_date: moment(data.transaction_date).format("yyyy-MM-DD HH:mm:ss"),
    });
    try {
      const response = await axios.post(
        "/api/user/transaction/add/" + id,
        formData
      );
      if (response.status === 200) {
        await upload(response.data.transaction.id);
      } else {
        setLoading(false);
        showAlert("error", response.data.errors[0]);
      }
    } catch (error) {
      console.error(error);
      showAlert("error", "Topup wallet failed.");
      setLoading(false);
    }
  };

  const fetch = React.useMemo(
    () =>
      throttle((request, callback) => {
        axios
          .get("/api/settings/company-bank/accounts/view", request)
          .then(callback)
          .catch((e) => setLoading(false));
      }, 200),
    []
  );

  React.useEffect(() => {
    let active = true;

    setLoading(true);

    fetch({}, (response) => {
      if (active) {
        if (response.status === 200) {
          const banks = response.data.map((bank) => ({
            value: bank.id,
            label: bank.account_name,
          }));

          setOptions(banks);
        }

        setLoading(false);
      }
    });

    return () => {
      active = false;
    };
  }, []);

  const submitCreate = handleSubmit((data) => setStep(1));

  const handleUpload = () => {
    setStep(2);
  };

  const submit = async () => {
    if (step === 0) {
      await submitCreate();
    } else if (step === 1) {
      handleUpload();
    } else {
      await save(getValues());
    }
  };

  return {
    register,
    setValue,
    errors,
    submit,
    loading,
    paymentTypes,
    format,
    step,
    setFile,
    getValues,
    options,
  };
};

export default useData;
