import React from "react";
import Box from "@material-ui/core/Box";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { GridContainer, TextField } from "app/uielements";
import IntlMessages from "@jumbo/utils/IntlMessages";
import CmtCard from "@coremat/CmtCard";
import CmtCardHeader from "@coremat/CmtCard/CmtCardHeader";
import CmtCardContent from "@coremat/CmtCard/CmtCardContent";

const useStyles = makeStyles((theme) => ({
  textPrimary: {
    color: theme.palette.primary.main,
  },
  mbSpace: {
    marginBottom: 16,
  },
  mtO: {
    margin: 0,
  },
}));

const SearchForm = ({
  onSearch,
  onReset,
  setValue,
  form,
  title,
  titleProps,
  className,
}) => {
  const handleOnChange = (evt) => {
    setValue(evt.target.name, evt.target.value);
  };

  const handleKeyDown = (evt) => {
    if (evt.key === 'Enter') {
      onSearch();
    }
  };

  const classes = useStyles();

  return (
    <CmtCard className={className}>
      <CmtCardHeader title={title} titleProps={titleProps} />
      <CmtCardContent>
        <Box>
          <Box pb={{ xs: 6, md: 6, xl: 6 }}>
            <GridContainer>
              <Grid item xs={12} sm={4}>
                  <TextField
                    variant="outlined"
                    id="telephone"
                    name="telephone"
                    label={<IntlMessages id="user.list.telephone" />}
                    value={form.telephone}
                    onChange={handleOnChange}
                    onKeyDown={handleKeyDown}
                  />
              </Grid>
              <Grid item xs={12} sm={4}>
                  <TextField
                    variant="outlined"
                    id="nicno"
                    name="nicno"
                    label={<IntlMessages id="user.list.nicno" />}
                    value={form.nicno}
                    onChange={handleOnChange}
                    onKeyDown={handleKeyDown}
                  />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  <Box ml={3}>
                    <Button variant="outlined" onClick={onReset}>
                      <IntlMessages id="sms.reset" />
                    </Button>
                  </Box>
                  <Box ml={3}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={onSearch}
                    >
                      <IntlMessages id="sms.search" />
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </GridContainer>
          </Box>
        </Box>
      </CmtCardContent>
    </CmtCard>
  );
};

export default SearchForm;
