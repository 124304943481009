import React, {useState, useEffect} from "react";
import useAxios from "../../hooks/useAxios";
import throttle from "lodash/throttle";
import {confirm} from "app/hooks/useAlerts";
import {useIntl} from "react-intl";
import showAlert from "app/hooks/useAlerts";
import {useForm} from "react-hook-form";


const useUsers = ({total, setTotal}) => {
    const axios = useAxios();
    const intl = useIntl();

    const [page, setPage] = useState(0);
    const [id, setId] = useState(0);
    const [selected, setSelected] = useState({});
    const [pageSize, setPageSize] = useState(10);
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(false);
    const [topupOpen, setTopupOpen] = useState(false);
    const [transactionOpen, setTransactionOpen] = useState(false);
    const [purchaseOpen, setPurchaseOpen] = useState(false);
    const [ticketsOpen, setTicketsOpen] = useState(false);
    const [editOpen, setEditOpen] = useState(false);
    const [updateInsurance, setUpdateInsurance] = useState(false);
    const [billOpen, setBillOpen] = useState(false);
    const [addSubscriptionOpen, setAddSubscriptionOpen] = useState(false);
    const [viewSubscriptionOpen, setViewSubscriptionOpen] = useState(false);
    const [params, setParams] = useState(null);
    const [filterModel, setFilterModel] = React.useState({items: []});
    const [isDataGridVisible, setIsDataGridVisible] = useState(true);

    const [sortModel, setSortModel] = React.useState([
        {field: "id", sort: "asc"},
    ]);

    const defaultValues = {
        telephone: '',
        nicno: '',
    }
    const [form, setForm] = useState(defaultValues);

    const setValue = (name, value) => {
        setForm({
            ...form,
            [name]: value,
        });
    };
    const handleSortModelChange = (params) => {
        if (params.sortModel !== sortModel) {
            setSortModel(params.sortModel);
            setPage(0);
        }
    };

    const handleFilter = (params) => {
        setFilterModel(params.filterModel);
        setPage(0);
    };

    const handleFilterChange = React.useCallback(throttle(handleFilter, 500), []);

    const handlePageChange = (params) => {
        setPage(params.page);
    };

    const handlePageCountChange = (params) => {
        setPageSize(params.pageSize);
        setPage(0);
    };

    const updateRow = (data) => {
        const newRows = [...rows];

        const row = newRows.find((r) => r.id === data.id);
        Object.keys(row).forEach((key) => (row[key] = data[key]));

        setRows(newRows);
    };

    const handleRefresh = async (active = true) => {
        if (params) {
            setLoading(true);

            const body = {
                page_count: pageSize,
                ...params,
                sortModel,
                filterModel: {
                    items: filterModel.items
                        .filter((i) => i.value)
                        .map((i) => ({
                            ...i,
                            columnField: `users.${i.columnField}`,
                        })),
                },
            };

            try {
                const response = await axios.post("/api/users/view?page=" + (page + 1), body);

                const newRows = response.data.data;
                const totalValue = response.data.total;

                if (!active) {
                    return;
                }

                setRows(newRows);
                setTotal(totalValue);
                setLoading(false);
                setIsDataGridVisible(true);
            } catch (error) {
                setLoading(false);
            }
        }
    };

    const deleteUser = async (selected) => {
        confirm({
            title: intl.formatMessage({id: 'user.list.delete.title'}),
            text: intl.formatMessage({id: 'user.list.delete.text'}, selected),
            successCallback: async () => {
                try {
                    setLoading(true);
                    const response = await axios.get('/api/user/delete/' + selected.id);
                    setLoading(false);
                    if (response.status === 200) {
                        showAlert('success', intl.formatMessage({id: 'user.list.delete.success'}, selected))
                        setTotal(total => total - 1)
                        setRows(rows => rows.filter(r => r.id !== selected.id))
                    }
                } catch (error) {
                    setLoading(false);

                }

            }
        })
    }

    useEffect(() => {
        let active = true;

        (async () => {
            await handleRefresh(active);
        })();

        return () => {
            active = false;
        };
    }, [
        page,
        total,
        pageSize,
        params,
        sortModel,
        filterModel,
    ]);

    const search = () => {
        setParams({...form});
    };

    const onReset = () => {
        setForm(defaultValues);
        setParams(null);
    };

    const onSearch = search;

    return {
        rows,
        total,
        loading,
        pageSize,
        id,
        selected,
        setSelected,
        handlePageChange,
        handlePageCountChange,
        setId,
        updateRow,
        topupOpen,
        setTopupOpen,
        transactionOpen,
        setTransactionOpen,
        purchaseOpen,
        setPurchaseOpen,
        handleSortModelChange,
        sortModel,
        handleFilterChange,
        ticketsOpen,
        setTicketsOpen,
        handleRefresh,
        billOpen,
        setBillOpen,
        addSubscriptionOpen,
        setAddSubscriptionOpen,
        viewSubscriptionOpen,
        setViewSubscriptionOpen,
        editOpen,
        setEditOpen,
        updateInsurance,
        setUpdateInsurance,
        deleteUser,
        onSearch,
        onReset,
        form,
        setValue,
        params,
        setRows,
        isDataGridVisible,
        setIsDataGridVisible
    };
};

export default useUsers;
