import { DialogModal, TextField, Loader, Select } from "app/uielements";
import React from "react";
import { useIntl } from "react-intl";

import Grid from "@material-ui/core/Grid";
import useStyles from "./AddUser.styles";
import useAddUser from "./AddUser.state";

import MobileList from './MobileList';
import clsx from 'clsx';

const AddUser = ({ open, handleClose, setTotal }) => {
  const classes = useStyles();

  const {
    primaryDisabled,
    controls,
    values,
    mobileList,
    setMobileList,
    setValue,
    errors,
    loading,
    submit,
  } = useAddUser({ handleClose, setTotal });

  const intl = useIntl();
  const format = (id) =>
    intl.formatMessage({
      id: `user.add.${id}`,
    });

  const genders = [
    { value: "male", label: format("gender.male") },
    { value: "female", label: format("gender.female") },
  ];

  return (
    <DialogModal
      isOpen={open}
      handleClose={handleClose}
      handlePrimaryButton={submit}
      handleSecondaryButton={handleClose}
      primaryButtonLabel={format("save")}
      secondaryButtonLabel={format("cancel")}
      primaryDisabled={primaryDisabled}
      title={format("title")}
      loading={loading}
    >
      <form noValidate>
      {loading && <Loader />}
        <Grid container item className={classes.modelContent} xs={12}>
          
          <Grid item xs={12} md={6} className={classes.formColumn}>
            <TextField              
              {...controls.fname}
              id="fname"
              name="fname"
              variant="outlined"
              label={format("fname")}
              error={errors.fname}
              helperText={errors.fname?.message}
            />
            <TextField              
              {...controls.lname}
              id="lname"
              name="lname"
              variant="outlined"
              label={format("lname")}
              error={errors.lname}
              helperText={errors.lname?.message}
            />
            <TextField
              {...controls.email}
              id="email"
              name="email"
              type="email"
              variant="outlined"
              label={format("email")}
              error={errors.email}
              helperText={errors.email?.message}
            />
            <Select        
              inputRef={controls.gender}
              onChange={(e) =>
                setValue("gender", e.target.value, {
                  shouldValidate: true,
                })
              }
              id="gender"
              name="gender"
              variant="outlined"
              label={format("gender")}
              error={errors.gender}
              helperText={errors.gender?.message}
              options={genders}
            />
            <TextField
              required
              id="password"
              label={format("password")}
              type="password"
              variant="outlined"
              {...controls.password}
              error={errors.password}
              helperText={errors.password?.message}
            />
            <TextField
              required
              id="password_confirmation"
              label={format("password_confirmation")}
              variant="outlined"
              type="password"
              {...controls.password_confirmation}
              error={!!errors.password_confirmation}
              helperText={errors.password_confirmation?.message}
            />
          </Grid>
          <Grid item xs={12} md={6} className={classes.formColumn}>
            <TextField
              required
              {...controls.nicno}
              id="nicno"
              name="nicno"
              variant="outlined"
              label={format("nicno")}
              error={errors.nicno}
              helperText={errors.nicno?.message}
            />
            <TextField              
              {...controls.address_1}
              id="address_1"
              name="address_1"
              variant="outlined"
              label={format("address_1")}
              error={errors.address_1}
              helperText={errors.address_1?.message}
            />
            <TextField
              {...controls.address_2}
              id="address_2"
              name="address_2"
              variant="outlined"
              label={format("address_2")}
              error={errors.address_2}
              helperText={errors.address_2?.message}
            />
            <TextField              
              {...controls.city}
              id="city"
              name="city"
              variant="outlined"
              label={format("city")}
              error={errors.city}
              helperText={errors.city?.message}
            />
            <TextField              
              {...controls.postal_code}
              id="postal_code"
              name="postal_code"
              variant="outlined"
              label={format("postal_code")}
              error={errors.postal_code}
              helperText={errors.postal_code?.message}
            />
            <div />
          </Grid>
          <Grid item xs={12} className={clsx(classes.formColumn, errors.telephone && classes.error)}>
              <MobileList columns={mobileList} setColumns={setMobileList} />
             
          </Grid>
        </Grid>
      </form>
    </DialogModal>
  );
};

export default AddUser;
