import { Box, Button, Grid, Typography } from "@material-ui/core";
import { Loader } from "app/uielements";
import { DropzoneArea } from "material-ui-dropzone";
import React from "react";
import { useIntl } from "react-intl";

const ImportImage = ({ loading, setFile }) => {
  const handleChange = (files) => setFile(files[0]);

  const intl = useIntl();
  const format = (id) =>
    intl.formatMessage({
      id: `lottery.add.import.${id}`,
    });

  return (
    <Grid
      container
      direction="row"
      justify="space-around"
      alignItems="flex-start"
    >
      {loading && <Loader />}
      <Grid container item xs={12}>
        <Typography variant="h3" gutterBottom>
          {format("heading")}
        </Typography>
      </Grid>
      <Grid container item xs={12}>
        <DropzoneArea
          acceptedFiles={[".png", ".jpeg", ".jpg", ".bmp"]}
          showPreviews={true}
          showPreviewsInDropzone={false}
          useChipsForPreview
          previewGridProps={{
            container: { spacing: 1, direction: "row" },
          }}
          previewText={format("preview")}
          onChange={handleChange}
          filesLimit={1}
        />
      </Grid>
      <Grid container item xs={12}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        ></Box>
      </Grid>
    </Grid>
  );
};

export default ImportImage;
