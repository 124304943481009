import showAlert from "app/hooks/useAlerts";
import useAxios from "app/hooks/useAxios";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import throttle from "lodash/throttle";
import moment from "moment";

const useData = ({ status }) => {
  const defaultValues = {
    end_date: null,
    start_date: null,
    mobile_no: '',
  };

  const {
    register,
    setValue,
    getValues,
    reset,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues,
  });

  const axios = useAxios();
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [params, setParams] = useState(null);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const [filterModel, setFilterModel] = React.useState({ items: [] });

  const [sortModel, setSortModel] = React.useState([
    { field: "send_at", sort: "asc" },
  ]);

  const formatDate = (val) => val ? moment(val).format("yyyy-MM-DD"): '';

  const handleSortModelChange = (params) => {
    if (params.sortModel !== sortModel) {
      setSortModel(params.sortModel);
      setPage(0);
    }
  };

  const handleFilter = (params) => {
    setFilterModel(params.filterModel);
    setPage(0);
  };

  const handleFilterChange = React.useCallback(throttle(handleFilter, 500), []);

  const handlePageChange = (params) => {
    setPage(params.page);
  };

  const handlePageCountChange = (params) => {
    setPageSize(params.pageSize);
    setPage(0);
  };

  const searchResults = async (active) => {
    try {
      if (!params) return;
      setLoading(true);
      const response = await axios.post(
        "/api/log/sms?page=" + (page + 1),
        {
          page_count: pageSize,
          ...params,
          start_date: formatDate(params.start_date),
          end_date: formatDate(params.end_date),
          status,
          sortModel,
          filterModel: {
            items: filterModel.items
              .filter((i) => i.value)
              .map((i) => ({
                ...i,
                columnField: `${i.columnField}`,
              })),
          },
        }
      );
      console.log("sms users", response.data.data);
      const newRows = response.data.data;
      const totalValue = response.data.total;

      if (!active) {
        return;
      }

      setRows(newRows);
      setTotal(totalValue);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    let active = true;

    (async () => await searchResults(active))();

    return () => {
      active = false;
    };
  }, [page, pageSize,total, params, sortModel, filterModel]);

  const search = (data) => {
    setParams({...data});
  };

  const onReset = () => {
    reset(defaultValues);
    setParams(null);
    setRows([])
  };

  const onSearch = handleSubmit(search);

  const resend = async (id, sms) => {
    setLoading(true);
    try {
      const response = await axios.post('/api/sms/sent', {
        sms_ids: [id]
      });
      setLoading(false);
      if(response.status === 200){
        showAlert('success', `SMS sent successfully to ${sms.mobile_no}`);
      }
    } catch (error) {
      setLoading(false);
    }
  }

  return {
    register,
    setValue,
    getValues,
    errors,
    onSearch,
    onReset,
    loading,
    rows,
    total,
    pageSize,
    handlePageChange,
    handlePageCountChange,
    handleSortModelChange,
    sortModel,
    handleFilterChange,
    resend
  };
};

export default useData;
