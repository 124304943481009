import React, {useState} from "react";
import Mobile from "./Mobile";

import {arrayMove, SortableContainer} from "react-sortable-hoc";
import {Box, Button, Grid, IconButton, InputAdornment} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import {TextField} from "app/uielements";
import {Add} from "@material-ui/icons";
import GridContainer from "@jumbo/components/GridContainer";
import {useForm} from "react-hook-form";
import IntlMessages from "@jumbo/utils/IntlMessages";
import {useIntl} from "react-intl";

const useStyles = makeStyles((theme) => ({
    root: {
        "& > *:not(:last-child)": {
            marginBottom: theme.spacing(4),
        },
    },
}));

const MobileList = SortableContainer(({columns, handleDelete, setColumn}) => {
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            {columns.map((column, index) => (
                <Mobile
                    key={index}
                    index={index}
                    column={column}
                    handleDelete={handleDelete}
                    setColumn={setColumn}
                />
            ))}
        </Box>
    );
});

const ColumnList = ({columns, setColumns}) => {
    const [addMobile, setAddMobile] = useState(false);

    const onSortEnd = ({oldIndex, newIndex}) => {
        let items = arrayMove(columns, oldIndex, newIndex);
        items = items.map((item, index) => ({...item, position: index + 1}));
        setColumns(items);
    };

    const handleDelete = ({column}) => {
        const items = columns.filter((col) => col !== column);
        if (items.filter(c => c.is_primary).length === 0 && items.length > 0) {
            items[0].is_primary = true;
        }
        setColumns(items);
    };

    const setColumn = ({value, column}) => {
        if (value.is_primary) {
            const items = [
                ...columns
                    .filter((col) => col !== column)
                    .map((col) => ({...col, is_primary: false})),
                value,
            ].sort((a, b) => a.position - b.position);
            setColumns(items);
        } else {
            if (columns.length === 1) {
                value.is_primary = true;
            }
            const items = [...columns.filter((col) => col !== column), value].sort(
                (a, b) => a.position - b.position
            );
            setColumns(items);
        }
    };

    const {
        register,
        formState: {errors},
        handleSubmit,
        reset,
    } = useForm();

    const addColumn = (data, e) => {
        setColumns([
            ...columns,
            {
                is_primary: columns.length === 0,
                mobile_no: data.telephone,
                position: columns.length + 1,
                otp: "",
            },
        ]);
        e.target.reset();
        reset();
    };

    const submit = handleSubmit(addColumn);

    const intl = useIntl();
    const format = (id) =>
        intl.formatMessage({
            id: `user.add.${id}`,
        });

    return (
        <React.Fragment>
            <form>
                <GridContainer wrap="nowrap" alignItems="center">
                    <Grid item>
                        <Button variant="contained" color="primary" onClick={() => setAddMobile(true)}>
                            Add Mobile Number
                        </Button>
                    </Grid>
                </GridContainer>

                {addMobile &&
                    <GridContainer wrap="nowrap" alignItems="center">
                        <Grid item>
                            <TextField
                                required
                                {...register("telephone", {
                                    required: {value: true, message: format("errors.required")},
                                    minLength: {value: 9, message: format("errors.telephone")},
                                    maxLength: {value: 9, message: format("errors.telephone")},
                                    pattern: {
                                        value: /^[0-9]*$/,
                                        message: format("errors.telephone"),
                                    },
                                    validate: (val) =>
                                        columns.findIndex((c) => c.mobile_no === val) < 0 ||
                                        format("errors.telephone"),
                                })}
                                name="telephone"
                                id="telephone"
                                label={<IntlMessages id="user.add.telephone"/>}
                                variant="outlined"
                                error={!!errors.telephone}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <IntlMessages id="user.add.dial_code"/>
                                        </InputAdornment>
                                    ),
                                }}
                                helperText={errors.telephone ? errors.telephone.message : ""}
                            />
                        </Grid>
                        <Grid item>
                            <IconButton onClick={submit}>
                                <Add/>
                            </IconButton>
                        </Grid>
                    </GridContainer>
                }
            </form>
            <MobileList
                columns={columns}
                onSortEnd={onSortEnd}
                handleDelete={handleDelete}
                useDragHandle={true}
                setColumn={setColumn}
            />
        </React.Fragment>
    );
};

export default ColumnList;
