import IntlMessages from "@jumbo/utils/IntlMessages";
import showAlert from "app/hooks/useAlerts";
import useAxios from "app/hooks/useAxios";
import {padStart} from "lodash-es";
import moment from "moment";
import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";


const useData = () => {
    const [loading, setLoading] = useState(false);
    const [drawSummary, setDrawSummary] = useState({
        data: []
    });
    const [drawSummaryTotal, setDrawSummaryTotal] = useState({
        data: []
    });
    const [drawAvailable, setDrawSummaryAvailable] = useState({
        data: []
    });

    const [agentsSalesWiseAgent, setAgentsSalesWiseAgent] = useState({
        data: []
    });

    const [agentSalesSummary, setAgentSalesSummary] = useState({});

    const [rows, setRows] = useState([]);
    const [data, setData] = useState({
        id: 0,
        columns: [],
        data: [],
    });
    const axios = useAxios();
    const {
        register,
        formState: {errors},
        setValue,
        getValues,
    } = useForm();
    const [refresh, setRefresh] = useState(false);

    const getStats = async (active = true) => {
        setLoading(true);
        try {
            const response = await axios.post("/api/dashboard/stats");
            setLoading(false);
            if (response.status === 200) {
                setData(response.data);
            }
        } catch (error) {
            setLoading(false);
        }
    }

    const formatDate = (val) => moment(val).format("MM/DD/yyyy hh:mm A");
    useEffect(() => {
        let active = true;
        (async () => {
            setLoading(true);
            const formData = new FormData();
            formData.append("search_date", moment().format("yyyy-MM-dd"));
            try {
                const drawSummaryRtn = await axios.post("/api/draws/summary");
                setLoading(false);
                if (drawSummaryRtn.status == 200) {
                    setDrawSummary(drawSummaryRtn.data);
                    setDrawSummaryTotal(drawSummaryRtn.total);
                    setDrawSummaryAvailable(drawSummaryRtn.drawAvailable);
                }
            } catch (error) {
                setLoading(false);
            }
        })();

        return () => {
            active = false;
        };
    }, []);

    useEffect(() => {
        if(refresh === true){
            let active = true;
            (async () => {
                setLoading(true);
                const formData = new FormData();
                formData.append("search_date", moment().format("yyyy-MM-dd"));
                try {
                    const response = await axios.post("/api/agentssales/summary");
                    setLoading(false);
                    if (response.status == 200) {
                        setAgentsSalesWiseAgent(response.data.data);
                        const { total_agent_sales_count, total_agent_passive_sales_count, total_agent_active_sales_count } = response.data;
                        setAgentSalesSummary({
                            total_agent_sales_count,
                            total_agent_passive_sales_count,
                            total_agent_active_sales_count
                        });
                    }

                } catch (error) {
                    setLoading(false);
                }
            })();
        }


        return () => {
            setRefresh(false);
        };
    }, [refresh]);

    const handleAgentRefresh = async () => {
        setRefresh(prev => !prev);
    }

    const handleRefresh = async (active) => {
        await getStats(active);
        }

    const updateRow = (drawSummary) => {
        const newRows = [...rows];
        const row = newRows.find((r) => r.id === drawSummary.id);
        Object.keys(row).forEach((key) => (row[key] = drawSummary[key]));

        setRows(newRows);
    };

    return {
        register,
        errors,
        setValue,
        getValues,
        loading,
        data,
        drawSummary,
        rows,
        setDrawSummary,
        setDrawSummaryTotal,
        setDrawSummaryAvailable,
        agentSalesSummary,
        agentsSalesWiseAgent,
        updateRow,
        handleRefresh,
        handleAgentRefresh,
    };
};

export default useData;
