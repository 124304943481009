import { DialogModal, Loader, Stepper } from "app/uielements";
import React from "react";
import { useIntl } from "react-intl";
import useStyles from "./AddWinnings.styles";
import Winnings from "./Winnings";
import useAddWinnings from "./AddWinnings.state";
import ImportWinngs from "./ImportWinnings";
import DisplayImport from "./DisplayImport";

const AddWinnings = ({ id, lottery_id, open, handleClose, draw, lotteries }) => {
  const classes = useStyles();

  const intl = useIntl();

  const {
    step,
    setStep,
    setFile,
    list,
    errors,
    loading,
    controls,
    setValue,
    setList,
    processImport,
    uploadWinnings,
    saveWinning,
    primaryDisabled,
    handleSubmit,
    values,
    lagnaVisible,
    luckyVisible,
    daynVisible,
    snoVisible,
    charVisible,
    specialNumberVisible,
    special2NumberVisible,
    handleChange
  } = useAddWinnings({ lottery_id, draw, lotteries });

  const format = (id) =>
    intl.formatMessage({
      id: `winnings.add.${id}`,
    });

  const steps = [
    { label: format("create"), optional: false },
    { label: format("import"), optional: false },
    { label: format("finish"), optional: false },
  ];

  const handleBack = () => {
    if (step === 0 || step === 1) {
      handleClose();
    } else {
      setStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const save = handleSubmit(saveWinning(id));

  const handleNext = async () => {
    if (step === 0) {
      save();
    } else if (step === 1) {
      await uploadWinnings({ id, lottery_id });
    } else {
      await processImport(handleClose);
    }
  };

  return (
    <DialogModal
      isOpen={open}
      handleClose={handleClose}
      handlePrimaryButton={handleNext}
      handleSecondaryButton={handleBack}
      primaryButtonLabel={step === 2 ? format("finish") : format("continue")}
      secondaryButtonLabel={
        step === 0 || step === 1 ? format("cancel") : format("back")
      }
      primaryDisabled={primaryDisabled}
      title={format("title")}
      backdrop="static"
      loading={loading}
    >
      <div className={classes.modelContent}>
        {loading && <Loader />}
        {step == 0 && (
          <Winnings
            errors={errors}
            loading={loading}
            controls={controls}
            setValue={setValue}
            values={values}
            lagnaVisible={lagnaVisible}
            luckyVisible={luckyVisible}
            daynVisible={daynVisible}
            charVisible={charVisible}
            snoVisible={snoVisible}
            specialNumberVisible={specialNumberVisible}
            special2NumberVisible={special2NumberVisible}
            handleChange={handleChange}
          />
        )}
        {step == 1 && <ImportWinngs loading={loading} setFile={setFile} />}
        {step == 2 && <DisplayImport loading={loading} list={list} />}
        <Stepper steps={steps} activeStep={step} />
      </div>
    </DialogModal>
  );
};

export default AddWinnings;
