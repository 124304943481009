import React from "react";
import {Box, Button, Grid, Hidden, Typography} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import {SortableElement, SortableHandle} from "react-sortable-hoc";

import {makeStyles} from "@material-ui/styles";
import GridContainer from "@jumbo/components/GridContainer";
import {Delete} from "@material-ui/icons";

const useStyles = makeStyles({
  dragButton: {
    minWidth: "auto",
    "&:hover": {
      cursor: "grab"
    },
    "&:focus, &:active, &:focus-within": {
      cursor: "grabbing"
    }
  }
});

const DragHandle = SortableHandle(() => <DragIndicatorIcon />);

const Column = props => {
  const classes = useStyles();
  const {column, handleDelete} = props;

  const OnDelete = () => {
    handleDelete({column});
  };

  const {file_header, status, length, required} = column;

  return (
    <Box>
      <GridContainer wrap="nowrap" alignItems="center">
        <Grid item>
          <Button size="small" className={classes.dragButton}>
            <DragHandle />
          </Button>
        </Grid>
        <Grid item xs lg={3} zeroMinWidth>
          <Typography noWrap>{file_header}</Typography>
        </Grid>
        <Hidden mdDown>
          <Grid item xs lg={3} zeroMinWidth>
            <Typography noWrap>{status}</Typography>
          </Grid>
        </Hidden>
        <Hidden smDown>
          <Grid item xs lg={2} zeroMinWidth>
            <Typography noWrap>{length}</Typography>
          </Grid>
        </Hidden>
        <Hidden lgDown>
          <Grid item xs zeroMinWidth>
            <Typography noWrap>{required}</Typography>
          </Grid>
        </Hidden>
        <Grid item xs zeroMinWidth>
          <Box display="flex" justifyContent="flex-end">
            <IconButton onClick={OnDelete}>
              <Delete />
            </IconButton>
          </Box>
        </Grid>
      </GridContainer>
    </Box>
  );
};

export default SortableElement(Column);
