import React, {useEffect, useState} from "react";
import ShoppingCartCard from './Card';
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    ticketList: {
        maxHeight: 500,
        minHeight: 400,
        overflow: "scroll",
    },
}));

function addToGroup(groups, ticket, lotteryList, group) {
    if (!group) {
        const lottery_name = lotteryList.find((l) => l.value === ticket.lottery_id).label;

        group = {
            lottery_id: ticket.lottery_id, tickets: [], lottery_name, source: ticket.source, count: 0, ticket_price: 0,
        };
        groups.push(group);
    }
    group.tickets.push(ticket);
    group.count = group.count + 1;
    group.ticket_price = group.ticket_price + ticket.ticket_price;
}

const ShoppingCart = ({
                          lotteryList,
                          selectedTickets,
                          setSelected,
                          handleDelete,
                          quantity,
                          price,
                          availableTickets,
                          selectedTicket,
                          mergedTicketsm,
                      }) => {
    const classes = useStyles();
    const [rows, setRows] = useState([]);

    useEffect(() => {
        const groups = [];
        selectedTickets.forEach((ticket) => {
            console.log("ticketid search", ticket.ticket_id);
            if (ticket.created_at) {
                console.log("created_at available");
            } else {
                console.log("created_at not available");
            }

            if (ticket.source === undefined) {
                let group = groups.find((g) => g.lottery_id === ticket.lottery_id && g.source === undefined);
                addToGroup(groups, ticket, lotteryList, group);
            } else {
                let group = groups.find((g) => g.lottery_id === ticket.lottery_id && g.source === 'quick_buy');
                addToGroup(groups, ticket, lotteryList, group);
            }

        });
        setRows(groups);
    }, [selectedTickets, lotteryList]);

    useEffect(() => {
        try {
            if (quantity > 0 && quantity === availableTickets.length) {
                const groups = [];
                availableTickets.forEach((ticket) => {
                    console.log("ticketid quick lock_on", ticket.lock_on);
                    console.log("ticketid quick", ticket.ticket_id);

                    if (ticket.lock_on) {
                        console.log("created_at available");
                    } else {
                        console.log("created_at not available");
                    }

                    if (ticket.created_at) {
                        console.log("created_at available");
                    } else {
                        console.log("created_at not available");
                    }
                    let group = groups.find((g) => g.lottery_id === ticket.lottery_id);

                    if (ticket.source === 'quick_buy') {
                        if (!group) {
                            const lottery_name = lotteryList.find((l) => l.value === ticket.lottery_id).label;
                            group = {
                                lottery_id: ticket.lottery_id,
                                tickets: [],
                                lottery_name,
                                source: ticket.source,
                                ticket_price: 0,

                            };
                            groups.push(group);
                        }
                        group.tickets.push(ticket);
                        group.count = group.count + 1;
                        group.ticket_price = group.ticket_price + ticket.ticket_price;
                    }
                });
                setSelected([...availableTickets, ...selectedTickets]);
                setRows(groups);
                quantity = 0;
            }
        } catch (error) {
            console.error("Error in useEffect:", error);

        }
    }, [availableTickets, lotteryList]);

    return (<ShoppingCartCard rows={rows} setRows={setRows} handleDelete={handleDelete} quantity={quantity}
                              price={price}
                              availableTickets={availableTickets} lotteryList={lotteryList}/>
    );
};

export default ShoppingCart;
