import { TextField, Select } from "app/uielements";
import React from "react";
import DateFnsUtils from "@date-io/date-fns";
import Grid from "@material-ui/core/Grid";
import useStyles from "./Topup.styles";

import IntlMessages from "@jumbo/utils/IntlMessages";
import {
  KeyboardDatePicker,
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";

const CreateTransaction = ({
  format,
  setValue,
  register,
  paymentTypes,
  errors,
  getValues,
  options
}) => {
  const classes = useStyles();

  return (
    <form noValidate>
      <Grid container item xs={12}>
        <Grid item xs={12} md={6} className={classes.formColumn}>
          <Select
            required
            {...register("payment_type", {
              required: { value: true, message: format("errors.required") },
            })}
            onChange={(e) =>
              setValue("payment_type", e.target.value, {
                shouldValidate: true,
              })
            }
            id="payment_type"
            name="payment_type"
            variant="outlined"
            label={format("payment_type")}
            error={errors.payment_type}
            helperText={errors.payment_type?.message}
            options={paymentTypes}
          />
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDateTimePicker
              required
              disableToolbar
              format="MM/dd/yyyy HH:mm:ss"
              margin="normal"
              inputVariant="outlined"
              id="transaction_date"
              label={format("transaction_date")}
              {...register("transaction_date", {
                required: {
                  value: true,
                  message: <IntlMessages id="errors.required" />,
                },
              })}
              className={classes.fullWidth}
              value={getValues().transaction_date}
              onChange={(val) =>
                setValue("transaction_date", val, { shouldValidate: true })
              }
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              autoOk
              error={errors.transaction_date}
              helperText={errors.transaction_date?.message}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={12} md={6} className={classes.formColumn}>
          <TextField
            required
            {...register("amount", {
              required: {
                value: true,
                message: <IntlMessages id="errors.required" />,
              },
            })}
            type="number"
            id="amount"
            name="amount"
            variant="outlined"
            label={format("amount")}
            error={errors.amount}
            helperText={errors.amount?.message}
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default CreateTransaction;
