import {makeStyles} from "@material-ui/core/styles";

import {useFluidModelContent} from "../../uielements/Dialog.styles";

const useStyles = makeStyles(theme => ({
  ...useFluidModelContent(theme),
  root: {
    minWidth: 275,
    minHeight: 150,
    height: "85%"
  },
  newDrawWrapper: {
    minHeight: 500
  },
  container: {},
  drawFirstCol: {
    padding: "30px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    "& > div": {
      marginBottom: 15
    }
  },
  drawSecondCol: {
    padding: "30px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    "& > div": {
      marginBottom: 15
    }
  },
  lastCol: {
    padding: "0 30px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    "& > div": {
      marginBottom: 15
    }
  },
  tableContainer: {
    height: "93%",
    overflow: "auto"
  },
  buttons: {
    display: "flex",
    flexDirection: "row-reverse"
  },
  primary: {
    justifyItems: "flex-end"
  }
}));

export default useStyles;
