import {Typography} from "@material-ui/core";
import {DropzoneArea} from "material-ui-dropzone";
import React from "react";
import {useIntl} from "react-intl";

const ImportWinngs = ({loading, setFile}) => {
  const handleChange = files => setFile(files[0]);

  const intl = useIntl();
  const format = id =>
    intl.formatMessage({
      id: `winnings.add.import.${id}`
    });

  return (
    <React.Fragment>
      <Typography variant="h3" gutterBottom>
        {format("heading")}
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        {format("subtitle")}
      </Typography>
      <DropzoneArea
        acceptedFiles={[
          ".csv",
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          "application/vnd.ms-excel"
        ]}
        showPreviews={true}
        showPreviewsInDropzone={false}
        useChipsForPreview
        previewGridProps={{
          container: {spacing: 1, direction: "row"}
        }}
        previewText={format("preview")}
        onChange={handleChange}
        filesLimit={1}
      />
    </React.Fragment>
  );
};

export default ImportWinngs;
