import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import configureStore from "./redux/store";
import { fetchUser } from "../src/redux/store/auth/actions";
import isEmpty from "lodash/isEmpty";

const store = configureStore();

const init = async () => {
  try {
    const { auth } = store.getState();
    const atkn = localStorage.getItem("atkn");
    if (!isEmpty(atkn) && atkn !== "undefined" && isEmpty(auth.authUser)) {
      await store.dispatch(fetchUser());
    }
  } catch (e) {
    console.error("There was a problem getting user data \n", e);
  }
  ReactDOM.render(<App />, document.getElementById("root"));
};

init();
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
