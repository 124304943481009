import {Grid, Typography} from "@material-ui/core";
import {DialogModal} from "app/uielements";

import React from "react";
import {useIntl} from "react-intl";

import useStyles from "./Download.styles";
import DownloadCard from "./DownloadCard";
import IntlMessages from "@jumbo/utils/IntlMessages";

const Download = ({id, open, handleClose}) => {
  const intl = useIntl();
  const classes = useStyles();
  const format = id =>
    intl.formatMessage({
      id: `download.${id}`
    });

  return (
    <DialogModal
      isOpen={open}
      handleClose={handleClose}
      enableActions={false}
      title={format("title")}
      backdrop="static"
    >
      <Grid
        container
        direction="row"
        justify="space-around"
        alignItems="flex-start"
        className={classes.modelContent}
      >
        <Grid item xs={12}>
          <Typography variant="subtitle1" gutterBottom>
            <IntlMessages id="draws.download.subtitle" />
          </Typography>
        </Grid>
        <Grid container item xs={12}>
          <Grid item xs={6} className={classes.formColumn}>
            <DownloadCard status="soldwithoutmask" draw_id={id} />
          </Grid>
          <Grid item container sm={12} md={6} className={classes.formColumn}>
            <DownloadCard includeDBF status="soldwithmask" draw_id={id} />
          </Grid>
          <Grid item container sm={12} md={6} className={classes.formColumn}>
            <DownloadCard status="unsold" draw_id={id} />
          </Grid>
          <Grid item container sm={12} md={6} className={classes.formColumn}>
            <DownloadCard status="unsoldwin" draw_id={id} />
          </Grid>
        </Grid>
      </Grid>
    </DialogModal>
  );
};

export default Download;
