import React from "react";
import {
  Box,
  MenuItem,
  MenuList,
  Paper,
  Popover,
  Typography,Tooltip
} from "@material-ui/core";
import CmtAvatar from "../../../../@coremat/CmtAvatar";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useDispatch, useSelector } from "react-redux";
import { AuhMethods } from "../../../../services/auth";
import { CurrentAuthMethod } from "../../../constants/AppConstants";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import PersonIcon from "@material-ui/icons/Person";
import { Link, useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "30px 16px 30px 16px",
    borderBottom: `solid 1px ${theme.palette.sidebar.borderColor}`,
  },
  userInfo: {
    paddingTop: 12,
    transition: "all 0.1s ease",
    height: 75,
    opacity: 1,
    ".Cmt-miniLayout .Cmt-sidebar-content:not(:hover) &": {
      height: 0,
      paddingTop: 0,
      opacity: 0,
      transition: "all 0.3s ease",
    },
  },
  userTitle: {
    color: theme.palette.sidebar.textDarkColor,
    marginBottom: 2,
    fontSize: 14,
  },
  userSubTitle: {
    fontSize: 12,
    fontWeight: theme.typography.fontWeightBold,
    letterSpacing: 0.25,
    textOverflow: 'ellipsis',
    overflow: "hidden",
    whiteSpace: "nowrap",
    width: "50px",
  },
}));

const SidebarHeader = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { authUser } = useSelector(({ auth }) => auth);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const history = useHistory();

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const onLogoutClick = () => {
    handlePopoverClose();
    dispatch(AuhMethods[CurrentAuthMethod].onLogout());
  };

  const onProfileClick = () => {
    handlePopoverClose();
    history.push(`/users/${authUser.id}`);
  };

  const email = authUser ? authUser.email : "";
  // const fullName = authUser
  //   ? `${authUser.fname} ${authUser.lname}`.toUpperCase()
  //   : "";
  const firstName = authUser
      ? authUser.fname.toUpperCase()
      : "";
  return (
      <Box className={classes.root}>
        <CmtAvatar alt={firstName} size="smaller" />
        <Box className={classes.userInfo} onClick={handlePopoverOpen}>
          <Box
              className="pointer"
              justifyContent="space-between"
              alignItems="flex-end"
          >
            <Box mr={2}>
              <Typography
                  className={classes.userTitle}
                  component="h3"
                  variant="h6"
              >
                {firstName}
              </Typography>
              <Tooltip title={email}>
              <Typography className={classes.userSubTitle}>{email}</Typography>
              </Tooltip>
            </Box>
            <ArrowDropDownIcon />
          </Box>
        </Box>

        {open && (
            <Popover
                open={open}
                anchorEl={anchorEl}
                container={anchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={{
                  vertical: "center",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "center",
                  horizontal: "right",
                }}
            >
              <Paper elevation={8}>
                <MenuList>
                  <MenuItem onClick={onProfileClick}>
                    <PersonIcon />
                    <Box ml={2}>Profile</Box>
                  </MenuItem>
                  <MenuItem onClick={onLogoutClick}>
                    <ExitToAppIcon />
                    <Box ml={2}>Logout</Box>
                  </MenuItem>
                </MenuList>
              </Paper>
            </Popover>
        )}
      </Box>
  );
};

export default SidebarHeader;
