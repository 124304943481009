import React , { useEffect, useState }from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Card, CardContent, Typography } from '@material-ui/core';

// import Grid from "@material-ui/core/Grid";
import PerfectScrollbar from 'react-perfect-scrollbar';
import useData from "./State";
import axios from "axios";
import {
  BasicInfoCard,
  InformationPanel,
  Avatar,
  AvatarWithName,
} from "../uielements";
import DrawSummaryContainer from "./Draws";
import usePermissions from "app/hooks/usePermissions";


const { applyPermissions, permissions } = usePermissions();


const DashboardData = () => {
  const agent_code = JSON.parse(localStorage.getItem("user")).agent_code;
  const [userId, setUserId] = useState(null);
  const [blocking, setBlocking] = useState(false);

  useEffect(() => {
    fetchUserId();
  }, []);

  const fetchUserId = async () => {
    try {
      setBlocking(true);
      const response = await axios.get('/api/user');
      setUserId(response.data.id);
    } catch (err) {
      console.error('Error fetching user ID:', err);
    } finally {
      setBlocking(false);
    }
  };

  const useStyles = makeStyles({
    root: {
      minWidth: 275,
      minHeight: 150,
    },
    scrollbarRoot: {
      height: 390,
      '& .CmtList-EmptyResult': {
        backgroundColor: 'transparent',
        border: '0 none',
      },
    },
  });

  const classes = useStyles();
  const {
    register,
    errors,
    setValue,
    getValues,
    loading,
    data,
    drawSummary,
    rows,
    updateRow,
    ...rest
  } = useData();

  const breadcrumbs = [
    { label: "Home", link: "/" },
    { label: "Sample Page", isActive: true },
  ];

  const getContent = () => {
    return (
      <AvatarWithName
        alt="Cinnamon Chip Wealth Promoters (Pvt) Ltd"
        src="/static/images/avatar/1.jpg"
        name="Cinnamon Chip Wealth Promoters (Pvt) Ltd"
      />
    );
  };


  const renderDraws = () => {
    return (
      <PerfectScrollbar className={classes.scrollbarRoot}>
        <DrawSummaryContainer draws={drawSummary.data} />
      </PerfectScrollbar>
    );
  };

  return (
    <div>
      <Grid container spacing={3}>



        <Grid container spacing={3}>

      <Grid item xs={6} sm={6} md={4}>
      {(localStorage.getItem("role") != "dialog") ?(
            <BasicInfoCard
              cardObj={{
                total: "",
                newValue: (data?.agent_wallet_balance || 0),
                type: "Today Revenue"
              }}
              name="Today Revenue"
            /> ):("")}
      </Grid>

        <Grid item xs={6} sm={6} md={4}>
            {(localStorage.getItem("role") != "dialog") ?(
                <BasicInfoCard
                    cardObj={{
                        total: "",
                        newValue: (data?.pending_revenue || 0),
                        type: "Pending Revenue",
                    }}
                    name="Pending Revenue"
                /> ):("")}
        </Grid>

      <Grid item xs={6} sm={6} md={4}>
      {(localStorage.getItem("role") != "dialog") ?(
            <BasicInfoCard
              cardObj={{
                total: "",
                newValue: data?.today_revenue || 0,
                type: "Settled Revenue",
              }}
              name="Settled Revenue"
            />  ):("")}
      </Grid>
    </Grid>


        {(localStorage.getItem("role") != "dialog") && (
    <Grid item xs={12} sm={12} md={12} lg={12}>
      {/*<DrawSummaryContainer draws={drawSummary.data} />*/}
    </Grid>
  )}
</Grid>

    </div>
  );
};

export default DashboardData;
