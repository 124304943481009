import React, { useMemo } from "react";

import CmtCard from "@coremat/CmtCard";
import CmtCardContent from "@coremat/CmtCard/CmtCardContent";
import ReturnCell from "app/Draws/Returns/ReturnCell";
import PerfectScrollbar from "react-perfect-scrollbar";
import { makeStyles } from "@material-ui/core/styles";
import CmtList from "@coremat/CmtList";
import moment from "moment";
const useStyles = makeStyles((theme) => ({
  cardRoot: {
    position: "relative",
    "& .Cmt-card-content": {
      padding: 0,
      paddingBottom: 24,
    },
  },
  scrollbarRoot: {
    height: 305,
  },
  progress: {
    display: "flex",
    alignContent: "center",
  },
}));

const Verify = ({ getValues, format, options }) => {
  const styles = useStyles();
  const data = useMemo(() => {
    const values = getValues();
    return Object.keys(values)
      .filter((key) => key !== "branch_code")
      .map((key) => ({
        label: format(key),
        value:
          key === "transaction_date"
            ? moment(values[key]).format("MM/DD/yyyy")
            : key === "to_bank_account"
            ? options.find((o) => o.value === values[key]).label
            : values[key],
      }));
  }, [getValues]);
  return (
    <CmtCard className={styles.cardRoot}>
      <CmtCardContent>
        <PerfectScrollbar className={styles.scrollbarRoot}>
          <CmtList
            data={data}
            renderRow={(row, index) => <ReturnCell key={index} data={row} />}
          />
        </PerfectScrollbar>
      </CmtCardContent>
    </CmtCard>
  );
};

export default Verify;
