import React from "react";
import { DialogModal, Grid, Loader, Select, TextField } from "app/uielements";
import useStyles from "./FileFormat.styles";
import {
  Box,
  FormControlLabel,
  IconButton,
  Paper,
  Switch,
  Typography,
} from "@material-ui/core";
import IntlMessages from "@jumbo/utils/IntlMessages";
import { AddCircleOutline } from "@material-ui/icons";
import { useIntl } from "react-intl";
import useData from "./State";
import ColumnList from "./Columns";

const FileFormat = ({ id, open, handleClose }) => {
  const classes = useStyles();
  const intl = useIntl();

  const format = (id) =>
    intl.formatMessage({
      id: `lottery.fileformat.${id}`,
    });

  const statusTypes = [
    { value: 1, label: format("status.charactor") },
    { value: 2, label: format("status.integer") },
  ];

  const requiredTypes = [
    { value: 1, label: format("required.mandatory") },
    { value: 0, label: format("required.optional") },
  ];

  const {
    handleNext,
    register,
    errors,
    setValue,
    handleSubmit,
    onSubmit,
    getValues,
    registerOuter,
    errorsOuter,
    setValueOuter,
    columnOptions,
    primaryDisabled,
    loading,
    columns,
    setColumns,
    getValuesOuter,
  } = useData({ id, handleClose });

  const handleOnChange = (evt) => {
    setValueOuter(evt.target.name, evt.target.value, { shouldValidate: true });
  };

  return (
    <DialogModal
      isOpen={open}
      handleClose={handleClose}
      handlePrimaryButton={handleNext}
      handleSecondaryButton={handleClose}
      primaryButtonLabel={format("save")}
      secondaryButtonLabel={format("cancel")}
      primaryDisabled={primaryDisabled}
      title={format("title")}
      loading={loading}
    >
      <Grid
        container
        direction="row"
        justify="space-around"
        alignItems="flex-start"
        className={classes.modelContent}
      >
        {loading && <Loader />}
        <Grid item xs={12}>
          <Paper>
            <Box p={1}>
              <Typography variant="subtitle2" gutterBottom>
                <IntlMessages id="lottery.fileformat.file_columns" />
              </Typography>
            </Box>
            <form noValidate onSubmit={handleSubmit(onSubmit)}>
              <Grid container item xs={12}>
                <Grid item sm={12} md={3} className={classes.formColumn}>
                  <TextField
                  variant="outlined"
                    label={format("file_header")}
                    {...register("file_header", { required: true })}
                    value={getValues().file_header}
                    onChange={(evt) =>
                      setValue("file_header", evt.target.value, {
                        shouldValidate: true,
                      })
                    }
                    error={!!errors.file_header}
                  />
                </Grid>
                <Grid item sm={12} md={3} className={classes.formColumn}>
                  <Select
                    required
                    variant="outlined"
                    inputRef={register("status", { required: true })}
                    value={getValues().status}
                    onChange={(e) =>
                      setValue("status", e.target.value, {
                        shouldValidate: true,
                      })
                    }
                    InputProps={{ inputProps: { min: 0 } }}
                    id="status"
                    name="status"
                    label={format("status")}
                    error={!!errors.status}
                    options={statusTypes}
                  />
                </Grid>
                <Grid item sm={12} md={2} className={classes.formColumn}>
                  <TextField
                    type="number"
                    variant="outlined"
                    label={format("length")}
                    {...register("length", { required: true, min: 1 })}
                    value={getValues().length}
                    onChange={(evt) =>
                      setValue("length", evt.target.value, {
                        shouldValidate: true,
                      })
                    }
                    InputProps={{ inputProps: { min: 1 } }}
                    error={!!errors.length}
                  />
                </Grid>
                <Grid item sm={12} md={3} className={classes.formColumn}>
                  <Select
                    required
                    variant="outlined"
                    {...register("required", { required: true })}
                    value={getValues().required}
                    onChange={(e) => {
                      setValue("required", e.target.value, {
                        shouldValidate: true,
                      });
                    }}
                    InputProps={{ inputProps: { min: 0 } }}
                    id="required"
                    name="required"
                    label={format("required")}
                    error={!!errors.required}
                    options={requiredTypes}
                  />
                </Grid>
                <Grid item sm={12} md={1} className={classes.iconButton}>
                  <IconButton
                  variant="outlined"
                    aria-controls="customized-menu"
                    aria-haspopup="true"
                    type="submit"
                  >
                    <AddCircleOutline />
                  </IconButton>
                </Grid>
              </Grid>
            </form>
            <ColumnList
              className={classes.list}
              columns={columns}
              setColumns={setColumns}
            />
          </Paper>
        </Grid>
        <Grid container item xs={12}>
          <Grid item sm={12} md={4} className={classes.formColumn}>
            <TextField
              variant="outlined"
              name="file_prefix"
              label={format("file_prefix")}
              {...registerOuter("file_prefix", { required: true })}
              value={getValuesOuter().file_prefix}
              onChange={handleOnChange}
              error={!!errorsOuter.file_prefix}
            />
          </Grid>
          <Grid item sm={12} md={4} className={classes.formColumn}>
            <TextField
              variant="outlined"
              name="barcode_prefix"
              label={format("barcode_prefix")}
              {...registerOuter("barcode_prefix", { required: true })}
              value={getValuesOuter().barcode_prefix}
              onChange={handleOnChange}
              error={!!errorsOuter.barcode_prefix}
            />
          </Grid>
          <Grid item sm={12} md={4} className={classes.formColumn}>
            <TextField
              variant="outlined"
              name="winning_prefix"
              label={format("winning_prefix")}
              {...registerOuter("winning_prefix", { required: true })}
              value={getValuesOuter().winning_prefix}
              onChange={handleOnChange}
              error={!!errorsOuter.winning_prefix}
            />
          </Grid>
          <Grid item sm={12} md={4} className={classes.formColumn}>
            <Select
            variant="outlined"
              inputRef={registerOuter("matching_column", { required: true })}
              value={getValuesOuter().matching_column}
              onChange={(e) =>
                setValueOuter("matching_column", e.target.value, {
                  shouldValidate: true,
                })
              }
              InputProps={{ inputProps: { min: 0 } }}
              id="matching_column"
              name="matching_column"
              label={format("matching_column")}
              options={columnOptions}
              error={!!errorsOuter.matching_column}
            />
          </Grid>
          <Grid item sm={12} md={4} className={classes.formColumn}>
            <Select
            variant="outlined"
              inputRef={registerOuter("special_column")}
              value={getValuesOuter().special_column}
              onChange={(e) =>
                setValueOuter("special_column", e.target.value, {
                  shouldValidate: true,
                })
              }
              InputProps={{ inputProps: { min: 0 } }}
              id="special_column"
              name="special_column"
              label={format("special_column")}
              options={columnOptions}
              error={!!errorsOuter.special_column}
            />
          </Grid>
          <Grid item sm={12} md={4} className={classes.formColumn}>
            <FormControlLabel
              control={
                <Switch
                  {...registerOuter("is_split")}
                  checked={getValuesOuter().is_split === 1}
                  onChange={(e) =>
                    setValueOuter("is_split", e.target.checked ? 1 : 0, {
                      shouldValidate: true,
                    })
                  }
                  name="is_split"
                  color="primary"
                />
              }
              label={format("is_split")}
            />
          </Grid>
          <Grid item sm={12} md={4} className={classes.formColumn}>
            <TextField
            variant="outlined"
              multiline
              rowsMax={4}
              label={format("format_description")}
              {...registerOuter("format_description")}
              value={getValuesOuter().format_description}
              onChange={handleOnChange}
              error={!!errorsOuter.format_description}
            />
          </Grid>
        </Grid>
      </Grid>
    </DialogModal>
  );
};

export default FileFormat;
