import React, { useState } from "react";

import Grid from "@material-ui/core/Grid";

import IntlMessages from "@jumbo/utils/IntlMessages";
import { Box, Button } from "@material-ui/core";
import WithPermission from "app/uielements/WithPermission";
import AddUser from "./AddUser";
import UserList from "./UserList";
import useLotteryList from "../hooks/useLotteries";
import PageContainer from "@jumbo/components/PageComponents/layouts/PageContainer";

const Users = ({}) => {
  const [addUserOpen, setAddUserOpen] = useState(false);
  const [total, setTotal] = useState(0);
  const { lotteryList } = useLotteryList();

  const handleClickOpen = () => {
    setAddUserOpen(true);
  };


  const handleDialogClose = () => {
    setAddUserOpen(false);
  };
  const breadcrumbs = [
    { label: "Home", link: "/" },
    { label: "CRM", link: "/users", isActive: true },
  ];

  return (
    <PageContainer
      heading={<IntlMessages id="user.title" />}
      breadcrumbs={breadcrumbs}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid
            container
            direction="row"
            justify="space-around"
            alignItems="flex-start"
            spacing={1}
          />
        </Grid>
        <Grid item xs={12}>
          <WithPermission permissions={["user.register"]}>
            <Box m={1}>
              <Button
                onClick={handleClickOpen}
                variant="contained"
                color="primary"
              >
                <IntlMessages id="user.list.create" />
              </Button>
            </Box>
          </WithPermission>
          {addUserOpen && (
            <AddUser open={addUserOpen} handleClose={handleDialogClose} setTotal={setTotal} />
          )}
        </Grid>
        <UserList lotteryList={lotteryList} total={total} setTotal={setTotal} />
      </Grid>
    </PageContainer>
  );
};

export default Users;
