import CmtCard from "@coremat/CmtCard";
import CmtCardContent from "@coremat/CmtCard/CmtCardContent";
import CmtCardHeader from "@coremat/CmtCard/CmtCardHeader";
import IntlMessages from "@jumbo/utils/IntlMessages";
import { IconButton, Tooltip, Typography } from "@material-ui/core";
import DataGrid from "app/uielements/Table/DataGrid";
import { PlayArrow, Send } from "@material-ui/icons";
import React from "react";
import { useIntl } from "react-intl";

const Results = ({
  rows,
  title,
  className,
  titleProps,
  total,
  pageSize,
  handlePageChange,
  handlePageCountChange,
  handleSortModelChange,
  sortModel,
  handleFilterChange,
  loading,
  resend,
}) => {

  const intl = useIntl();
  const columns = [
    {
      field: "mobile_no",
      headerName: intl.formatMessage({id:"sms.mobile_no"}),
      flex: 1.2,
    },

    {
      field: "type",
      headerName: intl.formatMessage({id:"sms.type"}),
      flex: 0.8,
    },
    {
      field: "status",
      headerName: intl.formatMessage({id:"sms.status"}),
      flex: 0.8,
    },
    {
      field: "send_at",
      headerName: intl.formatMessage({id:"sms.send_at"}),
      flex: 2.3,
    },
    
    {
      field: "mask",
      headerName: intl.formatMessage({id:"sms.mask"}),
      flex: 1.3,
    },
    {
      field: "campaign",
      headerName: intl.formatMessage({id:"sms.campaign"}),
      flex: 0.8,
    },
    
    {
      field: "text",
      headerName: intl.formatMessage({id:"sms.text"}),
      flex: 1.8,
      renderCell: (params) => (
        <Tooltip title={params.value} arrow>
          <Typography variant="subtitle2">{params.value}</Typography>
        </Tooltip>
      ),
    },
    {
      field: "sms_response",
      headerName: intl.formatMessage({id:"sms.response"}),
      flex: 2,
      renderCell: (params) => (
        <Tooltip title={params.value} arrow>
          <Typography variant="subtitle2">{params.value}</Typography>
        </Tooltip>
      ),
    },
    {
      field: "id",
      headerName: intl.formatMessage({id:"sms.send"}),
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <React.Fragment>
          <IconButton
            onClick={async (evt) => await resend(params.value, params.row)}
          >
            <Send />
          </IconButton>
        </React.Fragment>
      ),
      flex: 1,
    },
  ];
  return (
    <CmtCard className={className}>
      <CmtCardHeader title={title} titleProps={titleProps} />
      {rows.length > 0 && (
        <CmtCardContent style={{ height: 655 }}>
          <DataGrid
            rows={rows}
            columns={columns}
            pagination
            pageSize={pageSize}
            rowsPerPageOptions={[10, 20, 50, 100]}
            rowCount={total}
            paginationMode="server"
            onPageChange={handlePageChange}
            onPageSizeChange={handlePageCountChange}
            sortingMode="server"
            sortModel={sortModel}
            onSortModelChange={handleSortModelChange}
            filterMode="server"
            onFilterModelChange={handleFilterChange}
          
          />
        </CmtCardContent>
      )}
    </CmtCard>
  );
};

export default Results;
