import React, { useState } from 'react';
import useStyles from "./Profile.styles"
import useProfile from "./Profile.state";
import UpdateProfile from "../UpdateProfile";
import PageContainer from "@jumbo/components/PageComponents/layouts/PageContainer";
import IntlMessages from "@jumbo/utils/IntlMessages";
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import {Box} from "@material-ui/core";


const Profile = () => {
    const classes = useStyles();

    /*const result = useProfile();
    console.log(result);*/
    // console.log(userInfo.id);
    const { updateUser, setUpdateUser, userInfo } = useProfile();
    const [updateUserOpen, setUpdateUserOpen] = useState(false);
    // console.log(updateUser);

    const breadcrumbs = [
        { label: "Home", link: "/" },
        { label: "Users", link: "/users" },
        { label: "Index", link: "/profile", isActive: true },
    ];

    const handleUpdateOpen = () => {
        setUpdateUserOpen(true);
    };

    const handleUpdateClose = () => {
        setUpdateUserOpen(false);
    }

    return (
        <PageContainer
            heading={<IntlMessages id="user.profile.title" />}
            breadcrumbs={breadcrumbs}
        >
            <div className={classes.root}>
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <Paper className={classes.paper}>
                            <List component="nav" className={classes.root} aria-label="mailbox folders">
                                <ListItem divider>
                                    <ListItemText primary={userInfo.fullName} secondary="Name" />
                                </ListItem>
                                <ListItem divider>
                                    <ListItemText primary={userInfo.email} secondary="Email" />
                                </ListItem>
                                <ListItem divider>
                                    <ListItemText primary={userInfo.nicno} secondary="NIC no" />
                                </ListItem>
                                <ListItem divider>
                                    <ListItemText primary={userInfo.fullTelephone} secondary="Telephone" />
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary={userInfo.gender} secondary="Gender" />
                                </ListItem>
                            </List>
                            <Box p={1} m={1}>
                                <Button
                                    onClick={handleUpdateOpen}
                                    variant="contained"
                                    color="primary"
                                >
                                    {/*<IntlMessages id="user.list.create" />*/}
                                    Update Profile
                                </Button>
                            </Box>
                        </Paper>

                        <UpdateProfile open={updateUserOpen} handleClose={handleUpdateClose} user={updateUser} />

                    </Grid>
                    <Grid item xs={6}>
                        <Card className={classes.root}>
                            <CardActionArea>
                                <CardMedia
                                    className={classes.media}
                                    image={userInfo.avatar_path}
                                    title="Avatar Image"
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="h2">
                                        Profile Image
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        select profile image
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                            <CardActions>
                                <Button size="small" color="primary" variant="contained">
                                    Upload New Image
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>
                </Grid>
            </div>
        </PageContainer>
    );
}

export default Profile;