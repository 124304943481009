import React, { useEffect } from "react";
import useData from "./State";
import { useIntl } from "react-intl";
import DataGrid from "app/uielements/Table/DataGrid";
import { DialogModal } from "app/uielements";
import useStyles from "./Styles";
import CustomGridPanel from "app/uielements/Table/CustomGridPanel";
import { IconButton, Switch, Tooltip,Typography } from "@material-ui/core";
import { Delete, Edit } from "@material-ui/icons";
import EditSubscription from "../Edit";


const ViewSubscriptions = ({ open, handleClose, id, lotteries, user }) => {
  const {
    rows,
    total,
    loading,
    pageSize,
    handlePageChange,
    handlePageCountChange,
    setId,
    setRows,
    handleSortModelChange,
    sortModel,
    handleFilterChange,
    toggleStatus,
    getLotteryName,
    formatDate,
    editItem,
    deleteItem,
    selected,
    editOpen,
    handleEditClose
  } = useData({ lotteries });

  useEffect(() => {
    setId(id);
  }, [id]);

  const styles = useStyles();

  const intl = useIntl();

  const format = (id) =>
    intl.formatMessage({
      id: `user.subscriptions.${id}`,
    });

  const columns = [
    {
      field: "lottery_id",
      headerName: format("lottery_name"),
      flex:1.5,
      valueFormatter: getLotteryName,
    },
    {
      field: "package_name",
      headerName: format("package_name"),
      flex: 1,
    },
    {
      field: "run_date",
      headerName: format("run_date"),
      flex: 1,
    },
    {
      field: "status",
      headerName: format("status"),
      flex: 1.2,
      renderCell: (params) => (
        <React.Fragment>
          <Switch
            checked={!!params.value}
            onClick={async (evt) =>
              await toggleStatus(params.row.id, params.row)
            }
          />
        </React.Fragment>
      ),
    },
    {
      field: "ticket_quantity",
      headerName: format("ticket_quantity"),
      flex: 1,
    },
    {
      field: "current_ticket_count",
      headerName: format("current_ticket_count"),
      flex: 1,
      hide: true,
    },
    {
      field: "mobile",
      headerName: format("mobile_no"),
      renderCell: (params) => (
        <Tooltip title={params.value} arrow>
          <Typography variant="subtitle2">{params.value}</Typography>
        </Tooltip>
      ),
      flex: 1,
    },
    {
      field: "channel",
      headerName: format("channel"),
      flex: 1,
      hide: false,
    },
    {
      field: "created_at",
      headerName: format("created_at"),
      flex: 1.5,

      valueFormatter: formatDate,
      hide: false,
    },
    {
      field: "subscribe_date",
      headerName: format("subscribe_date"),
      flex: 1.5,
      valueFormatter: formatDate,
    },
    {
      field: "unsubscribe_date",
      headerName: format("unsubscribe_date"),
      flex: 1.5,
      valueFormatter: formatDate,
    },
    {
      field: "id",
      headerName: format("actions"),
      sortable:false,
      filterable:false,
      renderCell: (params) => (
        <React.Fragment>
          <IconButton onClick={async (evt) => await editItem(params.value, params.row)}>
            <Edit/>
          </IconButton>
          <IconButton onClick={async (evt) => await deleteItem(params.value, params.row)}>
            <Delete/>
          </IconButton>
        </React.Fragment>
      ),
      flex: 1,
    },
  ];


  const handleDialogClose = () => {
    handleClose();
    setRows([]);
  };

  return (
    <DialogModal
      isOpen={open}
      handleClose={handleDialogClose}
      primaryDisabled={true}
      enableActions={false}
      title={intl.formatMessage({ id: "user.subscriptions.view.title" }, user)}
    >
      <div className={styles.modelContent}>
        <DataGrid
          rows={rows}
          columns={columns}
          pagination
          pageSize={pageSize}
          rowsPerPageOptions={[10, 20, 50, 100]}
          rowCount={total}
          paginationMode="server"
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageCountChange}
          sortingMode="server"
          sortModel={sortModel}
          onSortModelChange={handleSortModelChange}
          filterMode="server"
          onFilterModelChange={handleFilterChange}
          loading={loading}
          components={{ Panel: CustomGridPanel }}
        />
        <EditSubscription 
          id={id}
          open={editOpen}
          handleClose={handleEditClose}
          lotteries={lotteries}
          user={user}
          data={selected}
        />
      </div>
    </DialogModal>
  );
};

export default ViewSubscriptions;
