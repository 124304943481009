import {makeStyles} from "@material-ui/core/styles";

import {useGridModelContent} from "../../uielements/Dialog.styles";

const useStyles = makeStyles(theme => ({
  ...useGridModelContent(theme),
  scrollbarRoot: {
    height: 280,
  },
  fullWidth: {
    width: '100%'
  }
}));

export default useStyles;
