import { DialogModal, Grid, Loader, Stepper, TextField } from "app/uielements";
import React from "react";
import { useIntl } from "react-intl";
import useStyles from "./AddLottery.styles";
import useAddLotteries from "./AddLottery.state";
import ImportImage from "./Import";
import CreateStep from "./Create";
import IntlMessages from "@jumbo/utils/IntlMessages";

const AddLotteries = ({ open, appendRow, handleClose }) => {
  const handleDialogClose = () => {
    handleClose();
  };
  const classes = useStyles();

  const intl = useIntl();

  const {
    loading,
    values,
    formValues,
    errors,
    submit,
    step,
    setValue,
    setFile,
    upload,
  } = useAddLotteries({ appendRow });

  const handleUpload = async () => {
    await upload(handleClose);
  };

  const format = (id) =>
    intl.formatMessage({
      id: `lottery.add.${id}`,
    });

  const steps = [
    { label: format("create"), optional: false },
    { label: format("import"), optional: false },
  ];

  const handleNext = async () => {
    if(step === 0){
      await submit();
    }else {
      await handleUpload();
    }
  }

  return (
    <DialogModal
      isOpen={open}
      handleClose={handleClose}
      handlePrimaryButton={handleNext}
      handleSecondaryButton={handleClose}
      primaryButtonLabel={<IntlMessages id="draws.edit.update" />}
      secondaryButtonLabel={<IntlMessages id="draws.edit.cancel" />}
      primaryDisabled={loading}
      loading={loading}
      title={format("title")}
    >
      <div className={classes.modelContent}>
      <Stepper steps={steps} activeStep={step} />
        {step == 0 && (
          <CreateStep
            loading={loading}
            errors={errors}
            values={values}
            submit={submit}
            setValue={setValue}
            formValues={formValues}
          />
        )}
        {step == 1 && (
          <ImportImage
            loading={loading}
            setFile={setFile}
            handleUpload={handleUpload}
          />
        )}
        
      </div>
      
    </DialogModal>
  );
};

export default AddLotteries;
