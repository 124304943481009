import useAxios from 'app/hooks/useAxios';
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';

const useData = ({id, updateAccount, bankItem}) => {
    const axios = useAxios();
    const defaultValues = {
      bank_name: '',
      branch_code: '',
      branch_name: '',
      account_name: '',
      account_number: '',
    }

    const {
      register,
      setValue,
      formState: { errors },
      handleSubmit,
      reset,
      getValues
    } = useForm({defaultValues});
  
    const [bank, setBank] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
     reset(bankItem)
    }, [bankItem]);

    const save = async data => {
      try {
        setLoading(true);
        const account = {
          user_id: id,
          account_name: data.account_name,
          bank_name: data.bank_name,
          account_number: data.account_number,
          branch_name: data.branch_name,
        }
        const response = await axios.post('/api/user/bankaccount/add',account )
        setLoading(false);
        if(response.status === 200){
          updateAccount(response.data.bank_account);
        }
      } catch (error) {
        setLoading(false);
      }
    }

    const submit = handleSubmit(save);

    return {
        register,
      setValue,
      setBank,
      bank,
      loading,
      errors,
      submit,
      getValues
    }
}

export default useData;