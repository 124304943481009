import useAxios from "app/hooks/useAxios";
import React, { useEffect, useState } from "react";

const useLotteryFormat = ({ setLoading, lottery_id }) => {

  const [lagnaVisible, setLagnaVisible] = useState(false);
  const [snoVisible, setSnoVisible] = useState(false);
  const [charVisible, setCharVisible] = useState(false);
  const [format, setFormat] = useState(null);

  const axios = useAxios();

  const isVisible = (format, cell) =>{
    const isVisible = Object.keys(format)
      .map((key) => format[key])
      .filter((val) => val === cell).length > 0
      return isVisible;
  }

  useEffect(() => {
    let active = true;
    setLoading(true);
    (async () => {
      try {
        const formatResponse = await axios.get(
          "/api/lottery/number/format/" + lottery_id
        );

        setLoading(false);

        const format = formatResponse.data.format;

        if (!active) {
          return;
        }

        if (format && format.original && format.original.errors === "1") {
          setLagnaVisible(false);
          setSnoVisible(false);
          setCharVisible(setCharVisible);
          setFormat(null);
        } else {
          setLagnaVisible(isVisible(format, "lagna"));
          setSnoVisible(isVisible(format, "sno"));
          setCharVisible(isVisible(format, "char"));
          setFormat({ ...format });
        }
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    })();

    return () => {
      active = false;
    };
  }, [lottery_id]);

  return {
    lagnaVisible,
    snoVisible,
    charVisible,
    format
  };
};

export default useLotteryFormat;
