import React from "react";
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  Hidden,
  Radio,
  Switch,
  Typography,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import { SortableElement, SortableHandle } from "react-sortable-hoc";

import { makeStyles } from "@material-ui/styles";
import GridContainer from "@jumbo/components/GridContainer";
import { Delete } from "@material-ui/icons";
import IntlMessages from "@jumbo/utils/IntlMessages";
import { TextField } from "app/uielements";
import useAxios from "app/hooks/useAxios";

const useStyles = makeStyles({
  dragButton: {
    minWidth: "auto",
    "&:hover": {
      cursor: "grab",
    },
    "&:focus, &:active, &:focus-within": {
      cursor: "grabbing",
    },
  },
});

const DragHandle = SortableHandle(() => <DragIndicatorIcon />);

const Mobile = (props) => {
  const classes = useStyles();
  const { column, handleDelete, setColumn } = props;
  const axios = useAxios();

  const OnDelete = () => {
    handleDelete({ column });
  };

  const handleVerify = () => {
    const value = {
      ...column,
      verified: true,
    };
    setColumn({ value, column });
  };

  const handleOtp = async () => {
    try {
      const response = await axios.post('/api/admin/otp/send', {
        mobile_no: '94' + mobile_no
      });

      if(response.status === 200){
        const value = {
          ...column,
          otp: response.data.otp,
        };
        setColumn({ value, column });
      }

     
    } catch (error) {
      
    }
    
  };

  const handlePrimary = () => {
    const value = {
      ...column,
      is_primary: true,
    };
    setColumn({ value, column });
  };

  const { is_primary, mobile_no, otp } = column;

  return (
    <Box>
      <GridContainer wrap="nowrap" alignItems="center">
        <Grid item>
          <Button size="small" className={classes.dragButton}>
            <DragHandle />
          </Button>
        </Grid>
        <Grid item xs lg={3} zeroMinWidth>
          <FormControlLabel
            control={
              <Switch
                checked={is_primary}
                onChange={handlePrimary}
                name="is_primary"
              />
            }
            label={<IntlMessages id="user.add.primary" />}
          />
        </Grid>
        <Grid item xs lg={3}>
          <Typography noWrap><TextField readOnly value={mobile_no} /></Typography>
        </Grid>
        <Grid item xs>
          <Box display="flex" justifyContent="flex-start" p={2} m={2}>
            <Button color="primary" onClick={handleOtp}>
              <IntlMessages id="user.add.otp" />
            </Button>
          </Box>
        </Grid>
        <Grid item xs lg={2} zeroMinWidth>
          <TextField readOnly value={otp} />
        </Grid>
        <Grid item xs>
          <Box display="flex" justifyContent="flex-start" p={2} m={2}>
            <Button color="primary" onClick={handleVerify}>
              <IntlMessages id="user.add.verify" />
            </Button>
          </Box>
        </Grid>
        <Grid item xs zeroMinWidth>
          <Box display="flex" justifyContent="flex-end">
            <IconButton onClick={OnDelete}>
              <Delete />
            </IconButton>
          </Box>
        </Grid>
      </GridContainer>
    </Box>
  );
};

export default SortableElement(Mobile);
