import useAxios from "app/hooks/useAxios";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { transformInToFormObject } from "services/utils";
import showAlert from 'app/hooks/useAlerts';

const useAddUser = ({ handleClose, setTotal }) => {
  const [primaryDisabled, setPrimaryDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [mobileList, setMobileList] = useState([]);
  const axios = useAxios();
  
  const {
    register,
    getValues,
    formState: { errors },
    setValue,
    handleSubmit,
  } = useForm();

  const intl = useIntl();
  const format = (id) =>
    intl.formatMessage({
      id: `user.add.${id}`,
    });

  const values = getValues();

  useEffect(() => {
    if (mobileList.length === 0) {
      setValue("telephone", undefined);
    }

    const primary = mobileList.find((m) => m.is_primary);
    if (primary) {
      setValue("telephone", primary.mobile_no);
    }
  }, [mobileList]);

  const controls = {
    fname: register("fname"),
    lname: register("lname"),
    email: register("email"),
    password: register("password", {
      required: { value: true, message: format("errors.required") },
    }),
    password_confirmation: register("password_confirmation", {
      required: { value: true, message: format("errors.required") },
      validate: {
        isMatch: (value) => {
          return value === values.password || format("errors.password")
        },
      },
    }),
    gender: register("gender"),
    nicno: register("nicno", {
      required: { value: true, message: format("errors.required") },
      minLength: { value: 10, message: format("errors.nicno") },
      maxLength: { value: 12, message: format("errors.nicno") },
    }),
    address_1: register("address_1"),
    address_2: register("address_2"),
    city: register("city"),
    postal_code: register("postal_code"),
    telephone: register("telephone", {
      required: { value: true, message: format("errors.required") },
      minLength: { value: 9, message: format("errors.telephone") },
      maxLength: { value: 9, message: format("errors.telephone") },
      pattern: {
        value: /^[0-9]*$/,
        message: format("errors.telephone"),
      },
    }),
  };

  const save = async (data) => {
    setLoading(true);
    const formData = transformInToFormObject({
      ...data,
      dialcode: "+94",
      user_mobiles: mobileList.map((m) => ({
        is_primary: m.is_primary ? '1' : '0',
        position: m.position,
        mobile_no: m.mobile_no,
      })),
    });
    try {
      const response = await axios.post("/api/user/register", formData);
      if (response.status === 200) {
        showAlert('success', 'User creation successful.')
        setTotal(total => total + 1)
        handleClose();
      } else {
        setLoading(false);
        showAlert('error', response.data.errors[0])
      }
    } catch (error) {
      console.error(error);
      showAlert('error', 'User creation failed.')
      setLoading(false);
    }
  };

  const submit = handleSubmit(save);

  return {
    primaryDisabled,
    controls,
    values,
    errors,
    loading,
    mobileList,
    setValue,
    submit,
    setMobileList,
  };
};

export default useAddUser;
