import {makeStyles} from "@material-ui/core/styles";

import {useGridModelContent} from "app/uielements/Dialog.styles";

const useStyles = makeStyles(theme => ({
  ...useGridModelContent(theme),
  root: {
    minWidth: 275,
    minHeight: 150,
    height: "85%"
  },
  newDrawWrapper: {
    height: 500
  }
}));

export default useStyles;
