import * as types from "./actionTypes";

const INIT_STATE = {
  initialURL: "/",
  error: "",
  loading: false,
  drawDetails: {
    id: null,
    name: "",
    winning_price: "10",
    draw_number: "10",
    lottery_id: "10",
    issue_start_date: new Date(),
    issue_end_date: new Date(),
    draw_date: new Date(),
    available_buffer: "10",
    selling_buffer: "10",
    return_buffer: "10",
    draw_type: "normal"
  },
  lotteriesForDraw: {
    id: 0,
    columns: [],
    data: []
  },
  lotteryFile: null
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case types.CREATE_DRAW: {
      return {...state, error: "", loading: true};
    }
    case types.CREATE_DRAW_SUCCESS: {
      const {draw} = action.payload.data;
      const drawDetails = {
        ...draw,
        winning_price: draw.winning_price.toString(),
        draw_number: draw.draw_number.toString(),
        lottery_id: draw.lottery_id.toString(),
        available_buffer: draw.available_buffer.toString(),
        selling_buffer: draw.selling_buffer.toString(),
        return_buffer: draw.return_buffer.toString()
      };
      return {...state, error: "", loading: false, drawDetails: drawDetails};
    }
    case types.CREATE_DRAW_FAIL: {
      return {...state, loading: false, error: action.payload};
    }
    case types.SET_DRAW_DETAILS: {
      const {key, value} = action.data;
      const drawDetails = state.drawDetails;
      drawDetails[key] = value;
      return {...state, drawDetails: drawDetails};
    }
    case types.SET_IMPORT_FILE: {
      return {...state, lotteryFile: action.file};
    }
    case types.IMPORT_LOTTERIES: {
      return {...state, loading: true};
    }
    case types.IMPORT_LOTTERIES_SUCCESS: {
      const {data} = action.payload;

      if (data) {
        const lotteries = {
          id: data.id,
          columns: data.csv_data_header,
          data: data.csv_data_body
        };

        return {
          ...state,
          lotteriesForDraw: lotteries,
          loading: false
        };
      }

      return {
        ...state,
        loading: false
      };
    }
    case types.IMPORT_LOTTERIES_FAIL: {
      return {...state, loading: false};
    }
    default:
      return state;
  }
};
