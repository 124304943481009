export const useFluidModelContent = (theme) => ({
  modelContent: {
    minHeight: "60vh",
    [theme.breakpoints.down("sm")]: {
      minHeight: "100vh",
    },
    [theme.breakpoints.up("md")]: {
      minHeight: "60vh",
    },
    [theme.breakpoints.up("lg")]: {
      minHeight: "60vh",
    },
  },
  modelContent1: {
    minHeight: "30vh",
    [theme.breakpoints.down("sm")]: {
      minHeight: "30vh",
    },
    [theme.breakpoints.up("md")]: {
      minHeight: "30vh",
    },
    [theme.breakpoints.up("lg")]: {
      minHeight: "30vh",
    },
  },
  formColumn: {
    display: "flex",
    flexDirection: "column",
    "& > div": {
      marginBottom: 15,
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0px",
    },
    [theme.breakpoints.up("md")]: {
      padding: "20px",
    },
    [theme.breakpoints.up("lg")]: {
      padding: "30px",
      minWidth: "200px",
    },
    "&.justify": {
      justifyContent: "space-around",
      alignItems: "center",
    },
  },
  formColumnEx: {
    display: "flex",
    flexDirection: "column",
    "& > div": {
      marginBottom: 5,
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0px",
    },
    [theme.breakpoints.up("md")]: {
      padding: "5px",
    },
    [theme.breakpoints.up("lg")]: {
      paddingLeft:"30px",
      minWidth: "200px",
    },
    "&.justify": {
      justifyContent: "space-around",
      alignItems: "center",
    },
  },
  fullWidth: {
    width: "100%",
    minWidth: '255px'
  },
  mt0:{
    marginTop: 0
  },
  cardRoot1:{
    height:"15vh",
    paddingTop:"1vh",
    paddingBottom:"1vh"
  }
});

export const useGridModelContent = (theme) => ({
  modelContent: {
    maxWidth: "80vw",
    height: "60vh",
    [theme.breakpoints.down("sm")]: {
      minWidth: "340px",
      height: "100vh",
    },
    [theme.breakpoints.up("md")]: {
      minWidth: "600px",
      height: "60vh",
    },
    [theme.breakpoints.up("lg")]: {
      minWidth: "75vw",
      height: "60vh",
    },
  },
  walletStyle:{
    fontFamily: 'Raleway',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: 400,
    color: 'red'
    
  }
});
