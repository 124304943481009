import {makeStyles} from "@material-ui/core/styles";

import {useFluidModelContent} from "app/uielements/Dialog.styles";

const useStyles = makeStyles(theme => ({
  ...useFluidModelContent(theme),
  root: {
    margin: theme.spacing(1),
    width: "100%"
  },
  newDrawWrapper: {
    height: 500
  },
  column: {},
  drawFirstCol: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    padding: "30px",
    "& > div": {
      marginBottom: 15
    }
  },
  drawSecondCol: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    padding: "30px",
    "& > div": {
      marginBottom: 15
    }
  },
  drawThirdCol: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    padding: "30px",
    "& > div": {
      marginBottom: 15
    }
  },
  tableContainer: {
    height: "93%",
    overflow: "auto"
  }
}));

export default useStyles;
