import {Box, Checkbox, fade, FormControlLabel, IconButton} from "@material-ui/core";
import {Search} from "@material-ui/icons";
import {Grid, Loader, TextField} from "app/uielements";
import useAxios from "app/hooks/useAxios";
import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";

import {makeStyles} from "@material-ui/core/styles";
import CmtCardHeader from "@coremat/CmtCard/CmtCardHeader";
import CmtCard from "@coremat/CmtCard";
import CmtCardContent from "@coremat/CmtCard/CmtCardContent";
import IntlMessages from "@jumbo/utils/IntlMessages";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
    error: {
        border: "1px solid red",
    },
    cell: {
        padding: theme.spacing(1),
        margin: theme.spacing(1),
        width: "10ch",
    },
    selectBtn: {
        margin: theme.spacing(5),
    },
    cardRoot: {
        position: "relative",
        "& .Cmt-card-content": {
            padding: 0,
            paddingBottom: 24,
        },
    },
    scrollbarRoot: {
        height: 280,
    },
    badgeRoot: {
        fontSize: 14,
        letterSpacing: 0.25,
        backgroundColor: fade(theme.palette.warning.main, 0.15),
        color: theme.palette.warning.main,
        padding: "2px 10px",
        borderRadius: 30,
    },
    btnRoot: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    icon: {
        borderRadius: 3,
        width: 16,
        height: 16,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        backgroundColor: '#137cbd',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 16,
            height: 16,
            backgroundImage:
                "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
                " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
                "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#106ba3',
        },
    },
}));

const Cells = ({id, search, checkAnywhere}) => {
    const [cells, setCells] = useState([]);
    const [loading, setLoading] = useState(false);
    const axios = useAxios();
    const classes = useStyles();
    const {register, handleSubmit} = useForm();
    const [isCheckAnywhere, setIsCheckAnywhere] = useState(false);

    function handleCheckBox(e) {
        setIsCheckAnywhere(e.target.checked);
        checkAnywhere(e.target.checked);
    }


    useEffect(() => {
        let active = true;
        setCells([]);
        setLoading(true);
        (async () => {
            try {
                const response = await axios.get("/api/lottery/number/format/" + id);
                setLoading(false);
                const format = response.data.format;

                if (!active) {
                    return;
                }

                if (format && format.original && format.original.errors === "1") {
                    setCells([]);
                } else {
                    setCells(
                        Object.keys(format).map((key) => ({
                            value: key,
                            label: format[key],
                        }))
                    );
                }
            } catch (error) {
                setLoading(false);
            }
        })();

        return () => {
            active = false;
        };
    }, [id]);

    return (
        <CmtCard className={classes.cardRoot}>
            <CmtCardHeader title={<IntlMessages id="user.purchase.format.title"/>}>
                <Box component="span" className={classes.badgeRoot}>
                    <IntlMessages id="user.purchase.format.badge"/>
                </Box>
            </CmtCardHeader>
            <CmtCardContent>
                {loading && <Loader/>}
                <Grid container>
                    <Grid container item xs={10}>
                        {cells.map((cell) => cell.label === "special" ? (
                                    <TextField
                                        className={classes.cell}
                                        variant="outlined"
                                        InputLabelProps={{shrink: true}}
                                        inputProps={{maxLength: 7}}
                                        {...register(cell.label)}
                                        name={cell.label}
                                        label={cell.label.toUpperCase()}
                                    />

                                ) :
                                (
                                    <TextField
                                        className={classes.cell}
                                        variant="outlined"
                                        InputLabelProps={{shrink: true}}
                                        inputProps={{maxLength: 7}}
                                        {...register(cell.label)}
                                        name={cell.label}
                                        label={cell.label.toUpperCase()}
                                    />

                                )
                        )}
                        <FormControlLabel
                            className={classes.selectBtn}
                            label="Pick from Any Position"
                            control={
                                <Checkbox
                                    name="check_anywhere"
                                    className={classes.btnRoot}
                                    disableRipple
                                    color="default"
                                    checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)}/>}
                                    icon={<span className={classes.icon}/>}
                                    onChange={handleCheckBox}
                                    checked={isCheckAnywhere}
                                />
                            }
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <IconButton onClick={handleSubmit(search)}>
                            <Search/>
                        </IconButton>
                    </Grid>
                </Grid>
            </CmtCardContent>
        </CmtCard>
    );
};

export default Cells;