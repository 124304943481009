import React, {useEffect} from "react";
import useWinners from "./Winners.state";
import {useIntl} from "react-intl";
import DataGrid from "app/uielements/Table/DataGrid";
import moment from "moment";
import {DialogModal} from "app/uielements";
import useStyles from "./Winners.styles";

const Winners = ({open, handleClose, id}) => {
  const {
    rows,
    total,
    loading,
    pageSize,
    handlePageChange,
    handlePageCountChange,
    setId,
    setRows
  } = useWinners();

  useEffect(
    () => {
      setId(id);
    },
    [ id ]
  );

  const styles = useStyles();

  const intl = useIntl();

  const format = id =>
    intl.formatMessage({
      id: `winners.list.${id}`
    });

  const getTicketNumber = params => {
    const val = field => params.row[field];

    return `${val("n1")} ${val("n2")} ${val("n3")} ${val("n4")}`;
  };

  const formatDate = params =>
    moment(params.value).format("yyyy-MM-DD h:mm:ss a");

  const columns = [
    {
      field: "serial_code",
      headerName: format("serial"), 
      flex: 1
    },
    {
      field: "tk_number",
      headerName: format("ticket"),
      flex: 1.2,
      // valueGetter: getTicketNumber,
      // sortComparator: (v1, v2, cellParams1, cellParams2) =>
      //   getTicketNumber(cellParams1).localeCompare(getTicketNumber(cellParams2))
    },
    {
      field: "telephone",
      headerName: format("phone"),
      flex: 1.2
    },
    {
      field: "winning_price",
      headerName: format("price"),
      flex: 1
    },
    {field: "nicno", headerName: format("nic"), flex: 1},
    {
      field: "paid_status",
      headerName: format("paid"),
      flex: 1
    }
  ];

  const handleDialogClose = () => {
    handleClose();
    setRows([]);
  };

  return (
    <DialogModal
      isOpen={open}
      handleClose={handleDialogClose}
      primaryDisabled={true}
      enableActions={false}
      title={format("title")}
      backdrop="static"
    >
      <div className={styles.modelContent}>
        <DataGrid
          rows={rows}
          columns={columns}
          pagination
          pageSize={pageSize}
          rowsPerPageOptions={[ 10, 20, 50, 100 ]}
          rowCount={total}
          paginationMode="server"
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageCountChange}
          loading={loading}
        />
      </div>
    </DialogModal>
  );
};

export default Winners;
