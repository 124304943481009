import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import useStyles from "./Draws.styles";
import DrawList from "./DrawList";
import LotteryStatus from "./LotteryStatus";
import { Box, Button } from "@material-ui/core";
import IntlMessages from "@jumbo/utils/IntlMessages";
import WithPermission from "app/uielements/WithPermission";
import useAxios from "app/hooks/useAxios";
import CreateDraw from "./CreateDraw";
import PageContainer from "@jumbo/components/PageComponents/layouts/PageContainer";

const Draws = (props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [lotteries, setLotteries] = useState([]);
  const [total, setTotal] = useState(0);
  const axios = useAxios();
  const buttonActiveStatus = false;
  

  useEffect(() => {
    let active = true;

    (async () => {
      const response = await axios.post("/api/lottery", {
        page_count: 100,
      });

      const newRows = response.data.data;
console.log('draw',response.data.data)
      if (!active) {
        return;
      }

      setLotteries(
        newRows.map((r) => ({
          value: r.id,
          label: r.name,
          block_size: r.return_block_size,
          file_prefix: r.file_prefix,
          matching_column: r.matching_column,
          pivot_index: r.pivot_index,
          chunk_length: r.chunk_length,
        }))
      );
    })();

    return () => {
      active = false;
    };
  }, []);


  const handleClickOpen = (event) => {
    event.preventDefault();    
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const breadcrumbs = [
    { label: "Home", link: "/" },
    { label: "Draws", link: "/draws", isActive: true },
  ];

  return (    
    <PageContainer
      heading={<IntlMessages id="draws.title" />}
      breadcrumbs={breadcrumbs}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid
            container
            direction="row"
            justify="space-around"
            alignItems="flex-start"
            spacing={1}
          >
            <LotteryStatus />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {open && (
            <CreateDraw
              open={open}
              handleClose={handleClose}
              lotteries={lotteries}
              setTotal={setTotal}
            />
          )}
          <WithPermission permissions={["draw.create"]}>
            <Box mb={1}>
              <Button
                onClick={handleClickOpen}
                variant="contained"
                color="primary"                
              >
                <IntlMessages id="draws.list.create" />
              </Button>
            </Box>
          </WithPermission>
        </Grid>
        <DrawList lotteries={lotteries} total={total} setTotal={setTotal} />
      </Grid>
    </PageContainer>
  );
};

export default Draws;
