import React from "react";
import { useIntl } from "react-intl";
import { Loader } from "app/uielements";
import useStyles from "./AddTickets.styles";
import { DropzoneArea } from "material-ui-dropzone";
import { Typography } from "@material-ui/core";

const ImportTickets = ({ handleChange, loading }) => {
  const styles = useStyles();

  const intl = useIntl();
  const format = (id) =>
    intl.formatMessage({
      id: `tickets.add.${id}`,
    });

  return (
    <React.Fragment>
      {loading && <Loader />}
      <Typography variant="h3" gutterBottom>
        {format("heading")}
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        {format("subtitle")}
      </Typography>
      <DropzoneArea
        acceptedFiles={[
          ".csv",
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          "application/vnd.ms-excel",
        ]}
        showPreviews={true}
        showPreviewsInDropzone={false}
        useChipsForPreview
        previewGridProps={{
          container: { spacing: 1, direction: "row" },
        }}
        previewChipProps={{ classes: { root: styles.previewChip } }}
        previewText={format("preview")}
        onChange={handleChange}
        filesLimit={1}
      />
    </React.Fragment>
  );
};

export default ImportTickets;
