import React, {useState} from "react";
import TextField from "@material-ui/core/TextField";
import IntlMessages from "../../../utils/IntlMessages";
import {useDispatch} from "react-redux";
import Button from "@material-ui/core/Button";
import {Box, fade} from "@material-ui/core";
import {AuhMethods} from "../../../../services/auth";
import ContentLoader from "../../ContentLoader";
import makeStyles from "@material-ui/core/styles/makeStyles";
import CmtImage from "../../../../@coremat/CmtImage";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {CurrentAuthMethod} from "../../../constants/AppConstants";
import {NavLink} from "react-router-dom";
import AuthWrapper from "./AuthWrapper";

const useStyles = makeStyles(theme => ({
  authThumb: {
    backgroundColor: fade(theme.palette.primary.main, 0.12),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 20,
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "50%",
      order: 2
    }
  },
  authContent: {
    padding: 30,
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: props => (props.variant === "default" ? "50%" : "100%"),
      order: 1
    },
    [theme.breakpoints.up("xl")]: {
      padding: 50
    }
  },
  titleRoot: {
    marginBottom: 14,
    color: theme.palette.text.primary
  },
  textFieldRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: fade(theme.palette.common.dark, 0.12)
    }
  },
  formcontrolLabelRoot: {
    "& .MuiFormControlLabel-label": {
      [theme.breakpoints.down("xs")]: {
        fontSize: 12
      }
    }
  },
  productLogo: {
    width: "20%"
  }
}));
//variant = 'default', 'standard'
const SignIn = ({
  method = CurrentAuthMethod,
  variant = "default",
  wrapperVariant = "default"
}) => {
  const [ mobile, setMobile ] = useState();
  const [ password, setPassword ] = useState();
  const dispatch = useDispatch();
  const classes = useStyles({variant});

  const onSubmit = () => {
    dispatch(AuhMethods[method].onLogin({mobile, password}));
  };

  return (
    <AuthWrapper variant={wrapperVariant}>
      {variant === "default" ? (
        <Box className={classes.authThumb}>
          <CmtImage src={"/images/auth/sign-up-img.png"} />
        </Box>
      ) : null}
      <Box className={classes.authContent}>
        <Box mb={7}>
          <CmtImage className={classes.productLogo} src={"/images/logo.png"} />
        </Box>
        <Typography component="div" variant="h1" className={classes.titleRoot}>
          Login
        </Typography>
        <form>
          <Box mb={2}>
            <TextField
              fullWidth
              label={<IntlMessages id="appModule.mobile" />}
              onChange={event => setMobile(event.target.value)}
              defaultValue={mobile}
              InputProps={{inputProps: {maxLength: 9}}}
              margin="normal"
              placeholder="712345678"
              variant="outlined"
              className={classes.textFieldRoot}
            />
          </Box>

          <Box mb={2}>
            <TextField
              type="password"
              label={<IntlMessages id="appModule.password" />}
              fullWidth
              onChange={event => setPassword(event.target.value)}
              defaultValue={password}
              margin="normal"
              variant="outlined"
              className={classes.textFieldRoot}
            />
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            mb={5}
          />

          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            mb={5}
          >
            <Button onClick={onSubmit} variant="contained" color="primary">
              <IntlMessages id="appModule.signIn" />
            </Button>
          </Box>
        </form>

        {dispatch(AuhMethods[method].getSocialMediaIcons())}

        <ContentLoader />
      </Box>
    </AuthWrapper>
  );
};

export default SignIn;
