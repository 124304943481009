import {Box, fade, FormControl, IconButton, InputLabel, MenuItem, OutlinedInput, Select} from "@material-ui/core";
import {Search} from "@material-ui/icons";
import {Grid, Loader, TextField} from "app/uielements";
import useAxios from "app/hooks/useAxios";
import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";

import {makeStyles} from "@material-ui/core/styles";
import CmtCardHeader from "@coremat/CmtCard/CmtCardHeader";
import CmtCard from "@coremat/CmtCard";
import CmtCardContent from "@coremat/CmtCard/CmtCardContent";
import IntlMessages from "@jumbo/utils/IntlMessages";
import AriesImage from '../../../assets/images/zodiac-signs/ARIES.png';
import TaurusImage from '../../../assets/images/zodiac-signs/TAURUS.png';
import GeminiImage from '../../../assets/images/zodiac-signs/GEMINI.png';
import CancerImage from '../../../assets/images/zodiac-signs/CANCER.png';
import LeoImage from '../../../assets/images/zodiac-signs/LEO.png';
import VirgoImage from '../../../assets/images/zodiac-signs/VIRGO.png';
import LibraImage from '../../../assets/images/zodiac-signs/LIBRA.png';
import ScorpioImage from '../../../assets/images/zodiac-signs/SCORPIO.png';
import SagittariusImage from '../../../assets/images/zodiac-signs/SAGITTARIUS.png';
import CapricornImage from '../../../assets/images/zodiac-signs/CAPRICORN.png';
import AquariusImage from '../../../assets/images/zodiac-signs/AQUARIUS.png';
import PiscesImage from '../../../assets/images/zodiac-signs/PISCES.png';

const options = [
    {value: 'ARIES', label: 'ARI/මේෂ/மேஷம்', image: AriesImage},
    {value: 'TAURUS', label: 'TAU/වෘෂභ/ரிஷபம்', image: TaurusImage},
    {value: 'GEMINI', label: 'GEM/මිථුන/மிதுனம்', image: GeminiImage},
    {value: 'CANCER', label: 'CAN/කටක/கடகம்', image: CancerImage},
    {value: 'LEO', label: 'LEO/සිංහ/சிம்மம்', image: LeoImage},
    {value: 'VIRGO', label: 'VIR/කන්‍යා/கன்னி', image: VirgoImage},
    {value: 'LIBRA', label: 'LIB/තුලා/துலாம்', image: LibraImage},
    {value: 'SCORPIO', label: 'SCO/වෘශ්‍චික/விருச்சிகம்', image: ScorpioImage},
    {value: 'SAGITTARIUS', label: 'SAG/ධනු/தனுசு', image: SagittariusImage},
    {value: 'CAPRICORN', label: 'CAP/මකර/மகரம்', image: CapricornImage},
    {value: 'AQUARIUS', label: 'AQU/කුම්‍භ/கும்பம்', image: AquariusImage},
    {value: 'PISCES', label: 'PIS/මීන/மீனம்', image: PiscesImage},
];


const useStyles = makeStyles((theme) => ({
    error: {
        border: "1px solid red",
    },
    cell: {
        padding: theme.spacing(1),
        margin: theme.spacing(1),
        width: "10ch",
    },
    cardRoot: {
        position: "relative",
        "& .Cmt-card-content": {
            padding: 0,
            paddingBottom: 24,
        },
    },
    scrollbarRoot: {
        height: 280,
    },
    badgeRoot: {
        fontSize: 14,
        letterSpacing: 0.25,
        backgroundColor: fade(theme.palette.warning.main, 0.15),
        color: theme.palette.warning.main,
        padding: "2px 10px",
        borderRadius: 30,
    },
    zodiacSign: {
        width: 30
    }
}));

const Cells = ({id, search}) => {
    const [cells, setCells] = useState([]);
    const [loading, setLoading] = useState(false);
    const axios = useAxios();
    const classes = useStyles();
    const {register, handleSubmit, reset} = useForm();

    const handleReset = () => {
        reset();
    };

    useEffect(() => {
        let active = true;
        setCells([]);
        setLoading(true);
        (async () => {
            try {
                const response = await axios.get("/api/agent/lottery/number/format/" + id);
                setLoading(false);
                const format = response.data.format;
                handleReset()

                if (!active) {
                    return;
                }

                if (format && format.original && format.original.errors === "1") {
                    setCells([]);
                } else {
                    setCells(
                        Object.keys(format).map((key) => ({
                            value: key,
                            label: format[key],
                        }))
                    );
                }
            } catch (error) {
                setLoading(false);
            }
        })();

        return () => {
            active = false;
        };
    }, [id]);

    return (
        <CmtCard className={classes.cardRoot}>
            <CmtCardHeader title={<IntlMessages id="user.purchase.format.title"/>}>
                <Box component="span" className={classes.badgeRoot}>
                    <IntlMessages id="user.purchase.format.badge"/>
                </Box>
            </CmtCardHeader>
            <CmtCardContent>
                {loading && <Loader/>}
                <Grid container>
                    <Grid container item xs={10}>
                        {cells.map((cell) => cell.label == "lagna" || cell.label == "lagna2" ? (
                                    <FormControl>
                                        <InputLabel id="demo-simple-select-outlined-label"
                                                    style={{marginLeft: "20px"}}>LAGNA</InputLabel>
                                        <Select
                                            InputProps={{inputProps: {min: 1}}}
                                            id={cell.label}
                                            type="text"
                                            className={classes.cell}
                                            variant="outlined"
                                            InputLabelProps={{shrink: true}}
                                            inputProps={{maxLength: 3}}
                                            {...register(cell.label)}
                                            name={cell.label}
                                            label={cell.label.toUpperCase()}
                                        >
                                            {options.map(option => (
                                                <MenuItem value={option.value}><img src={option.image}
                                                                                    alt={option.label}
                                                                                    className={classes.zodiacSign}/> {option.label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                ) :
                                (
                                    <TextField
                                        className={classes.cell}
                                        variant="outlined"
                                        InputLabelProps={{shrink: true}}
                                        inputProps={{maxLength: 3}}
                                        {...register(cell.label)}
                                        name={cell.label}
                                        label={cell.label.toUpperCase()}
                                    />

                                )
                        )}
                    </Grid>
                    <Grid item xs={2}>
                        <IconButton onClick={handleSubmit(search)}>
                            <Search/>
                        </IconButton>
                    </Grid>
                </Grid>
            </CmtCardContent>
        </CmtCard>
    )
        ;
};

export default Cells;
