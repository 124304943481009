import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  error: {
    border: '1px solid red'
  },
  formColumn: {
    padding: theme.spacing(2)
  },
  selection: {
    minHeight: 400,
  }
}));

export default useStyles;
