import {makeStyles} from "@material-ui/core/styles";
import {useGridModelContent} from "app/uielements/Dialog.styles";

const useStyles = makeStyles(theme => ({
  ...useGridModelContent(theme),
  list: {
    maxHeight: 40
  },
  iconButton: {
    display: 'flex',
    alignItems: 'center'
  }
}));

export default useStyles;
