import showAlert from "app/hooks/useAlerts";
import useAxios from "app/hooks/useAxios";
import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import throttle from "lodash/throttle";
import moment from "moment";

const useData = ({status, handleClose}) => {
    const defaultValues = {
        end_date: '',
        start_date: '',
        // payment_method:'',
        payment_type: '',
        searching_payment_type: "",
        mobile_no: '',
        lottery_id: '',
        draw_number: '',
    };

    const {
        register,
        setValue,
        getValues,
        reset,
        formState: {errors},
        handleSubmit,
    } = useForm({
        defaultValues,
    });

    const axios = useAxios();
    const [loading, setLoading] = useState(false);
    const [rows, setRows] = useState([]);
    const [params, setParams] = useState(null);
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    // const [selectionModel, setSelectionModel] = React.useState([]);
    const [filterModel, setFilterModel] = React.useState({items: []});
    const [expanded, setExpanded] = React.useState(false);


    const [sortModel, setSortModel] = React.useState([
        {field: "id", sort: "desc"},
    ]);

    const formatDate = (val) => val ? moment(val).format("yyyy-MM-DD") : '';

    const handleSortModelChange = (params) => {
        if (params.sortModel !== sortModel) {
            setSortModel(params.sortModel);
            setPage(0);
        }
    };

    const handleFilter = (params) => {
        setFilterModel(params.filterModel);
        setPage(0);
    };


    const handleFilterChange = React.useCallback(throttle(handleFilter, 500), []);

    const handlePageChange = (params) => {
        setPage(params.page);
    };

    const handlePageCountChange = (params) => {
        setPageSize(params.pageSize);
        setPage(0);
    };

    const searchResults = async (active = true) => {
        setLoading(true);
        const body = {
            page_count: pageSize,
            ...params,
            start_date: params ? formatDate(params.start_date) : '',
            end_date: params ? formatDate(params.end_date) : '',
            // payment_method:
            searching_payment_type:
            status,
            sortModel,
            filterModel: {
                items: filterModel.items
                    .filter((i) => i.value)
                    .map((i) => ({
                        ...i,
                        columnField: `${i.columnField}`,
                    })),
            },
        };

        try {
            const response = await axios.post(
                "/api/holdBalanceTransferRequests?page=" + (page + 1), body);

            console.log("Payment  value:", getValues().payment_type, "response:", response);

            const newRows = response.data.data;
            const totalValue = response.data.total;

            if (!active) {
                return;
            }

            setRows(newRows);
            setTotal(totalValue);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    useEffect(() => {
        let active = true;

        (async () => await searchResults(active))();

        return () => {
            active = false;
        };
    }, [page, pageSize, total, params, sortModel, filterModel]);

    const search = (data) => {
        console.log("Search data:", data);
        setParams({...data});
        setExpanded(!expanded);
    };
    // console.log("Payment  value:", getValues().payment_type);

    const handleRefresh = async (active) => {
        await searchResults(active);
    }

    const onReset = () => {
        reset(defaultValues);
        setParams(null);
    };

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const onSearch = handleSubmit(search);

    // const resend = async (id, sms) => {
    //   setLoading(true);
    //   try {
    //     const response = await axios.post('/api/sms/sent', {
    //       sms_ids: [id]
    //     });
    //     setLoading(false);
    //     if(response.status === 200){
    //       showAlert('success', `SMS sent successfully to ${sms.mobile_no}`);
    //     }
    //   } catch (error) {
    //     setLoading(false);
    //   }
    // }

    return {
        register,
        setValue,
        getValues,
        errors,
        onSearch,
        onReset,
        loading,
        rows,
        total,
        pageSize,
        handlePageChange,
        handlePageCountChange,
        handleSortModelChange,
        sortModel,
        handleFilterChange, handleClose,
        handleSubmit,
        handleRefresh,
        expanded,
        handleExpandClick,
        // selectionModel,
        // handleSelection
        // resend
    };
};

export default useData;
