import {DialogModal, Loader, Select} from "app/uielements";
import React, {useEffect, useState} from "react";
import {useIntl} from "react-intl";

import QuickBuy from "./QuickBuy";
import Box from '@material-ui/core/Box';
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import Grid from "@material-ui/core/Grid";
import useStyles from "./Styles";
import useData from "./State";
import ShoppingCart from "./ShoppingCart";
import Cells from "./Cells";
import SelectTickets from "./SelectTickets";
import Balance from "./Balance";
import Draws from "./Draws";

const AddUser = ({id, open, handleClose, lotteryList, tabId}) => {
    const classes = useStyles();

    const [value, setValues] = useState("1");
    const [isCheckAnywhere, setIsCheckAnywhere] = React.useState(false);

    const checkAnywhere = (isCheckAnywhere) => {
        setIsCheckAnywhere(isCheckAnywhere);
    };

    const handleChange = (event, newValue) => {
        (setValues(newValue))

    };

    const {
        primaryDisabled,
        loading,
        lotteryId,
        setLotteryId,
        searchFields,
        setSearchFields,
        setSelected,
        selected,
        winning,
        wallet,
        genie,
        setId,
        getValues,
        setValue,
        submit,
        handleQuickDelete,
        handleDelete,
        draw,
        premium,
        enableOno,
        setDraw,
        mobileList,
        setTabId
    } = useData({
        handleClose,
    });

    useEffect(() => {
        setId(id);
        setTabId(tabId);
    }, [id]);


    const intl = useIntl();
    const format = (id) =>
        intl.formatMessage({
            id: `user.purchase.${id}`,
        });

    const activeLotteries = lotteryList.filter(lottery => lottery.visibility_crm === 1);

    const handleSearch = (data) => {
        setSearchFields(
            Object.keys(data).map((key) => ({key, value: data[key] || ""}))
        );
    };

    const handleSelectLottery = (evt) => {
        setSearchFields([]);
        setLotteryId((id) => evt.target.value);
    };

    return (
        <DialogModal
            isOpen={open}
            handleClose={handleClose}
            handlePrimaryButton={submit}
            handleSecondaryButton={handleClose}
            primaryButtonLabel={format("submit")}
            secondaryButtonLabel={format("cancel")}
            primaryDisabled={loading}
            title={format("title")}
        >
            <Grid container item className={classes.modelContent} xs={12}>
                <Grid item xs={12} className={classes.formColumn}>
                    <Select
                        required
                        className={classes.fullWidth}
                        value={lotteryId}
                        onChange={handleSelectLottery}
                        id="lottery_id"
                        name="lottery_id"
                        variant="outlined"
                        label={format("lottery_id")}
                        // options={lotteryList}

                        // options={lotteryList.map(lottery => ({
                        //   value: lottery.value,
                        //   label: <span style={{ color: lottery.created_by === 75664 ? <div className="dot-green"></div> :  <div className="dot-red"></div> }}>{lottery.label}</span>

                        // }))}
                        options={activeLotteries.map(lottery => ({
                            value: lottery.value, label: (<span>
                      <span style={{
                          display: 'inline-block',
                          width: '8px',
                          height: '8px',
                          border: '2px solid green',
                          borderRadius: '50%',
                          marginRight: '5px'
                      }}></span>
                      <span style={{
                          display: 'inline-block',
                          width: '8px',
                          height: '8px',
                          border: '2px solid white',
                          borderRadius: '50%',
                          marginRight: '5px'
                      }}></span>
                            <span style={{color: 'black'}}>
                    {lottery.label}
                  </span>
                </span>)

                        }))}

                    />
                </Grid>

                {lotteryId !== 0 && (
                    <Grid item xs={12} className={classes.formColumn}>
                        <Draws draw={draw} setDraw={setDraw} lottery_id={lotteryId}/>
                    </Grid>
                )}

                {lotteryId !== 0 && draw !== 0 && (
                    <Grid item xs={12}>
                        <Box>
                            <TabContext value={value}>
                                <Box sx={{borderBottom: 1}}>
                                    <TabList onChange={handleChange} aria-label="lab API tabs example">
                                        <Tab label="quick buy" value="1"/>
                                        <Tab label="search and buy" value="2"/>

                                    </TabList>
                                </Box>
                                <TabPanel value="1">

                                    <QuickBuy selectedTickets={selected}
                                              lotteryList={lotteryList}
                                              handleDelete={handleDelete}
                                              handleQuickDelete={handleQuickDelete}
                                              tabId={tabId}
                                              selected={selected}
                                              winning={winning}
                                              wallet={wallet}
                                              genie={genie}
                                              getValues={getValues}
                                              setValue={setValue}
                                              mobileList={mobileList}
                                              enableOno={enableOno}
                                              premium={premium}
                                              lotteryId={lotteryId}
                                              draw={draw}
                                              setSelected={setSelected}
                                              userId={id}
                                    />
                                </TabPanel>
                                <TabPanel value="2">{lotteryId !== 0 && draw !== 0 && (
                                    <Grid item xs={12} className={classes.formColumn}>
                                        <Cells id={lotteryId} search={handleSearch} checkAnywhere={checkAnywhere}/>
                                    </Grid>)}
                                    {searchFields.length > 0 && (<Grid item xs={12} className={classes.formColumn}>
                                        <SelectTickets
                                            isCheckAnywhere={isCheckAnywhere}
                                            id={lotteryId}
                                            searchFields={searchFields}
                                            setSelected={setSelected}
                                            selected={selected}
                                            draw={draw}
                                            userId={id}
                                            tabId={tabId}
                                        />
                                    </Grid>)}

                                    {selected.length > 0 && (<Grid item xs={12} className={classes.formColumn}>
                                        <ShoppingCart
                                            selectedTickets={selected}
                                            lotteryList={lotteryList}
                                            handleDelete={handleDelete}
                                            handleQuickDelete={handleQuickDelete}
                                        />
                                    </Grid>)}


                                    {selected.length > 0 && (<Grid item xs={12} className={classes.formColumn}>
                                        <Balance
                                            selected={selected}
                                            winning={winning}
                                            wallet={wallet}
                                            genie={genie}
                                            getValues={getValues}
                                            setValue={setValue}
                                            mobileList={mobileList}
                                            enableOno={enableOno}
                                            premium={premium}
                                        />
                                    </Grid>)}
                                </TabPanel>

                            </TabContext>
                        </Box>

                    </Grid>

                )}
            </Grid>
            {loading && <Loader/>}
        </DialogModal>
    );
};

export default AddUser;
