import React from "react";
import { Grid, Loader, Select, TextField } from "app/uielements";
import { useIntl } from "react-intl";
import useStyles from "./Edit.styles";

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from "@material-ui/pickers";

const CreateStep = ({
  loading,
  errors,
  values,
  setValue,
  formValues,
  lottery
}) => {
  const classes = useStyles();
  const intl = useIntl();
  const format = (id) =>
    intl.formatMessage({
      id: `lottery.add.${id}`,
    });

  

  const dayTypes = [
    { value: 1, label: format("monday") },
    { value: 2, label: format("tuesday") },
    { value: 3, label: format("wednesday") },
    { value: 4, label: format("thursday") },
    { value: 5, label: format("friday") },
    { value: 6, label: format("saturday") },
    { value: 7, label: format("sunday") },
  ];

  const durationTypes = [
    { value: 1, label: format("daily") },
    { value: 2, label: format("weekly") },
    { value: 3, label: format("fortnight") },
    { value: 4, label: format("monthly") },
  ];

  const statusTypes = [
    { value: 1, label: format("enabled") },
    { value: 2, label: format("disabled") },
  ];

  const lotteryTypes = [
    { value: 1, label: format("normal") },
    { value: 2, label: format("special") },
  ];

  const agents = [{ value: 1, label: "Default" }];

  return (
    <form>
      <Grid
        container
        direction="row"
        justify="space-around"
        alignItems="flex-start"
        className={classes.newDrawWrapper}
      >
        {loading && <Loader />}
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid container item xs={12} className={classes.column}>
            <Grid item xs={12} md={4} className={classes.drawFirstCol}>
              <TextField
                required
                value={formValues.name}
                onChange={(e) =>
                  setValue("name", e.target.value, { shouldValidate: true })
                }
                InputProps={{ inputProps: { min: 0 } }}
                id="name"
                name="name"
                label={format("name")}
                error={errors.name}
                helperText={errors.name ? errors.name.message : ""}
                defaultValue={lottery.name}
              />

              <Select
                required
                value={formValues.status}
                onChange={(e) =>
                  setValue("status", e.target.value, { shouldValidate: true })
                }
                InputProps={{ inputProps: { min: 0 } }}
                id="status"
                name="status"
                label={format("status")}
                error={errors.status}
                options={statusTypes}
                helperText={errors.status ? format("errors.required") : ""}
                defaultValue={lottery.status}
              />

              <KeyboardTimePicker
                margin="normal"
                id="start_time"
                name="start_time"
                format="HH:mm"
                className={classes.root}
                label={format("start_time")}
                inputRef={values.start_time}
                value={formValues.start_time}
                onChange={(val) =>
                  setValue("start_time", val, { shouldValidate: true })
                }
                KeyboardButtonProps={{
                  "aria-label": "change time",
                }}
                error={errors.start_time}
                helperText={errors.start_time ? format("errors.required") : ""}
                defaultValue={lottery.start_time}
              />

              <TextField
                required
                value={formValues.min_return_count}
                onChange={(e) =>
                  setValue("min_return_count", e.target.value, { shouldValidate: true })
                }
                InputProps={{ inputProps: { min: 0 } }}
                id="min_return_count"
                name="min_return_count"
                type="number"
                label={format("min_return_count")}
                error={errors.min_return_count}
                helperText={
                  errors.min_return_count ? format("errors.required") : ""
                }
              />

              <TextField
                required
                value={formValues.commission}
                onChange={(e) =>
                  setValue("commission", e.target.value, { shouldValidate: true })
                }
                InputProps={{ inputProps: { min: 0 } }}
                id="commission"
                type="number"
                name="commission"
                label={format("commission")}
                error={errors.commission}
                helperText={errors.commission ? format("errors.required") : ""}
                defaultValue={lottery.commission}
              />
            </Grid>
            <Grid item xs={12} md={4} className={classes.drawSecondCol}>
              <TextField
                required
                id="code"
                name="code"
                label={format("code")}
                InputProps={{
                  inputProps: { min: 0, maxLength: 4, minLength: 2 },
                }}
                value={formValues.code}
                onChange={(e) =>
                  setValue("code", e.target.value, { shouldValidate: true })
                }
                error={errors.code}
                helperText={errors.code ? errors.code.message : ""}
                defaultValue={lottery.code}
              />

              <Select
                required
                value={formValues.duration_type}
                onChange={(e) =>
                  setValue("duration_type", e.target.value, { shouldValidate: true })
                }
                InputProps={{ inputProps: { min: 0 } }}
                id="duration_type"
                name="duration_type"
                label={format("duration_type")}
                error={errors.duration_type}
                options={durationTypes}
                helperText={
                  errors.duration_type ? format("errors.required") : ""
                }
                defaultValue={lottery.duration_type}
              />

              <KeyboardTimePicker
                margin="normal"
                id="close_time"
                name="close_time"
                format="HH:mm"
                className={classes.root}
                label={format("close_time")}
                inputRef={values.close_time}
                value={formValues.close_time}
                onChange={(val) =>
                  setValue("close_time", val, { shouldValidate: true })
                }
                KeyboardButtonProps={{
                  "aria-label": "change time",
                }}
                error={errors.close_time}
                helperText={errors.close_time ? format("errors.required") : ""}
                defaultValue={lottery.close_time}
              />

              <TextField
                required
                value={formValues.return_block_size}
                onChange={(e) =>
                  setValue("return_block_size", e.target.value, { shouldValidate: true })
                }
                InputProps={{ inputProps: { min: 0 } }}
                id="return_block_size"
                type="number"
                name="return_block_size"
                label={format("return_block_size")}
                error={errors.return_block_size}
                helperText={
                  errors.return_block_size ? format("errors.required") : ""
                }
                defaultValue={lottery.return_block_size}
              />
              <TextField
                required
                value={formValues.ussd_order}
                onChange={(e) =>
                  setValue("ussd_order", e.target.value, { shouldValidate: true })
                }
                id="ussd_order"
                name="ussd_order"
                label={format("ussd_order")}
                error={errors.ussd_order}
                helperText={errors.ussd_order ? format("errors.required") : ""}
                defaultValue={lottery.ussd_order}
              />
            </Grid>
            <Grid item xs={12} md={4} className={classes.drawThirdCol}>
              <Select
                required
                InputProps={{ inputProps: { min: 0 } }}
                value={formValues.lottery_type}
                onChange={(e) =>
                  setValue("lottery_type", e.target.value, {
                    shouldValidate: true,
                  })
                }
                id="lottery_type"
                name="lottery_type"
                label={format("lottery_type")}
                error={errors.lottery_type}
                options={lotteryTypes}
                helperText={
                  errors.lottery_type ? format("errors.required") : ""
                }
                defaultValue={lottery.lottery_type}
              />
              <Select
                required
                value={formValues.day_type}
                onChange={(e) =>
                  setValue("day_type", e.target.value, { shouldValidate: true })
                }
                InputProps={{ inputProps: { min: 0 } }}
                id="day_type"
                name="day_type"
                label={format("day_type")}
                error={errors.day_type}
                options={dayTypes}
                helperText={errors.day_type ? format("errors.required") : ""}
                defaultValue={lottery.day_type}
              />
              <KeyboardTimePicker
                margin="normal"
                id="return_time"
                name="return_time"
                format="HH:mm"
                className={classes.root}
                label={format("return_time")}
                inputRef={values.return_time}
                value={formValues.return_time}
                onChange={(val) =>
                  setValue("return_time", val, { shouldValidate: true })
                }
                KeyboardButtonProps={{
                  "aria-label": "change time",
                }}
                error={errors.return_time}
                helperText={errors.return_time ? format("errors.required") : ""}
                defaultValue={lottery.return_time}
              />

              <TextField
                required
                value={formValues.ticket_price}
                onChange={(e) =>
                  setValue("ticket_price", e.target.value, { shouldValidate: true })
                }
                InputProps={{ inputProps: { min: 0 } }}
                id="ticket_price"
                type="number"
                name="ticket_price"
                label={format("ticket_price")}
                error={errors.ticket_price}
                helperText={
                  errors.ticket_price ? format("errors.required") : ""
                }
                defaultValue={lottery.ticket_price}
              />
              <Select
                required
                value={formValues.agent_id}
                onChange={(e) =>
                  setValue("agent_id", e.target.value, { shouldValidate: true })
                }
                InputProps={{ inputProps: { min: 0 } }}
                id="agent_id"
                name="agent_id"
                label={format("agent_id")}
                error={errors.agent_id}
                options={agents}
                helperText={errors.agent_id ? format("errors.required") : ""}
                defaultValue={lottery.agent_id}
              />
            </Grid>
            <Grid item xs={12} className={classes.drawThirdCol}>
            <TextField
                required
                value={formValues.winning_number_format}
                onChange={(e) =>
                  setValue("winning_number_format", e.target.value, { shouldValidate: true })
                }
                id="winning_number_format"
                name="winning_number_format"
                label={format("winning_number_format")}
                error={errors.winning_number_format}
                multiLine
                rows={1}
                className={classes.fullWidth}
                helperText={
                  errors.winning_number_format ? format("errors.required") : ""
                }
                defaultValue={lottery.winning_number_format}
              />
            </Grid>
          </Grid>
        </MuiPickersUtilsProvider>
      </Grid>
    </form>
  );
};

export default CreateStep;
