import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
  paper: {
    minWidth: 500,
    maxWidth: "80vw"
  },
  closeButton: {
    position: "absolute",
    right: 2,
    top: 2,
    color: "#cccccc"
  }
});

export default useStyles;
