import showAlert from "app/hooks/useAlerts";
import isEmpty from "lodash/isEmpty";
import * as types from "./actionTypes";

const INIT_STATE = {
  authUser: null,
  loadUser: false,
  send_forget_password_email: false,
  verifiedUser: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case types.UPDATE_AUTH_USER: {
      return {
        ...state,
        authUser: action.payload,
        loadUser: true,
        verifiedUser: !isEmpty(action.payload?.email_verified_at),
      };
    }
    case types.UPDATE_LOAD_USER: {
      return {
        ...state,
        loadUser: action.payload,
      };
    }
    case types.SEND_FORGET_PASSWORD_EMAIL: {
      return {
        ...state,
        send_forget_password_email: action.payload,
      };
    }
    case types.LOGIN_USER: {
      return {
        ...state,
      };
    }
    case types.LOGIN_USER_SUCCESS: {
      const { data } = action.payload;
      if (
        data.role !== "admin" &&
        process.env.REACT_APP_ONLY_ADMIN === "true"
      ) {
        window.location.href = "/signin";
        throw Error('only admin can login')
      }
      return {
        ...state,
        authUser: data.user,
        verifiedUser: true,
      };
    }
    case types.LOGIN_USER_FAIL: {
      showAlert('error','Incorrect user name or password. Please try again.')
      return {
        ...state,
      };
    }
    case types.REGISTER_USER: {
      return {
        ...state,
      };
    }
    case types.REGISTER_USER_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        authUser: data.user,
        verifiedUser: !isEmpty(data.user.email_verified_at),
      };
    }
    case types.REGISTER_USER_FAIL: {
      return {
        ...state,
      };
    }
    case types.FETCH_USER: {
      return {
        ...state,
      };
    }
    case types.FETCH_USER_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        authUser: data,
        verifiedUser: !isEmpty(data.email_verified_at),
      };
    }
    case types.FETCH_USER_FAIL: {
      return {
        ...state,
      };
    }
    case types.GET_USER: {
      return {
        ...state,
      };
    }
    default:
      return state;
  }
};
