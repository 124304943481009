import React from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import makeStyles from "@material-ui/core/styles/makeStyles";
import IntlMessages from "@jumbo/utils/IntlMessages";
import { Box, Grid, IconButton, Typography } from "@material-ui/core";
import { RefreshSharp } from "@material-ui/icons";
import { GridContainer } from "app/uielements";

const useStyles = makeStyles((theme) => ({
  tableCellRoot: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 16,
    paddingBottom: 12,
    fontSize: 12,
    letterSpacing: 0.4,
    color: theme.palette.common.dark,
    borderBottom: "0 none",
    "&:first-child": {
      paddingLeft: 24,
    },
    "&:last-child": {
      textAlign: "right",
      paddingRight: 24,
    },
  },
}));

const TableHeading = ({ title, onRefresh }) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <GridContainer>
        <Grid item xs={10}>
          <Box flex="1" p={2}>
            <Typography>{title}</Typography>
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Box display="flex" alignItems="center" justifyItems="flex-end">
            {onRefresh !== null && (
              <IconButton onClick={onRefresh}>
                <RefreshSharp />
              </IconButton>
            )}
          </Box>
        </Grid>
      </GridContainer>
      <TableRow>
        <TableCell className={classes.tableCellRoot}>
          <IntlMessages id="lottery.list.code" />
        </TableCell>
        <TableCell className={classes.tableCellRoot}>
          <IntlMessages id="lottery.list.name" />
        </TableCell>
        <TableCell className={classes.tableCellRoot}>
          <IntlMessages id="lottery.list.status" />
        </TableCell>
        <TableCell className={classes.tableCellRoot}>
          <IntlMessages id="lottery.list.duration_type" />
        </TableCell>
        <TableCell className={classes.tableCellRoot}>
          <IntlMessages id="lottery.list.ticket_price" />
        </TableCell>
        <TableCell className={classes.tableCellRoot}>
          <IntlMessages id="lottery.list.return_block_size" />
        </TableCell>
        <TableCell className={classes.tableCellRoot}>
          <IntlMessages id="lottery.list.min_return_count" />
        </TableCell>
        <TableCell className={classes.tableCellRoot}>
          <IntlMessages id="lottery.list.actions" />
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default TableHeading;
