import React from "react";
import {Box, fade} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(theme => ({
  camCellItem: {
    transition: "all .2s",
    borderTop: `1px solid ${fade(theme.palette.common.dark, 0.04)}`,
    "&:last-child": {
      borderBottom: `1px solid ${fade(theme.palette.common.dark, 0.04)}`
    },
    "&:hover": {
      backgroundColor: fade(theme.palette.primary.main, 0.1),
      transform: "translateY(-4px)",
      boxShadow: `0 3px 10px 0 ${fade(theme.palette.common.dark, 0.2)}`
    }
  },
  IconSm: {
    fontSize: 16,
    marginLeft: 4
  }
}));

const ReturnCell = ({data}) => {
  const {label, value, icon} = data;
  const classes = useStyles();
  return (
    <Box
      className={classes.camCellItem}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      px={6}
      py={2}
    >
      <Box display="flex" alignItems="center" flexDirection="row">
        <Box ml={4}>
          <Box component="p">{label} </Box>
        </Box>
      </Box>
      <Box>
        <Box component="h3" mb={0}>
          {value}
        </Box>
      </Box>
    </Box>
  );
};

export default ReturnCell;
