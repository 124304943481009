import React from 'react';
import CmtCard from '@coremat/CmtCard';
import CmtCardHeader from '@coremat/CmtCard/CmtCardHeader';
import Box from '@material-ui/core/Box';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import AgentsSalesSummary from './AgentsSalesSummary';
import PerfectScrollbar from 'react-perfect-scrollbar';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Button from '@material-ui/core/Button';
import {GridSearchIcon} from '@material-ui/data-grid';
import CmtCardFooter from '@coremat/CmtCard/CmtCardFooter';
import {RefreshSharp} from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";

const useStyles = makeStyles(theme => ({
    cardRoot: {
        [theme.breakpoints.down('xs')]: {
            '& .Cmt-header-root': {
                flexDirection: 'column',
            },
            '& .Cmt-action-default-menu': {
                marginLeft: 0,
                marginTop: 10,
            },
        },
    },
    cardContentRoot: {
        padding: 0,
    },
    scrollbarRoot: {
        height: 275,
    },
}));

const DrawSummaryContainer = ({agentSalesSummary, agentsSalesWiseAgent, handleAgentRefresh}) => {
    const classes = useStyles();

    return (
        <CmtCard className={classes.cardRoot}>
            <CmtCardHeader
                className="pt-4"
                title="Agents' Sales Summary"
                titleProps={{
                    variant: 'h4',
                    component: 'div',
                }}>
                <Box clone>
                    <Button color="primary">
                        <GridSearchIcon/>
                        <Box component="span" ml={2}>
                            Search
                        </Box>
                    </Button>
                </Box>
                <Box clone>
                    <IconButton style={{padding: 0}}><RefreshSharp onClick={handleAgentRefresh}/></IconButton>
                </Box>
            </CmtCardHeader>
            <CmtCardContent className={classes.cardContentRoot}>
                <PerfectScrollbar className={classes.scrollbarRoot}>
                    <AgentsSalesSummary agentsSalesWiseAgent={agentsSalesWiseAgent}/>
                </PerfectScrollbar>
            </CmtCardContent>
            <CmtCardFooter>
                <Box m="auto">
                    <label color="primary"> Total Agents' Sales: {agentSalesSummary.total_agent_sales_count}</label>
                </Box>
                <Box m="auto">
                    <label color="primary"> Total Agents' Active
                        Sales: {agentSalesSummary.total_agent_active_sales_count} </label>
                </Box>
                <Box m="auto">
                    <label color="primary"> Total Agents' Passive
                        Sales: {agentSalesSummary.total_agent_passive_sales_count} </label>
                </Box>
            </CmtCardFooter>
        </CmtCard>

    );
};

export default DrawSummaryContainer;
