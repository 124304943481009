import React, { useEffect } from "react";
import useTickets from "./Tickets.state";
import { useIntl } from "react-intl";
import DataGrid from "app/uielements/Table/DataGrid";
import { DialogModal } from "app/uielements";
import useStyles from "./Tickets.styles";
import CustomGridPanel from "app/uielements/Table/CustomGridPanel";
import Download from './Download';

const TicketList = ({ open, handleClose, id, lotteries, user }) => {
  const {
    rows,
    total,
    loading,
    pageSize,
    handlePageChange,
    handlePageCountChange,
    setId,
    setRows,
    handleSortModelChange,
    sortModel,
    handleFilterChange,
    columns
  } = useTickets({lotteries });

  useEffect(() => {
    setId(id);
  }, [id]);

  const styles = useStyles();

  const intl = useIntl();

  const format = (id) =>
    intl.formatMessage({
      id: `tickets.list.${id}`,
    });  
 
  
  const handleDialogClose = () => {
    handleClose();
    setRows([]);
  };

  return (
    <DialogModal
      isOpen={open}
      handleClose={handleDialogClose}
      primaryDisabled={true}
      enableActions={false}
      title={intl.formatMessage({id: 'user.tickets.title'}, user)}
      backdrop="static"
      
    >
      <div className={styles.modelContent}>
        <Download id={id} />
        <DataGrid
          rows={rows}
          columns={columns}
          pagination
          pageSize={pageSize}
          rowsPerPageOptions={[10, 20, 50, 100]}
          rowCount={total}
          paginationMode="server"
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageCountChange}
          sortingMode="server"
          sortModel={sortModel}
          onSortModelChange={handleSortModelChange}
          filterMode="server"
          onFilterModelChange={handleFilterChange}
          loading={loading}
          components={{ Panel: CustomGridPanel }}
        />
      </div>
    </DialogModal>
  );
};

export default TicketList;
