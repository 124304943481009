import React from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import clsx from "clsx";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { fade } from "@material-ui/core";
import ContextMenu from "app/uielements/ContextMenu";
import { useIntl } from "react-intl";

const useStyles = makeStyles((theme) => ({
  tableRowRoot: {
    position: "relative",
    transition: "all .2s",
    borderTop: `solid 1px ${theme.palette.borderColor.main}`,
    "&:hover": {
      backgroundColor: fade(theme.palette.primary.main, 0.08),
      transform: "translateY(-4px)",
      boxShadow: `0 3px 10px 0 ${fade(theme.palette.common.dark, 0.2)}`,
      borderTopColor: "transparent",
      "& $tableCellRoot": {
        color: theme.palette.text.primary,
        "&:last-child": {
          color: theme.palette.error.main,
        },
        "&.success": {
          color: theme.palette.success.main,
        },
      },
    },
    "&:last-child": {
      borderBottom: `solid 1px ${theme.palette.borderColor.main}`,
    },
  },
  tableCellRoot: {
    padding: 16,
    fontSize: 14,
    letterSpacing: 0.25,
    color: theme.palette.text.secondary,
    borderBottom: "0 none",
    position: "relative",
    "&:first-child": {
      paddingLeft: 24,
    },
    "&:last-child": {
      textAlign: "right",
      color: theme.palette.error.main,
      paddingRight: 24,
    },
    "&.success": {
      color: theme.palette.success.main,
    },
  },
}));

const TableItem = ({ row, handleMenu }) => {
  const classes = useStyles();
  const intl = useIntl();

  const format = (id) =>
    intl.formatMessage({
      id: `lottery.list.${id}`,
    });
    const format2 = (id) =>
    intl.formatMessage({
      id: `lottery.add.${id}`,
    });
  const options = [
    format("options.edit"),
    format("options.fileformat"),
    format("options.messages"),
    format("options.delete")
    ,
    format("options.automationlotteries")
  ];

  const durationTypes = {
    1: format2("daily"),
    2: format2("weekly"),
    3: format2("fortnight"),
    4: format2("monthly"),
    "Daily": format2("daily"),
    "Weekly": format2("weekly"),
    "Fortnight": format2("fortnight"),
    "Monthly": format2("monthly"),
  };

  const statusTypes = {
    1: format2("enabled"),
    2: format2("disabled")
  };

  return (
    <TableRow className={classes.tableRowRoot}>
      <TableCell className={classes.tableCellRoot}>{row.code}</TableCell>
      <TableCell className={classes.tableCellRoot}>{row.name}</TableCell>
      <TableCell className={classes.tableCellRoot}>{statusTypes[row.status]}</TableCell>
      <TableCell className={classes.tableCellRoot}>
        {durationTypes[row.duration_type]}
      </TableCell>
      <TableCell className={clsx(classes.tableCellRoot, "success")}>
        {row.ticket_price}
      </TableCell>
      <TableCell className={classes.tableCellRoot}>
        {row.return_block_size}
      </TableCell>
      <TableCell className={classes.tableCellRoot}>
        {row.min_return_count}
      </TableCell>
      <TableCell className={classes.tableCellRoot}>
        <ContextMenu
          options={options}
          onClick={(evt) => handleMenu(evt, row.id, row)}
        />
      </TableCell>
    </TableRow>
  );
};

export default TableItem;
