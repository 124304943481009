import { useEffect, useState } from "react";
import useAxios from "./useAxios";

const useLotteryList = () => {
  const [lotteryList, setLotteries] = useState([]);
  const axios = useAxios();

  useEffect(() => {
    let active = true;

    (async () => {
      const response = await axios.post("/api/lottery", {
        page_count: 100,
      });
      const newRows = response.data.data;

      if (!active) {
        return;
      }

      setLotteries(
        newRows.map((r) => ({
          value: r.id,
          label: r.name,
          visibility_crm:r.visibility_crm
        }))
      );
    })();

    return () => {
      active = false;
    };
  }, []);

  return {
    lotteryList,
  };
};

export default useLotteryList;
