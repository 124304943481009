export const UPDATE_AUTH_USER = "UPDATE_AUTH_USER";
export const UPDATE_LOAD_USER = "UPDATE_LOAD_USER";
export const SEND_FORGET_PASSWORD_EMAIL = "SEND_FORGET_PASSWORD_EMAIL";

export const FETCH_USER = "FETCH_USER";
export const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS";
export const FETCH_USER_FAIL = "FETCH_USER_FAIL";

export const GET_USER = "GET_USER";
// export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
// export const GET_USER_FAIL = "GET_USER_FAIL";

export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_FAIL = "LOGIN_USER_FAIL";

export const LOGOUT_USER = "LOGOUT_USER";
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS";
export const LOGOUT_USER_FAIL = "LOGOUT_USER_FAIL";

export const REGISTER_USER = "REGISTER_USER";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const REGISTER_USER_FAIL = "REGISTER_USER_FAIL";

export const SEND_VERIFY_EMAIL = "SEND_VERIFY_EMAIL";
export const SEND_VERIFY_EMAIL_SUCCESS = "SEND_VERIFY_EMAIL_SUCCESS";
export const SEND_VERIFY_EMAIL_FAIL = "SEND_VERIFY_EMAIL_FAIL";
