import React from "react";
import useStyles from "./LotteryCard.styles";

import PropTypes from "prop-types";
import CmtCard from "@coremat/CmtCard";
import CmtCardContent from "@coremat/CmtCard/CmtCardContent";
import {Box, Grid} from "@material-ui/core";
import CmtCardFooter from "@coremat/CmtCard/CmtCardFooter";
import {useIntl} from "react-intl";
const LotteryCard = ({lottery}) => {
  const classes = useStyles();
  const intl = useIntl();
  return (
    <CmtCard>
      <CmtCardContent className={classes.center}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            {lottery.sold}
          </Grid>
          <Grid item xs={6}>
            {lottery.sold}
          </Grid>
        </Grid>
        <Grid alignItems="center" container spacing={1}>
          <Grid item xs={6}>
            {intl.formatMessage({id: "draws.status.sold"})}
          </Grid>
          <Grid item xs={6}>
            {intl.formatMessage({id: "draws.status.unsold"})}
          </Grid>
        </Grid>
      </CmtCardContent>
      <CmtCardFooter>
        <Box>{lottery.name}</Box>
      </CmtCardFooter>
    </CmtCard>
  );
};

LotteryCard.propTypes = {
  lottery: PropTypes.object.isRequired
};

export default LotteryCard;
