import CmtCard from "@coremat/CmtCard";
import CmtCardContent from "@coremat/CmtCard/CmtCardContent";
import CmtCardHeader from "@coremat/CmtCard/CmtCardHeader";
import IntlMessages from "@jumbo/utils/IntlMessages";
import {
    Box,
    FormControl,
    RadioGroup,
    Radio,
    FormControlLabel,
    FormLabel,
    Grid,
    FormHelperText,
    IconButton,
    Tooltip,
    Typography,
    Button, DialogTitle, InputLabel, MenuItem, DialogContent, Dialog
} from "@material-ui/core";
import Select from "app/uielements/Select/Select";
import useStyles from "./Styles";

import DataGrid from "app/uielements/Table/DataGrid";
import {Close, PlayArrow, RefreshSharp, Send} from "@material-ui/icons";
import React, {useState} from "react";
import {useIntl} from "react-intl";
import {TextField} from "app/uielements";
import axios from "axios";
import CustomGridPanel from "app/uielements/Table/CustomGridPanel";
import showAlert from "app/hooks/useAlerts";
import {useForm} from "react-hook-form";

const Results = ({
                     rows,
                     title,
                     className,
                     titleProps,
                     total,
                     pageSize,
                     handlePageChange,
                     handlePageCountChange,
                     // setId,
                     handleSortModelChange,
                     sortModel,
                     handleFilterChange,
                     handleClose,
                     // handleSubmit,
                     // handleSelection,
                     // selectionModel,
                     getValues,
                     errors,
                     setValue,
                     handleRefresh,
                     register,
                     loading,
                     setLoading,
                     resend,
                 }) => {
    const [selectionCheckModel, setSelectionCheckModel] = useState([]);
    /* useEffect(() => {
      setId(id);
    }, [id]); */
    const [selectedRadioValue, setSelectedRadioValue] = useState("");
    const [selectionModel, setSelectionModel] = React.useState([]);
    const [status, setStatus] = useState('');
    const [id, setId] = useState(0);
    const [clickedRow, setClickedRow] = useState(null);
    const [comment, setComment] = useState('');
    const [details, setDetails] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const [viewedRequests, setViewedRequests] = React.useState([]);

    const statusColor = (status) => {
        if (status === 1) {
            return 'rgb(3,156,3)';
        } else {
            return 'rgb(234,152,3)';
        }
    };

    const handleSelection = (newSelection) => {
        setSelectionModel(newSelection.selectionModel);
    };


    const handleChange = (event) => {
        setSelectedRadioValue(event.target.value);

    };

    const openModal = (event, data) => {
        event.stopPropagation();
        setDetails(data);
        setViewedRequests((prevIds) => [...prevIds, data.id]);
        setModalOpen(true);
    };


    const closeModal = () => {
        setDetails('');
        setModalOpen(false);
    };

    const defaultValues = {

        // payment_type:'',

    };
    const {
        lottery_id,
        handleSubmit,
    } = useForm({
        defaultValues,
    });

    // const handleSubmit = async () => {
    //   try {
    //     console.log('Selected Radio Value:', selectedRadioValue);

    //     const selectedRowData = rows
    //     // .filter((rows, index) => selectedRowData.indexOf(index) !== -1)
    //     .map(selectedRow => {
    //       return {
    //         serial_no: selectedRow.serial_no,
    //         user_mobile_no:selectedRow.user_mobile_no,
    //         amount:selectedRow.amount,
    //         payment_type:selectedRow.payment_type,
    //         status:selectedRow.status,
    //       };
    //     });
    //     console.log('Selected Row Data:', selectedRowData);
    //     const postData = {
    //      payment: selectedRadioValue,
    //     data: selectedRowData,
    //     };

    //     const response = await axios.post('/abcd', postData);

    //     console.log('POST Request Response:', response.data);
    //   } catch (error) {
    //     console.error('Error sending POST request:', error);
    //   }
    // };


// const handleChange = (event) =>
//   setValue(event.target.name, event.target.value, { shouldValidate: true });

    const intl = useIntl();

    const styles = useStyles();

    const handleOnChange = (event) =>
        setValue(event.target.name, event.target.value, {shouldValidate: true});

    const columns = [
        {
            field: "id",
            headerName: intl.formatMessage({id: "biller.request_id"}),
            flex: 0.7,
        },

        {
            field: "user_mobile_no",
            headerName: intl.formatMessage({id: "biller.mobile_no"}),
            flex: 1,
        },

        {
            field: "serial_no",
            headerName: intl.formatMessage({id: "biller.serial"}),
            flex: 1,
        },
        {
            field: "amount",
            headerName: intl.formatMessage({id: "biller.amount"}),
            flex: 0.7,
        },
        {
            field: "payment_type",
            headerName: intl.formatMessage({id: "biller.type"}),
            flex: 0.8,
        },
        {
            field: "bank",
            headerName: intl.formatMessage({id: "biller.bank_name"}),
            flex: 0.8,
        },
        {
            field: "created_at",
            headerName: intl.formatMessage({id: "biller.created_at"}),
            flex: 0.8,
        },
        {
            field: "status",
            headerName: intl.formatMessage({id: "biller.status"}),
            flex: 0.8,
            renderCell: (params) => (
                <Typography variant="subtitle2" style={{
                    display: 'flex',
                    whiteSpace: 'pre-line',
                    fontWeight: "bold",
                    fontSize: '15px',
                    marginBottom: '6px',
                    color: "white"
                }}>
                    <Button variant="contained" color="primary" size="small"
                            style={{fontSize: '9px', background: statusColor(params.value), width: '90px'}}>
                        {params.value === 1 ? 'Completed' : 'Pending'}
                    </Button>

                    {/* {params.value} */}
                </Typography>
            ),
        },
        {
            field: 'view',
            headerName: 'Actions',
            flex: 0.8,
            renderCell: (params) => (
                <Typography variant="subtitle2"
                            style={{display: 'flex', whiteSpace: 'pre-line', fontSize: '10px', marginBottom: '6px'}}>

                    <Button variant="contained" color="primary" size="small"
                            onClick={(event) => openModal(event, params.row)}
                            style={{fontSize: '9px'}}>
                        View
                    </Button>

                </Typography>

            ),
        },


    ];
    const handleRowClick = (event, params) => {
        setClickedRow(params.row);
        console.log("Row clicked:", params.row);
    };

    const save = async data => {
        setLoading(true);


        if (!clickedRow && selectionModel.length === 0) {
            showAlert("error", "No row selected.");
            setLoading(false);
            return;
        }
        try {

            const request_id = selectionModel;

            const response = await axios.post("/api/completeHoldPayTransactionRequest", {

                // data: clickedRow ? [clickedRow] : selectedRowsData,
                request_id,
                //...data,
                payment_type: selectedRadioValue,
                comment: comment,
            });


            if (response.status === 200) {
                showAlert('success', 'Funds transferred successfully')
                comment('')
                console.log("data:", response)
            }
        } catch (error) {
            setLoading(false);
            console.error('Error sending POST request:', error);

        }
    }

    const getRowClassName = (params) => {
        const rowId = params.row.id;
        console.log("rowId", rowId);
        return viewedRequests.includes(rowId) ? 'with-winning--present' : '';
    };

    // const submit = handleSubmit(save);
    return (
        <CmtCard className={className}>
            <CmtCardHeader style={{padding: '0px', display: 'flex', justifyContent: 'end'}} >
                {handleRefresh !== null && (
                    <IconButton onClick={handleRefresh} style={{padding: '0px'}}>
                        <RefreshSharp />
                    </IconButton>
                )}
            </CmtCardHeader>
            <CmtCardContent style={{height: 480}} className={styles.grid}>
                <DataGrid
                    showToolbar
                    rows={rows}
                    columns={columns}
                    pagination
                    pageSize={pageSize}
                    rowsPerPageOptions={[10, 20, 50, 100]}
                    rowCount={total}
                    paginationMode="server"
                    onPageChange={handlePageChange}
                    onPageSizeChange={handlePageCountChange}
                    sortingMode="server"
                    sortModel={sortModel}
                    onSortModelChange={handleSortModelChange}
                    filterMode="server"
                    onFilterModelChange={handleFilterChange}
                    checkboxSelection
                    onSelectionModelChange={handleSelection}
                    selectionModel={selectionModel}
                    loading={loading}
                    components={{Panel: CustomGridPanel}}
                    onRowClick={handleRowClick}
                    style={{width: "100%", height: 400}}
                    columnBuffer={2}
                    disableColumnVirtualization
                    // getRowClassName={getRowClassName}
                    getRowClassName={(params) =>
                        `${viewedRequests.includes(params.id) ? 'request' : 'request-not'}-viewed`
                    }
                    rowHeight={33}
                    onRefresh={null}
                />


            </CmtCardContent>
            {
                selectionModel.length > 0 && (<CmtCard>
                    <CmtCardContent>
                        <CmtCard><CmtCardContent>
                            <FormControl component="fieldset">
                                <FormLabel component="legend" style={{marginBottom: "20px"}}>
                                    <IntlMessages id="user.bill_recon.method"/>
                                </FormLabel>
                                <RadioGroup
                                    aria-label="payment_type"
                                    name="payment_type"
                                    onChange={handleChange}

                                >
                                    <Grid container xs={12}>
                                        <Grid item xs={12} md={4}>
                                            <FormControlLabel
                                                value="bank_transfer"
                                                className={styles.fullWidth}
                                                control={<Radio/>}
                                                label={<IntlMessages id="user.bill_recon.bank"/>}
                                            />

                                            {/* <FormControlLabel
                      value="cash"
                      className={styles.fullWidth}
                      control={<Radio />}
                      label={<IntlMessages id="user.bill_recon.cash" />}
                    /> */}
                                            <FormControlLabel
                                                value="ezcash"
                                                className={styles.fullWidth}
                                                control={<Radio/>}
                                                label={<IntlMessages id="user.bill_recon.ezcash"/>}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            {/* <FormControlLabel
                      value="mcash"
                      className={styles.fullWidth}
                      control={<Radio />}
                      label={<IntlMessages id="user.bill_recon.mcash" />}
                    /> */}
                                            <FormControlLabel
                                                value="mobile"
                                                className={styles.fullWidth}
                                                control={<Radio/>}
                                                label={<IntlMessages id="user.bill_recon.mobile"/>}
                                            />
                                            <FormControlLabel
                                                value="reload"
                                                className={styles.fullWidth}
                                                control={<Radio/>}
                                                label={<IntlMessages id="user.bill_recon.reload"/>}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <FormControlLabel
                                                value="wallet"
                                                className={styles.fullWidth}
                                                control={<Radio/>}
                                                label={<IntlMessages id="user.bill_recon.wallet"/>}
                                            />
                                            <FormControlLabel
                                                className={styles.fullWidth}
                                                value="other"
                                                control={<Radio/>}
                                                label={<IntlMessages id="user.bill_recon.other"/>}
                                            />
                                        </Grid>
                                    </Grid>
                                </RadioGroup>
                                <Grid item xs={12} md={6} className={styles.formColumn}>
                                    <TextField style={{margin: "10px"}}
                                        // label={<IntlMessages id="user.bill_recon.mobile_number" />}
                                               variant="outlined"
                                               label="comment"
                                               value={comment}
                                               onChange={(event) => setComment(event.target.value)}
                                    />
                                    <Button variant="contained" disabled={!selectedRadioValue} color="primary"
                                            onClick={handleSubmit(save)}>submit</Button>
                                </Grid>

                            </FormControl></CmtCardContent></CmtCard>


                    </CmtCardContent>
                </CmtCard>)
            }

            <Dialog open={modalOpen} fullWidth>
                {details && (
                    <>
                        <IconButton size="small" onClick={closeModal} style={{width: 10, marginLeft: '570px'}}>
                            <Close color="error"/>
                        </IconButton>
                        <DialogTitle>Request ID {details.id} Details</DialogTitle>
                        <CmtCard className={className} style={{width: 500, marginLeft: '50px', marginBottom: '15px'}}>
                            <CmtCardContent>
                                <DialogContent style={{height: 245}}>
                                    <Grid container xs={12} spacing={4}>
                                        <Grid container item xs={6}>
                                            <Typography><b>Holder Name: </b></Typography>
                                        </Grid>
                                        <Grid container item xs={6}>
                                            <Typography>{details.holder_name}</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container xs={12} spacing={4}>
                                        <Grid container item xs={6}>
                                            <Typography><b>NIC:</b></Typography>
                                        </Grid>
                                        <Grid container item xs={6}>
                                            <Typography>{details.holder_nic}</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container xs={12} spacing={4}>
                                        <Grid container item xs={6}>
                                            <Typography><b>Requested Amount:</b></Typography>
                                        </Grid>
                                        <Grid container item xs={6}>
                                            <Typography>Rs.{details.amount}</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container xs={12} spacing={4}>
                                        <Grid container item xs={6}>
                                            <Typography><b>Bank:</b></Typography>
                                        </Grid>
                                        <Grid container item xs={6}>
                                            <Typography>{details.bank}</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container xs={12} spacing={4}>
                                        <Grid container item xs={6}>
                                            <Typography><b>Branch:</b></Typography>
                                        </Grid>
                                        <Grid container item xs={6}>
                                            <Typography>{details.branch}</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container xs={12} spacing={4}>
                                        <Grid container item xs={6}>
                                            <Typography><b>Mobile No:</b></Typography>
                                        </Grid>
                                        <Grid container item xs={6}>
                                            <Typography>{details.user_mobile_no}</Typography>
                                        </Grid>
                                    </Grid>
                                </DialogContent>
                                <Grid container spacing={2} alignItems="end"
                                      style={{marginTop: "6px", display: "flex", justifyContent: 'end'}}>
                                    <Button variant="contained" color="primary" size="small"
                                            onClick={() => closeModal(true)}>Close</Button>
                                </Grid>
                            </CmtCardContent>
                        </CmtCard>
                    </>
                )}
            </Dialog>
        </CmtCard>
    );
};

export default Results;
