import useAxios from "app/hooks/useAxios";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";

const useData = ({ id }) => {
  const axios = useAxios();
  const intl = useIntl();

  const message = {
    name: "",
    lottery_id: id,
    language_id: 0,
    subject: "",
    content: "",
    default_content: "",
    status: 1,
    message_type_id: 1,
  };

  const [type, setType] = useState(1);
  const [language, setLanguage] = useState(1);
  const [loading, setLoading] = useState(false);

  const [messageTypes, setMessageTypes] = useState([
    {
      value: 1,
      label: intl.formatMessage({ id: "lottery.messages.type_sms" }),
    },
  ]);
  const [languages, setLanguages] = useState([
    {
      value: 1,
      label: intl.formatMessage({ id: "lottery.messages.language_english" }),
    },
  ]);

  const [messages, setMessages] = useState([]);

  const addMessage = () => {
    setMessages([
      ...messages,
      {
        ...message,
        language_id: language,
        message_type_id: type,
      },
    ]);
  };

  useEffect(() => {
    setLoading(true);
    let active = true;

    (async () => {
      try {
        const [response, templateResponse] = await Promise.all([
          axios.post("/api/lottery/message-template", {
            lottery_id: id,
          }),
          axios.get("api/settings/message-type"),
        ]);
        setLoading(false);
        if (!active) return;

        setMessages(response.data.data);
        setMessageTypes(
          templateResponse.data.data.map((t) => ({
            value: t.id,
            label: t.name,
          }))
        );
      } catch (error) {
        setLoading(false);
      }
    })();

    return () => {
      active = false;
    };
  }, []);

  const addName = (message) => {
    return {
      ...message,
      subject: message.name,
      default_content: message.default_content || message.content,
    };
  };

  const save = async (key, message) => {
    setLoading(true);
    setMessage(key, {
      ...message,
      loading: true,
    });
    try {
      let url = "/api/settings/message-template/create";
      if (message.id) {
        url = "/api/settings/message-template/update/" + message.id;
      }
      const response = await axios.post(url, addName(message));
      setLoading(false);
      const data = response.data.message_template;
      setMessage(key, {
        ...data,
      });
    } catch (error) {
      setMessage(key, {
        ...message,
        loading: false,
      });
      setLoading(false);
    }
  };

  const deleteMessage = async (key, message) => {
    setLoading(true);
    setMessage(key, {
      ...message,
      loading: true,
    });
    try {
      if (message.id) {
        const url = "/api/settings/message-template/delete/" + message.id;
        await axios.get(url);
      }

      setLoading(false);
      setMessages(messages.filter((_, i) => i !== key));
    } catch (error) {
      setMessage(key, {
        ...message,
        loading: false,
      });
      setLoading(false);
    }
  };

  const setMessage = (key, newMessage) => {
    const newMessages = messages.map((m, i) => {
      if (i === key) {
        return newMessage;
      }

      return m;
    });

    setMessages(newMessages);
  };

  return {
    type,
    language,
    messageTypes,
    languages,
    messages,
    save,
    setMessage,
    loading,
    addMessage,
    setLanguage,
    setType,
    deleteMessage,
  };
};

export default useData;
