import React , { useEffect, useState }from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Card, CardContent, Typography } from '@material-ui/core';

// import Grid from "@material-ui/core/Grid";
import PerfectScrollbar from 'react-perfect-scrollbar';
import useData from "./State";
import axios from "axios";
import {
  BasicInfoCard,
  InformationPanel,
  Avatar,
  AvatarWithName,
} from "../uielements";
import DrawSummaryContainer from "./Draws";
import usePermissions from "app/hooks/usePermissions";


const { applyPermissions, permissions } = usePermissions();


const DashboardData = () => {
  const agentCode = localStorage.getItem("agentCode");
  const [userId, setUserId] = useState(null);
  const [blocking, setBlocking] = useState(false);

  useEffect(() => {
    fetchUserId();
  }, []);

  const fetchUserId = async () => {
    try {
      setBlocking(true); 
      const response = await axios.get('/api/user');
      setUserId(response.data.id); 
    } catch (err) {
      console.error('Error fetching user ID:', err); 
    } finally {
      setBlocking(false); 
    }
  };

  const useStyles = makeStyles({
    root: {
      minWidth: 275,
      minHeight: 150,
    },
    scrollbarRoot: {
      height: 390,
      '& .CmtList-EmptyResult': {
        backgroundColor: 'transparent',
        border: '0 none',
      },
    },
  });

  const classes = useStyles();
  const {
    register,
    errors,
    setValue,
    getValues,
    loading,
    data,
    drawSummary,
    rows,
    updateRow,
    ...rest
  } = useData();

  const breadcrumbs = [
    { label: "Home", link: "/" },
    { label: "Sample Page", isActive: true },
  ];

  const getContent = () => {
    return (
      <AvatarWithName
        alt="Cinnamon Chip Wealth Promoters (Pvt) Ltd"
        src="/static/images/avatar/1.jpg"
        name="Cinnamon Chip Wealth Promoters (Pvt) Ltd"
      />
    );
  };

 
  const renderDraws = () => {
    return (
      <PerfectScrollbar className={classes.scrollbarRoot}>
        <DrawSummaryContainer draws={drawSummary.data} />
      </PerfectScrollbar>
    );
  };

  return (
    <div>
      <Grid container spacing={3}>
       
 

        <Grid container spacing={3}>
   
      <Grid item xs={6} sm={6} md={3}>
      <BasicInfoCard
              cardObj={{
                total: "",
                newValue:  agentCode ,
                type: "My Agent Code", 
              }}
              name="My Agent Code"
            />
      </Grid>
      
      <Grid item xs={6} sm={6} md={3}>
      {(localStorage.getItem("role") != "dialog") ?(   
            <BasicInfoCard
              cardObj={{
                total: "",
                newValue: (data?.sales?.totalsales || 0),
                type: "Total Sale"
              }}
              name="Total Sale"
            /> ):("")}
      </Grid>
     
      <Grid item xs={6} sm={6} md={3}>
      {(localStorage.getItem("role") != "dialog") ?( 
            <BasicInfoCard
              cardObj={{
                total: "",
                newValue: data?.sales?.today || 0,
                type: "Direct Sale",
              }}
              name="Direct Sale"
            />  ):("")}
      </Grid>
      
      <Grid item xs={6} sm={6} md={3}>
      {(localStorage.getItem("role") != "dialog") ?(         
            <BasicInfoCard
              cardObj={{
                total: "",
                newValue: (data?.sales?.total || 0),
                type: "Passive Sale",
              }}
              name="Passive Sale"
            /> ):("")}
      </Grid>
    </Grid>


        {(localStorage.getItem("role") != "dialog") && (
    <Grid item xs={12} sm={8} md={9} lg={9}>
      <DrawSummaryContainer draws={drawSummary.data} />
    </Grid>
  )}

  {(localStorage.getItem("role") != "dialog") && (
    <Grid item xs={12} sm={4} md={3} lg={3}>
      <InformationPanel
        headerTitle="Recent Activity"
        content={getContent()}
      />
    </Grid>
  )}
</Grid>
      
    </div>
  );
};

export default DashboardData;
