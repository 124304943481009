import useAxios from "app/hooks/useAxios";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { transformInToFormObject } from "services/utils";
import showAlert from "app/hooks/useAlerts";
import { confirm } from "app/hooks/useAlerts";

const useData = ({ handleClose, updateRow, user }) => {
  const [primaryDisabled, setPrimaryDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [selected, setSelected] = useState(null);
  const axios = useAxios();

  const {
    register,
    getValues,
    formState: { errors },
    setValue,
    handleSubmit,
    reset,
  } = useForm(
    {defaultValues: {
      gender: 'male'
    }}
  );

  const intl = useIntl();
  const format = (id) =>
    intl.formatMessage({
      id: `user.add.${id}`,
    });

  const values = getValues();



  useEffect(() => {
    let active = true;

    (async () => {
      try {
        setLoading(true);

        const response = await axios.post("/api/user/view/" + user.id);
        if (!active) return;
        setLoading(false);
        if (response.status === 200) {
          const user = response.data.user;
          const address = response.data.addresses[response.data.addresses.length-1] || {};
          const ono_active_subscription =response.data.ono_active_subscription;
          reset({
            fname: user.fname,
            lname: user.lname,
            email: user.email,
            nicno: user.nicno,
            premium_percentage_ccwp:ono_active_subscription?.premium_percentage_ccwp,
            premium_percentage_cx:ono_active_subscription?.premium_percentage_cx,
            is_ono_registered:ono_active_subscription?.is_ono_registered,
            ono_active_status:ono_active_subscription?.ono_active_status,
            address_1: address.address_1,
            address_2: address.address_2,
            city: address.city,
            postal_code: address.postal_code,
          });



        }
      } catch (error) {
        setLoading(true);
      }
    })();

    return () => {
      active = false;
    };
  }, [user]);

  const controls = {
    fname: register("fname", {
      required: { value: true, message: format("errors.required") },
    }),
    premium_percentage_ccwp: register("premium_percentage_ccwp", {
      //required: { value: true, message: format("errors.required") },
    }),
    premium_percentage_cx: register("premium_percentage_cx", {
      //required: { value: true, message: format("errors.required") },
    }),
    ono_active_status: register("ono_active_status", {
      //required: { value: true, message: format("errors.required") },
    }),
    is_ono_registered: register("is_ono_registered", {
      //required: { value: true, message: format("errors.required") },
    }),
    lname: register("lname", {
      //required: { value: true, message: format("errors.required") },
    }),
    email: register("email", {
      //required: { value: false },
      // pattern: {
      //   value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
      //   message: format("errors.email"),
      // },
    }),
    nicno: register("nicno", {
      required: { value: true, message: format("errors.required") },
      minLength: { value: 10, message: format("errors.nicno") },
      maxLength: { value: 12, message: format("errors.nicno") },
    }),
    address_1: register("address_1", {
     // required: { value: true, message: format("errors.required") },
    }),
    address_2: register("address_2", {
      required: { value: false },
    }),
    city: register("city", {
      //required: { value: true, message: format("errors.required") },
    }),
    postal_code: register("postal_code", {
      //required: { value: true, message: format("errors.required") },
    }),

  };

  const save = async (data) => {
    
    setLoading(true);

    try {
      const response = await axios.post("/api/user/ceylico/register/" + user.id, data);
      if (response.status === 200) {
        showAlert("success", "User update successful.");
       // updateRow(response.data.user);
        setLoading(false);
        
        handleClose();
      } else {
        setLoading(false);
        for(let i in errors.response.data.errors){
          showAlert("error", errors.response.data.errors[i]);
        }
      }
    } catch (errors) {
      //debugger
      //console.error("============>");      
      //console.error(errors.response.data.errors);    
      
      for(let i in errors.response.data.errors){
        showAlert("error", errors.response.data.errors[i]);
      }
      setLoading(false);
    }
  };

  const submit = handleSubmit(save);



  const handleEditClose = () => setShowEdit(false);

  return {
    primaryDisabled,
    controls,
    values,
    errors,
    loading,
    setValue,
    submit,
    getValues,
    register,
    showEdit,
    selected,
    handleEditClose
  };
};

export default useData;
