import React, {useEffect} from "react";

import {useIntl} from "react-intl";
import PerfectScrollbar from "react-perfect-scrollbar";
import {DialogModal, Loader} from "app/uielements";

import CmtCard from "@coremat/CmtCard";
import CmtCardContent from "@coremat/CmtCard/CmtCardContent";

import useStyles from "./Returns.styles";
import CmtList from "@coremat/CmtList";
import ReturnCell from "./ReturnCell";
import useReturns from "./Returns.state";
import CmtCardFooter from "@coremat/CmtCard/CmtCardFooter";
import {Box, Button} from "@material-ui/core";

const ViewReturns = ({open, handleClose, id}) => {
  const {loading, setId, data, rows, setRows, setData, download} = useReturns();
  useEffect(
    () => {
      setId(id);
    },
    [ id ]
  );

  const styles = useStyles();

  const intl = useIntl();

  const format = id =>
    intl.formatMessage({
      id: `returns.${id}`
    });

  const handleDialogClose = () => {
    setData({});
    setRows([]);
    handleClose();
  };

  return (
    <DialogModal
      isOpen={open}
      handleClose={handleDialogClose}
      enableActions={false}
      title={`${format("title")} : ${data.lottery_name ||
        ""} - ${data.draw_id || ""}`}
        backdrop="static"
    >
      <div className={styles.modelContent}>
        {loading && <Loader />}
        <CmtCard className={styles.cardRoot}>
          <CmtCardContent>
            <PerfectScrollbar className={styles.scrollbarRoot}>
              <CmtList
                data={rows}
                renderRow={(data, index) => (
                  <ReturnCell key={index} data={data} />
                )}
              />
            </PerfectScrollbar>
          </CmtCardContent>
          <CmtCardFooter
            separator={{
              color: "#eee",
              borderWidth: 1,
              borderStyle: "solid"
            }}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              width={1}
            >
              <Button onClick={download}>{format("download")}</Button>
            </Box>
          </CmtCardFooter>
        </CmtCard>
      </div>
    </DialogModal>
  );
};

export default ViewReturns;
