import React, { useState, useEffect } from "react";
import useAxios from "../../hooks/useAxios";
import moment from "moment";
import { useIntl } from "react-intl";
import { useForm } from "react-hook-form";
import showAlert from "app/hooks/useAlerts";

const useAddWinnings = ({ lottery_id, draw, lotteries }) => {
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [winning, setWinning] = useState({});
  const [list, setList] = useState({});
  const [primaryDisabled, setPrimaryDisabled] = useState(false);
  const [file, setFile] = useState(null);
  const [templates, setTemplates] = useState({
    winning_message: "",
    winner_message: "",
  });
  const [lagnaVisible, setLagnaVisible] = useState(false);
  const [luckyVisible, setLuckyVisible] = useState(false);
  const [daynVisible, setDaynVisible] = useState(false);
  const [snoVisible, setSnoVisible] = useState(false);
  const [charVisible, setCharVisible] = useState(false);
  const [specialNumberVisible, SetSpecialNumberVisible] = useState(false);
  const [special2NumberVisible, SetSpecial2NumberVisible] = useState(false); 

  const axios = useAxios();

  const intl = useIntl();
  const format = (id) =>
    intl.formatMessage({
      id: `winnings.add.${id}`,
    });

  const defaultValues = {
    id: 0,
    winning_numbers: "",
    count: "",
    //next_draw_number: "",
    letter: "",
   // grand_price: "",
    next_grand_price: "",
    lagna: "",
    lucky: "",
    dayn: " ",
    super_number: "",
    special_no: "",
    special2_no: "",
    schedule_start_date: null,
  };

  const {
    register,
    setValue,
    formState: { isValid, errors },
    handleSubmit,
    getValues,
    reset,
  } = useForm({ defaultValues });

  const values = getValues();

  const formKeyMap = {
    letter: "char",
    super_number: "super_number",
    lagna: "lagna",
    lucky: "lucky",
    dayn: "dayn",
    //special_no: "sno",
    super_number:"sno",
    winning_numbers: "winning_numbers",
    next_grand_price: "next_winning_price",
  };

 
  const replace = (template = "", values = getValues()) => {
    let result = template;
    const lottery = lotteries.find((l) => l.value === lottery_id);
    const lotteryName = lottery.label;

    Object.keys(draw).forEach((key) => {
      if (key === "name") {
        result = result.replace(`{${key}}`, lotteryName);
      } else if (key === "draw_date") {
        result = result.replace(
          `{${key}}`,
          moment(draw[key]).format("yyyy-MM-DD")
        );
      } else {
        result = result.replace(`{${key}}`, draw[key]);
      }
    });

    Object.keys(values).forEach((key) => {
      if (values[key]) {
        if(key === 'winning_numbers' && lottery.pivot_index > 0){
        
          if(lottery.chunk_length > 0){
            
            const numArr = values[key].split(" ");
            const no1 = numArr.slice(0,(lottery.pivot_index/2));
            result = result.replace(`{winning_no1}`, no1.join(' '));
            if(values[key].length > lottery.pivot_index){
              const no2 = numArr.slice(lottery.pivot_index/2);
              result = result.replace(`{winning_no2}`, no2.join(' '));
            }

          }else{
            const no = values[key];
            const no1 = no.substring(0, lottery.pivot_index);
            result = result.replace(`{winning_no1}`, no1);
            if(no.length > lottery.pivot_index){
              const no2 = no.substring(lottery.pivot_index);
              result = result.replace(`{winning_no2}`, no2);
            }
          }
        }
       
        else{
          result = result.replace(`{${formKeyMap[key] || key}}`, values[key]);
        }
      }
    });
    return result;
  };

  const handleChange = (evt) => {
    setValue(evt.target.name, evt.target.value, { shouldValidate: true });
    setValue(
      "message",
      replace(templates.winning_message, {
        ...values,
        [evt.target.name]: evt.target.value,
      }),
      { shouldValidate: true }
    );
  };

  const toString = (val) => (val ? String(val) : val);

  useEffect(() => {
    let active = true;
    setLoading(true);
    (async () => {
      try {
        const templateRequest = axios.post("/api/settings/message-template", {
          lottery_id,
        });

        const formatRequest = axios.get(
          "/api/lottery/number/format/" + lottery_id
        );

        const winningRequest = axios.get(`api/winning/edit/${draw.id}`);

        const responses = await Promise.all([
          templateRequest,
          formatRequest,
          winningRequest,
        ]);

        setLoading(false);

        const [response, formatResponse, winningResponse] = responses;
        
        const templates = response.data.templates;
        const format = formatResponse.data.format;

        if (!active) {
          return;
        }

        if (format && format.original && format.original.errors === "1") {
          setLagnaVisible(false);
          setLuckyVisible(false);
          setDaynVisible(false);
          setSnoVisible(false);
          setCharVisible(false);
          SetSpecialNumberVisible(false);
          SetSpecial2NumberVisible(false);
        } else {
          setLuckyVisible(
            Object.keys(format)
              .map((key) => format[key])
              .filter((val) => val === "lucky").length > 0
          );
          setDaynVisible(
            Object.keys(format)
              .map((key) => format[key])
              .filter((val) => val === "dayn").length > 0
          );
          setLagnaVisible(
            Object.keys(format)
              .map((key) => format[key])
              .filter((val) => val === "lagna").length > 0
          );
          setSnoVisible(
            Object.keys(format)
              .map((key) => format[key])
              .filter((val) => val === "sno" || val === "sn").length > 0
          );
          setCharVisible(
            Object.keys(format)
              .map((key) => format[key])
              .filter((val) => val === "char").length > 0
          );
          SetSpecialNumberVisible(
            Object.keys(format)
              .map((key) => format[key])
              .filter((val) => val === "special").length > 0
          );
          SetSpecial2NumberVisible(
            Object.keys(format)
              .map((key) => format[key])
              .filter((val) => val === "special2").length > 0
          );
        }
        let values = {};

        if (winningResponse.status === 200 && winningResponse.data.winning) {
          const winning = winningResponse.data.winning;
          console.log(winningResponse.data.winning);
          values = {
            id: winning.id,
            winning_numbers: toString(winning.winning_numbers),
            count: winning.count,
           // next_draw_number: toString(winning.next_draw_number),
            letter: toString(winning.letter),
            // grand_price: winning.grand_price,
            next_grand_price: winning.next_grand_price,
            lagna: toString(winning.lagna),
            lucky: toString(winning.lucky),
            dayn: toString(winning.dayn),
            super_number: toString(winning.super_number),
            special_no: toString(winning.special_no),
            special2_no: toString(winning.special2_no),
            schedule_start_date: winning.schedule_start_date
              ? moment(
                  winning.schedule_start_date,
                  "yyyy-MM-DD hh:mm:ss"
                ).toDate()
              : null,
          };

        }

        reset({
          ...values,
          message: replace(templates.winning_message, values),     
               
        });
        
        setTemplates(templates);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    })();

    return () => {
      active = false;
    };
  }, [lottery_id]);

  const controls = {
    count: register("count", {
      required: { value: true, message: format("errors.count") },
      min: 1,
    }),
    // grand_price: register("grand_price", {
    //   required: { value: true, message: format("errors.required") },
    //   min: 1,
    // }),
    lucky: register("lucky", {
      required: { value: luckyVisible, message: format("errors.required") },
    }),
    dayn: register("dayn", {
      required: { value: daynVisible, message: format("errors.required") },
    }),
    lagna: register("lagna", {
      required: { value: lagnaVisible, message: format("errors.required") },
    }),

    super_number: register("super_number", {
      required: { value: snoVisible, message: format("errors.required") },
    }),

    special_no: register("special_no", {
      required: { value: specialNumberVisible, message: format("errors.required") },
    }),
    special2_no: register("special2_no", {
      required: { value: special2NumberVisible, message: format("errors.required") },
    }),

    winning_numbers: register("winning_numbers", {
      required: { value: true, message: format("errors.required") },
    }),
    next_grand_price: register("next_grand_price", {
      required: { value: true, message: format("errors.next_grand_price") },
      min: 1,
    }),
    letter: register("letter", {
      required: { value: charVisible, message: format("errors.letter") },
      minLength: 1,
      maxLength: 1,
    }),
   /*  next_draw_number: register("next_draw_number", {
      required: { value: true, message: format("errors.next_draw_number") },
      min: 1,
    }), */
    message: register("message", {
      required: { value: true, message: format("errors.message") },
    }),
    schedule_start_date: register("schedule_start_date", {
      required: { value: true, message: format("errors.start_date") },
      validate: {
        validData: (val) =>
          !moment(val, "MM/DD/yyyy hh:mm a").isValid()
            ? format("errors.date")
            : true,
        futureData: (val) =>
          !getValues().id
            ? moment(val, "MM/DD/yyyy hh:mm a").diff(moment(), "hours") < 0
              ? format("errors.start_date")
              : true
            : true,
      },
    }),
  };

  useEffect(() => {
    if (step === 0) setPrimaryDisabled(false);
    else if (step === 1 && file) setPrimaryDisabled(false);
    else if (step === 2 && list.id) setPrimaryDisabled(false);
    else setPrimaryDisabled(true);
  }, [step, file, list, isValid]);

  const saveWinning = (draw_id) => async (data) => {
    setLoading(true);
    try {
      const body = {
        ...data,
        grand_price: data.next_grand_price,
        winning_numbers: data.winning_numbers,
        draw_id,
        schedule_start_date: moment(
          data.schedule_start_date,
          "MM/DD/yyyy hh:mm a"
        ).format("MM/DD/yyyy hh:mm a"),
      };
      let url = "/api/winning/create";
      if (data.id) {
        url = `/api/winning/update/${data.id}`;
      }
      const response = await axios.post(url, body);

      if (response.status === 200) {
        setWinning(response.data.winning);
        setStep(1);
      }
    } catch (error) {
      console.error(error);
    }

    setLoading(false);
  };

  const uploadWinnings = async ({ id, lottery_id }) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("csv_file", file);
    formData.append("draw_id", id);
    formData.append("header", 1);
    formData.append("lottery_id", lottery_id);
    formData.append("type", 2);
    formData.append("winning_id", winning.id);
    try {
      if (!file) throw Error("please select file");
      const response = await axios.post("/api/import_parse", formData);
      if (response.status === 200) {
        const lotteries = {
          id: response.data.id,
          columns: response.data.csv_data_header,
          data: response.data.csv_data_body,
        };
        setList(lotteries);
        setStep(2);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const processImport = async (handleClose) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("csv_data_file_id", list.id);
    formData.append("type", 2);
    try {
      const response = await axios.post("/api/import_process", formData);
      setLoading(false);
      if (response.status == 200) {
        showAlert(
          "success",
          `${response.data.message} uploaded count: ${response.data.uploaded_count}. updated count: ${response.data.updated_count} `
        );
        handleClose();
      }
    } catch (error) {
      setLoading(false);
    }
  };
  return {
    step,
    setStep,
    winning,
    setWinning,
    loading,
    list,
    setList,
    setLoading,
    uploadWinnings,
    processImport,
    primaryDisabled,
    controls,
    errors,
    setValue,
    saveWinning,
    setFile,
    handleSubmit,
    values,
    lagnaVisible,
    luckyVisible,
    daynVisible,
    snoVisible,
    charVisible,
    specialNumberVisible,
    special2NumberVisible,
    handleChange,
  };
};

export default useAddWinnings;
