import React, { useEffect } from "react";
import useData from "./Transactions.state";
import { useIntl } from "react-intl";
import DataGrid from "app/uielements/Table/DataGrid";
import moment from "moment";
import { DialogModal } from "app/uielements";
import useStyles from "./Transactions.styles";
import { Box, Tooltip, Typography } from "@material-ui/core";
import IntlMessages from "@jumbo/utils/IntlMessages";
import Download from "./Download";
import Grid from "@material-ui/core/Grid";

const List = ({ open, handleClose, id }) => {
  const {
    rows,
    walletBalance,
    holdbalance,
    genieBalance,
    total,
    loading,
    pageSize,
    handlePageChange,
    handlePageCountChange,
    setId,
    setRows,
  } = useData();
  useEffect(() => {
    setId(id);
  }, [id]);

  const styles = useStyles();

  const intl = useIntl();

  const format = (id) =>
    intl.formatMessage({
      id: `user.transactions.${id}`,
    });

  const formatDate = (params) => moment(params.value).format("yyyy-MM-DD HH:mm:ss");
  const formatCurrency = (params) => Number(params.value || 0).toFixed(2);
  const columns = [
    {
      field: "transdate",
      headerName: format("transdate"),
      flex: 1.8,
      valueFormatter: formatDate,
      sortComparator: (v1, v2, cellParams1, cellParams2) =>
        moment(cellParams1.value).isBefore(moment(cellParams2.value)),
    },
    { field: "type", headerName: format("type"), flex: 1.2 },
    { field: "status", headerName: format("status"), flex: 1 },
    {
      field: "credit",
      headerName: format("credit"),
      flex: 1,
      valueFormatter: formatCurrency,
    },
    {
      field: "debit",
      headerName: format("debit"),
      flex: 1,
      valueFormatter: formatCurrency,
    },
    {
      field: "hold",
      headerName: format("hold"),
      flex: 1,
      valueFormatter: formatCurrency,
    },
    {
      field: "balance",
      headerName: format("balance"),
      flex: 1,
      valueFormatter: formatCurrency,
    },
    {
      field: "payout",
      headerName: format("payout"),
      flex: 1,
      valueFormatter: formatCurrency,
    },
    {
      field: "comment",
      headerName: format("comment"),
      flex: 2,
      renderCell: (params) => (
        <Tooltip title={params.value} arrow>
          <Typography variant="subtitle2" className={styles.comment}>
            {params.value}
          </Typography>
        </Tooltip>
      ),
    },
  ];

  const handleDialogClose = () => {
    handleClose();
    setRows([]);
  };

  if(localStorage.getItem("role") != "dialog") {
  return (
    <DialogModal
      isOpen={open}
      handleClose={handleDialogClose}
      primaryDisabled={true}
      enableActions={false}
      title={format("title")}
    >
      <div className={styles.modelContent}>
      <Grid item xs={12}>
          <Grid
            container
            direction="row"
            justify="space-around"
            alignItems="flex-start"
            spacing={1}
          >
          <label className={styles.walletStyle}>
            <IntlMessages id="user.transactions.wallet_balance" /> :{" "}
            {walletBalance}
          </label>
          <label className={styles.walletStyle}>
            <IntlMessages id="user.transactions.hold_balance" /> :{" "}
            {holdbalance}
          </label>
          </Grid>
        </Grid>
        <Download id={id}/>
        <DataGrid
          rows={rows}
          columns={columns}
          pagination
          pageSize={pageSize}
          rowsPerPageOptions={[10, 20, 50, 100]}
          rowCount={total}
          paginationMode="server"
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageCountChange}
          loading={loading}
        />
      </div>
    </DialogModal>
  );
  }else{
    return null;
  }
};

export default List;
