import React, {useState} from "react";
import Column from "./Column";

import {arrayMove, SortableContainer} from "react-sortable-hoc";
import {Box} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
  root: {
    "& > *:not(:last-child)": {
      marginBottom: theme.spacing(4)
    }
  }
}));

const Columns = SortableContainer(({columns, handleDelete}) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      {columns.map((column, index) => (
        <Column
          key={index}
          index={index}
          column={column}
          handleDelete={handleDelete}
        />
      ))}
    </Box>
  );
});

const ColumnList = ({columns, setColumns}) => {
  const onSortEnd = ({oldIndex, newIndex}) => {
    setColumns(arrayMove(columns, oldIndex, newIndex));
  };

  const handleDelete = ({column}) => {
    setColumns(columns.filter(col => col !== column));
  };

  return (
    <React.Fragment>
      <Columns
        columns={columns}
        onSortEnd={onSortEnd}
        handleDelete={handleDelete}
        useDragHandle={true}
        validate
      />
    </React.Fragment>
  );
};

export default ColumnList;
