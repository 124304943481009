import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";

import useStyles from "./DialogModal.styles";

const DialogTitle = props => {
  const {children, classes, onClose, ...other} = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};

const DialogModal = prop => {
  const {
    isOpen,
    handleClose,
    handlePrimaryButton,
    handleSecondaryButton,
    primaryButtonLabel,
    secondaryButtonLabel,
    actions,
    title,
    description,
    children,
    primaryDisabled,
    secondaryDisabled,
    enableActions,
    loading,
    ...props
  } = prop;
  const classes = useStyles();

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="model-dialog-title"
        classes={classes}
        {...props}
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DialogTitle
          id="form-dialog-title"
          classes={classes}
          onClose={handleClose}
        >
          {title}
        </DialogTitle>
        <DialogContent dividers>
          {description && <DialogContentText>{description}</DialogContentText>}
          {children}
        </DialogContent>
        {enableActions ? (
          <DialogActions>
            {actions || (
              <React.Fragment>
                <Button
                  onClick={handleSecondaryButton}
                  color="primary"
                  disabled={secondaryDisabled || loading}
                >
                  {secondaryButtonLabel}
                </Button>
                <Button
                  onClick={handlePrimaryButton}
                  color="primary"
                  disabled={primaryDisabled || loading}
                >
                  {primaryButtonLabel}
                </Button>
              </React.Fragment>
            )}
          </DialogActions>
        ) : null}
      </Dialog>
    </div>
  );
};

DialogModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  enableActions: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  handlePrimaryButton: PropTypes.func,
  handleSecondaryButton: PropTypes.func,
  primaryButtonLabel: PropTypes.string,
  secondaryButtonLabel: PropTypes.string,
  actions: PropTypes.object,
  title: PropTypes.string,
  description: PropTypes.string,
  primaryDisabled: PropTypes.bool,
  secondaryDisabled: PropTypes.bool,
  loading: PropTypes.bool
};

DialogModal.defaultProps = {
  primaryButtonLabel: "Submit",
  secondaryButtonLabel: "Cancel",
  actions: null,
  title: "",
  description: "",
  primaryDisabled: false,
  secondaryDisabled: false,
  enableActions: true,
  loading: false
};

export default DialogModal;
