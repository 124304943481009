import * as t from "./actionTypes";

export const setAuthUser = (user) => {
  return (dispatch) => {
    dispatch({
      type: t.UPDATE_AUTH_USER,
      payload: user,
    });
  };
};

export const updateLoadUser = (loading) => {
  return (dispatch) => {
    dispatch({
      type: t.UPDATE_LOAD_USER,
      payload: loading,
    });
  };
};

export const setForgetPassMailSent = (status) => {
  return (dispatch) => {
    dispatch({
      type: t.SEND_FORGET_PASSWORD_EMAIL,
      payload: status,
    });
  };
};

export const logInUser = (user) => {
  return (dispatch) => {
    dispatch({
      types: [t.LOGIN_USER, t.LOGIN_USER_SUCCESS, t.LOGIN_USER_FAIL],
      payload: {
        request: {
          url: "/login",
          method: "POST",
          data: {
            telephone: user.mobile,
            password: user.password,
          },
        },
      },
    });
  };
};

export const logOutUser = (user) => {
  return (dispatch) => {
    dispatch({
      types: [t.LOGOUT_USER, t.LOGOUT_USER_SUCCESS, t.LOGOUT_USER_FAIL],
      payload: {
        request: {
          url: "/logout",
          method: "POST",
        },
      },
    });
  };
};

export const registerUser = (userObj) => ({
  types: [t.REGISTER_USER, t.REGISTER_USER_SUCCESS, t.REGISTER_USER_FAIL],
  payload: {
    request: {
      url: "/register",
      method: "POST",
      data: userObj,
    },
  },
});

export const sendVerifyEmail = () => {
  return (dispatch) => {
    dispatch({
      types: [
        t.SEND_VERIFY_EMAIL,
        t.SEND_VERIFY_EMAIL_SUCCESS,
        t.SEND_VERIFY_EMAIL_FAIL,
      ],
      payload: {
        request: {
          url: "/email/resend",
          method: "GET",
        },
      },
    });
  };
};

export const fetchUser = () => ({
  types: [t.FETCH_USER, t.FETCH_USER_SUCCESS, t.FETCH_USER_FAIL],
  payload: {
    request: {
      url: "/user",
      method: "GET",
    },
  },
});

export const getUser = () => {
  return (dispatch) => {
    dispatch({
      type: t.GET_USER,
    });
  };
};
