import showAlert from "app/hooks/useAlerts";
import useAxios from "app/hooks/useAxios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

const useData = ({ handleClose, data, setData }) => {
  const [id, setId] = useState(0);
  const [loading, setLoading] = useState(false);

  const axios = useAxios();
  const {
    register,
    formState: { errors },
    setValue,
    getValues,
    handleSubmit,
    reset,
  } = useForm();

  useEffect(() => {
    setId(data.id);
    reset(data);
  }, [data]);
  
  const formatDate = (val) => moment(val).format("MM/DD/yyyy hh:mm A");
  const saveDraw = async (data) => {
    setLoading(true);
    try {
      await axios.post("/api/draw/update/" + id, {
        ...data,
        issue_start_date: formatDate(data.issue_start_date),
        issue_end_date: formatDate(data.issue_end_date),
        draw_date: formatDate(data.draw_date),
        returned_buffer_date: formatDate(data.returned_buffer_date),
        subscription_start_date:  formatDate(data.subscription_start_date),
      });
      setLoading(false);

      setData({
        ...data,
        id,
      });
      showAlert("success", "Draw updated successfully.");
      handleClose();
    } catch (error) {
      setLoading(false);
    }
  };

  const submit = handleSubmit(saveDraw); 

  return {
    register,
    errors,
    setValue,
    getValues,
    submit,
    loading,id
  };
};

export default useData;
