import React from "react";
import SearchBillerForm from "./SearchBillerForm";
import CmtCard from "@coremat/CmtCard";
import CmtCardHeader from "@coremat/CmtCard/CmtCardHeader";
import CmtCardContent from "@coremat/CmtCard/CmtCardContent";
import {ExpandMore} from "@material-ui/icons";
import {styled} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import SearchIcon from "@material-ui/icons/Search";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";

const SearchBiller = ({
                          title,
                          titleProps,
                          lotteryList,
                          className,
                          getValues,
                          setValue,
                          onSearch,
                          onReset,
                          register,
                          errors,
                          onDownload,
                          status,
                          expanded,
                          handleExpandClick,
                      }) => {

    const ExpandMore = styled((props) => {
        const {expand, ...other} = props;
        return <IconButton {...other} style={{padding: '2px'}} />;
    })(({theme, expand}) => ({
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    }));

    return (
        <CmtCard className={className}>
            <CmtCardHeader title={title} titleProps={titleProps} style={{padding: '10px'}}>
                <ExpandMore
                    expand={expanded}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show more"
                >
                    {
                        !expanded ? <SearchIcon/> : <ExpandLessIcon/>
                    }
                </ExpandMore>
            </CmtCardHeader>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CmtCardContent>
                    <SearchBillerForm
                        lotteryList={lotteryList}
                        getValues={getValues}
                        onSearch={onSearch}
                        onReset={onReset}
                        register={register}
                        setValue={setValue}
                        errors={errors}
                        onDownload={onDownload}
                        status={status}
                    />
                </CmtCardContent>
            </Collapse>
        </CmtCard>
    );
};

export default SearchBiller;
