import { darken, lighten, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => {
  const getBackgroundColor = (color) =>
    theme.palette.mode === "dark"
      ? darken(color, 0.6)
      : lighten(color, 0.6);

  const getHoverBackgroundColor = (color) =>
    theme.palette.mode === "dark"
      ? darken(color, 0.5)
      : lighten(color, 0.5);

  return {
    grid: {
      height: "640px",
      padding: theme.spacing(2),
      width: "100%",
      "& .with-winning--present": {
        backgroundColor: getBackgroundColor(theme.palette.info.main),
        "&:hover": {
          backgroundColor: getHoverBackgroundColor(theme.palette.info.main),
        },
      },
    },
  };
});

export default useStyles;
