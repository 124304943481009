import React from "react";
import {CircularProgress, Box, Typography} from "@material-ui/core";
import useStyles from "./Loader.styles";

const Loader = props => {
  const classes = useStyles();

  return (
    <Box position="relative" display="flex">
      <CircularProgress className={classes.progress} />
      {props.value ? (
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography
            variant="caption"
            component="div"
            color="textSecondary"
          >{`${Math.round(props.value)}%`}</Typography>
        </Box>
      ) : null}
    </Box>
  );
};

export default Loader;
