import {makeStyles} from "@material-ui/core/styles";

import {useFluidModelContent} from "../../uielements/Dialog.styles";

const useStyles = makeStyles(theme => ({
  ...useFluidModelContent(theme),
  paper: {
    padding: "15px",
    width: "100%"
  }
}));

export default useStyles;
